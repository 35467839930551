import webdriverio from './assets/images/webdriverio.svg';
import playwright from './assets/images/playwright.svg';
import puppeteer from './assets/images/puppeteer.svg';
import webdriverjs from './assets/images/webdriverjs.svg';
import cypress from './assets/images/cypress.svg';

export const AUTOMATIONS = {
  CYPRESS: 'Cypress',
  WEBDRIVERJS: 'WebDriverJS',
  PLAYWRIGHT: 'Playwright',
  PLAYWRIGHT_TEST: 'Playwright Test',
  PUPPETEER: 'Puppeteer',
  WEBDRIVERIO: 'WebdriverIO',
  WEBDRIVERIOTESTRUNNER: 'WebdriverIO Testrunner'
};

type Automations = typeof AUTOMATIONS;

export type AutomationKey = keyof Automations;
export type Automation = Automations[AutomationKey];

export const AUTOMATION_IMAGES: Record<Automation, string> = {
  [AUTOMATIONS.CYPRESS]: cypress,
  [AUTOMATIONS.PLAYWRIGHT]: playwright,
  [AUTOMATIONS.PLAYWRIGHT_TEST]: playwright,
  [AUTOMATIONS.PUPPETEER]: puppeteer,
  [AUTOMATIONS.WEBDRIVERIO]: webdriverio,
  [AUTOMATIONS.WEBDRIVERIOTESTRUNNER]: webdriverio,
  [AUTOMATIONS.WEBDRIVERJS]: webdriverjs
};

export interface ProjectInfo {
  automation: Automation;
  name: string;
}

export interface SeverityCounts {
  totalCurrent: number;
  totalLast: number;
  numNew: number;
  numFixed: number;
}

export type SeveritiesType = 'critical' | 'serious' | 'moderate' | 'minor';
export type AdditionalFiltersType = 'new_issues';

export type PageType = 'git' | 'gitless' | 'branch';

export type Impact = SeveritiesType | AdditionalFiltersType;
export type Impacts = ReadonlyArray<Impact>;
export const IMPACTS: Impacts = [
  'critical',
  'serious',
  'moderate',
  'minor',
  'new_issues'
];

export enum SourceAppType {
  Integration = 'integration', // Refers to the package @axe-core/watcher
  Api = 'api', // Refers to the axe devtools for web API's e.g. @axe-devtools-npm/playwright
  Cli = 'cli' // Refers to the package @axe-devtools-npm/cli
}

export enum AutomationSupportedLanguages {
  Javascript = 'javascript',
  Typescript = 'typescript'
}

/** FEATURE FLAGS */

export const AXE_WATCHER_PAID_PLANS_V1 = 'axe_devtools_watcher_paid_plans_v1';
export const AXE_DEVHUB_STARTUP_V3 = 'axe_devhub_startup_v3';
export const AXE_DEVHUB_STARTUP_CLI = 'axe_devhub_startup_cli';
export const AXE_DEVHUB_STARTUP_APIS = 'axe_devhub_startup_apis';
export const AXE_DEVHUB_RESULTEMPTYSTATE = 'axe_devhub_resultemptystate';
export const AXE_DEVHUB_POLLING_OVERVIEWS_V1 =
  'axe_devhub_polling_overviews_v1';
export const AXE_DEVHUB_POLLING_BRANCHES_OVERVIEW_V1 =
  'axe_devhub_polling_branches_overview_v1';

export type ExportFormat = 'json' | 'csv';

export const MIN_OVERVIEW_POLLING_TIMEOUT_MS = 2000;
export const MAX_OVERVIEW_POLLING_TIMEOUT_MS = 1024000; // 17 minutes
export const AXE_DEVHUB_SHOW_SESSION_END_TIMEOUT =
  'axe_devhub_sessionend_timeout';
