const round = (number: number, maxDecimalPlaces: number): number => {
  // We are deliberately using 'en-US' here since we are only
  // relying on Intl.NumberFormat for its rounding abilities
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: maxDecimalPlaces,
    useGrouping: false
  });

  return Number(formatter.format(number));
};

export default round;
