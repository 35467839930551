import type { StripeTypes } from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';

export default {
  list(token: string): Promise<StripeTypes.Invoice[]> {
    return apiRequest({
      endpoint: '/api/billing/me/invoices',
      token
    });
  },
  get(token: string, invoiceId: string): Promise<StripeTypes.Invoice> {
    return apiRequest({
      endpoint: `/api/billing/me/invoices/${invoiceId}`,
      token
    });
  }
};
