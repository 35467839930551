import React, {
  ReactElement,
  useRef,
  useState,
  useEffect,
  useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  analyticsEvent,
  dquTransactionNotification,
  DquTransactionNotificationParams,
  HttpError,
  updateAccount
} from '../../common/api-client';
import billingClient, {
  Subscriptions
} from '../../common/utils/billing-client/client-v1';
import billingClientV2 from '../../common/utils/billing-client/client-v2';
import {
  UserWithKeycloakId,
  v2,
  StripeTypes,
  TaxId
} from '@deque/billing-service-client';
import {
  CreatePaymentMethodData,
  PaymentIntent,
  PaymentMethod,
  Stripe,
  StripeError,
  StripeAddressElementChangeEvent
} from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Loader, Toast } from '@deque/cauldron-react';
import {
  listProductAccess,
  taxIdTypes,
  formatPrices
} from '@deque/billing-utils';
import { useAuthContext } from '../../common/contexts/auth';
import { useProducts } from '../../common/contexts/products';
import { useEnterprises } from '../../common/contexts/enterprises';
import { useHistory, useLocation } from 'react-router-dom';
import getPrices from '../../common/utils/get-prices';
import getPlans from '../../common/utils/get-plans';
import getStripeSubscription from '../../common/utils/get-stripe-subscription';
import PaymentConfirmForm from '../components/PaymentConfirmForm';
import validate from '../utils/validate-checkout-form';
import {
  useAxeAccountAnalytics,
  useAxeDevtoolsProAnalytics
} from '../../common/contexts/analytics';
import sendPlanChange from '../../common/utils/send-plan-change-to-extension';
import isValidLicenseCount from '../utils/is-valid-license-count';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import {
  HubSpotProperties,
  getProductUrl,
  ProductSlugs
} from '../../common/constants';
import round from '../../common/utils/round';
import PageTitle from '../../common/components/PageTitle';
import PaymentForm, { Errors, RadioItem } from '../components/PaymentForm';
import { useGlobalToast } from '../../common/contexts/globalToast';
import type {
  PreviewInvoice,
  Subscription,
  TaxIds,
  TaxIdType
} from '@deque/billing-utils';
import useV2ListLicenses from '../../common/hooks/useV2ListLicenses';
import ErrorMessage from '../components/issue/ErrorMessage';
import { PURCHASE_QUERY_NAME } from '../../common/hooks/usePurchaseNotification';

export type TaxIdWithValue = TaxIds & { value: string };

const isUnknownObject = (
  raw: unknown
): raw is { [key in PropertyKey]: unknown } => {
  return raw !== null && typeof raw === 'object';
};

const isPaymentIntent = (raw: unknown): raw is StripeTypes.PaymentIntent => {
  return (
    isUnknownObject(raw) &&
    typeof raw.object === 'string' &&
    raw.object === 'payment_intent'
  );
};

const isInvoice = (raw: unknown): raw is StripeTypes.Invoice => {
  return (
    isUnknownObject(raw) &&
    typeof raw.object === 'string' &&
    raw.object === 'invoice'
  );
};

interface PurchaseProps {
  title: string;
  // `paymentOnly` is truthy on the `/addpayment` page in which we are only
  // adding a payment rather than paying and creating a subscription
  paymentOnly?: boolean;
  initialWho?: Who;
}

interface PromoCodeToast {
  type: 'caution' | 'confirmation';
  message: string;
}

export type Who = 'single-me' | 'single-other' | 'multiple';

export const PURCHASE_SUCCESS_PATH = '?purchase-success=true';

const DEFAULT_WHO = 'single-me';

const initialTaxId = taxIdTypes.find(
  taxId => taxId.type === 'us_ein'
) as TaxIds;

const Purchase = ({
  title,
  paymentOnly = false,
  initialWho = DEFAULT_WHO
}: PurchaseProps): ReactElement => {
  const { t } = useTranslation();
  const analytics = useAxeAccountAnalytics();
  const axeDevToolsProAnalytics = useAxeDevtoolsProAnalytics();
  const { billingUser: v2BillingUser, updateBillingUser } = useAuthContext();
  const { products } = useProducts();
  const { activeEnterprise, isAdmin } = useEnterprises();
  const hasMultiBuyV1 = useFeatureFlagState('multi_buy_v1');
  const hasPaymentsV2 = useFeatureFlagState('payments_v2');
  const hasTaxIdV1 = useFeatureFlagState('tax_id_v1');
  const history = useHistory();
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const { setContents } = useGlobalToast();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Errors>({});
  const [billingUser, setBillinguser] = useState<UserWithKeycloakId>();
  const [amount, setAmount] = useState('');
  const [currentLicenseCount, setCurrentLicenseCount] = useState(1);
  const [promoAmount, setPromoAmount] = useState<string>();
  const [discountAmount, setDiscountAmount] = useState<string>();
  const [promoPercent, setPromoPercent] = useState<string>();
  const [promoOpen, setPromoOpen] = useState(false);
  const [billingPeriod, setBillingPeriod] = useState('yearly');
  const [product, setProduct] = useState<v2.Product>();
  const [monthlyPrice, setMonthlyPrice] = useState<StripeTypes.Price>();
  const [yearlyPrice, setYearlyPrice] = useState<StripeTypes.Price>();
  const [savings, setSavings] = useState(0);
  const [subscription, setSubscription] = useState<StripeTypes.Subscription>();
  const [chosenPrice, setChosenPrice] = useState<string>('');
  const [chosenWho, setChosenWho] = useState<Who>(initialWho);
  const [subscribing, setSubscribing] = useState(false);
  const [isRetry, setRetry] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [applying, setApplying] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [fullNameText, setFullNameText] = useState<string | undefined | null>();
  const [companyText, setCompanyText] = useState<string>();
  const [stripeCoupon, setStripeCoupon] = useState<StripeTypes.Coupon>();
  const [couponName, setCouponName] = useState<string>();
  const [promoCodeToast, setPromoCodeToast] = useState<PromoCodeToast>();
  const [initialCouponCode, setInitialCouponCode] = useState<string>();
  const [previewInvoice, setPreviewInvoice] = useState<PreviewInvoice>();
  const [address, setAddress] = useState<StripeTypes.Address>();
  const [taxIdOpen, setTaxIdOpen] = useState(false);
  const [country, setCountry] = useState<string>();
  const [selectedTaxId, setSelectedTaxId] = useState<TaxIdWithValue>({
    ...initialTaxId,
    value: ''
  });

  const [showConfirmRemoveMembers, setShowConfirmRemoveMembers] =
    useState(false);
  const [assignedLicenseCount, setAssignedLicenseCount] = useState<
    number | null
  >(null);
  const fullName = useRef<HTMLInputElement>(null);
  const company = useRef<HTMLInputElement>(null);
  const taxIdValueRef = useRef<HTMLInputElement>(null);
  const taxIdTypeRef = useRef<HTMLSelectElement>(null);
  const taxIdCheckboxRef = useRef<HTMLInputElement>(null);
  const coupon = useRef<HTMLInputElement>(null);
  const promoCheckbox = useRef<HTMLInputElement>(null);
  const agreeCheckbox = useRef<HTMLInputElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const removePromoRef = useRef<HTMLButtonElement>(null);
  const submitRef = useRef<HTMLButtonElement>(null);
  const licenseCountRef = useRef<HTMLInputElement>(null);
  const authContext = useAuthContext();
  const userCountText = useMemo((): string => {
    if (!hasMultiBuyV1) {
      return '';
    }

    return chosenWho !== 'multiple' || currentLicenseCount === 1
      ? t('(1 user)')
      : t('({{count}} users)', {
          count: currentLicenseCount
        });
  }, [chosenWho, currentLicenseCount]);

  // There will always be a user with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = authContext.user!;

  const {
    licenses,
    loading: licensesLoading,
    error: licensesError
  } = useV2ListLicenses(user.token, {
    // TODO: might need changes when we support multiple enterprises for one user
    enterpriseId: activeEnterprise?.id || ''
  });

  const calculateCost = () => {
    if (!stripeCoupon || !product) {
      return;
    }
    const discountMultiplier = (stripeCoupon.percent_off || 0) / 100;
    const amountOff = (stripeCoupon.amount_off || 0) / 100;

    const price =
      yearlyPrice && yearlyPrice.id === chosenPrice
        ? yearlyPrice
        : monthlyPrice;
    /* istanbul ignore next */
    if (!price || !price.unit_amount) {
      return;
    }

    // Notes:
    // - Stripe applies the discount to the overall purchase, not each individual license
    // - To calculate the discounted amount, Stripe will do subtotal - roundToTwoDecimals(subtotal * discountMultiplier).
    //   INSTEAD of roundToTwoDecimals(subtotal * (1 - discountMultiplier)), which can produce different results based on
    //   when the rounding happens.
    const subtotal = round((currentLicenseCount * price?.unit_amount) / 100, 2);
    const amountToDiscount =
      round(subtotal * discountMultiplier, 2) + amountOff;
    const priceWithDiscount = subtotal - amountToDiscount;

    setDiscountAmount(formatPrices(amountToDiscount) || '');
    setPromoAmount(formatPrices(priceWithDiscount) || '');
    setPromoPercent(stripeCoupon.percent_off?.toString() || undefined);
    setCouponName(stripeCoupon.name || stripeCoupon.id);
  };

  const matchesSubscriptionStatus = (
    statuses: string[],
    axe_subscription?: StripeTypes.Subscription
  ): boolean => {
    if (!axe_subscription) {
      return false;
    }
    if (!isInvoice(axe_subscription.latest_invoice)) {
      return false;
    }
    /* istanbul ignore if */
    if (!isPaymentIntent(axe_subscription.latest_invoice.payment_intent)) {
      return false;
    }
    if (
      !statuses.includes(axe_subscription.latest_invoice.payment_intent.status)
    ) {
      return false;
    }
    return true;
  };

  const searchParams = new URLSearchParams(location.search);
  const productParam = searchParams.get('product');
  const productPlanParam = searchParams.get('plan') || undefined;
  const productSlug =
    productParam &&
    Object.values(ProductSlugs).includes(productParam as ProductSlugs)
      ? productParam
      : ProductSlugs.axeDevToolsExtension;
  const { planSlugs } = getPlans(products, productSlug);
  const planSlug =
    productPlanParam &&
    planSlugs &&
    Object.values(planSlugs).includes(productPlanParam)
      ? productPlanParam
      : undefined;

  useEffect(() => {
    if (!confirming) {
      analytics.viewPaymentPage();
    }

    const getData = async () => {
      if (!user || !v2BillingUser) {
        return;
      }
      if (billingUser) {
        return;
      }
      try {
        const [bUser, paymentMethods, bProducts] = await Promise.all([
          billingClient.me.get(user.token),
          billingClient.users.listPaymentMethods(user.token),
          billingClientV2.products.list()
        ]);
        const {
          product: prod,
          yearlyPrice: yPrice,
          monthlyPrice: mPrice
        } = getPrices(bProducts, productSlug, planSlug);
        setProduct(prod);
        setYearlyPrice(yPrice);
        setMonthlyPrice(mPrice);

        const stripeSubscription = getStripeSubscription(
          bUser,
          !paymentOnly ? prod?.stripe_id : undefined
        );

        const stripeSubscriptions: Subscription[] =
          activeEnterprise?.subscriptions?.filter(sub => sub.stripe_id) ||
          v2BillingUser.subscriptions?.filter(sub => sub.stripe_id) ||
          [];

        const hasActiveOnlineSubscription = !!stripeSubscriptions.some(
          (sub: Subscription) =>
            ['paid', 'past_due'].includes(
              sub.latest_invoice?.payment_status || ''
            )
        );

        const hasSubscriptionRequiringAction = !!(
          stripeSubscription &&
          (matchesSubscriptionStatus(
            ['requires_action', 'requires_payment_method'],
            stripeSubscription
          ) ||
            ['past_due'].includes(stripeSubscription.status))
        );

        const hasDefaultPaymentMethod = !!(isAdmin
          ? activeEnterprise?.default_payment_method_id
          : v2BillingUser.default_payment_method_id);

        const canUpdatePaymentMethod = !!(
          !hasDefaultPaymentMethod && stripeSubscriptions.length > 0
        );

        const canMakePurchase =
          !hasActiveOnlineSubscription ||
          hasSubscriptionRequiringAction ||
          !stripeSubscription;

        /**
         * @remarks
         *
         * A user can only have one payment method and we do not currently allow users to update the PM;
         * If a user would like to add a new payment method, they would need to remove the old one first.
         * Using `window.location.assign` is necessary here as state was not being updated via `history.push`.
         */

        /** Redirect user who already has a default payment method and `active` or `past_due` subscription to `/billing` **/
        if (!canUpdatePaymentMethod && !canMakePurchase) {
          window.location.assign('/billing');
          return;
        }

        /** Redirect user who has no default payment method, but has an active subscription to `/addpayment` **/
        if (!paymentOnly && canUpdatePaymentMethod && !canMakePurchase) {
          window.location.assign('/addpayment');
          return;
        }

        /** Redirect user who has no payment method and no active subscription OR who needs to take corrective action to complete a purchase to `/purchase`  **/
        if (paymentOnly && !canUpdatePaymentMethod) {
          window.location.assign('/billing');
        }

        const customerPrice = stripeSubscription?.items.data[0].price;

        if (
          matchesSubscriptionStatus(['requires_action'], stripeSubscription) &&
          stripeSubscription &&
          isInvoice(stripeSubscription.latest_invoice) &&
          isPaymentIntent(stripeSubscription.latest_invoice.payment_intent) &&
          bUser &&
          paymentMethods &&
          paymentMethods.length
        ) {
          const lookFor =
            stripeSubscription.latest_invoice.payment_intent.payment_method;
          let pm = paymentMethods.find(p => p.id === lookFor);
          /* istanbul ignore else */
          if (!pm) {
            // just take the newest one
            pm = paymentMethods.reduce((prev, curr) => {
              if (!prev || curr.created > prev.created) {
                return curr;
              }
              return prev;
            });
          }
          /* istanbul ignore else */
          if (pm) {
            setPaymentMethod(pm as PaymentMethod);
            setConfirming(true);
          }
        }

        setBillinguser(bUser);
        setFullNameText(bUser.name);
        setCompanyText(user.company);

        const currentTaxIds =
          bUser.enterprise_stripe_data?.tax_ids?.data || bUser?.tax_ids?.data;

        if (hasTaxIdV1 && currentTaxIds?.length) {
          const { placeholder, countryCode, description } =
            taxIdTypes.find(
              taxId =>
                taxId.type === currentTaxIds[0].type &&
                taxId.countryCode === currentTaxIds[0].country
            ) || initialTaxId;

          setTaxIdOpen(true);
          setSelectedTaxId({
            ...currentTaxIds[0],
            placeholder,
            countryCode,
            description
          });
        }

        const currentAddress =
          v2BillingUser?.enterprises[0]?.address || v2BillingUser?.address;

        if (currentAddress) {
          setAddress({
            ...address,
            ...currentAddress
          });

          if (currentAddress.country) {
            setCountry(currentAddress.country);
          }
        }

        setSubscription(stripeSubscription);

        if (customerPrice) {
          setChosenPrice(customerPrice.id);
        } else if (yPrice?.id || mPrice?.id) {
          setChosenPrice(yPrice?.id || mPrice?.id || '');
        }

        const promoCode = searchParams.get('promoCode');
        if (!paymentOnly && promoCode && prod?.stripe_id) {
          try {
            const couponValid =
              await billingClient.discounts.validateWithProduct(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                user.token!,
                promoCode,
                prod.stripe_id
              );

            if (!couponValid.coupon) {
              throw new Error('Coupon not found');
            }

            setStripeCoupon(couponValid.coupon);
            setInitialCouponCode(promoCode);
            setPromoCodeToast({
              type: 'confirmation',
              message: t('Promo code {{promoCode}} applied!', { promoCode })
            });
          } catch {
            setPromoCodeToast({
              type: 'caution',
              message: t('Invalid promo code {{promoCode}}', { promoCode })
            });
          }
        }
      } catch (err) {
        /** This makes sure if a logged in user tries to access /addpayment before completing the FirstLoginAlert, it will redirect them to /purchase instead of rendering the addpayment page. **/
        if (paymentOnly && (err as HttpError).status === 404) {
          window.location.assign('/purchase');
          return;
        }

        if ((err as HttpError).status === 401) {
          authContext.login(); // should redirect
        }

        setErrors({
          alert: t('Could not load products or user information')
        });
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [user, v2BillingUser]);

  interface PriceTuple {
    price: StripeTypes.Price | undefined;
    recurrence: string | undefined;
  }

  useEffect(() => {
    if (!subscription && chosenPrice && (monthlyPrice || yearlyPrice)) {
      const year = {
        price: yearlyPrice,
        recurrence: 'yearly'
      };
      const month = {
        price: monthlyPrice,
        recurrence: 'monthly'
      };
      const priceChoices = [year, month];
      const resolved = priceChoices.reduce<PriceTuple>(
        (value, current) => {
          if (value.recurrence) {
            return value;
          }
          if (current.price && current.price.id === chosenPrice) {
            return current;
          }
          return value;
        },
        { price: yearlyPrice, recurrence: undefined }
      );
      const radioValue = resolved.recurrence || 'yearly';
      const price = resolved.price;

      /* istanbul ignore next */
      if (!radioValue) {
        // this will cause this effect to be called again
        if (yearlyPrice) {
          setChosenPrice(yearlyPrice.id);
        } else if (monthlyPrice) {
          setChosenPrice(monthlyPrice.id);
        }
        return;
      }
      const unitAmount = price && price.unit_amount && price.unit_amount / 100;
      const monthAmount =
        monthlyPrice &&
        monthlyPrice.unit_amount &&
        monthlyPrice.unit_amount / 100;

      const priceAmount = unitAmount && unitAmount * currentLicenseCount;

      setAmount(formatPrices(priceAmount) || '');
      setSavings(
        radioValue === 'yearly' && unitAmount && monthAmount
          ? ((monthAmount * 12 - unitAmount) * 100) / (monthAmount * 12)
          : 0
      );
      setBillingPeriod(radioValue);
      calculateCost();
    }
  }, [
    chosenPrice,
    monthlyPrice,
    yearlyPrice,
    subscription,
    currentLicenseCount,
    previewInvoice
  ]);

  useEffect(() => {
    calculateCost();
  }, [stripeCoupon]);

  useEffect(() => {
    if (subscription && isInvoice(subscription.latest_invoice)) {
      setAmount(
        formatPrices(subscription.latest_invoice?.amount_remaining / 100) || ''
      );
      const interval =
        subscription.items?.data[0]?.price.recurring?.interval || 'year';
      setBillingPeriod(`${interval}ly`); // to convert month | year to monthly | yearly
      setCurrentLicenseCount(subscription.items?.data[0]?.quantity || 1);
    }
  }, [subscription]);

  useEffect(() => {
    /* istanbul ignore if */
    if (applying && loaderRef.current) {
      return loaderRef.current.focus();
    }
    /* istanbul ignore else */
    if (!promoAmount) {
      return coupon.current?.focus();
    }
    // ignoring the situation where current is not defined
    /* istanbul ignore next */
    return removePromoRef.current?.focus();
  }, [applying]);

  useEffect(() => {
    if (calculating && loaderRef.current) {
      return loaderRef.current.focus();
    }
  }, [calculating]);

  useEffect(() => {
    if (subscribing) {
      return loaderRef.current?.focus();
    }
    // failed validation and the validator should focus the right thing
  }, [subscribing]);

  // check if the admin has existing enterprise and enterprise axe subscription
  useEffect(() => {
    if (activeEnterprise && activeEnterprise.subscriptions?.length) {
      const license = licenses?.find(l => l.product_id === product?.id);

      if (license) {
        setAssignedLicenseCount(license.used + license.pending);
        setChosenWho('multiple');
      }
    }
  }, [activeEnterprise, product, licenses]);

  const getPreviewInvoice = async (
    elementValue: StripeAddressElementChangeEvent['value']
  ): Promise<PreviewInvoice | undefined> => {
    /* istanbul ignore next */
    if (!elementValue || !hasPaymentsV2) {
      return;
    }
    setCalculating(true);

    try {
      const {
        city,
        country: elementCountry,
        line1,
        line2,
        postal_code,
        state
      } = elementValue.address;

      const invoice: PreviewInvoice = await billingClientV2.invoices.getPreview(
        {
          price_id: chosenPrice,
          license_count: currentLicenseCount,
          coupon_id: stripeCoupon?.id,
          address: {
            city: city,
            country: elementCountry,
            line1: line1,
            line2: line2 || undefined,
            postal_code: postal_code,
            state: state
          },
          ...(!!(hasTaxIdV1 && selectedTaxId.value && selectedTaxId.type) && {
            tax_id: {
              type: selectedTaxId.type,
              value: selectedTaxId.value
            }
          })
        },
        user.token
      );
      setPreviewInvoice(invoice);
      return invoice;
    } catch (err: unknown) {
      const isTaxIdInvalidError =
        (err as HttpError).status === 400 &&
        (err as HttpError).message === 'tax_id_invalid';

      if (isTaxIdInvalidError && hasTaxIdV1) {
        setErrors({ taxId: 'Invalid tax ID' });
      } else {
        setErrors({
          previewInvoice: 'Something went wrong. Please try again.'
        });
      }
    } finally {
      setCalculating(false);
    }
    return;
  };

  const onPaymentComplete = async () => {
    let additionalRedirectParam = '';
    try {
      const subscriptions = activeEnterprise
        ? activeEnterprise.subscriptions
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          v2BillingUser!.subscriptions;
      const access = listProductAccess(subscriptions);

      const referrerEmail = searchParams.get('referrerEmail');
      if (referrerEmail) {
        await Promise.all([
          analyticsEvent(
            user.token,
            HubSpotProperties.REFERRER_EMAIL,
            referrerEmail
          ),
          analyticsEvent(user.token, HubSpotProperties.REFERRAL_PURCHASE_DATE)
        ]);
      }
      if (
        ['trialing', 'free', 'trial_ended', 'none'].includes(
          access[ProductSlugs.axeDevToolsExtension]
        )
      ) {
        const originalLandingPage =
          axeDevToolsProAnalytics.distinctIdCookie?.data?.original_landing_page;
        const originalUtmSource =
          axeDevToolsProAnalytics.distinctIdCookie?.data?.original_utm_source;

        await Promise.all([
          axeDevToolsProAnalytics.clickPurchase(),
          product?.slug === ProductSlugs.axeDevToolsExtension
            ? analyticsEvent(user.token, HubSpotProperties.AXE_PAID_DATE)
            : null,
          originalLandingPage
            ? analyticsEvent(
                user.token,
                HubSpotProperties.ORIGINAL_LANDING_PAGE,
                originalLandingPage as string,
                'NONE'
              )
            : null,
          originalUtmSource
            ? analyticsEvent(
                user.token,
                HubSpotProperties.ORIGINAL_UTM_SOURCE,
                originalUtmSource as string,
                'NONE'
              )
            : null
        ]);
      }
      if (product?.slug === ProductSlugs.dequeUniversity) {
        const params: DquTransactionNotificationParams = {
          newSubscription: true,
          priceId: chosenPrice,
          quantity: currentLicenseCount
        };
        await dquTransactionNotification(user.token, params);
      }
    } catch (e) {
      /* fail silently */
    }

    // Add a special URL parameter to show Toast on Purchase completion
    if (product?.slug === ProductSlugs.dequeUniversity) {
      additionalRedirectParam = `&${PURCHASE_QUERY_NAME}=${product?.slug}`;
    }

    const welcomeURL =
      product && getProductUrl(product.slug as ProductSlugs, 'welcome');
    window.location.assign(
      `${welcomeURL || '/'}${PURCHASE_SUCCESS_PATH}${additionalRedirectParam}`
    );
  };

  const handlePaymentThatRequiresCustomerAction = (
    sub: StripeTypes.Subscription
  ) => {
    if (
      !sub ||
      !sub.latest_invoice ||
      sub.status === 'active' ||
      !isInvoice(sub.latest_invoice) ||
      !isPaymentIntent(sub.latest_invoice.payment_intent)
    ) {
      return sub;
    }
    const paymentIntent = sub.latest_invoice.payment_intent;
    if (paymentIntent.status === 'requires_action') {
      return (stripe as Stripe)
        .confirmCardPayment(paymentIntent.client_secret as string, {
          payment_method: paymentMethod && paymentMethod.id
        })
        .then(
          (result: { paymentIntent?: PaymentIntent; error?: StripeError }) => {
            /* istanbul ignore else*/
            if (result.error) {
              // start code flow to handle updating the payment details
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              setSubscription(sub);
              throw result;
            } else if (result.paymentIntent) {
              /* istanbul ignore else*/
              if (result.paymentIntent.status === 'succeeded') {
                // There's a risk of the customer closing the window before callback
                // execution. To handle this case, set up a webhook endpoint and
                // listen to invoice.payment_succeeded. This webhook endpoint
                // returns an Invoice.
              }
            }
            return sub;
          }
        );
    }
    return sub;
  };

  const handleRequiresPaymentMethod = (sub: StripeTypes.Subscription) => {
    if (
      !sub ||
      !sub.latest_invoice ||
      sub.status === 'active' ||
      !isInvoice(sub.latest_invoice) ||
      !isPaymentIntent(sub.latest_invoice.payment_intent)
    ) {
      return sub;
    }
    if (
      sub.latest_invoice.payment_intent.status === 'requires_payment_method'
    ) {
      setSubscription(sub);
      throw new Error('Your card was declined');
    }
    return sub;
  };

  const createNewSubscription = async () => {
    const canCreateNewSubscription = billingUser && user && paymentMethod;

    if (!canCreateNewSubscription) {
      return;
    }

    const paymentMethodId = paymentMethod?.id;
    const hasTaxId = !!(selectedTaxId.value && selectedTaxId.type && taxIdOpen);
    const subscriptionParams: Subscriptions.CreateParams = {
      token: user.token,
      price_id: chosenPrice,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      payment_method_id: paymentMethodId!,
      coupon: stripeCoupon?.id,
      ...(hasTaxId && {
        tax_id: { type: selectedTaxId.type, value: selectedTaxId.value }
      }),
      product_slug: product?.slug as ProductSlugs
    };
    const isForOthers = chosenWho !== 'single-me';

    if (isForOthers) {
      subscriptionParams.licenses = currentLicenseCount;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const companyName = companyText || user.company!;

      try {
        // allow user to update enterprise name if an enterprise already exists
        if (activeEnterprise) {
          await billingClient.enterprises.update({
            token: user.token,
            enterpriseId: activeEnterprise.id,
            name: companyName
          });
        } else {
          await billingClientV2.enterprises.create({
            token: user.token,
            name: companyName,
            admin_keycloak_id: user.id,
            offline: false
          });
        }
      } catch (err) {
        if ((err as HttpError)?.status === 409) {
          setErrors({
            company: t(
              'Company name currently unavailable. Please choose a different name.'
            )
          });
        } else {
          setErrors({
            alert: t('Something went wrong. Please try again.')
          });
        }

        // We've errored before even creating the subscription (see below).
        // Reset the retry state so the `handlePaySubscription` knows to try everything again
        setRetry(false);
        setConfirming(false);
        setSubscribing(false);
        setCalculating(false);

        return;
      }
    }

    billingClient.subscriptions
      .create(subscriptionParams)
      .then(userWithSubscription => {
        /* istanbul ignore else */
        if (userWithSubscription.subscriptions?.data.length) {
          return userWithSubscription.subscriptions.data[0];
        }
        /* istanbul ignore next */
        throw new Error(t('Subscription was not created'));
      })
      .then(handlePaymentThatRequiresCustomerAction)
      .then(handleRequiresPaymentMethod)
      .then(onPaymentComplete)
      .catch((error: HttpError) => {
        if (error.status === 401) {
          authContext.login(); // should redirect
        } else if (
          hasTaxIdV1 &&
          error.status === 400 &&
          error.message.includes('tax_id_invalid')
        ) {
          setErrors({
            taxId: t('Invalid Tax ID value')
          });
          setRetry(false);
          setConfirming(false);
        } else {
          setErrors({
            billing:
              error.message ||
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (error as any).error.decline_code ||
              t('Your card verification failed')
          });
          sendPlanChange();
        }
      })
      .finally(() => {
        setSubscribing(false);
      });
  };

  const payExistingSubscription = () => {
    /* istanbul ignore else */
    if (billingUser && user && paymentMethod && subscription) {
      billingClient.subscriptions
        .pay(user.token, subscription.id, paymentMethod.id)
        .then(handlePaymentThatRequiresCustomerAction)
        .then(handleRequiresPaymentMethod)
        .then(onPaymentComplete)
        .catch((error: HttpError) => {
          if (error.status === 401) {
            authContext.login(); // should redirect
          } else {
            setErrors({
              billing:
                error.message ||
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (error as any).error.decline_code ||
                t('Your card verification failed')
            });
            sendPlanChange();
          }
        })
        .finally(() => {
          setSubscribing(false);
        });
    }
  };

  const payWithAction = (sub: StripeTypes.Subscription) => {
    Promise.resolve(handlePaymentThatRequiresCustomerAction(sub))
      .then(handleRequiresPaymentMethod)
      .then(onPaymentComplete)
      .catch((error: HttpError) => {
        setErrors({
          billing:
            error.message ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (error as any).error.decline_code ||
            t('Your card verification failed')
        });
        sendPlanChange();
      })
      .finally(() => {
        setSubscribing(false);
      });
  };

  const handleRetryThatRequiresCustomerAction = (
    invoice: StripeTypes.Invoice
  ) => {
    const paymentIntent = invoice.payment_intent;
    /* istanbul ignore else*/
    if (
      isPaymentIntent(paymentIntent) &&
      paymentIntent.status === 'requires_payment_method' &&
      paymentIntent.client_secret
    ) {
      return (stripe as Stripe)
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethod && paymentMethod.id
        })
        .then(
          (result: { paymentIntent?: PaymentIntent; error?: StripeError }) => {
            /* istanbul ignore else*/
            if (result.error) {
              // start code flow to handle updating the payment details
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              throw result;
            } else if (result.paymentIntent) {
              /* istanbul ignore else*/
              if (result.paymentIntent.status === 'succeeded') {
                return true;
              }
            }
            // next line ignored because we don't know what could lead to no error but a status of not 'succeeded'
            /* istanbul ignore next */
            return false;
          }
        );
    }
    // not sure what would cause a payment intent that is not one or entry into this function without
    // client_secret and 'requiress_payment_method'
    /* istanbul ignore next */
    return false;
  };

  const retryInvoiceWithNewPaymentMethod = (
    pmId: string,
    subscriptionId: string
  ) => {
    return billingClient.subscriptions
      .retryPayment(user.token, subscriptionId, pmId)
      .then(handleRetryThatRequiresCustomerAction)
      .then(onPaymentComplete)
      .catch(() => {
        // An error has happened. Display the failure to the user here.
        setSubscribing(false);
        setErrors({
          billing: t('Your card verification failed')
        });
        sendPlanChange();
      });
  };

  const handlePaySubscription = async () => {
    if (!isRetry) {
      setRetry(true);

      if (!activeEnterprise && companyText !== user.company && companyText) {
        try {
          await updateAccount(user.id, { company: companyText }, user.token);
        } catch (error) {
          setErrors({
            company: t('Could not update company name')
          });
          setSubscribing(false);
          return;
        }
      }

      if (!subscription) {
        createNewSubscription();
        return;
      }

      if (matchesSubscriptionStatus(['requires_action'], subscription)) {
        payWithAction(subscription);
        return;
      }

      if (
        paymentMethod &&
        matchesSubscriptionStatus(['requires_payment_method'], subscription)
      ) {
        retryInvoiceWithNewPaymentMethod(paymentMethod.id, subscription.id);
        return;
      }
      payExistingSubscription();
      return;
    }
    /* istanbul ignore else */
    if (paymentMethod && subscription) {
      if (matchesSubscriptionStatus(['requires_action'], subscription)) {
        payWithAction(subscription);
        return;
      }
      retryInvoiceWithNewPaymentMethod(paymentMethod.id, subscription.id);
    }
  };

  const handlePaymentOnly = async () => {
    /* istanbul ignore if */
    if (!subscription || !paymentMethod) {
      // cannot even get to the page without a subscription
      // if no payment method, nothing to do (should be impossible to get into that state)
      return;
    }

    try {
      const pm_id =
        typeof paymentMethod === 'string' ? paymentMethod : paymentMethod?.id;

      let tax_id: TaxId | undefined = undefined;

      if (
        hasTaxIdV1 &&
        taxIdOpen &&
        selectedTaxId.value &&
        selectedTaxId.type
      ) {
        tax_id = {
          type: selectedTaxId.type,
          value: selectedTaxId.value
        };
      }

      const pm = await billingClient.users.addPaymentMethod(
        user.token,
        pm_id,
        ...(tax_id ? [tax_id] : [])
      );

      if (!activeEnterprise && companyText !== user.company && companyText) {
        await updateAccount(user.id, { company: companyText }, user.token);
      }

      /* istanbul ignore else */
      if (pm?.id === pm_id) {
        // success
        await updateBillingUser();
        window.location.assign('/billing');
        setContents(null);
        return;
      } else {
        setSubscribing(false);
      }
    } catch (error) {
      if ((error as HttpError).status === 401) {
        authContext.login(); // should redirect
      } else if (
        hasTaxIdV1 &&
        (error as HttpError).status === 400 &&
        (error as HttpError)?.message === 'tax_id_invalid'
      ) {
        setErrors({ taxId: 'Invalid tax ID' });
        setSubscribing(false);
      } else {
        setErrors({
          billing: (error as Error).message || (error as string)
        });
        setSubscribing(false);
      }
    }
  };

  const handleConfirmSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    // Block native form submission.
    event.preventDefault();

    /* istanbul ignore if */
    if (!stripe || !elements) {
      return;
    }

    if (!agreeCheckbox.current?.checked) {
      setErrors({
        agree: t('You must agree to the terms and conditions to continue')
      });
      // ignoring the situation where current is not defined
      /* istanbul ignore next */
      agreeCheckbox.current?.focus();
      return;
    }

    setSubscribing(true);

    // Pay the subscription
    if (paymentOnly) {
      handlePaymentOnly();
    } else {
      handlePaySubscription();
    }
  };

  const createPaymentMethod = async () => {
    const cardElement = elements?.getElement(CardElement);
    const addressElement = hasPaymentsV2
      ? elements?.getElement('address')
      : null;

    /* istanbul ignore if */
    if (
      !stripe ||
      !cardElement ||
      (!hasPaymentsV2 && !fullName?.current) ||
      !company?.current
    ) {
      return;
    }

    if (!hasPaymentsV2) {
      setFullNameText(fullName?.current?.value);
    }

    setCompanyText(company.current.value);

    setSubscribing(true);
    setErrors({ billing: '' });

    try {
      let result;
      if (hasPaymentsV2 && addressElement) {
        result = await addressElement.getValue();

        if (!result.complete) {
          /* istanbul ignore next */
          setErrors({ address: t('Please fill out all address fields') });
        }

        setAddress({ ...address, ...result.value.address });
      } else {
        result = {
          value: {
            name: fullName?.current?.value
          }
        };
      }

      const createPMResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          ...result.value
        }
      } as CreatePaymentMethodData);

      const newPaymentMethod = createPMResult.paymentMethod;

      if (createPMResult.error) {
        setErrors({ billing: createPMResult.error.message });
        setSubscribing(false);
        return;
      }

      setPaymentMethod(newPaymentMethod);

      setConfirming(true);
    } catch {
      setErrors({
        billing: t('Could not create payment method')
      });
    } finally {
      setSubscribing(false);
    }
  };

  const onRemoveMembers = () => {
    setShowConfirmRemoveMembers(false);
    createPaymentMethod();
  };

  const validatePaymentSubmit = async () => {
    /* istanbul ignore if */
    if (
      !stripe ||
      !elements ||
      (!hasPaymentsV2 && !fullName?.current) ||
      !company?.current ||
      (hasTaxIdV1 &&
        taxIdCheckboxRef.current?.checked &&
        !taxIdValueRef.current)
    ) {
      return;
    }

    const validationErrors = validate(
      {
        fullName: fullName.current,
        company: company.current,
        licenseCount: licenseCountRef.current,
        taxIdValue: taxIdValueRef.current,
        taxIdType: taxIdTypeRef.current,
        taxIdOpen: taxIdCheckboxRef.current?.checked,
        hasTaxIdV1,
        hasPaymentsV2
      },
      t
    );

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length) {
      return;
    }

    if (hasPaymentsV2) {
      setSubscribing(true);
      const addressElement = elements.getElement('address');

      if (addressElement) {
        const { complete, value } = await addressElement.getValue();

        if (!complete) {
          setErrors({ address: t('Please fill out all address fields') });
          setSubscribing(false);
          return;
        }

        if (!paymentOnly) {
          const invoice = await calculateTaxAmount(value);

          if (!invoice) {
            setSubscribing(false);
            return;
          }
        }

        setAddress({ ...address, ...value.address });
      }
    }

    // if current license count is less than existing count, show Modal in PaymentForm.
    if (
      !paymentOnly &&
      currentLicenseCount &&
      assignedLicenseCount &&
      currentLicenseCount < assignedLicenseCount
    ) {
      setSubscribing(false);
      return setShowConfirmRemoveMembers(true);
    }

    createPaymentMethod();
  };

  const handlePaymentSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Block native form submission.
    event.preventDefault();
    validatePaymentSubmit();
  };

  const handlePriceChange = (radio: RadioItem) => {
    let price;
    /* istanbul ignore else*/
    if (radio.value === 'monthly' && monthlyPrice) {
      price = monthlyPrice;
    } else if (radio.value === 'yearly' && yearlyPrice) {
      price = yearlyPrice;
    }
    /* istanbul ignore else */
    if (price) {
      calculateCost();
      setChosenPrice(price.id);
    }
  };
  const onWhoChange = (radio: RadioItem) => {
    const value = radio.value as Who;
    setChosenWho(value);
    if (value !== 'multiple') {
      setCurrentLicenseCount(1);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoOpen(e.target.checked);
  };

  const handleApplyPromo = async () => {
    /* istanbul ignore next */
    if (!product) {
      return;
    }
    if (!coupon?.current?.value) {
      setErrors({
        promoCode: t('Please provide a coupon')
      });
      coupon.current?.focus();
      return;
    }
    try {
      setApplying(true);
      const couponValid = await billingClient.discounts.validateWithProduct(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user.token!,
        coupon.current.value,
        product.stripe_id || ''
      );

      if (couponValid.coupon) {
        setStripeCoupon(couponValid.coupon);
        setErrors({});
      } else {
        setErrors({
          promoCode:
            couponValid.error ||
            t('The supplied coupon is not applicable to this product')
        });
        coupon.current?.focus();
      }
    } catch (applyError) {
      if ((applyError as HttpError).status === 401) {
        authContext.login(); // should redirect
      } else {
        /* istanbul ignore next */
        setErrors({
          promoCode: (applyError as Error).message
        });
      }
    } finally {
      setApplying(false);
    }
  };

  const handleRemovePromo = () => {
    /* istanbul ignore else */
    if (!subscription) {
      setApplying(true);
      setStripeCoupon(undefined);
      setDiscountAmount(undefined);
      setPromoAmount(undefined);
      setPromoPercent(undefined);
      setCouponName(undefined);
      setErrors({});
      setApplying(false);
    }
  };

  const clearTaxData = () => {
    /* istanbul ignore next */
    if (!hasPaymentsV2) {
      return;
    }
    setCalculating(true);
    setErrors({});
    setCalculating(false);
  };

  const handleEditClicked = () => {
    setConfirming(false);
    /* istanbul ignore next */
    if (hasPaymentsV2) {
      clearTaxData();
    }
  };

  const calculateTaxAmount = async (
    addressElementValue: StripeAddressElementChangeEvent['value']
  ): Promise<PreviewInvoice | void> => {
    /* istanbul ignore next */
    if (!hasPaymentsV2 || paymentOnly) {
      return;
    }

    setCalculating(true);
    clearTaxData();

    try {
      const invoice = await getPreviewInvoice(addressElementValue);

      setPreviewInvoice(invoice);
      return invoice;
    } catch (err) {
      setErrors({
        previewInvoice: t('Something went wrong. Please try again.')
      });
    } finally {
      setCalculating(false);
    }
  };

  const handleLicenseCountChange = (value: string) => {
    const isValid = isValidLicenseCount(value);

    if (isValid) {
      setCurrentLicenseCount(parseInt(value, 10));
    }
  };

  const handleTaxIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxIdOpen(e.target.checked);
    if (!e.target.checked) {
      setSelectedTaxId({ ...selectedTaxId, value: '' });
    }
  };

  const handleTaxIdTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTaxId({ ...selectedTaxId, type: e.target.value as TaxIdType });
  };

  const handleTaxIdValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedTaxId({ ...selectedTaxId, value: e.target.value });
  };

  const handleCountryChange = (e: StripeAddressElementChangeEvent) => {
    setCountry(e.value.address.country);

    if (hasTaxIdV1) {
      setSelectedTaxId({
        ...selectedTaxId,
        value: '',
        type: taxIdTypes.filter(
          tId => tId.countryCode === e.value.address.country
        )[0].type
      });
    }
  };

  const minorHeading = !paymentOnly
    ? product?.name + (productPlanParam ? ` (${productPlanParam})` : '')
    : '';

  if (licensesError && activeEnterprise && !paymentOnly && !confirming) {
    return (
      <>
        <PageTitle title={title} />
        <ErrorMessage error={licensesError.message} />
      </>
    );
  }

  return (
    <>
      <PageTitle title={title} />
      {promoCodeToast && (
        <Toast show type={promoCodeToast.type}>
          {promoCodeToast.message}
        </Toast>
      )}
      {loading || licensesLoading ? (
        <Loader label={t('Loading...')} ref={loaderRef} tabIndex={-1} />
      ) : !confirming ? (
        <PaymentForm
          onSubmit={handlePaymentSubmit}
          onPriceChange={handlePriceChange}
          onLicenseCountChange={handleLicenseCountChange}
          onWhoChange={onWhoChange}
          onPromoChange={handleCheckboxChange}
          applyPromoClick={handleApplyPromo}
          removePromoClick={handleRemovePromo}
          productName={product?.name}
          productSlug={productSlug}
          planSlug={planSlug}
          defaultFullName={fullNameText}
          defaultCompany={companyText}
          amount={amount}
          discountAmount={discountAmount}
          promoAmount={promoAmount}
          promoPercent={promoPercent}
          couponName={couponName}
          initialCouponCode={initialCouponCode}
          currentLicenseCount={currentLicenseCount}
          promoOpen={promoOpen}
          subscribing={subscribing}
          applying={applying}
          calculating={calculating}
          staticPrice={!!subscription}
          billingPeriod={billingPeriod}
          yearlySavings={savings}
          errors={errors}
          fullName={fullName}
          company={company}
          promoCodeInput={coupon}
          promoCheckbox={promoCheckbox}
          loaderRef={loaderRef}
          removePromoRef={removePromoRef}
          submitRef={submitRef}
          licenseCountRef={licenseCountRef}
          paymentOnly={paymentOnly}
          onCancel={() => {
            history.push('/billing');
          }}
          chosenWho={chosenWho}
          assignedLicenseCount={assignedLicenseCount}
          showConfirmRemoveMembers={showConfirmRemoveMembers}
          onCancelRemoveMembers={() => setShowConfirmRemoveMembers(false)}
          onRemoveMembers={onRemoveMembers}
          defaultAddress={address}
          onTaxIdTypeChange={handleTaxIdTypeChange}
          taxIdOpen={taxIdOpen}
          onTaxIdValueChange={handleTaxIdValueChange}
          onTaxIdCheckboxChange={handleTaxIdChange}
          taxIdCheckboxRef={taxIdCheckboxRef}
          taxIdValueRef={taxIdValueRef}
          taxIdTypeRef={taxIdTypeRef}
          onCountryChange={handleCountryChange}
          country={country}
          selectedTaxId={selectedTaxId}
          hasYearlyPrice={yearlyPrice !== undefined}
          hasMonthlyPrice={monthlyPrice !== undefined}
        />
      ) : (
        <PaymentConfirmForm
          onSubmit={handleConfirmSubmit}
          onEditClicked={handleEditClicked}
          majorHeading={
            subscription || paymentOnly ? t('Review Payment') : t('Review Plan')
          }
          minorHeading={minorHeading}
          defaultFullName={fullNameText}
          defaultCompany={companyText}
          previewInvoice={previewInvoice}
          amount={amount}
          promoAmount={promoAmount}
          discountAmount={discountAmount}
          promoPercent={promoPercent}
          couponName={couponName}
          subscribing={subscribing}
          staticPrice={!!subscription}
          billingPeriod={billingPeriod}
          yearlySavings={savings}
          errors={errors}
          agreeCheckbox={agreeCheckbox}
          paymentMethod={paymentMethod as StripeTypes.PaymentMethod}
          loaderRef={loaderRef}
          submitRef={submitRef}
          paymentOnly={paymentOnly}
          userCountText={userCountText}
          productName={product?.name}
        />
      )}
    </>
  );
};

export default Purchase;
