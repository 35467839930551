import { TFunction } from 'i18next';
import { PasswordRequirements } from './parsePasswordRequirements';

interface ValidateProps {
  currentPassword: HTMLInputElement;
  password: HTMLInputElement;
  confirmPassword: HTMLInputElement;
}

const rDigits = /\d/g;
const rLowerCase = /[a-z]/g;
const rUpperCase = /[A-Z]/g;
const rSpecialCharacters = /[@#!$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;

const countDigits = (s: string): number => s.match(rDigits)?.length || 0;
const countLowerCase = (s: string): number => s.match(rLowerCase)?.length || 0;
const countUpperCase = (s: string): number => s.match(rUpperCase)?.length || 0;
const countSpecialCharacters = (s: string): number =>
  s.match(rSpecialCharacters)?.length || 0;

export interface Errors {
  [n: string]: string;
}

export default function (
  { currentPassword, password, confirmPassword }: ValidateProps,
  passwordRequirements: PasswordRequirements,
  t: TFunction
): Errors {
  let firstErroneousInput: HTMLInputElement | null = null;

  const errors: Errors = {};

  if (currentPassword && password && confirmPassword) {
    if (!currentPassword.value) {
      errors.currentPassword = t('Current password is required');
      firstErroneousInput = firstErroneousInput || currentPassword;
    }

    if (!password.value) {
      errors.password = t('Password is required');
      firstErroneousInput = firstErroneousInput || password;
    }

    if (!confirmPassword.value) {
      errors.confirmPassword = t('Confirm password is required');
      firstErroneousInput = firstErroneousInput || confirmPassword;
    }

    if (
      !errors.currentPassword &&
      !errors.password &&
      currentPassword.value === password.value
    ) {
      errors.password = t(
        'New password must be different from current password'
      );
      firstErroneousInput = firstErroneousInput || password;
    }

    if (
      !errors.password &&
      !errors.confirmPassword &&
      password.value !== confirmPassword.value
    ) {
      errors.password = t('Passwords must match');
      firstErroneousInput = firstErroneousInput || password;
    }

    // Assert the password contains N digits.
    if (!errors.password && passwordRequirements.digits) {
      const count = countDigits(password.value);
      if (count < passwordRequirements.digits) {
        errors.password = t('Password must contain {{n}} digits', {
          n: passwordRequirements.digits
        });
        firstErroneousInput = firstErroneousInput || password;
      }
    }

    // Assert the password is of sufficient length.
    if (!errors.password && passwordRequirements.length) {
      if (password.value.length < passwordRequirements.length) {
        errors.password = t('Password must be {{n}} characters in length', {
          n: passwordRequirements.length
        });
        firstErroneousInput = firstErroneousInput || password;
      }
    }

    // Assert password contains N lower case chars.
    if (!errors.password && passwordRequirements.lowerCase) {
      const count = countLowerCase(password.value);
      if (count < passwordRequirements.lowerCase) {
        errors.password = t(
          'Password must be contain {{n}} lower case characters',
          {
            n: passwordRequirements.lowerCase
          }
        );
        firstErroneousInput = firstErroneousInput || password;
      }
    }

    // Assert password contains N upper case chars.
    if (!errors.password && passwordRequirements.upperCase) {
      const count = countUpperCase(password.value);
      if (count < passwordRequirements.upperCase) {
        errors.password = t(
          'Password must be contain {{n}} upper case characters',
          {
            n: passwordRequirements.upperCase
          }
        );
        firstErroneousInput = firstErroneousInput || password;
      }
    }

    // Assert password contains N special characters.
    if (!errors.password && passwordRequirements.specialCharacters) {
      const count = countSpecialCharacters(password.value);
      if (count < passwordRequirements.specialCharacters) {
        errors.password = t(
          'Password must be contain {{n}} special characters',
          {
            n: passwordRequirements.specialCharacters
          }
        );
        firstErroneousInput = firstErroneousInput || password;
      }
    }
  }

  firstErroneousInput?.focus();

  return errors;
}
