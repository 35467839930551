import { findJiraFieldByKey } from './findJiraFieldByKey';
import type { JiraFieldMapper, JiraFieldSchema } from './types';

export class SingleOptionMapper implements JiraFieldMapper {
  id = 'jira-single-option-mapper';
  allowedCustomKeys = [
    'com.atlassian.jira.plugin.system.customfieldtypes:radiobuttons',
    'com.atlassian.jira.plugin.system.customfieldtypes:select'
  ];

  canMapThisField(jiraSchema: JiraFieldSchema[], fieldKey: string): boolean {
    const targetField = findJiraFieldByKey(jiraSchema, fieldKey);

    if (!targetField) {
      return false;
    }

    const { type, custom } = targetField.schema;
    const isOption = type === 'option';
    const isValidCustomType =
      !!custom && this.allowedCustomKeys.includes(custom);

    return isOption && isValidCustomType;
  }

  formatFieldValue(
    jiraSchema: JiraFieldSchema[],
    fieldKey: string,
    sourceValue: unknown
  ): unknown {
    const field = findJiraFieldByKey(jiraSchema, fieldKey);
    const fieldName = field?.name;
    const allowedValues = field?.allowedValues?.map(entity => entity.name);

    if (!this.canMapThisField(jiraSchema, fieldKey)) {
      throw new Error(`Can not map to '${fieldName}'`);
    }

    const canSetNull = !field?.required || field?.hasDefaultValue;

    if (canSetNull && (sourceValue === null || sourceValue === undefined)) {
      return sourceValue;
    }

    const formattedSourceValue =
      typeof sourceValue === 'object'
        ? JSON.stringify(sourceValue)
        : sourceValue?.toString();

    const isAllowedValue = allowedValues?.includes(formattedSourceValue || '');

    if (!isAllowedValue) {
      throw new Error(
        `Can not map to '${fieldName}': mapped value is not allowed for this field`
      );
    }

    return {
      value: formattedSourceValue
    };
  }
}
