import { useCallback } from 'react';
import { getIssueById } from '../../common/api-client';
import type { TestRecordIssue } from '@deque/axe-devtools-issues-utils';
import useAPI, { State } from '../../common/hooks/useAPI';
export interface IssueState extends Omit<State<TestRecordIssue>, 'data'> {
  issue: TestRecordIssue | null;
}
const useIssue = (id: string, token?: string): IssueState => {
  const dataLoader = useCallback(() => getIssueById(id, token), [id, token]);
  const state = useAPI(dataLoader);
  return { loading: state.loading, error: state.error, issue: state.data };
};
export default useIssue;
