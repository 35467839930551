import type { TFunction } from 'react-i18next';
import React, { type Dispatch, type SetStateAction } from 'react';

import type {
  IssueType,
  ProjectsAndIssueTypes
} from '../../common/utils/integrations-client/jira/getProjectsAndIssueTypes';
import type { StepMap } from '../types';
import Link from '../../common/components/Link';
import { SupportedIntegrationProductSlugs } from '../../common/constants';
import Review from '../components/integrations/IntegrationTemplateWizard/Steps/Review/Review';
import getIntegrationProductName from '../../common/utils/get-integration-product-name-from-slug';
import FieldMapping from '../components/integrations/IntegrationTemplateWizard/Steps/FieldMapping/FieldMapping';
import ImpactMapping from '../components/integrations/IntegrationTemplateWizard/Steps/ImpactMapping/ImpactMapping';
import TemplateInformation from '../components/integrations/IntegrationTemplateWizard/Steps/TemplateInformation/TemplateInformation';

export interface GetTemplateArgs {
  t: TFunction;
  integrationProductSlug: SupportedIntegrationProductSlugs;
  instanceUrl: string;
  setIsStepDataLoading: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  projects: ProjectsAndIssueTypes[];
  getProjectById: (id: string) => ProjectsAndIssueTypes | undefined;
  getIssueTypeById: (id: string) => IssueType | undefined;
  getIssueTypesByProjectId: (id: string | null) => IssueType[] | undefined;
  isPriorityEnabled?: boolean;
  connectionId: string;
  token: string;
  setIsStepDataError: Dispatch<SetStateAction<boolean>>;
}

const getTemplateSteps = ({
  t,
  integrationProductSlug,
  instanceUrl,
  setIsStepDataLoading,
  setCurrentStep,
  projects,
  getProjectById,
  getIssueTypeById,
  getIssueTypesByProjectId,
  isPriorityEnabled = false,
  connectionId,
  token,
  setIsStepDataError
}: GetTemplateArgs) => {
  const templateSteps: StepMap[] = [
    {
      header: t('Template Information'),
      description: (
        <>
          {t(`Select the project and issue type to send issues to when using this template.
        This template is created for`)}{' '}
          <Link url={instanceUrl} openInNewTab />
        </>
      ),
      content: (
        <TemplateInformation
          projects={projects}
          getIssueTypesByProjectId={getIssueTypesByProjectId}
        />
      ),
      stepFields: ['templateName', 'project', 'issueType']
    },
    {
      header: t('Field Mapping'),
      description: t(
        `Map axe fields to {{ productName }} fields. Some fields may be required by {{ productName }}. You may leave any required {{ productName }} field empty. Users will be asked to populate those fields with values when sending an issue. This is particularly useful for fields that only allow certain values in which axe fields do not map to.`,
        {
          productName: getIntegrationProductName(integrationProductSlug, {
            capitalize: true
          })
        }
      ),
      content: (
        <FieldMapping
          integrationProductSlug={integrationProductSlug}
          setIsStepDataLoading={setIsStepDataLoading}
          connectionId={connectionId}
          token={token}
          setIsStepDataError={setIsStepDataError}
        />
      ),
      stepFields: ['fieldMapping']
    },
    // TODO: Implement post-MVP
    // {
    //   header: t('Custom Labels'),
    //   description: t(
    //     `Add custom labels to associate with any new {{ axe }} issue.`,
    //     {
    //       axe: ProductNames.axe
    //     }
    //   ),
    //   content: <CreateIntegrationTemplate />,
    //   stepFields: ['customLabels']
    // },
    {
      header: t('Review'),
      content: (
        <Review
          isPriorityEnabled={isPriorityEnabled}
          setIsStepDataLoading={setIsStepDataLoading}
          setCurrentStep={setCurrentStep}
          getProjectById={getProjectById}
          getIssueTypeById={getIssueTypeById}
          connectionId={connectionId}
          token={token}
          setIsStepDataError={setIsStepDataError}
        />
      ),
      stepFields: [
        'templateName',
        'project',
        'issueType',
        'fieldMapping',
        'customLabels',
        'impactMapping'
      ]
    }
  ];

  if (isPriorityEnabled) {
    templateSteps.splice(2, 0, {
      header: t('Impact Mapping'),
      description: t(
        `Map Impact levels to {{ productName }} Priorities (e.g., Critical = P1, Serious = P2, etc.) or, leave blank to use {{ productName }}'s default priority.`,
        {
          productName: getIntegrationProductName(integrationProductSlug, {
            capitalize: true
          })
        }
      ),
      content: (
        <ImpactMapping
          integrationProductSlug={integrationProductSlug}
          setIsStepDataLoading={setIsStepDataLoading}
          connectionId={connectionId}
          token={token}
          setIsStepDataError={setIsStepDataError}
        />
      ),
      stepFields: ['impactMapping']
    });
  }

  return templateSteps;
};

export default getTemplateSteps;
