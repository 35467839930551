import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Icon,
  IconButton,
  OptionsMenu,
  OptionsMenuItem,
  OptionsMenuTrigger,
  Tooltip,
  DescriptionList,
  DescriptionListItem,
  DescriptionTerm,
  DescriptionDetails,
  Link
} from '@deque/cauldron-react';
import type { ApiKey } from '@deque/api-key-client';

import styles from './ApiKeyManagement.css';
import { ProductSlugs } from '../../common/constants';

export type ApiKeyTableData = Omit<
  ApiKey,
  | 'enterprise_id'
  | 'user_id'
  | 'created_at'
  | 'updated_at'
  | 'enterprise_name'
  | 'platform'
  | 'user_email'
> & {
  product_name: string;
  elapsed_time: string;
};

/** Maximum length of an API key to be shown. The rest will be truncated. */
const MAX_API_KEY_LENGTH = 12;

/** Truncate the given API key string `s`. */
export const truncateApiKey = (s: string): string =>
  `${s.substring(0, MAX_API_KEY_LENGTH)}...`;

interface ApiKeyTableProps {
  apiKeys: ApiKeyTableData[];
  handleCopyApiKey: (apiKey: string) => void;
  onRegenerateClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditSelect: (id: string, product_slug: string, name: string) => void;
  variant?: string;
}

const ApiKeyTable = ({
  apiKeys,
  handleCopyApiKey,
  onRegenerateClick,
  onDeleteClick,
  onEditSelect,
  variant
}: ApiKeyTableProps): JSX.Element => {
  const { t } = useTranslation();
  const optionsMenuTriggerRef = useRef<HTMLButtonElement>(null);

  const actionIcons = (apiKey: ApiKeyTableData) => (
    <div className={styles.actionIcons}>
      <IconButton
        icon="copy"
        tooltipProps={{ variant: 'info' }}
        label={t('Copy API Key')}
        onClick={() => handleCopyApiKey(apiKey.api_key)}
      />
      <OptionsMenu
        align={variant === 'narrow' ? 'left' : undefined}
        trigger={triggerProps => (
          <OptionsMenuTrigger {...triggerProps} ref={optionsMenuTriggerRef}>
            <Icon type="kabob" label={t('Options')} />
          </OptionsMenuTrigger>
        )}
      >
        <OptionsMenuItem
          onSelect={() =>
            onEditSelect(apiKey.id, apiKey.product_slug, apiKey.name)
          }
        >
          {t('Edit API Project')}
        </OptionsMenuItem>
        <OptionsMenuItem onSelect={() => onRegenerateClick(apiKey.id)}>
          {t('Regenerate API Key')}
        </OptionsMenuItem>
        <OptionsMenuItem
          onSelect={() => {
            onDeleteClick(apiKey.id);
          }}
        >
          {t('Delete API Key')}
        </OptionsMenuItem>
      </OptionsMenu>
      <Tooltip target={optionsMenuTriggerRef} placement="left" variant="info">
        {t('Additional Options')}
      </Tooltip>
    </div>
  );

  return (
    <div>
      {variant === 'wide' ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader scope="col">{t('Name')}</TableHeader>
              <TableHeader scope="col">{t('API Key')}</TableHeader>
              <TableHeader scope="col">{t('Last Generated On')}</TableHeader>
              <TableHeader scope="col">{t('Product')}</TableHeader>
              <TableHeader scope="col">{t('Actions')}</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys.map(apiKey => (
              <TableRow key={apiKey.id}>
                <TableHeader scope="row">
                  {apiKey.product_slug === ProductSlugs.axeDevToolsWatcher ? (
                    <Link href={`/axe-watcher/projects/${apiKey.id}`}>
                      {apiKey.name}
                    </Link>
                  ) : (
                    <>{apiKey.name}</>
                  )}
                </TableHeader>
                <TableCell>{truncateApiKey(apiKey.api_key)}</TableCell>
                <TableCell>{apiKey.elapsed_time}</TableCell>
                <TableCell>{apiKey.product_name}</TableCell>
                <TableCell>{actionIcons(apiKey)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>
          {apiKeys.map(apiKey => (
            <DescriptionList key={apiKey.id} collapsed={variant === 'narrow'}>
              <DescriptionListItem>
                <DescriptionTerm>{t('Project Name')}</DescriptionTerm>
                <DescriptionDetails>{apiKey.name}</DescriptionDetails>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionTerm>{t('API Key')}</DescriptionTerm>
                <DescriptionDetails>
                  {truncateApiKey(apiKey.api_key)}{' '}
                </DescriptionDetails>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionTerm>{t('Last Generated On')}</DescriptionTerm>
                <DescriptionDetails>{apiKey.elapsed_time}</DescriptionDetails>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionTerm>{t('Product')}</DescriptionTerm>
                <DescriptionDetails>{apiKey.product_name}</DescriptionDetails>
              </DescriptionListItem>
              <DescriptionListItem>
                <DescriptionTerm>{t('Actions')}</DescriptionTerm>
                <DescriptionDetails>{actionIcons(apiKey)}</DescriptionDetails>
              </DescriptionListItem>
            </DescriptionList>
          ))}
        </div>
      )}
    </div>
  );
};

export default ApiKeyTable;
