import { useCallback } from 'react';

import {
  type IntegrationConnection,
  getIntegrationConnectionById
} from '../../common/utils/integrations-client/jira/connections';
import useAPI, { type State } from '../../common/hooks/useAPI';

const useIntegrationConnection = (
  connectionId?: string,
  enterpriseId?: string,
  token?: string
): State<IntegrationConnection> => {
  const dataLoader = useCallback(() => {
    if (!connectionId || !enterpriseId) {
      return Promise.resolve(null);
    }

    return getIntegrationConnectionById({
      enterpriseId,
      connectionId,
      token
    });
  }, [enterpriseId, connectionId, token]);

  const { loading, error, data } = useAPI(dataLoader);

  return { loading, error, data };
};

export default useIntegrationConnection;
