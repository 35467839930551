import React from 'react';
import styles from './FormHeader.css';

const FormHeader = ({
  majorHeading,
  minorHeading
}: {
  majorHeading: string;
  minorHeading?: string;
}): React.ReactElement => {
  return (
    <div className={styles.headings}>
      <h1>
        {majorHeading}
        <span aria-hidden="true">{' - '}</span>
      </h1>
      <h2>{minorHeading}</h2>
    </div>
  );
};

export default FormHeader;
