import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon, Button, Panel } from '@deque/cauldron-react';
import styles from './ApiKeyManagement.css';
import ApiKeyTable, { ApiKeyTableData } from './ApiKeyTable';

interface ApiKeyManagementProps {
  apiKeys: ApiKeyTableData[];
  hasProducts: boolean;
  handleCopyApiKey: (apiKey: string) => void;
  onRegenerateClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onAddClick: () => void;
  onEditSelect: (id: string, product_slug: string, name: string) => void;
  variant?: string;
}

const ApiKeyManagement = ({
  apiKeys,
  hasProducts,
  handleCopyApiKey,
  onRegenerateClick,
  onDeleteClick,
  onAddClick,
  onEditSelect,
  variant = 'medium'
}: ApiKeyManagementProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.collapsed]: variant === 'narrow'
      })}
    >
      <Panel>
        <div className={styles.apiKeyTable}>
          <h2>{t('Manage API Keys')}</h2>
          <div className={styles.tableInfo}>
            <h3>{t('API Keys')}</h3>
            <Button
              thin
              disabled={!hasProducts}
              variant="primary"
              onClick={onAddClick}
            >
              <Icon type="plus" />
              {t('ADD NEW API KEY')}
            </Button>
          </div>
          <div className={styles.tableWrap}>
            {!hasProducts && (
              <div className={styles.messageWrap}>
                <p>
                  {t('You do not have any subscriptions that support API keys')}
                </p>
              </div>
            )}
            {!!apiKeys.length && hasProducts && (
              <ApiKeyTable
                apiKeys={apiKeys}
                handleCopyApiKey={handleCopyApiKey}
                onRegenerateClick={onRegenerateClick}
                onDeleteClick={onDeleteClick}
                onEditSelect={onEditSelect}
                variant={variant}
              />
            )}
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default ApiKeyManagement;
