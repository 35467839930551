import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Loader, Toast } from '@deque/cauldron-react';

import PageTitle from '../../common/components/PageTitle';

import { EditVariant } from '../types';
import { useAuthContext } from '../../common/contexts/auth';
import { useEnterprises } from '../../common/contexts/enterprises';
import useTeam from '../hooks/useTeam';

import { Team } from '../../common/api-client';
import billingClient from '../../common/utils/billing-client/client-v2';

import TeamSettings from '../containers/TeamSettings';
import globalStyles from '../app.css';
import NotFound from '../../common/pages/NotFound';

interface RouteParams {
  id: string;
}

const EditTeam: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { activeEnterprise } = useEnterprises();
  const { id: teamId } = useParams<RouteParams>();

  // There will always be a user with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  // TODO: When multi-enterprise is supported, we should fetch the appropriate enterprise_id
  // There should always be an active enterprise because of <Protected isEnterpriseAdmin>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const enterpriseId = activeEnterprise!.id;

  const {
    team: initialTeam,
    loading,
    error
  } = useTeam({ token, teamId, enterpriseId });

  if (loading) {
    return (
      <>
        <PageTitle title={t('Edit Team')} />
        <Loader tabIndex={-1} label={t('Loading team')} />
      </>
    );
  }
  if (!initialTeam) {
    return (
      <>
        <PageTitle title={t('Edit Team')} />
        <NotFound />
      </>
    );
  }

  const submit = async (team: Team) => {
    await billingClient.teams.update({
      token,
      enterpriseId,
      id: team.id || teamId,
      name: team.name
    });

    if (team.user_ids_to_add.length) {
      await billingClient.teamMemberships.create({
        token,
        enterpriseId,
        id: team.id || teamId,
        keycloakIds: team.user_ids_to_add
      });
    }

    if (team.user_ids_to_remove.length) {
      await billingClient.teamMemberships.delete({
        token,
        enterpriseId,
        id: team.id || teamId,
        keycloakIds: team.user_ids_to_remove
      });
    }
  };

  return (
    <div className={globalStyles.wrap}>
      <PageTitle title={t('Edit Team')} />
      {error && (
        <Toast show type="error">
          {error?.message}
        </Toast>
      )}
      <TeamSettings
        variant={EditVariant.edit}
        initialTeam={initialTeam}
        submit={submit}
      />
    </div>
  );
};

export default EditTeam;
