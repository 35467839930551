/**
 * These functions are here to abstract the non-implemented JSDom scroll functionality
 * so we can test stuff
 * https://github.com/jsdom/jsdom/issues/2751
 */

export interface ScrollPosition {
  left: number;
  top: number;
}

export interface Dims {
  clientHeight: number;
  clientWidth: number;
}

const scrollElement = (
  element: HTMLElement,
  scrollPos: ScrollPosition
): void => {
  element.scrollTo?.(scrollPos);
};

const getClientDims = (element: HTMLElement): Dims => {
  return {
    clientHeight: element.clientHeight,
    clientWidth: element.clientWidth
  };
};

export { getClientDims, scrollElement };
