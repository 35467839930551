import React from 'react';
import classNames from 'classnames';
import styles from './Navigation.css';

export interface NavigationLink {
  id: string;
  text: string;
  order?: number;
}

interface Props {
  title: string;
  links: Array<NavigationLink>;
  onClickLink: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const Navigation: React.ComponentType<Props> = ({
  title,
  links,
  onClickLink
}) => {
  const sortedLinks = links.sort((l1, l2) => (l1.order || 0) - (l2.order || 0));

  return (
    <div className={classNames('narrowCenteredContainer', styles.container)}>
      <div className={styles.title}>
        <strong>{title}</strong>
      </div>
      <nav>
        <ul>
          {sortedLinks.map(({ id, text }) => (
            <li key={id}>
              <a href={`#${id}`} onClick={onClickLink}>
                <span>{text}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
