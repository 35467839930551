import { Team } from '../../common/api-client';

export const getCurrentUserIds = (team: Team): string[] => {
  /* 
  NOTE: If `Set.difference` and `Set.union` become supported, this could become:
  const userIds = new Set(team.user_ids)
    .union(new Set(team.user_ids_to_add))
    .difference(new Set(team.user_ids_to_remove));
  */
  const userIds = new Set(team.user_ids);
  team.user_ids_to_add.forEach(id => userIds.add(id));
  team.user_ids_to_remove.forEach(id => userIds.delete(id));
  return Array.from(userIds);
};

export const deduplicateUserIds = (
  team: Team,
  userIdsToAdd: string[],
  userIdsToRemove: string[]
): [string[], string[]] => {
  // Set of all user IDs that are current members of the team
  const currentUserIds = new Set(team.user_ids);
  // Deduplicated set of IDs that should be added to the team
  const userIdsAdded = new Set([...team.user_ids_to_add, ...userIdsToAdd]);
  // Deduplicated set of IDs that should be removed from the team
  const userIdsRemoved = new Set([
    ...team.user_ids_to_remove,
    ...userIdsToRemove
  ]);
  // A user cannot be both added and removed
  userIdsAdded.forEach(id => {
    if (userIdsRemoved.has(id)) {
      userIdsAdded.delete(id);
      userIdsRemoved.delete(id);
    }
  });
  // Users should not be added to the team twice
  currentUserIds.forEach(id => userIdsAdded.delete(id));
  return [Array.from(userIdsAdded), Array.from(userIdsRemoved)];
};
