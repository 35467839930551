import type { v2 } from '@deque/billing-service-client';
import React, { useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { TextField } from '@deque/cauldron-react';

import LicenseBox from './LicenseBox';

export interface ChooseLicenseCountStepProps {
  currentLicenseCount: number;
  currentUnallocatedLicenses: number;
  setTotalLicenseValue: (n: number | null) => void;
  totalLicensesValue: number | null;
  totalLicensesError: string;
  licenseChangeCount: number;
  term: v2.SubscriptionPricing['interval'];
  price: number;
  productName: string | undefined;
  isBillingAdmin: boolean;
  minLicenseCount: number;
}

const ChooseLicenseCountStep = ({
  currentLicenseCount,
  currentUnallocatedLicenses,
  setTotalLicenseValue,
  totalLicensesValue,
  totalLicensesError,
  licenseChangeCount,
  term,
  price,
  productName,
  isBillingAdmin,
  minLicenseCount
}: ChooseLicenseCountStepProps) => {
  const input = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!totalLicensesError) {
      return;
    }

    input.current?.focus();
  }, [totalLicensesError]);

  return (
    <>
      <Trans parent="p">
        Changing your number of {{ productName }} licenses couldn‘t be easier!
        All you have to do is{' '}
        <strong>
          type in the total number of {{ productName }} licenses you‘d like to
          have
        </strong>{' '}
        in the &quot;Total licenses&quot; field, and we‘ll do the rest!
      </Trans>
      <TextField
        fieldRef={input}
        type="number"
        min={minLicenseCount}
        error={totalLicensesError}
        value={totalLicensesValue === null ? '' : totalLicensesValue.toString()}
        onChange={value => setTotalLicenseValue(value === '' ? null : +value)}
        label={t('Total licenses')}
      />
      <LicenseBox
        currentLicenseCount={currentLicenseCount}
        licenseChangeCount={licenseChangeCount}
        term={term}
        price={price}
        totalLicensesValue={totalLicensesValue ?? 0}
      />
      {isBillingAdmin && (
        <Trans parent="p">
          <strong>Note:</strong> if you want to reduce your number of{' '}
          {{ productName }} licenses, you must have less than or equal to the
          number of <em>unallocated licenses</em> available to reduce.{' '}
          <strong>
            You currently have {{ currentUnallocatedLicenses }} unallocated
            licenses
          </strong>
          .
        </Trans>
      )}
      <Trans parent="p">
        <strong>Note:</strong> Your existing payment is going to be charged.
      </Trans>
    </>
  );
};

export default ChooseLicenseCountStep;
