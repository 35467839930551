import React, { useState, forwardRef } from 'react';
import { ExpandCollapsePanel, PanelTrigger } from '@deque/cauldron-react';
import styles from './Section.css';

interface Props {
  id: string;
  title: string;
  children: React.ReactNode;
}

const Section = forwardRef<HTMLElement, Props>(
  ({ id, title, children }, ref) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const toggle = () => setIsExpanded(!isExpanded);
    const onClick = () => {
      if (!isExpanded) {
        setIsExpanded(true);
      }
    };

    // TODO: fix the `<section>` (https://github.com/dequelabs/walnut/issues/1719)
    return (
      <section
        ref={ref}
        id={id}
        onClick={onClick}
        className={styles.section}
        tabIndex={-1}
      >
        <ExpandCollapsePanel
          className={styles.expandCollapsePanel}
          open={isExpanded}
          onToggle={toggle}
        >
          <PanelTrigger className={styles.panelTrigger}>
            <h2>{title}</h2>
          </PanelTrigger>
          {children}
        </ExpandCollapsePanel>
      </section>
    );
  }
);

Section.displayName = 'Section';

export default Section;
