import React from 'react';

export const MAX_COUNT_DISPLAYED = 9999;

interface FormattedCountProps extends React.HTMLAttributes<HTMLSpanElement> {
  /* the count of issues */
  count: number;
  /* maximum number of issues to display as a localized number, round above - this is a UI design constraint */
  maxCountDisplayed?: number;
}

const FormattedCount = ({
  count,
  maxCountDisplayed = MAX_COUNT_DISPLAYED,
  ...other
}: FormattedCountProps) => {
  if (count === 0) {
    return <span {...other}>0</span>;
  }

  if (count <= maxCountDisplayed) {
    return <span {...other}>{count.toLocaleString()}</span>;
  }

  let rounded;
  let suffix = '';
  if (count > 9999 && count <= 999999) {
    // if the issue count is greater than the max, show as thousands
    const thousands = count / 1000;
    // rounding to 1 decimal place, but if it's a whole number, don't show the decimal
    // https://stackoverflow.com/questions/7342957/how-do-you-round-to-1-decimal-place-in-javascript
    rounded = Math.round(thousands * 10) / 10;
    suffix = 'K';
  } else if (count > 999999 && count <= 999999999) {
    // if the issue count is greater than the max, show as millions
    const millions = count / 1000000;
    // rounding to 1 decimal place, but if it's a whole number, don't show the decimal
    // https://stackoverflow.com/questions/7342957/how-do-you-round-to-1-decimal-place-in-javascript
    rounded = Math.round(millions * 10) / 10;
    suffix = 'M';
  } else if (count > 999999999) {
    // if the issue count is greater than the max, show as billions
    const billions = count / 1000000000;
    // rounding to 1 decimal place, but if it's a whole number, don't show the decimal
    // https://stackoverflow.com/questions/7342957/how-do-you-round-to-1-decimal-place-in-javascript
    rounded = Math.round(billions * 10) / 10;
    suffix = 'B';
  }

  return (
    <span {...other}>
      {rounded}
      {suffix}
    </span>
  );
};

export default FormattedCount;
