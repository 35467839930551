import type { UserWithKeycloakId } from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';

export default {
  /**
   * Get the billing data for the logged-in user.
   */
  get(token: string): Promise<UserWithKeycloakId> {
    return apiRequest<UserWithKeycloakId>({
      endpoint: '/api/billing/me',
      method: 'GET',
      token
    });
  }
};
