import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Icon, IconButton } from '@deque/cauldron-react';

import type { IntegrationTemplate } from '../../../common/api-client';
import type {
  IssueType,
  ProjectsAndIssueTypes
} from '../../../common/utils/integrations-client/jira/getProjectsAndIssueTypes';
import {
  ProductNames,
  SupportedIntegrationProductSlugs
} from '../../../common/constants';
import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../common/analyticsInstances';
import { getRelativeTime } from '../../../common/utils/dates';
import TemplateCardComponent from './TemplateCard/TemplateCard';
import getIntegrationProductName from '../../../common/utils/get-integration-product-name-from-slug';
import styles from './Integrations.css';
import PageTitle from '../../../common/components/PageTitle';

export interface ListIntegrationTemplatesProp {
  token: string;
  instanceUrl: string;
  enterpriseId: string;
  connectionId: string;
  userKeycloakId: string;
  refreshTemplates: () => void;
  canManageIntegrations: boolean;
  templates: IntegrationTemplate[];
  integrationProductSlug: SupportedIntegrationProductSlugs;
  getIssueTypeById: (id: string | null) => IssueType | undefined;
  getProjectById: (id: string | null) => ProjectsAndIssueTypes | undefined;
}

const ListIntegrationTemplates = ({
  token,
  templates,
  instanceUrl,
  connectionId,
  enterpriseId,
  userKeycloakId,
  getProjectById,
  getIssueTypeById,
  refreshTemplates,
  canManageIntegrations,
  integrationProductSlug
}: ListIntegrationTemplatesProp) => {
  const { t } = useTranslation();
  const history = useHistory();
  const analytics =
    analyticsInstances[getFallbackAnalyticsInstanceId(integrationProductSlug)];

  const capitalizedIntegrationName = getIntegrationProductName(
    integrationProductSlug,
    {
      capitalize: true
    }
  );

  return (
    <>
      <PageTitle title={t('Templates')} />
      <div className={styles.listWrap}>
        <div className={styles.headerGroup}>
          <div className={styles.header}>
            <div>
              <IconButton
                label={t('Back')}
                icon="arrow-left"
                onClick={() => history.push('/configuration/integrations')}
              />
              <h2>
                {capitalizedIntegrationName} {t('Project Templates')}
              </h2>
            </div>
            <p>
              {t(
                'Create custom templates for each site, project, and issue type, ensuring seamless integration between {{axe}} and {{jira}}',
                { axe: ProductNames.axe, jira: ProductNames.jira }
              )}
            </p>
          </div>

          <Button
            onClick={() => {
              analytics.integrationTemplateAddClick({
                integration: integrationProductSlug,
                connectionId: connectionId,
                enterpriseId
              });
              history.push(
                `/configuration/integrations/${connectionId}/templates/new`
              );
            }}
          >
            <Icon type="plus" />
            {t('Add Template')}
          </Button>
        </div>

        <div className={styles.templateCardContainer}>
          {templates.map((template, i) => (
            <TemplateCardComponent
              key={`${template.project_id}-${template.name}-${i}`}
              token={token}
              templateId={template.id}
              instanceUrl={instanceUrl}
              connectionId={connectionId}
              enterpriseId={enterpriseId}
              templateName={template.name}
              refreshTemplates={refreshTemplates}
              project={getProjectById(template.project_id)?.name || ''}
              issueType={getIssueTypeById(template.issue_type_id)?.name || ''}
              updatedBy={`${template.updated_by_first_name} ${template.updated_by_last_name}`}
              integrationProductSlug={integrationProductSlug}
              updatedAt={
                template.updated_at
                  ? getRelativeTime(new Date(template.updated_at))
                  : null
              }
              canEdit={
                canManageIntegrations || template.created_by === userKeycloakId
              }
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ListIntegrationTemplates;
