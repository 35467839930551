import { TFunction } from 'i18next';
import * as EmailValidator from 'email-validator';

interface ValidateProps {
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
  email: HTMLInputElement;
}

export interface Errors {
  [n: string]: string;
}

export default function (
  { firstName, lastName, email }: ValidateProps,
  t: TFunction
): Errors {
  let firstErroneousInput: HTMLInputElement | null = null;

  const errors: Errors = {};

  // NOTE: order of the below validation checks matches DOM order which is important
  if (!email.value) {
    errors.email = t('Email is required');
    firstErroneousInput = firstErroneousInput || email;
  } else if (!EmailValidator.validate(email.value)) {
    errors.email = t('Valid email is required');
    firstErroneousInput = firstErroneousInput || email;
  }

  if (!firstName.value) {
    errors.firstName = t('First name is required');
    firstErroneousInput = firstName;
  }

  if (!lastName.value) {
    errors.lastName = t('Last name is required');
    firstErroneousInput = firstErroneousInput || lastName;
  }

  firstErroneousInput?.focus();

  return errors;
}
