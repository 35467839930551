import type { v2 } from '@deque/billing-service-client';
import { EnterpriseMembers } from '../../common/utils/billing-client/client-v2';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { TextField, Button, PanelContent, Link } from '@deque/cauldron-react';
import ReviewUserChanges from '../components/ReviewUserChanges';
import emailValidator from 'email-validator';
import styles from '../pages/AddUsersToProduct.css';

export const MAX_NEW_USERS = 100;

export interface StepData {
  product?: v2.Product;
  availableLicenses: number;
  unlimitedLicenses: boolean;
  newUsers?: string[];
}

export interface StepComponentProps extends StepData {
  members: EnterpriseMembers.PendingOrAcceptedMember[];
  setNewUsers: (users: string[]) => void;
  onStepComplete: () => void;
  onPreviousStep?: () => void;
}

export const AddUsersStep = ({
  /* istanbul ignore next */
  newUsers = [],
  product,
  availableLicenses,
  unlimitedLicenses,
  setNewUsers,
  onStepComplete
}: StepComponentProps) => {
  const [emailValue, setEmailValue] = useState(newUsers.join(','));
  const [emailError, setEmailError] = useState<string | null>(null);
  const userCount = emailValue.trim().length ? emailValue.split(',').length : 0;
  const licenseOverage = userCount > availableLicenses && !unlimitedLicenses;
  const textfieldRef = useRef<HTMLTextAreaElement>(null);
  const extraLicenses = userCount - availableLicenses;

  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const emails = emailValue
      .split(',')
      .map(email => email.trim())
      .filter(Boolean);

    if (!emailValue || !emails.length) {
      setEmailError(t('At least 1 email is required'));
      /* istanbul ignore next */
      textfieldRef.current?.focus();
      return;
    }

    const invalidEmails = emails.filter(
      email => !emailValidator.validate(email)
    );
    if (invalidEmails.length) {
      setEmailError(
        invalidEmails.length === 1
          ? t(`The following email address is invalid: {{ email }}`, {
              email: invalidEmails[0]
            })
          : t(
              `The following ({{ count }}) email addresses are invalid: {{ emails }}`,
              { count: invalidEmails.length, emails: invalidEmails.join(', ') }
            )
      );
      /* istanbul ignore next */
      textfieldRef.current?.focus();
      return;
    }

    if (emails.length > MAX_NEW_USERS) {
      setEmailError(
        t('You can only add {{max}} users at a time.', { max: MAX_NEW_USERS })
      );
      /* istanbul ignore next */
      textfieldRef.current?.focus();
      return;
    }

    // De-dupe emails
    setNewUsers(Array.from(new Set(emails)));
    onStepComplete();
  };

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(target.value);
  };

  return (
    <>
      <PanelContent>
        <form id="user-steps-form" onSubmit={handleSubmit}>
          <p>
            {t(
              'Use the text area below to add the emails, comma separated, for each user you’d like to add to {{name}}. All brand new users will have an axe Account created for them automatically.',
              { name: product ? product.name : t('your axe Account') }
            )}
          </p>
          {(availableLicenses >= MAX_NEW_USERS || unlimitedLicenses) && (
            <p>
              <em>
                {t(
                  'Note: You can add up to {{max}} users at a time. If you have more you’d like to add, you can go through this process multiple times.',
                  { max: MAX_NEW_USERS }
                )}
              </em>
            </p>
          )}
          <TextField
            label={t('List user emails (comma separated)')}
            multiline
            required
            onInput={handleInput}
            error={emailError}
            value={emailValue}
            fieldRef={textfieldRef}
          />
          {!unlimitedLicenses && (
            <div
              className={classnames(styles.userCounter, {
                [styles.userCounterError]: licenseOverage
              })}
              role="status"
              aria-live="polite"
              aria-atomic="true"
              id="license_count_text"
            >
              {t('{{count}}/{{total}} available licenses. {{supportText}}', {
                count: userCount,
                total: availableLicenses,
                supportText: licenseOverage
                  ? extraLicenses === 1
                    ? t('Please remove 1 user.')
                    : t('Please remove {{extra}} users.', {
                        extra: extraLicenses
                      })
                  : ''
              })}
            </div>
          )}
        </form>
      </PanelContent>
      <PanelContent>
        <Link variant="button-secondary" href="/user-access">
          {t('Cancel')}
        </Link>
        <Button
          type="submit"
          form="user-steps-form"
          variant="primary"
          disabled={!userCount || licenseOverage}
          aria-describedby={
            licenseOverage && !unlimitedLicenses
              ? 'license_count_text'
              : undefined
          }
        >
          {t('Next')}
        </Button>
      </PanelContent>
    </>
  );
};

export const ReviewAddUsersStep = ({
  members,
  newUsers = [],
  product,
  onStepComplete,
  onPreviousStep = () => null
}: StepComponentProps) => (
  <ReviewUserChanges
    members={members}
    changedUsers={newUsers}
    onComplete={onStepComplete}
    onCancel={onPreviousStep}
    changes={
      product
        ? {
            newProducts: [product]
          }
        : {}
    }
  />
);
