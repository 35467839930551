import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Code } from '@deque/cauldron-react';
import classNames from 'classnames';

import type { OrNull } from '../../../../typings/utils';
import selectorToString from '../../utils/selectorToString';
import Section from './Section';
import styles from './ElementInfo.css';
import type { BoundingBox } from '../../../common/api-client';
import Screenshot from './Screenshot';

interface PartProps {
  title: string;
  className?: string;
  children: React.ReactNode;
}

const Part: React.FC<PartProps> = ({ title, className, children }) => {
  const cls = classNames(styles.part, className);
  return (
    <div className={cls}>
      <h3>{title}</h3>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

interface Props {
  id: string;
  selector: OrNull<string[]>;
  source: OrNull<string>;
  relatedNodes: OrNull<string[]>;
  boundingBox?: OrNull<BoundingBox>;
  screenshotId: OrNull<string>;
}

const ElementInfo = forwardRef<HTMLElement, Props>(
  (
    { id, selector, source, relatedNodes, boundingBox = null, screenshotId },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <Section ref={ref} id={id} title={t('Element Info')}>
        <div className={styles.info}>
          {selector ? (
            <Part title={t('Element location')}>
              <Code language="css">{selectorToString(selector)}</Code>
            </Part>
          ) : null}

          {source ? (
            <Part title={t('Element source')}>
              <Code language="html">{source}</Code>
            </Part>
          ) : null}

          {screenshotId ? (
            <Part title={t('Screenshot')}>
              <Screenshot
                screenshotId={screenshotId}
                boundingBox={boundingBox}
              />
            </Part>
          ) : null}

          {relatedNodes && relatedNodes.length > 0 ? (
            <Part title={t('Related nodes')}>
              <Code language="html">{selectorToString(relatedNodes)}</Code>
            </Part>
          ) : null}
        </div>
      </Section>
    );
  }
);

ElementInfo.displayName = 'ElementInfo';

export default ElementInfo;
