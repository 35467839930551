import { Checkbox } from '@deque/cauldron-react';
import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  FieldPathByValue,
  FieldValues
} from 'react-hook-form';

type FeatureFlagDefaultStateCheckboxProps<
  NameType extends FieldPathByValue<ControlType, boolean>,
  ControlType extends FieldValues
> = {
  name: NameType;
  control: Control<ControlType>;
};

const FeatureFlagDefaultStateCheckbox = <
  ControlType extends FieldValues,
  NameType extends FieldPathByValue<ControlType, boolean>
>({
  name,
  control
}: FeatureFlagDefaultStateCheckboxProps<
  NameType,
  ControlType
>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { value, ...restField } = field;
        return (
          <Checkbox
            id="new_feature_id"
            label={t('Default state')}
            checked={value}
            error={fieldState.error?.message}
            {...restField}
          />
        );
      }}
    />
  );
};

export default FeatureFlagDefaultStateCheckbox;
