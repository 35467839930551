import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../common/contexts/auth';
import NotFound from '../../common/pages/NotFound';
import PageTitle from '../../common/components/PageTitle';
import AcceptInvitationForm from '../containers/AcceptInvitationForm';
import globalStyles from '../app.css';
import styles from './AcceptInvitationV2.css';

const AcceptInvitationV2: React.FC = () => {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = authContext.user!;
  const history = useHistory();
  const location = useLocation();

  const { invitationId, enterpriseId } = React.useMemo(() => {
    const q = new URLSearchParams(location.search);
    return {
      invitationId: q.get('invitation_id'),
      enterpriseId: q.get('enterprise_id')
    };
  }, [location.search]);

  // Upon successful submission (accepting the invite), redirect the
  // user to the dashboard and show a confirmation toast.
  const handleSubmit = React.useCallback(async () => {
    // Reload billing state to show the latest product access
    await authContext.updateBillingUser();
    history.push('/?invitation-accepted=true');
  }, [history, authContext.updateBillingUser]);

  // Upon successful rejection (rejecting the invite), redirect the
  // user to the dashboard and show a confirmation toast.
  const handleReject = React.useCallback(async () => {
    // Reload billing state to show the latest product access
    await authContext.updateBillingUser();
    history.push('/?invitation-rejected=true');
  }, [history, authContext.updateBillingUser]);

  // If the user has already accepted the invite, redirect them to
  // the dashboard and show an error toast.
  const handleAlreadyAccepted = React.useCallback(() => {
    history.push('/?already-accepted-invite=true');
  }, [history]);

  // If we don't have all required information, 404.
  // This could happen if:
  // - the invite is old (we didn't always include the enterprise ID)
  // - the user incorrectly cut/paste the link into their browser
  if (!enterpriseId || !invitationId) {
    return <NotFound />;
  }

  return (
    <div className={classNames(globalStyles.wrap, styles.main)}>
      <PageTitle title={t('Accept Invitation')} />

      <h1>{t('You’ve been invited to...')}</h1>

      <AcceptInvitationForm
        enterpriseId={enterpriseId}
        invitationId={invitationId}
        onSubmit={handleSubmit}
        onReject={handleReject}
        onAlreadyAccepted={handleAlreadyAccepted}
        token={user.token}
      />
    </div>
  );
};

export default AcceptInvitationV2;
