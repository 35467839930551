import React, { type Dispatch, type SetStateAction, useEffect } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Loader, Select, SelectProps } from '@deque/cauldron-react';

import { useGlobalToast } from '../../../../../../common/contexts/globalToast';
import { SupportedIntegrationProductSlugs } from '../../../../../../common/constants';
import getIntegrationProductName from '../../../../../../common/utils/get-integration-product-name-from-slug';
import useIssueTypeFields from '../../../../../hooks/useIssueTypeFields';

type ControlledSelectProps = {
  name: string;
  control: Control<FieldValues>;
} & Omit<SelectProps, 'ref'>;

const ControlledSelect = ({
  name,
  control,
  ...otherSelectProps
}: ControlledSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <Select {...field} {...otherSelectProps} />}
    />
  );
};

const makeTitle = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export interface ImpactMappingProps {
  integrationProductSlug: SupportedIntegrationProductSlugs;
  setIsStepDataLoading: Dispatch<SetStateAction<boolean>>;
  connectionId: string;
  token: string;
  setIsStepDataError: Dispatch<SetStateAction<boolean>>;
}

const ImpactMapping = ({
  integrationProductSlug,
  setIsStepDataLoading,
  connectionId,
  token,
  setIsStepDataError
}: ImpactMappingProps) => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const { control, getValues } = useFormContext();
  const impactMapping = useWatch({ control, name: 'impactMapping' });

  const projectId = getValues('project');
  const issueTypeId = getValues('issueType');
  const { priorities, error, loading } = useIssueTypeFields(
    projectId,
    issueTypeId,
    connectionId,
    token
  );

  const prioritiesOptions =
    priorities && priorities.allowedValues
      ? [
          {
            key: '',
            label: '',
            value: ''
          },
          ...priorities.allowedValues.map(({ id, name }) => ({
            key: id,
            label: name,
            value: id
          }))
        ]
      : [
          {
            key: '',
            label: t('No priorities available'),
            value: ''
          }
        ];

  useEffect(() => {
    setIsStepDataError(!!error);

    if (error) {
      setContents(
        t('There was an error fetching fields for this issue type.'),
        'error'
      );
    }
  }, [error]);

  useEffect(() => setIsStepDataLoading(loading), [loading]);

  if (loading) {
    return <Loader label={t('Loading issue priorities...')} />;
  }

  return (
    <div>
      {impactMapping
        ? Object.keys(impactMapping).map(field => (
            <ControlledSelect
              key={field}
              name={`impactMapping.${field}`}
              control={control}
              label={t('{{ integration }} Priority for {{impact}} Impact', {
                integration: getIntegrationProductName(integrationProductSlug, {
                  capitalize: true
                }),
                impact: makeTitle(field)
              })}
              options={prioritiesOptions.map(option => ({
                ...option,
                disabled: Object.entries(impactMapping).some(
                  ([key, value]) =>
                    key !== field && option.value && value === option.value
                )
              }))}
              disabled={!priorities}
            />
          ))
        : null}
    </div>
  );
};

export default ImpactMapping;
