import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertContent,
  AlertActions,
  Button,
  TextField
} from '@deque/cauldron-react';
import TagManager from 'react-gtm-module';

import TermsAndConditionsCheckbox from './TermsAndConditionsCheckbox';
import ScrimmedLoader from './ScrimmedLoader';
import { createAccount, analyticsEvent } from '../api-client';
import billingClient from '../utils/billing-client/client-v2';
import validate, { Errors } from '../utils/validate-user-form';
import { useAuthContext } from '../contexts/auth';
import dequeLogo from '../assets/images/deque.png';
import styles from './FirstLoginAlert.css';
import setAuthComplete from '../utils/set-auth-complete';
import { useOnPremValue } from '../contexts/onPrem';
import getProductFromUrl from '../../app/utils/get-product-from-url';
import { useCrossProductAnalytics } from '../contexts/analytics';
import { originalRedirectQueryParam, HubSpotProperties } from '../constants';
import { isValidRedirectURI } from '../utils/validate-redirect';
import { useFeatureFlagState } from '../contexts/featureFlags';
import queryString from 'query-string';

type FirstLoginAlertProps = {
  initialShowAlert?: boolean;
};

const FirstLoginAlert = ({
  initialShowAlert = false
}: FirstLoginAlertProps): ReactElement | null => {
  const { t } = useTranslation();
  const {
    user,
    billingUser,
    checkForUser,
    loading: authLoading
  } = useAuthContext();
  const loadWrap = useRef<HTMLDivElement>(null);
  const agree = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);
  const firstName = useRef<HTMLInputElement>(null);
  const lastName = useRef<HTMLInputElement>(null);
  const company = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<Errors>({});
  const [show, setShow] = useState(initialShowAlert);
  const [loading, setLoading] = useState(false);
  const [fromExtension, setFromExtension] = useState(false);
  const isOnPrem = useOnPremValue();
  const productSlug = getProductFromUrl(window.location.pathname);
  const analytics = useCrossProductAnalytics(productSlug);
  const queryParamsObj = queryString.parse(window.location.search, {
    // This is the default, but we're being explicit here.
    decode: true
  });
  const isSupportRegistrationAllowed = useFeatureFlagState(
    'support_registration'
  );

  const onClose = () => {
    setShow(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    /* istanbul ignore if */
    if (
      !firstName.current ||
      !lastName.current ||
      !email.current ||
      !company.current ||
      !agree.current ||
      !user ||
      !user.token
    ) {
      setErrors({
        alert: t('Something went wrong. Please try again.')
      });
      return;
    }

    const userErrors = validate(
      {
        firstName: firstName.current,
        lastName: lastName.current,
        email: email.current,
        company: company.current,
        agree: agree.current
      },
      // We don't validate the password here, so we don't need password requirements.
      {},
      t
    );

    setErrors(userErrors);

    if (Object.keys(userErrors).length) {
      return;
    }

    setLoading(true);

    try {
      // check to see if there is already a billing user, if so, do not attempt to create one
      if (!billingUser) {
        await billingClient.users.create(
          {
            email: email.current.value,
            name: `${firstName.current.value} ${lastName.current.value}`
          },
          user.token
        );
      }

      await createAccount(
        user.id,
        {
          firstName: firstName.current.value,
          lastName: lastName.current.value,
          company: company.current.value
        },
        user.token
      );

      // checkForUser updates v2 billing user
      await checkForUser();

      TagManager.dataLayer({
        dataLayer: {
          event: 'axeFinalization'
        }
      });

      await Promise.all([
        analyticsEvent(user.token, HubSpotProperties.AXE_FIRST_LOGIN_DATE),
        analyticsEvent(user.token, HubSpotProperties.HS_MARKETABLE_STATUS, true)
      ]);
      analytics.acceptTerms();
      onClose();
      setAuthComplete();
    } catch (err) {
      setErrors({
        alert: t('Something went wrong. Please try again.')
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const shouldShow =
      !isOnPrem && user && !show && !user.hasAccountEntry && !authLoading;

    if (user && user.hasAccountEntry) {
      const originalRedirectURI = queryParamsObj[originalRedirectQueryParam];
      // Redirect to support site if redirection query exists
      if (
        originalRedirectURI &&
        typeof originalRedirectURI === 'string' &&
        isValidRedirectURI(originalRedirectURI) &&
        isSupportRegistrationAllowed
      ) {
        window.location.assign(originalRedirectURI);
      }
    }
    if (!shouldShow) {
      return;
    }

    setShow(true);
  }, [user, isSupportRegistrationAllowed]);

  useEffect(() => {
    if (!loading) {
      return;
    }
    /* istanbul ignore next */
    loadWrap.current?.focus();
  }, [loading]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('fromextension')) {
      setFromExtension(true);
    }
  }, []);

  return user ? (
    <Alert
      show={show}
      className={styles.alert}
      onClose={onClose}
      heading={
        <img className={styles.headingImage} src={dequeLogo} alt={t('Deque')} />
      }
    >
      <form noValidate onSubmit={onSubmit}>
        <AlertContent>
          {loading && (
            <ScrimmedLoader
              label={t('Finalizing your account...')}
              loaderRef={loadWrap}
            />
          )}
          {errors.alert && (
            <div className="Error" role="alert">
              {errors.alert}
            </div>
          )}
          <p>
            {t(
              "We'll use the following information to finalize your account. Please provide the missing information."
            )}
            {fromExtension && (
              <>
                <br />
                <strong>
                  {t(
                    'You will be sent back to the axe extension after creating your account.'
                  )}
                </strong>
              </>
            )}
          </p>
          <TextField
            label={t('First name')}
            defaultValue={user.firstName}
            disabled={!!user.firstName}
            required={!user.firstName}
            error={errors.firstName}
            fieldRef={firstName}
          />
          <TextField
            label={t('Last name')}
            defaultValue={user.lastName}
            disabled={!!user.lastName}
            required={!user.lastName}
            error={errors.lastName}
            fieldRef={lastName}
          />
          <TextField
            label={t('Business email')}
            defaultValue={user.email}
            disabled={!!user.email}
            required={!user.email}
            error={errors.email}
            fieldRef={email}
          />
          <TextField
            label={t('Company')}
            defaultValue={user.company}
            disabled={!!user.company}
            required={!user.company}
            error={errors.company}
            fieldRef={company}
          />
          <TermsAndConditionsCheckbox
            id="terms"
            name="terms"
            checkboxRef={agree}
            invalid={!!errors.agree}
            className={styles.agree}
            error={errors.agree}
          />
        </AlertContent>
        <AlertActions>
          <Button type="submit">{t('Create')}</Button>
        </AlertActions>
      </form>
    </Alert>
  ) : null;
};

export default FirstLoginAlert;
