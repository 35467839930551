import type { AxePurchaseState } from '@deque/billing-service-client';
import { ACTIVE_STATES, FREE_STATES, ProductSlugs } from '../constants';

interface HasAnyActiveSubscriptionParams {
  productAccess: Record<ProductSlugs, AxePurchaseState>;
  allowedSlugsWithFreeStates?: ProductSlugs[];
}

export const hasAnyActiveSubscription = ({
  productAccess,
  allowedSlugsWithFreeStates = []
}: HasAnyActiveSubscriptionParams) => {
  // Only check free states for slugs that are in the allowedSlugsWithFreeStates array
  if (allowedSlugsWithFreeStates.length) {
    const includesSlug = (slug: ProductSlugs) =>
      allowedSlugsWithFreeStates.includes(slug);

    return !!Object.entries(productAccess).find(([slug, state]) =>
      includesSlug(slug as ProductSlugs)
        ? FREE_STATES.includes(state) || ACTIVE_STATES.includes(state)
        : ACTIVE_STATES.includes(state)
    );
  }

  return !!Object.values(productAccess).find(state =>
    ACTIVE_STATES.includes(state)
  );
};

interface HasAccessToProductParams {
  productAccess: Record<ProductSlugs, AxePurchaseState>;
  slug: ProductSlugs;
  allowedSlugsWithFreeStates?: ProductSlugs[];
}

export const hasAccessToProduct = ({
  productAccess,
  slug,
  allowedSlugsWithFreeStates = []
}: HasAccessToProductParams) => {
  // Only check free states for slugs that are in the allowedSlugsWithFreeStates array
  if (allowedSlugsWithFreeStates.includes(slug)) {
    return (
      FREE_STATES.includes(productAccess[slug]) ||
      ACTIVE_STATES.includes(productAccess[slug])
    );
  }

  return ACTIVE_STATES.includes(productAccess[slug]);
};
