import type {
  EnterpriseAccount,
  AxePurchaseState,
  TaxId
} from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Enterprises {
  export interface GetParams {
    token: string;
    id: string;
  }

  export interface Invitation {
    accepted_at?: string;
    email: string;
    enterprise_id: string;
    id: string;
    sender_email: string;
  }

  export interface AcceptInvitationRequest {
    token: string;
    id: string;
    enterpriseId: string;
  }

  export type Enterprise = EnterpriseAccount;

  export interface CreateParams {
    token: string;
    name: string;
    email: string;
    keycloak_id: string;
    admin_keycloak_id: string;
    offline?: boolean;
    tax_id?: TaxId;
  }
}

interface ListInvitesParams {
  token: string;
  enterpriseId: string;
}

interface UpdateParams {
  token: string;
  enterpriseId: string;
  name?: string;
  stripe_id?: string;
  axe_purchase_state?: AxePurchaseState;
  axe_trial_days?: number;
}

export default {
  getInvitation({
    token,
    id
  }: Enterprises.GetParams): Promise<Enterprises.Invitation> {
    return apiRequest({
      method: 'GET',
      token,
      endpoint: `/api/billing/enterprises/invitations/${id}`
    });
  },
  get({ token, id }: Enterprises.GetParams): Promise<Enterprises.Enterprise> {
    return apiRequest({
      method: 'GET',
      token,
      endpoint: `/api/billing/enterprises/${id}`
    });
  },
  create({
    token,
    name,
    email,
    keycloak_id,
    admin_keycloak_id,
    tax_id
  }: Enterprises.CreateParams): Promise<void> {
    return apiRequest({
      method: 'POST',
      token,
      endpoint: '/api/billing/enterprises',
      body: {
        name,
        email,
        keycloak_id,
        admin_keycloak_id,
        tax_id
      }
    });
  },
  update({
    token,
    enterpriseId,
    name
  }: UpdateParams): Promise<EnterpriseAccount> {
    return apiRequest({
      method: 'PUT',
      token,
      endpoint: `/api/billing/enterprises/${enterpriseId}`,
      body: {
        name
      }
    });
  },
  acceptInvitation({
    token,
    id,
    enterpriseId
  }: Enterprises.AcceptInvitationRequest): Promise<void> {
    return apiRequest({
      method: 'POST',
      token,
      endpoint: `/api/billing/enterprises/${enterpriseId}/invitations/${id}`
    });
  },
  listInvitations({
    token,
    enterpriseId
  }: ListInvitesParams): Promise<Enterprises.Invitation[]> {
    return apiRequest({
      method: 'GET',
      token,
      endpoint: `/api/billing/enterprises/${enterpriseId}/invitations`
    });
  },
  listUsers(token: string, enterpriseId: string): Promise<string[]> {
    return apiRequest({
      token,
      endpoint: `/api/billing/enterprises/${enterpriseId}/users`
    });
  }
};
