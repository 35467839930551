import React from 'react';
import styles from './HighlightText.css';

const HighlightText = ({
  text,
  highlight
}: {
  text: string;
  highlight?: string;
}) => {
  if (!highlight) {
    return <>{text}</>;
  }

  // Escapes any special characters
  const highlightEscaped = highlight?.replace(/[.*+?^${}()|[\]\\/]/g, '\\$&');

  // Split the text into parts before and after the highlight
  const parts = text.split(new RegExp(`(${highlightEscaped})`, 'gi'));
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={`${part}-${index}`} className={styles.textUnderline}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};

export default HighlightText;
