import { Button, ColumnHeader, TextField } from '@deque/cauldron-react';
import React, { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Errors } from '../../../common/utils/validate-password-settings-form';

interface PasswordAccountSettingsProps {
  handlePasswordSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
  currentPasswordRef?: React.RefObject<HTMLInputElement>;
  passwordRef?: React.RefObject<HTMLInputElement>;
  confirmPasswordRef?: React.RefObject<HTMLInputElement>;
  submitting?: boolean;
  errors: Errors;
}

const PasswordAccountSettings = ({
  handlePasswordSubmit,
  currentPasswordRef,
  passwordRef,
  confirmPasswordRef,
  submitting,
  errors
}: PasswordAccountSettingsProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <ColumnHeader>
        <h2>{t('Password')}</h2>
      </ColumnHeader>
      <form onSubmit={handlePasswordSubmit}>
        <TextField
          label={t('Current Password')}
          name="currentPassword"
          type="password"
          error={errors.currentPassword}
          fieldRef={currentPasswordRef}
          required
        />
        <TextField
          label={t('New Password')}
          name="password"
          type="password"
          error={errors.password}
          fieldRef={passwordRef}
          required
        />
        <TextField
          label={t('Confirm Password')}
          name="confirmPassword"
          type="password"
          error={errors.confirmPassword}
          fieldRef={confirmPasswordRef}
          required
        />
        <Button type="submit" disabled={submitting}>
          {t('Save')}
        </Button>
      </form>
    </>
  );
};

export default PasswordAccountSettings;
