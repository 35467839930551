import {
  UserWithKeycloakId,
  type StripeTypes
} from '@deque/billing-service-client';

export interface SubscriptionWithPlan extends StripeTypes.Subscription {
  plan: {
    product?: string;
  } | null;
}

const getStripeSubscription = (
  billingUser: UserWithKeycloakId | undefined,
  stripeProductId?: string | null
) => {
  if (
    !billingUser?.enterprise_billing_id &&
    !billingUser?.subscriptions?.data.length
  ) {
    return;
  }

  // Look through the enterprise subscriptions if the user is a billing admin
  const stripeSubscriptions =
    (billingUser?.enterprise_billing_id
      ? (billingUser?.enterprise_stripe_data?.subscriptions
          ?.data as SubscriptionWithPlan[])
      : (billingUser?.subscriptions?.data as SubscriptionWithPlan[])) || [];

  let stripeSubscription;
  if (stripeProductId) {
    stripeSubscription =
      stripeSubscriptions.find(
        subscription =>
          subscription.plan !== null &&
          subscription.plan?.product === stripeProductId
      ) ||
      stripeSubscriptions.find(subscription =>
        subscription.items.data.find(
          item => item.price.product === stripeProductId
        )
      );
  } else {
    // Try to grab an active subscription first and then fall back to a past_due or incomplete subscription
    stripeSubscription =
      stripeSubscriptions.find(
        subscription => subscription.status === 'active'
      ) ||
      stripeSubscriptions.find(
        subscription =>
          subscription.status === 'past_due' ||
          subscription.status === 'incomplete'
      );
  }

  if (billingUser?.axe_purchase_state === 'trialing' && !stripeSubscription) {
    return;
  }

  return stripeSubscription;
};

export default getStripeSubscription;
