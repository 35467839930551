import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import useMediaQuery from '../../common/hooks/useMediaQuery';
import PageTitle from '../../common/components/PageTitle';
import ProductsContainer from '../containers/Products';
import styles from './Products.css';

import usePurchaseNotification from '../../common/hooks/usePurchaseNotification';

const ProductsPage = (): ReactElement => {
  const { t } = useTranslation();
  const narrow = useMediaQuery('(max-width: 31.25rem)');

  usePurchaseNotification();

  return (
    <>
      <PageTitle title={t('Products')} />
      <div
        className={classNames(styles.wrap, {
          // This is manged in JS, rather than CSS to help keep in sync with
          // the panel breakpoint
          [styles.collapsed]: narrow
        })}
      >
        <h1>{t('Products')}</h1>
        <p className={styles.mainMessage}>
          {t('You can view and manage your products.')}
        </p>
        <ProductsContainer />
      </div>
    </>
  );
};

export default ProductsPage;
