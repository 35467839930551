import React from 'react';
import type { StripeTypes } from '@deque/billing-service-client';
import billingClient from '../utils/billing-client/client-v2';
import useAPI, { State as BaseState, Methods } from './useAPI';
import { useAuthContext } from '../contexts/auth';
import { useServerInfo } from '../contexts/serverInfo';

export interface State
  extends Omit<BaseState<StripeTypes.PaymentMethod[]>, 'data'> {
  paymentMethods: StripeTypes.PaymentMethod[];
  defaultPaymentMethod?: StripeTypes.PaymentMethod;
}

const usePaymentMethods = (): State & Methods => {
  const authContext = useAuthContext();
  const { serverInfo } = useServerInfo();

  const dataLoader = React.useCallback(async () => {
    const v2User = authContext.billingUser;
    if (
      !authContext.user?.token ||
      !v2User ||
      !serverInfo?.isSelfProvisioningEnabled
    ) {
      return { paymentMethods: [] };
    }
    const paymentMethods = await billingClient.users.listPaymentMethods(
      authContext.user.token
    );

    const defaultPaymentMethod = paymentMethods.find(
      pm => pm.id === v2User.default_payment_method_id
    );
    return { paymentMethods, defaultPaymentMethod };
  }, [authContext.user?.token, authContext.billingUser, serverInfo]);

  const state = useAPI(dataLoader);

  return {
    loading: state.loading,
    error: state.error,
    paymentMethods: state.data?.paymentMethods || [],
    defaultPaymentMethod: state.data?.defaultPaymentMethod,
    refresh: state.refresh
  };
};

export default usePaymentMethods;
