import type { IssueTypeField } from '../getIssueTypeFields';
import { findJiraFieldByKey } from './findJiraFieldByKey';
import type { JiraFieldMapper } from './types';

export class MultiOptionsMapper implements JiraFieldMapper {
  id = 'jira-multi-options-mapper';
  allowedCustomKeys = [
    'com.atlassian.jira.plugin.system.customfieldtypes:multiselect',
    'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes'
  ];

  canMapThisField(jiraSchema: IssueTypeField[], fieldKey: string): boolean {
    const targetField = findJiraFieldByKey(jiraSchema, fieldKey);

    if (!targetField) {
      return false;
    }

    const { type, items, custom } = targetField.schema;
    const isArrayOfOptions = type === 'array' && items === 'option';
    const isValidCustomType =
      !!custom && this.allowedCustomKeys.includes(custom);

    return isArrayOfOptions && isValidCustomType;
  }

  formatFieldValue(
    jiraSchema: IssueTypeField[],
    fieldKey: string,
    sourceValue: unknown
  ): unknown {
    const field = findJiraFieldByKey(jiraSchema, fieldKey);
    const fieldName = field?.name;
    const allowedValues = field?.allowedValues?.map(entity => entity.name);

    if (!this.canMapThisField(jiraSchema, fieldKey)) {
      throw new Error(`Can not map to '${fieldName}'`);
    }

    const canSetNull = !field?.required || field?.hasDefaultValue;

    if (canSetNull && (sourceValue === null || sourceValue === undefined)) {
      return sourceValue;
    }

    const isArray = Array.isArray(sourceValue);

    const isAllowedValue = isArray
      ? sourceValue.every(
          item => !!item && allowedValues?.includes(item.toString())
        )
      : !!sourceValue && allowedValues?.includes(sourceValue.toString());

    if (!isAllowedValue) {
      throw new Error(
        `Can not map to '${fieldName}': mapped value is not allowed for this field`
      );
    }

    const formattedValue = isArray
      ? sourceValue.map(item => ({ value: item.toString() }))
      : [{ value: sourceValue?.toString() }];

    return formattedValue;
  }
}
