import React, { ReactElement, ReactNode } from 'react';
import { Panel } from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './Products.css';

interface ProductsProps {
  heading:
    | ReactNode
    | {
        id?: string;
        text: ReactNode;
        level: number | undefined;
      };
  helperText?: string;
  showFooter?: boolean;
  children?: ReactNode;
}

const Products = ({
  heading,
  helperText,
  showFooter = false,
  children
}: ProductsProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Panel heading={heading} className={styles.panel}>
      {helperText && <p>{helperText}</p>}
      <div className={styles.productsWrap}>{children}</div>
      {showFooter && (
        <div className={styles.footer}>
          <span>
            {t('Hide expired products with')}{' '}
            <Link
              to="/configuration?focus=expiredProducts"
              className={`${styles.footerLink} Link`}
            >
              axe {t('Configuration')}
            </Link>
          </span>
        </div>
      )}
    </Panel>
  );
};

export default Products;
