import React from 'react';

import type { ErrorOrNull } from '../../../typings/utils';
import * as API from '../api-client';
import { useAuthContext } from './auth';

/**
 * A feature flag.
 */

export interface FeatureFlag {
  id: string;
  state: boolean;
  description: string;
  product_name: string;
}

/**
 * State stored within the context.
 */

interface State {
  // Feature flag data.
  featureFlags: FeatureFlag[];
  // Are we loading feature flag data?
  loading: boolean;
  // An error from the GET feature flag API.
  loadError: ErrorOrNull;
  // An error from the POST feature flag API.
  updateError: ErrorOrNull;
}

/**
 * Methods provided by the context.
 */

interface Methods {
  // Reload the feature flag data from the API.
  loadFeatureFlags: () => void;
  // Update the feature flags by hitting the API.
  // This can only be called by logged-in Dequ'ers.
  updateFeatureFlags: (features: FeatureFlag[]) => void;
}

/**
 * State and methods provided by the context.
 */

type ContextShape = State & Methods;

/**
 * Throw a "helpful" error if using context methods without mounting its provider.
 */

const throwProviderNotMountedError = (): never => {
  throw new Error('FeatureFlags: Provider not mounted');
};

const context = React.createContext<ContextShape>({
  featureFlags: [],
  loading: true,
  loadError: null,
  updateError: null,
  loadFeatureFlags: throwProviderNotMountedError,
  updateFeatureFlags: throwProviderNotMountedError
});

export interface FeatureFlagProviderProps {
  children: React.ReactNode;
  initialFeatureFlags?: FeatureFlag[];
  initialLoading?: boolean;
  initialLoadingError?: ErrorOrNull;
}

/**
 * FeatureFlag context provider. Will render `children` before fetching feature flag data. As result, you may have a "flash" of the off state before the feature data comes back from the server.
 */

export const Provider: React.ComponentType<
  React.PropsWithChildren<FeatureFlagProviderProps>
> = ({
  children,
  initialFeatureFlags = [],
  initialLoading = true,
  initialLoadingError = null
}) => {
  const { user, loading: userLoading } = useAuthContext();
  const [featureFlags, setFeatureFlags] =
    React.useState<FeatureFlag[]>(initialFeatureFlags);
  const [loading, setLoading] = React.useState(initialLoading);
  const [loadError, setLoadError] =
    React.useState<ErrorOrNull>(initialLoadingError);
  const [updateError, setUpdateError] = React.useState<ErrorOrNull>(null);

  const loadFeatureFlags = async () => {
    setLoading(true);
    try {
      const features = await API.getFeatureFlags(user?.token);
      if (features.length > 0) {
        setFeatureFlags(features);
      }
    } catch (error) {
      setLoadError(error as Error);
    }
    setLoading(false);
  };

  const updateFeatureFlags = async (features: FeatureFlag[]): Promise<void> => {
    // If the logged-in user doesn't have a token, we cannot call the API.
    // This should never happen.
    if (!user?.token) {
      setUpdateError(new Error('Bearer token required'));
      return;
    }

    // Enter a loading state, as we need to prevent the user from making any more feature flag changes.
    setLoading(true);

    // Update the feature flags on the server, then update our internal state by making another GET request.
    try {
      await API.updateFeatureFlags(
        user.token,
        features.map(({ id, state }) => ({ id, state }))
      );
      loadFeatureFlags();
    } catch (error) {
      setUpdateError(error as Error);
      setLoading(false);
    }
  };

  // Load the feature flags on mount.
  React.useEffect(() => {
    if (initialLoading === false) {
      return;
    }
    if (!userLoading) {
      loadFeatureFlags();
    }
  }, [userLoading]);

  // We intentionally render children before we get the response back from the feature flag API.
  // There are many pages which don't require feature flag data, and only a _very small_ subset of users will ever have feature flags enabled.

  return (
    <context.Provider
      value={{
        featureFlags,
        loading,
        loadFeatureFlags,
        loadError,
        updateFeatureFlags,
        updateError
      }}
    >
      {children}
    </context.Provider>
  );
};

/**
 * Use the FeatureFlag context.
 */

export const useFeatureFlags = (): ContextShape => React.useContext(context);

/**
 * Use the value (state) of a particular feature.
 *
 * Will return the provided `defaultState` if the feature flag has not been set, or if the context has not yet received data from the feature flag API.
 */

export const useFeatureFlagState = (
  id: string,
  defaultValue = false
): boolean => {
  const { featureFlags } = useFeatureFlags();
  const feature = featureFlags.find(f => f.id === id);
  return feature?.state ?? defaultValue;
};

export const useFeatureFlagLoading = (): boolean => {
  const { loading } = useFeatureFlags();
  return loading;
};
