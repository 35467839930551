import React, { FC, useCallback } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateFeatureFlagFields } from '../types';
import { Loader } from '@deque/cauldron-react';

import FeatureFlagProductSelect from './FeatureFlagProductSelect';
import FeatureFlagDescriptionField from './FeatureFlagDescriptionField';
import FeatureFlagIdField from './FeatureFlagIdField';
import FeatureFlagDefaultStateCheckbox from './FeatureFlagDefaultStateCheckbox';

type CreateFeatureFlagFormFieldsProps = {
  formControl: Control<CreateFeatureFlagFields, unknown>;
  loading?: boolean;
};

const CreateFeatureFlagFormFields: FC<CreateFeatureFlagFormFieldsProps> = ({
  formControl,
  loading
}) => {
  const { t } = useTranslation();
  const loaderRef = useCallback(
    (element: HTMLDivElement | null) => {
      if (loading && element) {
        element.focus();
      }
    },
    [loading]
  );

  return loading ? (
    <Loader label={t('Creating feature')} tabIndex={-1} ref={loaderRef} />
  ) : (
    <>
      <FeatureFlagIdField name="featureId" control={formControl} />
      <FeatureFlagProductSelect name="productName" control={formControl} />
      <FeatureFlagDescriptionField name="description" control={formControl} />
      <FeatureFlagDefaultStateCheckbox
        name="defaultState"
        control={formControl}
      />
    </>
  );
};

export default CreateFeatureFlagFormFields;
