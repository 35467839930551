import React, {
  FormEvent,
  ReactElement,
  useState,
  useCallback,
  useMemo
} from 'react';
import queryString from 'query-string';
import { Icon, Button, LoaderOverlay } from '@deque/cauldron-react';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { useAuthContext } from '../../common/contexts/auth';
import globalStyles from '../app.css';
import styles from './AcceptInvitation.css';
import billingClient, {
  Enterprises
} from '../../common/utils/billing-client/client-v1';
import useAPI, { State } from '../../common/hooks/useAPI';
import ErrorMessage from '../components/issue/ErrorMessage';
import useDidUpdate from '../../common/hooks/useDidUpdate';
import PageTitle from '../../common/components/PageTitle';
import { useHistory } from 'react-router-dom';

interface APIData {
  invitation?: Enterprises.Invitation;
  enterprise?: Enterprises.Enterprise;
}

type APIState = State<APIData>;

const AcceptInvitation = (): ReactElement => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const history = useHistory();
  const invitationId: string = useMemo(() => {
    const { id } = queryString.parse(window.location.search);
    return id as string;
  }, []);
  const fetchInvitation = useCallback(async (): Promise<APIData> => {
    const invitation = await billingClient.enterprises.getInvitation({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      token: user!.token,
      id: invitationId as string
    });

    const enterpriseAccount = await billingClient.enterprises.get({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      token: user!.token,
      id: invitation.enterprise_id
    });

    return {
      invitation,
      enterprise: enterpriseAccount
    };
  }, []);
  const { data, error, loading }: APIState = useAPI(fetchInvitation);
  const [invitationError, setInvitationError] = useState('');
  const [accepting, setAccepting] = useState(false);
  const accept = async () => {
    if (!data?.invitation) {
      return;
    }

    try {
      setAccepting(true);

      await billingClient.enterprises.acceptInvitation({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        token: user!.token,
        enterpriseId: data.invitation.enterprise_id,
        id: invitationId
      });

      // route to dashboard and show confirmation toast
      history.push('/?invitation-accepted=true');
    } catch (err) {
      setAccepting(false);
      setInvitationError(t('Accepting invitation failed. Please try again.'));
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    // clean up any previous submission errors
    setInvitationError('');
    accept();
  };

  useDidUpdate(() => {
    if (loading || !data?.invitation?.accepted_at) {
      return;
    }

    // they've already accepted this invitation - route them to dashboard
    history.push('/');
  }, [loading]);

  return (
    <form
      onSubmit={onSubmit}
      className={classNames(globalStyles.wrap, styles.acceptInvitation)}
    >
      <PageTitle title={t('Accept Invitation')} />

      {error && <ErrorMessage error={error.message} />}

      {(loading || accepting) && (
        <LoaderOverlay
          label={
            accepting
              ? t('Accepting invitation...')
              : t('Loading invitation...')
          }
          focusOnInitialRender
        />
      )}

      {data?.invitation && data?.enterprise && (
        <>
          <h1>{t('You’ve been invited to axe DevTools Extension')}</h1>
          <hr />
          <Trans>
            <p>
              You were invited by{' '}
              <strong>{{ senderEmail: data.invitation.sender_email }}</strong>{' '}
              at <strong>{{ enterprise: data.enterprise.name }}</strong> to
              access axe DevTools.
            </p>
            <p>
              {t(
                'Selecting “Accept Invitation” will grant you immediate access to axe DevTools and associate your Deque account:'
              )}
            </p>
            <div className={classNames(globalStyles.emailBlock, styles.email)}>
              {{ email: user?.email }}
            </div>
            <p>
              with the <strong>{{ enterprise: data.enterprise.name }}</strong>’s
              joint account.
            </p>
          </Trans>
          {invitationError && (
            <div role="alert">
              <div className="Error">{invitationError}</div>
            </div>
          )}
          <Button type="submit">
            <Icon type="check-circle" />
            {t('Accept Invitation')}
          </Button>
        </>
      )}
    </form>
  );
};

export default AcceptInvitation;
