import type { v2 } from '@deque/billing-service-client';
import { ProductSlugs } from '@deque/billing-utils';
import {
  AccessTypes,
  type EnterpriseMembers
} from '../../common/utils/billing-client/client-v2';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import {
  Button,
  Table,
  TableHeader,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  DescriptionList,
  DescriptionListItem,
  DescriptionTerm,
  DescriptionDetails,
  Pagination,
  PanelContent
} from '@deque/cauldron-react';
import { useProducts } from '../../common/contexts/products';
import {
  getAccessString,
  getCurrentAccess,
  UserChanges
} from '../utils/access-utils';
import styles from './ReviewUserChanges.css';

interface ReviewUserChangesProps {
  changedUsers: string[];
  members: EnterpriseMembers.PendingOrAcceptedMember[];
  changes: Partial<UserChanges>;
  onComplete: () => void;
  onCancel: () => void;
}

const ReviewUserChanges = ({
  /* istanbul ignore next */
  changedUsers = [],
  members,
  changes,
  onComplete,
  onCancel
}: ReviewUserChangesProps): JSX.Element => {
  const { t } = useTranslation();
  const wide = useMediaQuery('(min-width: 49rem)');
  const narrow = useMediaQuery('(max-width: 31rem)');
  const variant = wide ? 'wide' : narrow ? 'narrow' : 'medium';
  const { getProductBySlug } = useProducts();
  const [sort, setSort] = useState<'ascending' | 'descending' | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const itemStart = currentPage * itemsPerPage - itemsPerPage + 1;
  const itemEnd = Math.min(itemStart + itemsPerPage - 1, changedUsers.length);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onComplete();
  };

  const sortedTableData = !sort
    ? changedUsers
    : Array.from(changedUsers).sort((a, b) =>
        sort === 'ascending' ? a.localeCompare(b) : b.localeCompare(a)
      );

  const paginatedTableData = sortedTableData.slice(
    (currentPage - 1) * itemsPerPage,
    itemsPerPage * currentPage
  );

  const handleFirstPageClick = () => setCurrentPage(1);
  const handlePreviousPageClick = () =>
    setCurrentPage(Math.max(currentPage - 1, 1));
  const handleNextPageClick = () =>
    setCurrentPage(Math.max(currentPage + 1, 1));
  const handleLastPageClick = () =>
    setCurrentPage(Math.ceil(changedUsers.length / itemsPerPage));

  const getProductAccess = (changedUserEmail: string): JSX.Element => {
    const existingEnterpriseMember = members.find(
      ({ email }) => email === changedUserEmail
    );

    const productDisplay = (
      product: v2.Product,
      accessType: AccessTypes,
      message?: string
    ) => {
      return (
        <React.Fragment key={product.slug}>
          <dt>{product.name}:</dt>
          <dd>
            {getAccessString(t, accessType)}
            {message && <em>{message}</em>}
          </dd>
        </React.Fragment>
      );
    };

    return (
      <dl>
        {
          // List new products
          changes.newProducts?.map(product => {
            const existingProductAccess =
              existingEnterpriseMember?.product_access.find(
                m => m.product_slug === product.slug
              );
            if (existingProductAccess) {
              return null;
            }
            const accessType = getCurrentAccess(
              changes,
              product.slug as ProductSlugs
            );
            return productDisplay(product, accessType, t(' (new)'));
          })
        }
        {
          // List removal products
          changes.removalProducts?.map(product => {
            const existingProductAccess =
              existingEnterpriseMember?.product_access.find(
                m => m.product_slug === product.slug
              );
            if (!existingProductAccess) {
              return null;
            }
            const accessType = existingProductAccess.access_type as AccessTypes;
            return productDisplay(product, accessType, t(' (removal)'));
          })
        }
        {
          // List existing products that will not be changed
          existingEnterpriseMember?.product_access.map(access => {
            if (
              changes.removalProducts?.some(p => p.slug === access.product_slug)
            ) {
              return null;
            }
            const product = getProductBySlug(access.product_slug);
            if (!product) {
              return null;
            }
            const newAccessType = getCurrentAccess(
              changes,
              access.product_slug as ProductSlugs,
              access
            );
            const message =
              newAccessType !== access.access_type ? t(' (change)') : undefined;
            return productDisplay(product, newAccessType, message);
          })
        }
        <dt>{t('axe Account')}:</dt>
        <dd>
          {(existingEnterpriseMember?.is_admin && changes.isAdmin !== false) ||
          changes.isAdmin
            ? t('Admin')
            : t('General Access')}
          {((changes.isAdmin !== undefined &&
            changes.isAdmin !== existingEnterpriseMember?.is_admin) ||
            !existingEnterpriseMember) && (
            <em>{existingEnterpriseMember ? t(' (change)') : t(' (new)')}</em>
          )}
        </dd>
      </dl>
    );
  };

  return (
    <>
      <PanelContent>
        <form
          id="review-user-changes-form"
          className={styles.reviewUsers}
          onSubmit={handleSubmit}
        >
          {variant === 'wide' && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader
                    scope="col"
                    sortDirection={sort || 'none'}
                    sortAscendingAnnouncement={t('sorted ascending')}
                    sortDescendingAnnouncement={t('sorted descending')}
                    onSort={() =>
                      setSort(
                        !sort || sort === 'descending'
                          ? 'ascending'
                          : 'descending'
                      )
                    }
                  >
                    {t('User')}
                  </TableHeader>
                  <TableHeader scope="col">
                    {t('Product Access: Permissions')}
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedTableData.map(newUser => (
                  <TableRow key={newUser}>
                    <TableCell>{newUser}</TableCell>
                    <TableCell>{getProductAccess(newUser)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {variant !== 'wide' &&
            paginatedTableData.map(newUser => (
              <DescriptionList
                key={`${newUser}-dl`}
                collapsed={variant === 'narrow'}
              >
                <DescriptionListItem>
                  <DescriptionTerm>{t('User')}</DescriptionTerm>
                  <DescriptionDetails>{newUser}</DescriptionDetails>
                </DescriptionListItem>
                <DescriptionListItem>
                  <DescriptionTerm>
                    {t('Product Access: Permissions')}
                  </DescriptionTerm>
                  <DescriptionDetails>
                    {getProductAccess(newUser)}
                  </DescriptionDetails>
                </DescriptionListItem>
              </DescriptionList>
            ))}
          <div className={styles.actions}>
            <div className={styles.center}>
              <Pagination
                currentPage={currentPage}
                totalItems={changedUsers.length}
                onFirstPageClick={handleFirstPageClick}
                onPreviousPageClick={handlePreviousPageClick}
                onNextPageClick={handleNextPageClick}
                onLastPageClick={handleLastPageClick}
                statusLabel={
                  <span>
                    {variant === 'wide' && t('Showing')}{' '}
                    <strong>{itemStart}</strong> - <strong>{itemEnd}</strong> of{' '}
                    <strong>{changedUsers.length}</strong>
                  </span>
                }
              />
            </div>
          </div>
        </form>
      </PanelContent>
      <PanelContent>
        <Button type="button" variant="secondary" onClick={onCancel}>
          {t('Back')}
        </Button>
        <Button type="submit" form="review-user-changes-form" variant="primary">
          {t('Finish')}
        </Button>
      </PanelContent>
    </>
  );
};

export default ReviewUserChanges;
