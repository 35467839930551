import type { v2 } from '@deque/billing-service-client';
import React, {
  useState,
  useContext,
  ReactElement,
  useRef,
  useEffect
} from 'react';
import Keycloak from 'keycloak-js';
import { datadogLogs } from '@datadog/browser-logs';
import { useTranslation } from 'react-i18next';
import { getUser, User, getSSOConfig, analyticsEvent } from '../api-client';
import billingV2Client from '../utils/billing-client/client-v2';
import setAuthComplete from '../utils/set-auth-complete';
import { HubSpotProperties } from '../constants';

export interface AuthUser extends User {
  logout: (options?: { redirectUri: string }) => void;
}

export const LOGIN_INITIATED_FLAG_KEY = 'loginInitiated';

export interface AuthState {
  user?: AuthUser;
  loading: boolean;
  error: string;
  checkForUser: () => Promise<void>;
  isAuthenticated: boolean;
  login: (redirect_uri?: string) => void;
  createAccountUrl: (() => string) | null;
  billingUser?: v2.User;
  updateBillingUser: () => Promise<v2.User | void>;
}

export interface AuthenticatedState extends Omit<AuthState, 'user'> {
  user: AuthUser;
}

export interface AuthProviderProps {
  children: React.ReactNode;
  initialUser?: AuthUser;
  initialBillingUser?: v2.User;
  initialKeycloak?: Keycloak.KeycloakInstance | null;
  initialError?: string;
  initialLoading?: boolean;
  isOnPrem?: boolean;
}

const defaultContext = {
  loading: true,
  error: '',
  // eslint-disable-next-line
  checkForUser: /* istanbul ignore next */ () => Promise.resolve(),
  isAuthenticated: false,
  login: /* istanbul ignore next */ () => Promise.resolve(),
  createAccountUrl: /* istanbul ignore next */ () => '',
  updateBillingUser: /* istanbul ignore next */ () => Promise.resolve()
};

const AuthContext = React.createContext<AuthState>(defaultContext);
const { Provider } = AuthContext;

const AuthProvider = ({
  children,
  initialUser,
  initialBillingUser,
  initialKeycloak = null,
  initialError = defaultContext.error,
  isOnPrem = false,
  initialLoading = defaultContext.loading
}: AuthProviderProps): ReactElement => {
  const { t } = useTranslation();
  const keycloak = useRef<Keycloak.KeycloakInstance | null>(initialKeycloak);
  const [user, setUser] = useState<AuthUser | undefined>(initialUser);
  const [v2BillingUser, setV2BillingUser] = useState<v2.User | undefined>(
    initialBillingUser
  );
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState<string>(initialError);

  useEffect(() => {
    const onMessage = (e: MessageEvent) => {
      if (e.data.message !== 'extension:logout') {
        return;
      }

      keycloak.current?.logout();
    };

    window.addEventListener('message', onMessage);

    // If we already have a `user` (eg provided by tests), there's no need to re-fetch the data.
    if (user) {
      setLoading(false);
    } else {
      checkForUser().then(() => {
        setLoading(false);
      });
    }
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    datadogLogs.setUser({ id: user.id });
    analyticsEvent(user.token, HubSpotProperties.AXE_KEYCLOAK_ID, user.id);
    analyticsEvent(
      user.token,
      HubSpotProperties.AXE_LAST_LOGIN_DATE,
      new Date().setUTCHours(0, 0, 0, 0).toString(),
      'REPLACE'
    );
  }, [user]);

  const updateV2BillingUser = async () => {
    const token = keycloak.current?.token;

    // Without a token, we cannot make any API calls. The token is _required_!
    if (!token) {
      return;
    }

    try {
      const updatedV2BillingUser = await billingV2Client.me.get(token);
      setV2BillingUser(updatedV2BillingUser);
      return updatedV2BillingUser;
      // eslint-disable-next-line no-empty
    } catch {
      // silently fail
    }
    /* istanbul ignore next  */
    return;
  };

  const handleSetUser = async () => {
    const token = keycloak.current?.token;
    // Without a token, we cannot make any API calls. The token is _required_!
    if (!token) {
      return;
    }

    const currentUser = await getUser(token);

    setUser({
      ...currentUser,
      token,
      logout: (...args) => {
        window.postMessage({ message: 'app:logout' }, '*');
        keycloak.current?.logout(...args);
      }
    });

    try {
      // Update/get the v2 billing user, if one exists
      await updateV2BillingUser();

      // eslint-disable-next-line no-empty
    } catch (err) {} // silently fail

    // The axe extensions creates a new tab for authentication purposes and
    // looks for an `authComplete` param to determine when it's safe to onClose
    // the created tab
    const params = new URLSearchParams(window.location.search);
    if (params.get('fromextension')) {
      // When on-prem, we don't show the `<FirstLoginAlert>`, and therefore, never write an entry to the accounts table for the user.
      if (!isOnPrem) {
        // A user receives an entry in the "accounts" table after confirming our Ts and Cs. If `hasAccountEntry` is false, the user has not yet confirmed the Ts and Cs and therefore, the alert is still open.
        const isAlertOpen = !currentUser.hasAccountEntry;
        if (isAlertOpen) {
          return;
        }
      }
      setTimeout(setAuthComplete, 100);
    }
  };

  // instantiates and initializes keycloak (and updates the keycloak ref)
  const initKeycloak = async (): Promise<boolean> => {
    const authConfig = await getSSOConfig();
    const kc = new Keycloak({
      realm: authConfig.realm,
      url: `${authConfig.url}/auth`,
      clientId: authConfig.publicClientId
    });
    const authenticated = await kc.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
      timeSkew: 0
    });
    keycloak.current = kc;
    return authenticated;
  };

  // Checks for user by initializing keycloak, checking for an existing token
  // (which indicates an already logged in user), and setting the user if one
  // exists. NOTE: the user will NOT be redirected to the login page if not
  // authenticated - use `authenticate` for that functionality
  const checkForUser = async () => {
    try {
      if (!keycloak.current) {
        await initKeycloak();
      }
      await handleSetUser();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setError(t('Failed to initialize auth'));
    }
  };

  const login = (redirectUri = window.location.href) => {
    // we use this key to track the login flow when redirected back from keycloak and trigger login analytics event
    window.localStorage.setItem(LOGIN_INITIATED_FLAG_KEY, 'true');
    keycloak.current?.login({ redirectUri });
  };

  return (
    <Provider
      value={{
        user,
        loading,
        error,
        checkForUser,
        isAuthenticated: !!user?.id,
        login,
        createAccountUrl: keycloak.current && keycloak.current.createAccountUrl,
        billingUser: v2BillingUser,
        updateBillingUser: updateV2BillingUser
      }}
    >
      {children}
    </Provider>
  );
};

const useAuthContext = (): AuthState => useContext(AuthContext);

// A hook that ensures 'user' is defined, intended for use in protected routes when the user is guaranteed to be authenticated and loaded.
const useAuthenticatedContext = (): AuthenticatedState => {
  const context = useAuthContext();

  if (context.loading) {
    throw new Error(
      'useAuthenticatedContext was used in a context where the user is not yet loaded.'
    );
  }

  if (!context.user) {
    throw new Error(
      'useAuthenticatedContext was used in a context where the user is not authenticated.'
    );
  }

  return context as AuthenticatedState;
};

export { AuthProvider, useAuthContext, useAuthenticatedContext };
