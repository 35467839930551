import { screen } from '@testing-library/react';
import { assert } from 'chai';
import deepCopy from 'deep-copy';
import React from 'react';
import { TFunction } from 'react-i18next';
import sinon from 'sinon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SinonStubFunction<T extends (...args: any) => any> =
  sinon.SinonStub<Parameters<T>, ReturnType<T>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComponentProps<T extends React.ComponentType<any>> = Omit<
  React.ComponentProps<T>,
  'children'
>;

export const sleep = (override?: number): Promise<void> => {
  const ms = parseInt(process.env.APP_AVA_SLEEP_MS || '100', 10);
  return new Promise(r => setTimeout(r, override || ms));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const changeValue = (obj: any, target: string, newValue: any): void => {
  const components = target.split('.');
  const index = /^[0-9]*$/.test(components[0])
    ? parseInt(components[0], 10)
    : components[0];
  if (components.length === 1) {
    obj[index] = newValue;
    return;
  }
  return changeValue(obj[index], components.slice(1).join('.'), newValue);
};

interface Mod {
  t: string;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  v: any;
}

export const cloneModObject = <T>(obj: T, mods: Mod[]): T => {
  const clone = deepCopy(obj);
  mods.forEach(m => {
    changeValue(clone, m.t, m.v);
  });
  return clone;
};

export const t: TFunction<'translation', undefined> = (
  s: string,
  replacements?: unknown
) => {
  let res = s;
  if (replacements && typeof replacements === 'object') {
    for (const k of Object.keys(replacements as Record<string, unknown>)) {
      const regex = new RegExp(`{{\\s*${k}\\s*}}`, 'g');
      res = res.replace(
        regex,
        replacements[k as keyof typeof replacements] as string
      );
    }
  }
  return res;
};

// If the text is broken up into multiple nested nodes, the assertion will succeed if at least one of the nodes matches the text
export const findMatchingNodes = (text: string) => {
  const matchingNodes = screen.queryAllByText(
    (_content, node) => node?.textContent === text
  );
  // Matching nodes may be greater than 1 if the text is nested in multiple nodes, so we just need to make sure that there is at least one
  assert(matchingNodes.length > 0, `No matching nodes found for ${text}`);
};

export const findNotMatchingNodes = (text: string) => {
  const matchingNodes = screen.queryAllByText(
    (_content, node) => node?.textContent === text
  );
  assert(matchingNodes.length === 0, `Matching nodes found for ${text}`);
};
