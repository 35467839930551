export type CreateFeatureFlagFields = {
  featureId: string;
  description: string;
  defaultState: boolean;
  productName: string;
};

export type EditFeatureFlagFields = CreateFeatureFlagFields;

export interface StepMap {
  header: string;
  description?: string | React.ReactNode;
  content: React.ReactNode;
  stepFields: string[];
}

export type Impacts = 'critical' | 'serious' | 'moderate' | 'minor';

export const IMPACTS: Impacts[] = ['critical', 'serious', 'moderate', 'minor'];

export interface TemplateFormFields {
  templateName: string;
  project: string;
  issueType: string;
  fieldMapping: Record<string, string>[];
  impactMapping: Record<Impacts, string>;
  customLabels: Record<string, string>[];
}

export type TemplateFormFieldsKeys = keyof TemplateFormFields;

export enum EditVariant {
  edit,
  create
}
