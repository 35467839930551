import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@deque/cauldron-react';
import styles from './Loadable.css';

interface BoundaryProps {
  errorText: string;
  children: React.ReactNode;
}

interface BoundaryState {
  error: Error | null;
}

/** Simple error boundary for wrapping `React.lazy()`. */
class Boundary extends React.Component<BoundaryProps, BoundaryState> {
  state = { error: null };

  static getDerivedStateFromError = (error: Error): BoundaryState => ({
    error
  });

  render(): React.ReactNode {
    const { error } = this.state;
    const { children, errorText } = this.props;

    if (error) {
      return <p className={styles.error}>{errorText}</p>;
    }

    return children;
  }
}

interface Props {
  children: React.ReactNode;
}

/** Utility component for wrapping a `React.lazy()` import. */
const Loadable: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const loaderRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  return (
    <Boundary
      errorText={t(
        'An error occurred and we were unable to load the required data for this page.'
      )}
    >
      <React.Suspense
        fallback={
          <Loader label={t('Loading...')} tabIndex={-1} ref={loaderRef} />
        }
      >
        {children}
      </React.Suspense>
    </Boundary>
  );
};

export default Loadable;
