import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Workspace } from '@deque/cauldron-react';
import GlobalToast from './GlobalToast';
import FirstLoginAlert from './FirstLoginAlert';
import styles from './MainContent.css';
import Footer from './Footer';
import useDidUpdate from '../hooks/useDidUpdate';
import InvitationsToast from './InvitationsToast';
import { useFeatureFlagState } from '../contexts/featureFlags';

interface Props {
  children: React.ReactNode;
  shouldShowFooter?: boolean;
}

const MainContent = ({
  children,
  shouldShowFooter = true
}: Props): React.ReactElement => {
  const main = useRef<HTMLDivElement>(null);
  const isInvitationToastEnabled = useFeatureFlagState('invitations_toast');
  const location = useLocation();

  useDidUpdate(() => {
    main.current?.focus();
  }, [location.pathname]);

  return (
    <Workspace noSideBar id="main-content" tabIndex={-1} workspaceRef={main}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <GlobalToast />
          {isInvitationToastEnabled && <InvitationsToast />}
          <FirstLoginAlert />
          {children}
        </div>
      </div>
      {shouldShowFooter && <Footer />}
    </Workspace>
  );
};

export default MainContent;
