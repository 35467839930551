import Analytics from './Analytics';
import { ProductSlugs } from './constants';

export enum AxeAccountAmplitudeEvents {
  LOGIN = 'login',
  VIEW_PAYMENT_PAGE = 'conversion:viewpayment',
  VIEW_LICENSE_AGREEMENT = 'conversion:license',
  VIEW_PRIVACY_POLICY = 'conversion:privacy',
  CONFIG_GLOBAL_VIEW = 'configuration:global:view'
}

export type DynamicEvents =
  | `configuration:${string}:submit`
  | `configuration:${string}:discardForm`;

type ConfigTab = 'global' | ProductSlugs;

export type EventData = Record<string, string | boolean | number>;

export default class AxeAccountAnalytics extends Analytics<
  AxeAccountAmplitudeEvents | DynamicEvents
> {
  public login() {
    return this.sendAmplitudeEvent({ event: AxeAccountAmplitudeEvents.LOGIN });
  }

  public viewPaymentPage() {
    return this.sendAmplitudeEvent({
      event: AxeAccountAmplitudeEvents.VIEW_PAYMENT_PAGE
    });
  }

  public viewLicenseAgreement() {
    return this.sendAmplitudeEvent({
      event: AxeAccountAmplitudeEvents.VIEW_LICENSE_AGREEMENT
    });
  }

  public viewPrivacyPolicy() {
    return this.sendAmplitudeEvent({
      event: AxeAccountAmplitudeEvents.VIEW_PRIVACY_POLICY
    });
  }

  public configView(isAdmin: boolean) {
    return this.sendAmplitudeEvent({
      event: AxeAccountAmplitudeEvents.CONFIG_GLOBAL_VIEW,
      data: { isAdmin }
    });
  }

  public configSubmit(tab: ConfigTab, data: EventData) {
    return this.sendAmplitudeEvent({
      event: `configuration:${tab}:submit`,
      data
    });
  }

  public configDiscard(tab: ConfigTab) {
    return this.sendAmplitudeEvent({
      event: `configuration:${tab}:discardForm`
    });
  }
}
