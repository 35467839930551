import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Offscreen, Step, Stepper } from '@deque/cauldron-react';

import type { StepMap } from '../../../../types';
import getStepStatus, {
  getCapitalizedStepStatus
} from '../../../../../common/utils/get-step-status';
import useMediaQuery from '../../../../../common/hooks/useMediaQuery';
import styles from './TemplateStepper.css';

export interface TemplateStepper {
  currentStep: number;
  steps: StepMap[];
}

const TemplateStepper = ({ currentStep, steps }: TemplateStepper) => {
  const { t } = useTranslation();
  const narrow = useMediaQuery('(max-width: 37.5rem)');

  const stepsContent = steps.map(({ header }, index) => {
    const stepStatus = getStepStatus(currentStep, index);
    const stepStatusCapitalized = getCapitalizedStepStatus(t, stepStatus);
    const offscreenStepStatus =
      stepStatus === 'complete'
        ? t('Completed step')
        : t('{{stepStatusCapitalized}} step', { stepStatusCapitalized });

    if (!narrow) {
      return (
        <Step status={stepStatus} key={header}>
          {header}
          <Offscreen>{offscreenStepStatus}</Offscreen>
        </Step>
      );
    }

    const tooltip = (
      <Trans>
        <div>
          Step {{ i: index + 1 }}: {{ header }}
        </div>
        <div>Status: {{ stepStatusCapitalized }}</div>
      </Trans>
    );

    return (
      <Step
        status={stepStatus}
        key={header}
        tooltip={tooltip}
        tooltipText={`${header} ${stepStatus}`}
      >
        <Offscreen>{`${header} ${offscreenStepStatus}`}</Offscreen>
      </Step>
    );
  });

  return (
    <Stepper className={narrow ? styles.narrowStepper : styles.stepper}>
      {stepsContent}
    </Stepper>
  );
};

export default TemplateStepper;
