import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from './PageTitle';
import styles from './NotOnPrem.css';
import { useOnPremValue } from '../contexts/onPrem';
import { Loader } from '@deque/cauldron-react';
import { useServerInfo } from '../contexts/serverInfo';

/** Default fallback content. */
const Fallback: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.error}>
      <PageTitle
        title={t('Unavailable for deployments without billing service')}
      />
      <h1>
        {t(
          'This page is not available for deployments without billing service'
        )}
      </h1>
    </div>
  );
};

interface Props {
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

/**  Utility component used to disable content when billing service is not enabled in an on-prem environment.
 * @param [fallback="Default fallback content"] - fallback content to be rendered when billing service is not enabled.
 * @param children - content to be rendered when billing service is enabled.
 */
const BillingRequired: React.FC<Props> = ({
  children,
  fallback = <Fallback />
}) => {
  const { t } = useTranslation();
  const isOnPrem = useOnPremValue();
  const [isBillingEnabled, setIsBillingEnabled] = useState(!isOnPrem);
  const [loading, setLoading] = useState(isOnPrem);
  const { serverInfo, loading: serverInfoLoading } = useServerInfo();

  useEffect(() => {
    if (isOnPrem) {
      // If the deployment is on-prem, we need to check if the billing service is enabled.
      if (serverInfoLoading) {
        // If the server info is still loading, we need to wait for it to finish.
        return;
      }
      if (serverInfo) {
        // If the server info is available, we can check if the billing service is enabled.
        setIsBillingEnabled(serverInfo.billingServiceEnabled);
      } else {
        // If server info is not available, we can ignore and assume that the billing service is not enabled.
      }
    } else {
      // Billing service is always enabled for `non on-prem` deployments.
      setIsBillingEnabled(true);
    }
    setLoading(false);
  }, [isOnPrem, serverInfo, serverInfoLoading]);

  if (loading) {
    return <Loader label={t('Loading...')} />;
  }
  if (isBillingEnabled) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
};

export default BillingRequired;
