import type { JiraFieldMapper } from './types';
import { ShortTextMapper } from './ShortTextMapper';
import { LabelsMapper } from './LabelsMapper';
import { NumberMapper } from './NumberMapper';
import { UrlMapper } from './UrlMapper';
import { DateMapper } from './DateMapper';
import { SingleOptionMapper } from './SingleOptionMapper';
import { MultiOptionsMapper } from './MultiOptionsMapper';

const JiraShortTextMapper = new ShortTextMapper();
const JiraNumberMapper = new NumberMapper();
const JiraLabelsMapper = new LabelsMapper();
const JiraUrlMapper = new UrlMapper();
const JiraDateMapper = new DateMapper();
const JiraSingleOptionMapper = new SingleOptionMapper();
const JiraMultiOptionsMapper = new MultiOptionsMapper();

const JiraFieldMapper: Record<string, JiraFieldMapper> = {
  [JiraShortTextMapper.id]: JiraShortTextMapper,
  [JiraNumberMapper.id]: JiraNumberMapper,
  [JiraLabelsMapper.id]: JiraLabelsMapper,
  [JiraUrlMapper.id]: JiraUrlMapper,
  [JiraDateMapper.id]: JiraDateMapper,
  [JiraSingleOptionMapper.id]: JiraSingleOptionMapper,
  [JiraMultiOptionsMapper.id]: JiraMultiOptionsMapper
};

export default JiraFieldMapper;
