import React from 'react';

/**
 * Suffix added to all page titles.
 *
 * NOTE: This was previously accomplished by using `<Helmet titleTemplate="%s | axe DevTools" />`.
 */
export const TITLE_SUFFIX = ' | axe DevTools';

interface Props {
  /** The document title. */
  title: string;
  suffix?: string;
}

/** PageTitle is a utility component for setting the document title. */
const PageTitle: React.FC<Props> = ({ title, suffix = TITLE_SUFFIX }) => {
  React.useEffect(() => {
    const previousTitle = document.title;
    document.title = title + suffix;
    return () => {
      document.title = previousTitle;
    };
  }, [title]);

  return null;
};

export default PageTitle;
