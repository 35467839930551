import React from 'react';
import { Notice } from '@deque/cauldron-react';
import { Trans, useTranslation } from 'react-i18next';

const DQUPrePurchaseNotification = () => {
  const { t } = useTranslation();

  return (
    <Notice
      type="info"
      title={<h3>{t('Purchase Processing Time')}</h3>}
      role="note"
    >
      <Trans>
        <p>
          We will process your request (typically on business days between the
          hours of 9:00am and 5:00pm U.S. Eastern Standard Time) and send an
          email notification once your courses are ready for viewing.
        </p>

        <p>
          If you are subscribing someone else or multiple people, please send a
          list with the full name and email address of each subscriber to{' '}
          <a rel="noopener noreferrer" href="mailto:training@deque.com">
            training@deque.com
          </a>
        </p>
      </Trans>
    </Notice>
  );
};

export default DQUPrePurchaseNotification;
