import React from 'react';
import { getSSOConfig, SSOConfig } from '../../common/api-client';
import useAPI, { State } from '../../common/hooks/useAPI';

const useSSOConfig = (): State<SSOConfig> => {
  const dataLoader = React.useCallback(() => getSSOConfig(), []);
  return useAPI(dataLoader);
};

export default useSSOConfig;
