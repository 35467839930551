import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Stepper,
  Step,
  Offscreen
} from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';

import useDidUpdate from '../hooks/useDidUpdate';
import classes from './StepperModal.css';

type StepStatus = 'complete' | 'current' | 'future';
export interface Step {
  heading: string;
  content: ReactElement;
  isValid?: () => boolean;
}

export interface StepperModalProps {
  heading: string;
  steps: Step[];
  onClose: () => void;
  onSubmit: () => Promise<void>;
  dialogOpen?: boolean;
  stepOverride?: number | null;
}

const StepperModal = ({
  heading,
  onSubmit,
  steps,
  onClose,
  stepOverride = null,
  dialogOpen = false
}: StepperModalProps): ReactElement => {
  const { t } = useTranslation();
  const loaderRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLHeadingElement>(null);

  const [currentStep, setCurrentStep] = useState(0);

  const getStepStatus = (stepNumber: number) =>
    currentStep === stepNumber
      ? 'current'
      : currentStep > stepNumber
      ? 'complete'
      : 'future';

  const getOffscreenText = (stepStatus: StepStatus) =>
    stepStatus === 'complete' ? (
      <Offscreen>{t('Completed step')}</Offscreen>
    ) : stepStatus === 'future' ? (
      <Offscreen>{t('Future step')}</Offscreen>
    ) : (
      <Offscreen>{t('Current step')}</Offscreen>
    );

  const incrementStep = () => setCurrentStep(s => s + 1);
  const onBackClick = () => setCurrentStep(s => s - 1);
  const onContinueClick = async () => {
    const isValid = steps[currentStep].isValid
      ? steps[currentStep].isValid?.()
      : true;

    if (isValid) {
      if (currentStep === steps.length - 1) {
        await onSubmit();
        return;
      }
      incrementStep();
    }
  };

  useDidUpdate(() => {
    headingRef.current?.focus();
  }, [currentStep]);

  useEffect(() => {
    if (stepOverride !== null) {
      setCurrentStep(stepOverride);
    }
  }, [stepOverride]);

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  return (
    <Modal
      className={classes.stepperModal}
      aria-labelledby="stepper-modal-dialog-heading"
      show={dialogOpen}
      heading={<span id="stepper-modal-dialog-heading">{heading}</span>}
      onClose={onClose}
    >
      <ModalContent>
        <Stepper>
          {steps.map((step: Step, i: number) => (
            <Step
              key={step.heading}
              status={getStepStatus(i)}
              aria-label={step.heading}
            >
              {step.heading}
              {getOffscreenText(getStepStatus(i))}
            </Step>
          ))}
        </Stepper>
        <div className={classes.dialogMainContent}>
          <h3 ref={headingRef} tabIndex={-1}>
            {steps[currentStep].heading}
          </h3>
          {steps[currentStep].content}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          variant="secondary"
          onClick={currentStep === 0 ? onClose : onBackClick}
        >
          {currentStep === 0 ? t('Cancel') : t('Back')}
        </Button>
        <Button onClick={onContinueClick}>
          {currentStep === Object.keys(steps).length - 1
            ? t('Save')
            : t('Continue')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StepperModal;
