import React from 'react';
import { ServerInfo, getServerInfo } from '../api-client';
import useAPI from '../hooks/useAPI';

export interface ServerInfoState {
  serverInfo: ServerInfo | null;
  loading: boolean;
  error: Error | null;
}
const defaultContext = {
  serverInfo: null,
  loading: false,
  error: null
};
const context = React.createContext<ServerInfoState>(defaultContext);

interface ProviderProps {
  children: React.ReactNode;
  initialServerInfo?: ServerInfo | null;
}

export const Provider: React.FC<ProviderProps> = ({
  children,
  initialServerInfo
}) => {
  const { data: serverInfo, loading, error } = useAPI(getServerInfo);

  return (
    <context.Provider
      value={{ serverInfo: initialServerInfo || serverInfo, loading, error }}
    >
      {children}
    </context.Provider>
  );
};

export const useServerInfo = (): ServerInfoState => React.useContext(context);
