import React, { ReactElement, useEffect, useLayoutEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PageTitle from '../../common/components/PageTitle';
import inspectRightClick from '../assets/images/inspect-right-click.png';
import axePane from '../assets/images/devtools-axe-pane.png';
import styles from './Installed.css';
import { Link } from 'react-router-dom';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { PRODUCT_URLS, ProductSlugs } from '../../common/constants';
import { useAnalyticsInstance } from '../../common/contexts/analytics';

const Installed = (): ReactElement => {
  const { t } = useTranslation();
  const hasProWalkthrough = useFeatureFlagState('pro_walkthrough_v1');
  const analytics = useAnalyticsInstance(ProductSlugs.axeDevToolsExtension);

  useEffect(() => {
    analytics.viewInstallPage(window.location.href);
  }, []);
  const appVer = navigator.appVersion;
  // from https://developers.google.com/web/tools/chrome-devtools/open
  /* istanbul ignore next */
  const keyboardShortcut = appVer.includes('Mac') ? (
    <Trans>
      Open developer tools by pressing <kbd>command</kbd> &#43;{' '}
      <kbd>option</kbd> &#43; <kbd>i</kbd> or by right-clicking on the screen
      and selecting &quot;Inspect&quot;.
      <div>
        <img className={styles.inspect} src={inspectRightClick} alt="" />
      </div>
    </Trans>
  ) : (
    <Trans>
      Open developer tools by pressing <kbd>ctrl</kbd> &#43; <kbd>shift</kbd>{' '}
      &#43; <kbd>i</kbd> or by right-clicking on the screen and selecting
      &quot;Inspect&quot;.
      <div>
        <img className={styles.inspect} src={inspectRightClick} alt="" />
      </div>
    </Trans>
  );

  useLayoutEffect(() => {
    document.body.classList.add('Flexmain');
    return () => document.body.classList.remove('Flexmain');
  }, []);

  return (
    <>
      <PageTitle title={t('Extension installed')} />
      <div className={classNames('narrowCenteredContainer', styles.wrap)}>
        <div>
          <h1>{t('Axe DevTools successfully installed!')}</h1>
          {hasProWalkthrough && (
            <>
              <h2>{t('Ready to start testing?')}</h2>
              <p>
                {t(
                  'Follow along with our step by step walkthrough of the extension. We will show you how the product works so you can start fixing accessibility issues.'
                )}
              </p>
              <Link
                to={`${
                  PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].root
                }/walkthrough?utm_source=webapp_install_success&utm_medium=referral&utm_campaign=start_walkthrough`}
                className="Button--primary"
              >
                {t('Start walkthrough')}
              </Link>
              <p className={styles.orFollowInstructions}>
                {t('Or, follow the instructions below.')}
              </p>
              <h2 className={styles.instructionsHeading}>
                {t('Instructions')}
              </h2>
            </>
          )}
          {!hasProWalkthrough && <h2>{t('Getting started')}</h2>}
          <div className={styles.instructions}>
            <ol>
              <li>{keyboardShortcut}</li>
              <li>
                <div>{t('Navigate to the "axe DevTools" tab')}</div>
                <img className={styles.pane} src={axePane} alt="" />
              </li>
              <li>
                <Trans>
                  <Link to="/axe-devtools/plans?utm_source=webapp_install_success&utm_medium=referral&utm_campaign=install_success_activate_your_free_trial">
                    Activate your free trial
                  </Link>
                  &nbsp; to unlock all Pro features including:
                  <ul>
                    <li>Saving, Exporting, Sharing</li>
                    <li>Testing a part of your page</li>
                    <li>
                      Intelligent Guided Testing (IGTs) powered by machine
                      learning
                    </li>
                  </ul>
                </Trans>
              </li>
            </ol>
            <p>
              {t(
                'Now you’re ready to use the world’s most trusted accessibility testing application!'
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Installed;
