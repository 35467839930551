import React, { useRef, useState, type ReactElement } from 'react';
import Settings from '../components/AccountSettings/AccountSettings';
import { useConfiguration } from '../../common/contexts/Configuration';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { useAuthContext } from '../../common/contexts/auth';
import { SettingsSchemaAxeAccountJobFunctionValueEnum as JobFunctionSchema } from '@deque/orgwide-settings-client';
import {
  getRealmInfo,
  updateAccount,
  updatePassword
} from '../../common/api-client';
import { useGlobalToast } from '../../common/contexts/globalToast';
import { useTranslation } from 'react-i18next';
import validateGeneralSettingsForm, {
  Errors
} from '../../common/utils/validate-general-settings-form';
import validatePasswordSettingsForm from '../../common/utils/validate-password-settings-form';

const AccountSettings = (): ReactElement => {
  const narrow = useMediaQuery('(max-width: 31.25rem)');
  const { user, loading: userLoading, error: userError } = useAuthContext();
  const {
    settings,
    updateSettings,
    loading: configurationLoading,
    loadingError
  } = useConfiguration();
  const { setContents } = useGlobalToast();
  const { t } = useTranslation();

  const [generalErrors, setGeneralErrors] = useState<Errors>({});
  const [passwordErrors, setPasswordErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  /** input refs */
  const emailRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const jobFunctionRef = useRef<HTMLSelectElement>(null);
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const loading = configurationLoading || userLoading;
  const error = loadingError || userError;

  const handleGeneralSubmit = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    if (!firstNameRef.current || !lastNameRef.current || !emailRef.current) {
      setContents(t('Something went wrong. Please try again.'), 'caution');
      return;
    }

    const formErrors = validateGeneralSettingsForm(
      {
        firstName: firstNameRef.current,
        lastName: lastNameRef.current,
        email: emailRef.current
      },
      t
    );

    if (Object.keys(formErrors).length) {
      setGeneralErrors(formErrors);
      return;
    } else {
      setGeneralErrors({});
    }

    try {
      setSubmitting(true);
      await updateSettings({
        axeAccount: {
          jobFunction: {
            value: jobFunctionRef.current
              ?.value as unknown as JobFunctionSchema | null,
            type: 'default'
          }
        }
      });

      await updateAccount(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user!.id,
        {
          email: emailRef.current?.value,
          firstName: firstNameRef.current?.value,
          lastName: lastNameRef.current?.value
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user!.token
      );
      setContents(t('Settings successfully saved.'), 'confirmation');
    } catch (e) {
      setContents(t('Failed to save settings. Please try again.'), 'caution');
    } finally {
      setSubmitting(false);
    }
  };

  const handlePasswordSubmit = async (
    event: React.ChangeEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    setSubmitting(true);

    if (
      !currentPasswordRef.current ||
      !passwordRef.current ||
      !confirmPasswordRef.current
    ) {
      setContents(t('Something went wrong. Please try again.'), 'caution');
      return;
    }

    let realmInfo;
    try {
      realmInfo = await getRealmInfo();
    } catch {
      setSubmitting(false);
      setContents(
        t('We were unable to fetch password requirements.'),
        'caution'
      );
      return;
    }

    const formErrors = validatePasswordSettingsForm(
      {
        currentPassword: currentPasswordRef.current,
        password: passwordRef.current,
        confirmPassword: confirmPasswordRef.current
      },
      realmInfo?.passwordPolicy || {},
      t
    );

    if (Object.keys(formErrors).length) {
      setPasswordErrors(formErrors);
      setSubmitting(false);
      return;
    } else {
      setPasswordErrors({});
    }

    try {
      await updatePassword(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user!.id,
        {
          currentPassword: currentPasswordRef.current?.value || '',
          password: passwordRef.current?.value || '',
          confirmPassword: confirmPasswordRef.current?.value || ''
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        user!.token
      );
      setContents(t('Password successfully changed.'), 'confirmation');
    } catch (e) {
      setContents(t('Failed to save password. Please try again.'), 'caution');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Settings
      loading={loading}
      error={error}
      generalErrors={generalErrors}
      passwordErrors={passwordErrors}
      submitting={submitting}
      narrow={narrow}
      user={user}
      settings={settings}
      handleGeneralSubmit={handleGeneralSubmit}
      handlePasswordSubmit={handlePasswordSubmit}
      emailRef={emailRef}
      firstNameRef={firstNameRef}
      lastNameRef={lastNameRef}
      jobFunctionRef={jobFunctionRef}
      currentPasswordRef={currentPasswordRef}
      passwordRef={passwordRef}
      confirmPasswordRef={confirmPasswordRef}
    />
  );
};

export default AccountSettings;
