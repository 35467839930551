import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProductLogo.css';
import classNames from 'classnames';

interface ProductLogoProps {
  title: string;
  subtitle?: React.ReactNode;
  homeURL?: string;
}

const ProductLogo = ({
  title,
  subtitle,
  homeURL
}: ProductLogoProps): React.ReactElement => {
  const formattedTitle = title.startsWith('axe ') ? (
    <>
      axe <span>{title.substring(4)}</span>
    </>
  ) : (
    <span>{title}</span>
  );

  const content = (
    <div className={styles.content}>
      <div className={styles.title}>{formattedTitle}</div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  );

  // TODO: once multiple integrations are supported, this will need to be updated to handle it
  const integrationStyles = title.match(/integration/i)
    ? styles.integration
    : undefined;

  return (
    <div className={classNames(styles.wrap, integrationStyles)}>
      {homeURL ? <Link to={homeURL}>{content}</Link> : content}
    </div>
  );
};

export default ProductLogo;
