import type { v2 } from '@deque/billing-service-client';
import React, { createContext, useCallback, useState, useEffect } from 'react';
import billingClient from '../utils/billing-client/client-v2';

/**
 * State stored within the context.
 */

interface State {
  products: v2.Product[];
  loading: boolean;
  error: Error | null;
  getProductBySlug: (slug: string) => v2.Product | undefined;
  getProductById: (id: string) => v2.Product | undefined;
}

/**
 * Throw a "helpful" error if using context methods without mounting its provider.
 */

const throwProviderNotMountedError = (): never => {
  throw new Error('Products: Provider not mounted');
};

const Context = createContext<State>({
  products: [],
  loading: true,
  error: null,
  getProductBySlug: throwProviderNotMountedError,
  getProductById: throwProviderNotMountedError
});

interface ProviderProps {
  initialProducts?: v2.Product[];
  initialLoading?: boolean;
  initialError?: Error | null;
  children: React.ReactNode;
}

export const ProductsProvider: React.ComponentType<ProviderProps> = ({
  initialProducts = [],
  initialLoading = false,
  initialError = null,
  children
}) => {
  const [products, setProducts] = useState<v2.Product[]>(initialProducts);
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState<Error | null>(initialError);

  useEffect(() => {
    (async () => {
      if (!products.length) {
        try {
          setLoading(true);
          setProducts(await billingClient.products.list());
        } catch (ex) {
          setError(ex as Error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  const getProductBySlug = useCallback(
    (slug: string) => {
      return products?.find(product => product.slug === slug);
    },
    [products]
  );

  const getProductById = useCallback(
    (id: string) => products?.find(product => product.id === id),
    [products]
  );

  return (
    <Context.Provider
      value={{
        products,
        loading,
        error,
        getProductBySlug,
        getProductById
      }}
    >
      {children}
    </Context.Provider>
  );
};

/**
 * Use the Products context.
 */

export const useProducts = (): State => React.useContext(Context);
