import { Button, Icon, PanelContent } from '@deque/cauldron-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  CategoryFilter,
  FilterCategory,
  FilterValues,
  FiltersLabelsAndValues
} from '../../../hooks/useUserManagementFilters';
import { filterCategories } from '../../../hooks/useUserManagementFilters';
import styles from './FilterBar.css';
import FilterModal from './FilterModal/FilterModal';
import FilterTag from './FilterTag/FilterTag';

export interface FilterConfigs<T extends FilterCategory> {
  categoryValue: T;
  filters: {
    label: string;
    value: CategoryFilter<T>;
  }[];
  selectedFilters: CategoryFilter<T>[];
}

export interface FilterBarProps {
  allowedFilterValuesAndLabels: FiltersLabelsAndValues;
  appliedFilters: FilterValues;
  handleApplyFilters: (filters: FilterValues) => void;
}

const FilterBar = ({
  allowedFilterValuesAndLabels,
  appliedFilters,
  handleApplyFilters
}: FilterBarProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <PanelContent padding className={styles.filterPanelContainer}>
      <Button
        variant="tag"
        className={styles.filtersButton}
        onClick={() => setIsOpen(true)}
      >
        {t('Filter:')} <Icon type="plus" className={styles.plusIcon} />{' '}
        <strong className={styles.addFilters}>{t('Add')}</strong>
      </Button>
      {filterCategories.map(category => {
        return (
          appliedFilters[category].length > 0 &&
          appliedFilters[category].map(filter => (
            <FilterTag
              key={filter}
              categoryValue={category}
              value={filter}
              appliedFilters={appliedFilters}
              handleApplyFilters={handleApplyFilters}
              allowedFilterValuesAndLabels={allowedFilterValuesAndLabels}
            />
          ))
        );
      })}
      {isOpen && (
        <FilterModal
          setIsOpen={setIsOpen}
          appliedFilters={appliedFilters}
          handleApplyFilters={handleApplyFilters}
          allowedFilterValuesAndLabels={allowedFilterValuesAndLabels}
        />
      )}
    </PanelContent>
  );
};

export default FilterBar;
