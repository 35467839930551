import { apiRequest } from '../../../api-client';

// TODO: Figure out why importing this from the billing client breaks everything?
export enum AccessTypes {
  GENERAL = 'GENERAL',
  ADMIN = 'ADMIN'
}
export interface ProductAccess {
  product_slug: string;
  access_type: string;
}
interface EnterpriseMember {
  is_admin: boolean;
  // A list of product slugs the user has access to
  product_access: ProductAccess[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EnterpriseMembers {
  // A user who was added directly to the billing service without having
  // logged in to the app and so does not have a user record yet.
  export interface PendingUser extends EnterpriseMember {
    user_id: string;
  }

  // A user who has logged in and accepted an invitation to the enterprise
  // or was added automatically.
  export interface AcceptedMember extends EnterpriseMember {
    user_id: string;
    email: string;
    first_name: string | null;
    last_name: string | null;
  }

  // A member who has been sent an invite but has not yet accepted the invitation.
  export interface PendingMember extends EnterpriseMember {
    invitation_id: string;
    email: string;
  }

  export type Member =
    | EnterpriseMembers.PendingMember
    | EnterpriseMembers.AcceptedMember
    | EnterpriseMembers.PendingUser;

  export type PendingOrAcceptedMember =
    | EnterpriseMembers.PendingMember
    | EnterpriseMembers.AcceptedMember;
}

export function memberIsPendingUser(
  member: EnterpriseMembers.Member
): member is EnterpriseMembers.PendingUser {
  return 'user_id' in member && !('email' in member);
}

export function memberIsPending(
  member: EnterpriseMembers.Member
): member is EnterpriseMembers.PendingMember {
  return 'invitation_id' in member;
}

export function memberHasAccepted(
  member: EnterpriseMembers.Member
): member is EnterpriseMembers.AcceptedMember {
  return 'user_id' in member && 'email' in member;
}

interface EnterpriseMembersProps {
  token: string;
}

interface ListMembersProps extends EnterpriseMembersProps {
  enterpriseId: string;
}

interface DeleteMemberProps extends EnterpriseMembersProps {
  enterpriseId: string;
  memberId: string;
}

interface RemoveProductAccessProps extends EnterpriseMembersProps {
  enterpriseId: string;
  memberId: string;
  productId: string;
}

type EditProductAccessProps = RemoveProductAccessProps & {
  accessType: string;
};

export default {
  /** Gets the list of enterprise members */
  list({
    token,
    enterpriseId
  }: ListMembersProps): Promise<EnterpriseMembers.Member[]> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/members`,
      method: 'GET',
      token
    });
  },
  /** Removes an enterprise member */
  remove({ token, enterpriseId, memberId }: DeleteMemberProps): Promise<void> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/members/${memberId}`,
      method: 'DELETE',
      token
    });
  },
  /** Removes a member's access to an enterprise subscription */
  removeProductAccess({
    token,
    enterpriseId,
    memberId,
    productId
  }: RemoveProductAccessProps): Promise<void> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/members/${memberId}/products/${productId}`,
      method: 'DELETE',
      token
    });
  },
  /** Updates a member's access type to an enterprise subscription */
  updateProductAccess({
    token,
    enterpriseId,
    memberId,
    productId,
    accessType
  }: EditProductAccessProps): Promise<void> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/members/${memberId}/products/${productId}`,
      method: 'PUT',
      body: {
        access_type: accessType
      },
      token
    });
  }
};
