import type { ErrorOrNull } from '../../../typings/utils';

export const toError = (error: string | ErrorOrNull): ErrorOrNull => {
  if (!error) {
    return null;
  }

  if (typeof error === 'string') {
    return new Error(error);
  }

  return error;
};
