import React from 'react';

const context = React.createContext<boolean>(false);

interface ProviderProps {
  isOnPrem: boolean;
  children: React.ReactNode;
}

export const Provider: React.FC<ProviderProps> = ({ isOnPrem, children }) => {
  return <context.Provider value={isOnPrem}>{children}</context.Provider>;
};

/**
 * Are we running in an on-premises environment?
 */

export const useOnPremValue = (): boolean => React.useContext(context);
