import React from 'react';
import { Trans } from 'react-i18next';
import styles from './DQUPurchaseNotification.css';

const DQUPurchaseNotification = () => (
  <p className={styles.wrapper}>
    <Trans>
      Deque University order processes in 1 business day (Monday - Friday,
      9AM-5PM EST). You&apos;ll get an email when courses are ready. For
      multiple users, email a list of full names & email addresses to{' '}
      <a href="mailto:training@deque.com">training@deque.com</a>
    </Trans>
  </p>
);

export default DQUPurchaseNotification;
