import React from 'react';
import {
  type AdminFeatureFlag,
  getAdminFeatureFlags
} from '../../common/api-client';
import useAPI, { State as BaseState } from '../../common/hooks/useAPI';

interface State extends Omit<BaseState<AdminFeatureFlag[]>, 'data'> {
  features: AdminFeatureFlag[];
}

const useAdminFeatureFlags = (token: string): State => {
  const dataLoader = React.useCallback(
    () => getAdminFeatureFlags(token),
    [token]
  );
  const state = useAPI(dataLoader);
  return {
    error: state.error,
    loading: state.loading,
    features: state.data || []
  };
};

export default useAdminFeatureFlags;
