import { useCallback } from 'react';
import { getScreenshotById } from '../../common/api-client';
import useAPI, { State } from '../../common/hooks/useAPI';

export interface ScreenshotState extends Omit<State<Blob>, 'data'> {
  blob: Blob | null;
}

const useScreenshot = (
  id: string | null | undefined,
  token?: string
): ScreenshotState => {
  const dataLoader = useCallback(() => {
    if (!id) {
      return Promise.resolve(null);
    }
    return getScreenshotById(id, token);
  }, [id, token]);
  const state = useAPI(dataLoader);
  return { loading: state.loading, error: state.error, blob: state.data };
};

export default useScreenshot;
