import React, { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@deque/cauldron-react';

import { dayjs } from '../../../common/utils/dates';
import type { TestRecordIssue } from '@deque/axe-devtools-issues-utils';
import Section from './Section';
import styles from './About.css';

interface Props {
  id: string;
  issue: TestRecordIssue;
  language: string;
}

interface LabelValueProps {
  label: string;
  children: React.ReactNode;
}

const LabelValue: React.ComponentType<LabelValueProps> = ({
  label,
  children
}) => {
  return (
    <div className={styles.labelvalue}>
      <span>{label}:</span>
      {children}
    </div>
  );
};

interface TagsProps {
  tags: string[];
}

const IssueTags: React.ComponentType<TagsProps> = ({ tags = [] }) => (
  <div className={styles.tags}>
    <ul>
      {tags.map(tag => (
        <li key={tag} className={styles.tag}>
          {tag}
        </li>
      ))}
    </ul>
  </div>
);

const formatTime = (date: string) => dayjs(date).format('h:mm a');
const formatDate = (date: string, language: string) =>
  new Intl.DateTimeFormat(language).format(dayjs(date).toDate());
const About = forwardRef<HTMLElement, Props>(({ id, issue, language }, ref) => {
  const { t } = useTranslation();

  const foundOnValue = useMemo(() => {
    if (!issue.created_at) {
      return t('unknown');
    }

    const date = formatDate(issue.created_at, language);
    const time = formatTime(issue.created_at);
    return t('{{ date }} at {{ time }}', { date, time });
  }, [issue]);

  const foundValue = issue.is_manual
    ? issue.manifest_guide
      ? t('IGT | {{guide}}', { guide: issue.manifest_guide })
      : t('Manually')
    : t('Automatically');

  const formatUrl = (url: string) =>
    url && /^https?:\/\//.test(url) ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url} <Icon type="external-link" label={t('opens in new window')} />
      </a>
    ) : (
      <span>{url}</span>
    );

  return (
    <Section ref={ref} title={t('About this issue')} id={id}>
      <div className={styles.about}>
        <LabelValue label={t('Test Name')}>
          <span>{issue.test_name}</span>
        </LabelValue>
        <LabelValue label={t('Test URL')}>
          {issue.test_url ? (
            formatUrl(issue.test_url)
          ) : (
            <em>{t('URL is not available for this issue')}</em>
          )}
        </LabelValue>
        <LabelValue label={t('Impact')}>
          <span className={styles.impact}>{issue.impact}</span>
        </LabelValue>
        <LabelValue label={t('Found on')}>
          <span>{foundOnValue}</span>
        </LabelValue>
        <LabelValue label={t('Found')}>
          <span>{foundValue}</span>
        </LabelValue>
        <LabelValue label={t('Issue tags')}>
          <IssueTags tags={issue.tags || []} />
        </LabelValue>
        {issue.help_url && (
          <LabelValue label={t('Help')}>
            <a href={issue.help_url} target="_blank" rel="noopener noreferrer">
              {issue.help}{' '}
              <Icon type="external-link" label={t('opens in new window')} />
            </a>
          </LabelValue>
        )}
      </div>
    </Section>
  );
});

About.displayName = 'About';

export default About;
