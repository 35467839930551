import React from 'react';
import PageTitle from '../../common/components/PageTitle';
import Markdown from '../components/Markdown';
import styles from './ScreenReaders.css';
import content from './ScreenReaders.md';

const ScreenReaders: React.FC = () => {
  return (
    <React.Fragment>
      <PageTitle title="Screen Reader Guide" />
      <div className={styles.wrap}>
        <Markdown className={styles.content} source={content} />
      </div>
    </React.Fragment>
  );
};

export default ScreenReaders;
