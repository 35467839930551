const rSpecialCharacters = /specialChars\(([\d]+)\)/;
const rUpperCase = /upperCase\(([\d]+)\)/;
const rLength = /length\(([\d]+)\)/;
const rForceExpiredPasswordChange = /forceExpiredPasswordChange\(([\d]+)\)/;
const rPasswordHistory = /passwordHistory\(([\d]+)\)/;
const rLowerCase = /lowerCase\(([\d]+)\)/;
const rDigits = /digits\(([\d]+)\)/;
const rPasswordBlacklist = /passwordBlacklist\((.*)\)/;
const rRegexPattern = /regexPattern\((.*)\)/;
const rNotUsername = /notUsername\((.*)\)/;
const rHashIterations = /hashIterations\((.*)\)/;
const rHashAlgorithm = /hashAlgorithm\((.*)\)/;

export interface PasswordRequirements {
  specialCharacters?: number;
  upperCase?: number;
  lowerCase?: number;
  length?: number;
  forceExpiredPasswordChange?: number;
  passwordHistory?: number;
  digits?: number;
  notUsername?: boolean;
  // The below either cannot be rendered in a meaningful way in the UI or are unable to test/get working locally.
  hashIterations?: unknown;
  hashAlgorithm?: unknown;
  passwordBlacklist?: unknown;
  regexPattern?: unknown;
}

/**
 * Parse the given `strings` into useable `PasswordRequirements`. The provided `strings` should be Keycloak password policies, for example:
 * - "specialChars(1)"
 * - "upperCase(1)"
 * - "length(8)"
 */

const parsePasswordRequirements = (strings: string[]): PasswordRequirements => {
  const requirements: PasswordRequirements = {};

  for (const raw of strings) {
    let m: RegExpExecArray | null = null;
    if ((m = rSpecialCharacters.exec(raw))) {
      requirements.specialCharacters = parseInt(m[1], 10);
    } else if ((m = rUpperCase.exec(raw))) {
      requirements.upperCase = parseInt(m[1], 10);
    } else if ((m = rLength.exec(raw))) {
      requirements.length = parseInt(m[1], 10);
    } else if ((m = rForceExpiredPasswordChange.exec(raw))) {
      requirements.forceExpiredPasswordChange = parseInt(m[1], 10);
    } else if ((m = rPasswordHistory.exec(raw))) {
      requirements.passwordHistory = parseInt(m[1], 10);
    } else if ((m = rLowerCase.exec(raw))) {
      requirements.lowerCase = parseInt(m[1], 10);
    } else if ((m = rDigits.exec(raw))) {
      requirements.digits = parseInt(m[1], 10);
    } else if ((m = rPasswordBlacklist.exec(raw))) {
      requirements.passwordBlacklist = m[1];
    } else if ((m = rRegexPattern.exec(raw))) {
      requirements.regexPattern = m[1];
    } else if ((m = rNotUsername.exec(raw))) {
      requirements.notUsername = true;
    } else if ((m = rHashIterations.exec(raw))) {
      requirements.hashIterations = m[1];
    } else if ((m = rHashAlgorithm.exec(raw))) {
      requirements.hashAlgorithm = m[1];
    }
  }

  return requirements;
};

export default parsePasswordRequirements;
