import React from 'react';
import {
  Checkbox,
  Icon,
  Select,
  SelectOption,
  TooltipTabstop
} from '@deque/cauldron-react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type OptionWithValueOfType<T> = Array<
  SelectOption & { value: T; disableSelectOptionOnly?: boolean }
>;

export interface Props<T extends FieldValues> {
  label: string;
  name: FieldPath<T>;
  id: string;
  allowChangeName?: FieldPath<T>;
  shouldShowAllowChange: boolean;
  disabled: boolean;
  formControl: Control<T>;
  options: SelectOption[];
  tooltip?: React.ReactNode;
}

const SelectSetting = <T extends FieldValues>({
  formControl,
  label,
  name,
  id,
  allowChangeName,
  shouldShowAllowChange,
  disabled,
  options,
  tooltip
}: Props<T>) => {
  const { t } = useTranslation();

  return (
    <fieldset>
      <div>
        <Controller
          name={name}
          control={formControl}
          render={({ field }) => (
            <Select
              {...field}
              id={id}
              label={label}
              options={options}
              disabled={disabled}
            />
          )}
        />
        {tooltip && (
          <TooltipTabstop tooltip={tooltip} placement="top" variant="info">
            <Icon
              type="question-circle"
              label={t('{{settingName}} help', { settingName: label })}
            />
          </TooltipTabstop>
        )}
      </div>
      {shouldShowAllowChange && allowChangeName && (
        <Controller
          control={formControl}
          name={allowChangeName}
          render={({ field: { value, ...field } }) => (
            <Checkbox
              {...field}
              checked={value}
              id={`allow-users-to-change-${id}`}
              label={t('Allow users to change')}
              aria-label={t('Allow users to change {{label}}', { label })}
              disabled={disabled}
            />
          )}
        />
      )}
    </fieldset>
  );
};

export default SelectSetting;
