import React, { useMemo, useRef } from 'react';
import PageTitle from '../../../common/components/PageTitle';
import { useTranslation } from 'react-i18next';
import ContentToast from '../../../common/components/ContentToast';
import {
  ColumnHeader,
  ColumnLeft,
  ColumnRight,
  Icon,
  Loader,
  TwoColumnPanel
} from '@deque/cauldron-react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import commonStyles from '../../../common/utils/common.css';
import styles from './AccountSettings.css';
import classNames from 'classnames';
import GeneralAccountSettings from './General';
import PasswordAccountSettings from './Password';
import NavLink from '../../../common/components/Link';
import { AuthUser, useAuthContext } from '../../../common/contexts/auth';
import { AllSettingsSchema } from '../../../common/contexts/Configuration';
import ScrimmedLoader from '../../../common/components/ScrimmedLoader';
import { Errors } from '../../../common/utils/validate-general-settings-form';

interface AccountSettingsProps {
  children?: React.ReactNode;
  loading: boolean;
  error: Error | string | null;
  submitting: boolean;
  narrow: boolean;
  user?: AuthUser;
  settings: AllSettingsSchema | null;
  handleGeneralSubmit?: (event: React.ChangeEvent<HTMLFormElement>) => void;
  handlePasswordSubmit?: (event: React.ChangeEvent<HTMLFormElement>) => void;
  firstNameRef?: React.RefObject<HTMLInputElement>;
  lastNameRef?: React.RefObject<HTMLInputElement>;
  emailRef?: React.RefObject<HTMLInputElement>;
  jobFunctionRef?: React.RefObject<HTMLSelectElement>;
  currentPasswordRef?: React.RefObject<HTMLInputElement>;
  passwordRef?: React.RefObject<HTMLInputElement>;
  confirmPasswordRef?: React.RefObject<HTMLInputElement>;
  generalErrors: Errors;
  passwordErrors: Errors;
}

const AccountSettings: React.FC<AccountSettingsProps> = ({
  error,
  loading,
  submitting,
  narrow,
  user,
  settings,
  handleGeneralSubmit,
  handlePasswordSubmit,
  emailRef,
  firstNameRef,
  lastNameRef,
  jobFunctionRef,
  currentPasswordRef,
  passwordRef,
  confirmPasswordRef,
  generalErrors,
  passwordErrors
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { createAccountUrl } = useAuthContext();
  const loaderRef = useRef<HTMLDivElement>(null);

  const accountUrl = createAccountUrl && createAccountUrl();

  const paths = useMemo(() => {
    const { pathname } = location;
    return {
      accountIsActive: pathname === '/account-settings',
      passwordIsActive: pathname === '/account-settings/password'
    };
  }, [location]);

  if (error) {
    return (
      <ContentToast show={true} type="caution">
        {t('Failed to load account settings.')}
      </ContentToast>
    );
  }

  if (loading) {
    return (
      <Loader
        label={t('Loading account settings...')}
        tabIndex={-1}
        ref={loaderRef}
      />
    );
  }

  return (
    <>
      <PageTitle title={t('Account Settings')} />
      <div
        className={classNames(styles.wrap, commonStyles.resetFieldsets, {
          // This is managed in JS, rather than CSS to help keep in sync with the panel breakpoint
          [styles.collapsed]: narrow
        })}
      >
        <h1>{t('Account Settings')}</h1>
        <TwoColumnPanel>
          <ColumnLeft aria-labelledby="account-settings-sidebar-header">
            <ColumnHeader
              id="account-settings-sidebar-header"
              className={styles.columnHeader}
            >
              <h2>{t('Administrator')}</h2>
            </ColumnHeader>
            <nav aria-labelledby="account-settings-heading">
              <ul>
                <li>
                  <Link
                    to="/account-settings"
                    aria-current={paths.accountIsActive}
                  >
                    {t('General')}
                    <em>{t('Manage general account settings.')}</em>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/account-settings/password"
                    aria-current={paths.passwordIsActive}
                  >
                    {t('Password')}
                    <em>{t('Manage and change your password.')}</em>
                  </Link>
                </li>
                <li>
                  <NavLink url={`${accountUrl}&origin=account_portal` || '/'}>
                    <span>
                      {t('Advanced Settings')}{' '}
                      <Icon
                        type="external-link"
                        label={t('Opens in new window')}
                        className={styles.externalLink}
                      />
                    </span>
                    <em>{t('Manage additional account settings.')}</em>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </ColumnLeft>
          <ColumnRight aria-labelledby="account-settings-item-header">
            {submitting && (
              <ScrimmedLoader label={t('Saving account settings...')} />
            )}
            <Switch>
              <Route exact path="/account-settings">
                <GeneralAccountSettings
                  user={user}
                  settings={settings}
                  submitting={submitting}
                  handleGeneralSubmit={handleGeneralSubmit}
                  emailRef={emailRef}
                  firstNameRef={firstNameRef}
                  lastNameRef={lastNameRef}
                  jobFunctionRef={jobFunctionRef}
                  errors={generalErrors}
                />
              </Route>
              <Route exact path="/account-settings/password">
                <PasswordAccountSettings
                  submitting={submitting}
                  handlePasswordSubmit={handlePasswordSubmit}
                  currentPasswordRef={currentPasswordRef}
                  passwordRef={passwordRef}
                  confirmPasswordRef={confirmPasswordRef}
                  errors={passwordErrors}
                />
              </Route>
            </Switch>
          </ColumnRight>
        </TwoColumnPanel>
      </div>
    </>
  );
};

export default AccountSettings;
