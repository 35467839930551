import React from 'react';

import type { OrNull } from '../../../typings/utils';
import type { HttpError } from '../../common/api-client';
import { NotFoundError } from '../utils/errors';

type DataLoader<T> = () => Promise<T>;

export interface State<T = unknown> {
  loading: boolean;
  error: OrNull<Error>;
  data: OrNull<T>;
}

export interface Methods {
  refresh: () => void;
}

/**
 * Use the axe API. This hook handles fetching data via the provided `loadData` function.
 *
 * The `loadData` function should be wrapped in `React.useCallback()` with all of its dependencies set to avoid unnecessarily re-fetching data.
 */

const useAPI = <T = unknown>(
  loadData: DataLoader<T>,
  skipRequest = false
): State<T> & Methods => {
  const [loading, setLoading] = React.useState(!skipRequest);
  const [error, setError] = React.useState<OrNull<Error>>(null);
  const [data, setData] = React.useState<OrNull<T>>(null);
  const [cacheBuster, setCacheBuster] = React.useState(1);

  React.useEffect(() => {
    if (skipRequest) {
      return;
    }

    let cancelled = false;

    setLoading(true);
    (async () => {
      let result: OrNull<T> = null;
      let err: OrNull<Error> = null;

      try {
        result = await loadData();
      } catch (e) {
        /* istanbul ignore next*/
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        err =
          (e as HttpError).status === 404 ? new NotFoundError() : (e as Error);
      }

      // Avoid memory leaks/etc.
      if (cancelled) {
        return;
      }

      setData(result);
      setError(err);
      setLoading(false);
    })();

    return () => {
      cancelled = true;
    };
  }, [loadData, cacheBuster, skipRequest]);

  return {
    loading,
    error,
    data,
    refresh: () => setCacheBuster(cacheBuster + 1)
  };
};

export default useAPI;
