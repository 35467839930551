import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ErrorMessage.css';

interface Props {
  error: string | React.ReactNode;
  title?: string;
}

const ErrorMessage: React.ComponentType<Props> = ({ error, title }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.error}>
        <p>{title || t('An unexpected error occurred')}</p>
        {typeof error === 'string' ? (
          <pre>
            <code>{error}</code>
          </pre>
        ) : (
          error
        )}
      </div>
    </>
  );
};

export default ErrorMessage;
