import { findJiraFieldByKey } from './findJiraFieldByKey';
import type { JiraFieldMapper, JiraFieldSchema } from './types';

export class LabelsMapper implements JiraFieldMapper {
  id = 'jira-labels-mapper';
  maxLabelLength = 255;

  canMapThisField(jiraSchema: JiraFieldSchema[], fieldKey: string): boolean {
    const targetField = findJiraFieldByKey(jiraSchema, fieldKey);

    if (!targetField) {
      return false;
    }

    const { type, custom, items, system } = targetField.schema;
    const isArrayOfString = type === 'array' && items === 'string';

    return (
      isArrayOfString &&
      (system === 'labels' ||
        custom === 'com.atlassian.jira.plugin.system.customfieldtypes:labels')
    );
  }

  formatFieldValue(
    jiraSchema: JiraFieldSchema[],
    fieldKey: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sourceValue: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    const fieldName = findJiraFieldByKey(jiraSchema, fieldKey)?.name;

    if (!this.canMapThisField(jiraSchema, fieldKey)) {
      throw new Error(`Can not map to '${fieldName}'`);
    }

    if (typeof sourceValue === 'object' && !Array.isArray(sourceValue)) {
      throw new Error(
        `Can not map to '${fieldName}': mapped value type invalid`
      );
    }

    const array = Array.isArray(sourceValue) ? sourceValue : [sourceValue];

    try {
      const formattedArray = array.map(item => this.mapArrayItems(item));
      return formattedArray;
    } catch (error) {
      throw new Error(
        `Can not map to '${fieldName}': ${(error as Error).message}`
      );
    }
  }

  private mapArrayItems(item: unknown) {
    const formattedValue =
      typeof item === 'object' ? JSON.stringify(item) : String(item);

    const hasSpaces = /\s/.test(formattedValue);

    if (hasSpaces) {
      throw Error('mapped array item contains spaces');
    }

    if (formattedValue.length > this.maxLabelLength) {
      throw Error(`mapped array item length more than ${this.maxLabelLength}`);
    }

    return formattedValue;
  }
}
