import React, { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@deque/cauldron-react';
import useRuleHelp from '../../hooks/useRuleHelp';
import Markdown from '../Markdown';
import Section from './Section';
import ErrorMessage from './ErrorMessage';
import styles from './HowToFix.css';
import { isNotFoundError } from '../../../common/utils/errors';

interface Props {
  id: string;
  ruleId: string;
  language: string;
  setHasRuleHelp: (value: boolean) => void;
  // For tests only.
  loadData?: typeof useRuleHelp;
}

const HowToFix = forwardRef<HTMLElement, Props>(
  (
    {
      id,
      ruleId,
      language,
      setHasRuleHelp,
      /* istanbul ignore next */
      loadData = useRuleHelp
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { loading, error, ruleHelp } = loadData(language, ruleId);
    const loaderRef = React.useRef<HTMLDivElement>(null);
    const errorMessage = React.useMemo(() => {
      // We don't want to show an error when rule help data is not available for a particular rule. Instead we just show nothing.
      if (error && isNotFoundError(error)) {
        return null;
      }

      if (error) {
        return error.message;
      }

      if (!ruleHelp) {
        return t('Unable to load rule help');
      }

      return null;
    }, [error, ruleHelp]);

    useEffect(() => {
      loaderRef.current?.focus();
    }, [loaderRef.current]);

    useEffect(() => {
      if (ruleHelp?.rule) {
        setHasRuleHelp(true);
      }
    }, [ruleHelp]);

    return (
      <div className={styles.howToFix}>
        {loading ? (
          <Loader
            label={t('Loading rule help')}
            tabIndex={-1}
            ref={loaderRef}
          />
        ) : errorMessage ? (
          <ErrorMessage error={errorMessage} />
        ) : ruleHelp?.rule ? (
          <Section ref={ref} id={id} title={t('How to fix')}>
            {ruleHelp.howToFix && (
              <div className={styles.fixSectionWrapper}>
                <h2>{t('How To Fix')}</h2>
                <Markdown
                  source={ruleHelp.howToFix}
                  className={styles.fixSection}
                />
              </div>
            )}
          </Section>
        ) : null}
      </div>
    );
  }
);

HowToFix.displayName = 'HowToFix';

export default HowToFix;
