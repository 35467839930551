import { useCallback, useMemo, useState } from 'react';

type SortDirection = 'ascending' | 'descending';

type SortHandler<T> = (a: T, b: T) => number;

interface SorterOptions<T, SortBy extends string> {
  items: T[];
  initialSortBy?: SortBy;
  initialSortDirection?: SortDirection;
  sortHandlers?: Record<SortBy, SortHandler<T>>;
}

export interface Sorter<T, SortBy> {
  sortedItems: T[];
  sortDirection: SortDirection;
  sortBy?: SortBy;
  updateSortBy: (sortBy: SortBy) => void;
}

const useSorter = <T, SortBy extends string>({
  items,
  initialSortBy,
  initialSortDirection = 'ascending',
  sortHandlers = {} as Record<SortBy, SortHandler<T>>
}: SorterOptions<T, SortBy>): Sorter<T, SortBy> => {
  const [sortDirection, setSortDirection] = useState(initialSortDirection);
  const [sortBy, setSortBy] = useState<SortBy | undefined>(initialSortBy);

  const updateSortBy = useCallback(
    (newSortBy: SortBy) => {
      if (newSortBy === sortBy) {
        setSortDirection(
          sortDirection === 'ascending' ? 'descending' : 'ascending'
        );
        return;
      }

      setSortBy(newSortBy);
      setSortDirection('ascending');
    },
    [sortBy, sortDirection]
  );

  const sortHandler =
    (sortBy && sortHandlers[sortBy]) || ((a, b) => (a < b ? -1 : 1));

  const sortedItems = useMemo(() => {
    const ascendingItems = [...items].sort(sortHandler);
    return sortDirection === 'ascending'
      ? ascendingItems
      : ascendingItems.reverse();
  }, [items, sortDirection, sortHandler]);

  return {
    sortedItems,
    sortBy,
    sortDirection,
    updateSortBy
  };
};

export default useSorter;
