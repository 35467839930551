import React, { ComponentProps, FC } from 'react';
import {
  ExpandCollapsePanel,
  PanelTrigger,
  TableHeader,
  IconButton,
  Offscreen,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Table,
  Icon
} from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import type { AdminFeatureFlag } from '../../common/api-client';
import FeatureFlagToggle from '../containers/FeatureFlagToggle';
import { useProducts } from '../../common/contexts/products';
import { ProductSlugs } from '../../common/constants';
import UserIdLink from '../containers/UserIdLink';
import useSorter from '../hooks/useSorter';
import styles from './FeatureFlagsTableAdmin.css';

export type HeaderKeys =
  | 'id'
  | 'default_state'
  | 'created_at'
  | 'created_by'
  | 'updated_by'
  | 'updated_at';

export type HeaderType = {
  key: HeaderKeys;
  title: string;
};

type TimestampProps = {
  date: Date;
};

const Timestamp: React.FC<TimestampProps> = ({ date }) => {
  const dateTime = dayjs(date).toDate().toISOString();
  const formatted = React.useMemo(
    () => new Intl.DateTimeFormat('en').format(dayjs(date).toDate()),
    [date]
  );

  return (
    <time dateTime={dateTime} data-testid="timestamp">
      {formatted}
    </time>
  );
};

type FeatureFlagTableAdminProps = {
  featureFlags: AdminFeatureFlag[];
  isTogglingAllowed?: boolean;
  onEditClick?: (feature: AdminFeatureFlag) => void;
};

const FeatureFlagTableAdmin: FC<FeatureFlagTableAdminProps> = ({
  featureFlags,
  isTogglingAllowed,
  onEditClick
}) => {
  const { t } = useTranslation();
  const { getProductBySlug } = useProducts();
  const tableHeaders: HeaderType[] = [
    {
      key: 'id',
      title: t('ID')
    },
    {
      key: 'default_state',
      title: t('Default state')
    },
    {
      key: 'created_at',
      title: t('Created at')
    },
    {
      key: 'created_by',
      title: t('Created by')
    },
    {
      key: 'updated_at',
      title: t('Updated at')
    },
    {
      key: 'updated_by',
      title: t('Updated by')
    }
  ];

  const sorter = useSorter<AdminFeatureFlag, HeaderKeys>({
    items: featureFlags,
    initialSortDirection: 'ascending',
    initialSortBy: 'id',
    sortHandlers: {
      id: (a, b) => {
        return a.id?.localeCompare(b.id);
      },
      default_state: (a, b) => {
        const astate = String(Number(a.default_state));
        const bstate = String(Number(b.default_state));
        return astate?.localeCompare(bstate);
      },
      created_at: (a, b) => {
        const leftHand = new Date(a.created_at).getTime();
        const rightHand = new Date(b.created_at).getTime();

        return leftHand - rightHand;
      },
      created_by: (a, b) => {
        const leftHand = a.created_by;
        const rightHand = b.created_by;

        return leftHand?.localeCompare(rightHand);
      },
      updated_by: (a, b) => {
        const leftHand = a.updated_by || '';
        const rightHand = b.updated_by || '';

        return leftHand?.localeCompare(rightHand);
      },
      updated_at: (a, b) => {
        const leftHand = a.updated_at
          ? new Date(a.updated_at).getTime()
          : Infinity;
        const rightHand = b.updated_at
          ? new Date(b.updated_at).getTime()
          : Infinity;
        return leftHand - rightHand;
      }
    }
  });

  const getSortingPropsForTableHeader = (
    sortingPropName: HeaderKeys
  ): Pick<ComponentProps<typeof TableHeader>, 'onSort' | 'sortDirection'> => {
    return {
      sortDirection:
        sorter.sortBy === sortingPropName ? sorter.sortDirection : 'none',
      onSort: () => sorter.updateSortBy(sortingPropName)
    };
  };

  const productNames = [
    {
      productSlug: 'axe-account',
      productName: 'axe Account',
      sortedItems: sorter.sortedItems.filter(
        feature => feature.product_name === 'axe-account'
      )
    },
    ...Object.values(ProductSlugs)
      // Sort products by name alphabetically
      .sort(
        (a, b) =>
          getProductBySlug(a)?.name?.localeCompare(
            getProductBySlug(b)?.name ?? ''
          ) ?? 0
      )
      .map(productSlug => ({
        productSlug,
        productName: getProductBySlug(productSlug)?.name,
        sortedItems: sorter.sortedItems.filter(
          feature => feature.product_name === productSlug
        )
      }))
      // Sort products with feature flags to the beginning of the list
      .sort((a, b) => b.sortedItems.length - a.sortedItems.length)
  ];

  return (
    <>
      {productNames.map(({ productSlug, productName, sortedItems }) =>
        sortedItems.length > 0 ? (
          <ExpandCollapsePanel key={productSlug} animationTiming={false}>
            <PanelTrigger className={styles.featureFlagProductTrigger}>
              {sortedItems.length > 1
                ? t(`{{ productName }} ({{ count }} Feature Flags)`, {
                    productName,
                    count: sortedItems.length
                  })
                : t(`{{ productName }} (1 Feature Flag)`, {
                    productName
                  })}
              <hr />
            </PanelTrigger>
            <Table className={styles.featureFlagTable}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map(({ key, title }) => {
                    return (
                      <TableHeader
                        key={key}
                        {...getSortingPropsForTableHeader(key)}
                        sortAscendingAnnouncement={t('sorted ascending')}
                        sortDescendingAnnouncement={t('sorted descending')}
                      >
                        {title}
                      </TableHeader>
                    );
                  })}
                  <TableHeader>
                    <></>
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {sorter.sortedItems.map(feature => {
                  return (
                    <TableRow key={feature.id}>
                      <TableCell>
                        {!feature.description ? (
                          <code>{feature.id}</code>
                        ) : (
                          <ExpandCollapsePanel animationTiming={0}>
                            <PanelTrigger
                              className={styles.featureDescriptionTrigger}
                            >
                              {feature.id}
                            </PanelTrigger>
                            <em>{feature.description}</em>
                          </ExpandCollapsePanel>
                        )}
                      </TableCell>
                      <TableCell>
                        <FeatureFlagToggle
                          disabled={!isTogglingAllowed}
                          feature={feature}
                        />
                      </TableCell>
                      <TableCell>
                        <Timestamp date={feature.created_at} />
                      </TableCell>
                      <TableCell>
                        <UserIdLink id={feature.created_by} />
                      </TableCell>
                      <TableCell>
                        {feature.updated_at ? (
                          <Timestamp date={feature.updated_at} />
                        ) : (
                          <Icon type="no" />
                        )}
                      </TableCell>
                      <TableCell>
                        {feature.updated_by ? (
                          <UserIdLink id={feature.updated_by} />
                        ) : (
                          <Icon type="no" />
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          icon="pencil"
                          role="button"
                          label={t('Edit {{id}}', { id: feature.id })}
                          aria-label={t('Edit feature {{id}}', {
                            id: feature.id
                          })}
                          onClick={() => onEditClick?.(feature)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </ExpandCollapsePanel>
        ) : (
          <div
            key={productSlug}
            className={styles.disabledFeatureFlagProductTrigger}
          >
            <span className={styles.disabledFeatureFlagProductTriggerText}>
              {t(`{{ productName }} (0 Feature Flags)`, { productName })}
            </span>
            <Offscreen>{t('Disabled')}</Offscreen>
            <hr />
          </div>
        )
      )}
    </>
  );
};

export default FeatureFlagTableAdmin;
