import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  TextField,
  Select
} from '@deque/cauldron-react';
import styles from './AddEditApiKey.css';
import type { SelectOption } from '@deque/cauldron-react';
import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import {
  CreateApiKeyBody,
  UpdateApiKeyBody,
  HttpError
} from '../../common/api-client';

export interface EditParams {
  id: string;
  product_slug: string;
  name: string;
}

export interface AddEditApiKeyModalProps {
  handleSaveAdd: (params: CreateApiKeyBody) => void;
  handleSaveEdit: (params: UpdateApiKeyBody) => void;
  handleClose: () => void;
  showApiKeyModal: boolean;
  selectionOptions: SelectOption[];
  isEditMode?: boolean;
  editParams?: EditParams | null;
  error: HttpError | null;
  showLoader?: boolean;
}

const AddEditApiKey = ({
  handleSaveAdd,
  handleSaveEdit,
  handleClose,
  showApiKeyModal,
  selectionOptions,
  isEditMode = false,
  editParams = null,
  error,
  showLoader
}: AddEditApiKeyModalProps): JSX.Element => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const [projectName, setProjectName] = useState(
    editParams ? editParams.name : ''
  );
  const [productName, setProductName] = useState(
    editParams ? editParams.product_slug : selectionOptions[0]?.value
  );
  const [projectError, setProjectError] = useState('');

  const title = isEditMode ? t('EDIT API KEY') : t('ADD NEW API KEY');
  const handleChangeProduct = (e: ChangeEvent<HTMLSelectElement>) =>
    setProductName(e.target.value);
  const handleChangeProject = (value: string) => setProjectName(value);

  const onSave = (e: React.FormEvent) => {
    e.preventDefault();

    if (!projectName) {
      setProjectError(t`Name required`);
      return;
    }

    if (isEditMode) {
      return handleSaveEdit({
        name: projectName
      });
    }

    handleSaveAdd({
      product_slug: productName,
      name: projectName,
      tags: []
    });
  };

  useEffect(() => {
    if (projectError) {
      /* istanbul ignore next */
      inputRef.current?.focus();
    }
  }, [projectError]);

  useEffect(() => {
    if (error?.status && error.status < 500) {
      if (error.status === 409) {
        setProjectError(t`Project name already exists`);
      } else {
        setProjectError(error.message);
      }
    } else {
      setProjectError('');
    }
  }, [error]);

  return (
    <Modal
      heading={<span>{title}</span>}
      show={showApiKeyModal}
      onClose={handleClose}
      className={styles.apiKeyModal}
    >
      {showLoader && <ScrimmedLoader label={t('Saving...')} />}
      <form onSubmit={onSave} noValidate>
        <ModalContent>
          <Select
            disabled={isEditMode}
            label={t('Product')}
            onChange={handleChangeProduct}
            options={selectionOptions}
            value={productName}
            ref={selectRef}
          />
          <TextField
            label={t('Name')}
            error={projectError}
            value={projectName}
            onChange={handleChangeProject}
            fieldRef={inputRef}
            required
          />
        </ModalContent>
        <ModalFooter>
          <Button
            disabled={
              projectName === editParams?.name ||
              // Disable save button if the product is disabled
              !!selectionOptions.find(
                s => s.value === productName && s.disabled
              )
            }
            type="submit"
          >
            {t('Save')}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddEditApiKey;
