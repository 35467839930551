import React, { type Dispatch, type SetStateAction } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, PanelContent } from '@deque/cauldron-react';
import { useFormContext, useFormState } from 'react-hook-form';

import type { ProjectsAndIssueTypes } from '../../../../../../common/utils/integrations-client/jira/getProjectsAndIssueTypes';
import type { IntegrationTemplate } from '../../../../../../common/api-client';
import type { StepMap } from '../../../../../types';
import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../../../../common/analyticsInstances';
import Link from '../../../../../../common/components/Link';
import { useGlobalToast } from '../../../../../../common/contexts/globalToast';
import { SupportedIntegrationProductSlugs } from '../../../../../../common/constants';
import styles from './FormFooter.css';

export interface FormFooterProps {
  integrationProductSlug: SupportedIntegrationProductSlugs;
  connectionId: string;
  handleSubmit: () => void;
  narrow?: boolean;
  isEditMode: boolean;
  steps: StepMap[];
  currentStep: number;
  isStepDataLoading: boolean;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  templates: IntegrationTemplate[];
  projects: ProjectsAndIssueTypes[];
  enterpriseId: string;
  isStepDataError?: boolean;
}

const FormFooter = ({
  integrationProductSlug,
  connectionId,
  handleSubmit,
  narrow = false,
  isEditMode,
  steps,
  currentStep,
  isStepDataLoading,
  setCurrentStep,
  templates,
  projects,
  enterpriseId,
  isStepDataError = false
}: FormFooterProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const analytics =
    analyticsInstances[getFallbackAnalyticsInstanceId(integrationProductSlug)];

  const { trigger, reset, getValues, setValue, setError } = useFormContext();
  const { touchedFields } = useFormState();
  const { setContents } = useGlobalToast();

  const isTemplateNameUnique = (name: string, templateId?: string): boolean =>
    !templates.some(
      template =>
        template.name.toLocaleLowerCase() === name.toLocaleLowerCase() &&
        templateId !== template.id
    );
  const { stepFields } = steps[currentStep];
  const totalSteps = steps.length - 1;

  const isNotFirstStep = currentStep > 0;
  const isLastStep = currentStep === totalSteps;

  // Removes empty value pairs from the data
  const cleanData = (key: string) => {
    if (stepFields.includes(key) && getValues(key)) {
      const keyValues = getValues(key) as Record<string, string>[];
      const cleanedData = keyValues.reduce((acc, value) => {
        if (Object.values(value).some(Boolean)) {
          acc.push(value);
        }
        return acc;
      }, [] as Record<string, string>[]);

      setValue(key, cleanedData);
    }
  };

  const onNext = async () => {
    let isStepValid = await trigger(stepFields);
    if (stepFields.includes('templateName')) {
      const templateName = getValues('templateName');

      if (!isTemplateNameUnique(templateName, id)) {
        setError(
          'templateName',
          {
            type: 'manual',
            message: t(
              'A template with this name already exists. Please choose a different name.'
            )
          },
          { shouldFocus: true }
        );

        isStepValid = false;
      }
    }
    if (isStepValid) {
      setContents(null);
      setCurrentStep(prev => prev + 1);
      cleanData('fieldMapping');
      cleanData('customLabels');
    }
  };

  const isLoading = isStepDataLoading;

  const isSaveEnable = id ? Object.keys(touchedFields).length : true;

  return (
    <PanelContent
      className={classNames(
        styles.footerContainer,
        narrow && styles.mobilePanelContent
      )}
    >
      <Link
        name={t('Cancel')}
        url={`/configuration/integrations/${connectionId}/templates`}
        onClick={() => {
          const projectName =
            projects.find(project => project.id === getValues('project'))
              ?.name || '';
          const issueTypeName =
            projects
              .find(project => project.id === getValues('project'))
              ?.issueTypes?.find(
                issueType => issueType.id === getValues('issueType')
              )?.name || '';
          const templateName = getValues('templateName') || '';
          reset();

          if (isEditMode) {
            analytics.integrationTemplateEditCancel({
              integration: integrationProductSlug,
              project: projectName,
              issueType: issueTypeName,
              template: templateName,
              connectionId: connectionId,
              enterpriseId
            });
          } else {
            analytics.integrationTemplateAddCancel({
              integration: integrationProductSlug,
              project: projectName,
              issueType: issueTypeName,
              template: templateName,
              connectionId: connectionId,
              enterpriseId
            });
          }
        }}
        className={styles.cancelLink}
      />
      <div>
        {isNotFirstStep && (
          <Button
            variant="secondary"
            onClick={() => setCurrentStep(prev => prev - 1)}
            className={styles.footerButton}
            disabled={isLoading}
          >
            {t('Back')}
          </Button>
        )}
        {isLastStep ? (
          <Button
            onClick={handleSubmit}
            className={styles.footerButton}
            disabled={!isSaveEnable || isLoading || isStepDataError}
          >
            {t('Save')}
          </Button>
        ) : (
          <Button
            onClick={onNext}
            className={styles.footerButton}
            disabled={isLoading || isStepDataError}
          >
            {t('Next')}
          </Button>
        )}
      </div>
    </PanelContent>
  );
};

export default FormFooter;
