import { EnterpriseMembers } from '../../common/utils/billing-client/client-v2';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import emailValidator from 'email-validator';
import {
  Link,
  Button,
  Select,
  TextField,
  PanelContent,
  Loader
} from '@deque/cauldron-react';
import StepContainer from '../../common/components/StepContainer';
import ErrorMessage from '../components/issue/ErrorMessage';
import { useAuthContext } from '../../common/contexts/auth';
import { useEnterprises } from '../../common/contexts/enterprises';
import useV2EnterpriseMembers from '../../common/hooks/useV2EnterpriseMembers';
import ReviewUserChanges from '../components/ReviewUserChanges';
import AddAdminUserProgress from '../components/AddAdminUserProgress';
import styles from './AddAdminUser.css';

interface StepComponentProps {
  members: EnterpriseMembers.PendingOrAcceptedMember[];
  changedUser: string | null;
  setChangedUser: (email: string) => void;
  onStepComplete: () => void;
  onPreviousStep: () => void;
}

const AddAdminUserStep = ({
  members,
  onStepComplete,
  setChangedUser
}: StepComponentProps) => {
  const { t } = useTranslation();
  const [emailValue, setEmailValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<string | null>();
  const [emailError, setEmailError] = useState(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUserChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'existing-user') {
      setSelectedUser(null);
    } else if (e.target.value) {
      setSelectedUser(e.target.value);
    }
  };

  const handleNewUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const email = emailValue.trim();

    if (selectedUser) {
      setChangedUser(selectedUser);
      onStepComplete();
    }

    if (!email) {
      setEmailError(t('Email is required'));
      /* istanbul ignore next */
      inputRef.current?.focus();
      return;
    }

    if (!emailValidator.validate(email)) {
      setEmailError(t('The email address provided is invalid'));
      /* istanbul ignore next */
      inputRef.current?.focus();
      return;
    }

    setChangedUser(email);
    onStepComplete();
  };

  return (
    <>
      <PanelContent>
        <form id="add-admin-user-form" onSubmit={handleSubmit}>
          <h3>
            {t(
              'Use the select list below to choose an existing user to make them an admin'
            )}
          </h3>
          <Select
            label={t('Select an existing user')}
            options={[
              {
                key: 'existing-user',
                value: 'existing-user',
                label: t('(Make an existing user an admin)')
              },
              ...members.map(member => ({
                key: member.email,
                value: member.email,
                label: member.email
              }))
            ]}
            disabled={!!emailValue.trim().length || !members.length}
            onChange={handleUserChange}
          />
          {!members.length && (
            <p className={styles.help}>
              {t('All existing users are an axe Account admin.')}
            </p>
          )}
          <div className={styles.or}>{t('OR')}</div>
          <h3>{t('Enter email of a new user to make them an admin')}</h3>
          <TextField
            label={t('Enter email of the new user')}
            disabled={!!selectedUser}
            onInput={handleNewUserInput}
            value={emailValue}
            error={emailError}
            fieldRef={inputRef}
          />
        </form>
      </PanelContent>
      <PanelContent>
        <Link variant="button-secondary" href="/user-access">
          {t('Cancel')}
        </Link>
        <Button
          type="submit"
          form="add-admin-user-form"
          variant="primary"
          disabled={!(selectedUser || emailValue.trim().length)}
        >
          {t('Next')}
        </Button>
      </PanelContent>
    </>
  );
};

const ReviewAddAdminUserStep = ({
  members,
  changedUser,
  onStepComplete,
  onPreviousStep = () => null
}: StepComponentProps) => (
  <ReviewUserChanges
    members={members}
    // The user is validated during the add step, so it should not be null
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    changedUsers={[changedUser!]}
    onComplete={onStepComplete}
    onCancel={onPreviousStep}
    changes={{
      isAdmin: true
    }}
  />
);

const AddAdminUser = (): JSX.Element => {
  const [currentStepIndex, setStepIndex] = useState(0);
  const [changedUser, setChangedUser] = useState<string | null>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { activeEnterprise } = useEnterprises();
  // There will always be a user and with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  // TODO: When multi-enterprise is supported, we should fetch the appropriate enterprise_id
  // There should always be an active enterprise because of <Protected isEnterpriseAdmin>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const enterpriseId = activeEnterprise!.id;
  const {
    loading: enterpriseMembersLoading,
    error: enterpriseMembersError,
    pendingOrAcceptedMembers: enterpriseMembers
  } = useV2EnterpriseMembers({ token, enterpriseId });
  const isLoading = enterpriseMembersLoading;

  const steps = [
    {
      title: t('Add axe Account admin'),
      heading: t('Choose a user you’d like to make an axe Account admin'),
      name: t('Add Admin'),
      component: AddAdminUserStep
    },
    {
      title: t('Review your users'),
      heading: t('Summary of user changes'),
      name: t('Review'),
      component: ReviewAddAdminUserStep
    }
  ];

  const StepComponent = steps[currentStepIndex]?.component;

  const handleStepComplete = () => {
    setStepIndex(currentStepIndex + 1);
  };

  const handlePreviousStep = () => {
    setStepIndex(currentStepIndex - 1);
  };

  React.useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  if (isLoading) {
    return <Loader label={t('Loading')} tabIndex={-1} ref={loaderRef} />;
  }

  const nonAdminMembers = enterpriseMembers?.filter(
    (member: EnterpriseMembers.Member) => !member.is_admin
  );

  if (enterpriseMembersError) {
    return (
      <div className={styles.error}>
        <ErrorMessage
          error={t('Unable to add admin users. Please try again.')}
        />
      </div>
    );
  }

  // Final step after user has reviewed user changes
  if (currentStepIndex >= steps.length) {
    return (
      <AddAdminUserProgress
        email={changedUser as string}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        enterprise={activeEnterprise!}
      />
    );
  }

  return (
    <StepContainer
      className={styles.addAdminUser}
      title={steps[currentStepIndex].title}
      heading={<>{steps[currentStepIndex].heading}</>}
      steps={steps.map((step, index) => ({
        status:
          currentStepIndex === index
            ? 'current'
            : currentStepIndex > index
            ? 'complete'
            : 'future',
        label: step.name
      }))}
    >
      <StepComponent
        members={nonAdminMembers || []}
        changedUser={changedUser}
        setChangedUser={setChangedUser}
        onStepComplete={handleStepComplete}
        onPreviousStep={handlePreviousStep}
      />
    </StepContainer>
  );
};

export default AddAdminUser;
