import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  TextField
} from '@deque/cauldron-react';

interface EditCompanyProps {
  companyText: string;
  onCompanyEditSubmit: (value: string) => void;
}

const EditCompany = ({
  companyText,
  onCompanyEditSubmit
}: EditCompanyProps): React.ReactElement => {
  const { t } = useTranslation();
  const input = useRef<HTMLInputElement>(null);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [companyError, setCompanyError] = useState('');
  const [companyValue, setCompanyValue] = useState(companyText);
  const onEditCompanyClick = () => setCompanyModalOpen(true);
  const onEditCompanyClose = () => setCompanyModalOpen(false);
  const onCompanyChange = (value: string) => setCompanyValue(value);
  const onEditCompanySubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!companyValue) {
      setCompanyError(t('Company name required'));
      return;
    }

    setCompanyError('');
    onEditCompanyClose();
    onCompanyEditSubmit(companyValue);
  };

  useEffect(() => {
    if (!companyError) {
      return;
    }

    input.current?.focus();
  }, [companyError]);

  return (
    <>
      <Button thin variant="secondary" onClick={onEditCompanyClick}>
        {t('Edit company information')}
      </Button>
      <Modal
        heading={<span>{t('Edit company information')}</span>}
        show={companyModalOpen}
        onClose={onEditCompanyClose}
      >
        <form onSubmit={onEditCompanySubmit}>
          <ModalContent>
            <TextField
              label={t('Company name')}
              error={companyError}
              value={companyValue}
              onChange={onCompanyChange}
              fieldRef={input}
            />
          </ModalContent>
          <ModalFooter>
            <Button type="submit">{t('Save')}</Button>
            <Button variant="secondary" onClick={onEditCompanyClose}>
              {t('cancel')}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default EditCompany;
