import React, {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Loader } from '@deque/cauldron-react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import type { FieldMappingField } from '../../TemplateWizardForm/TemplateWizardForm';
import {
  ProductNames,
  SupportedIntegrationProductSlugs
} from '../../../../../../common/constants';
import Field from './Field/Field';
import { useGlobalToast } from '../../../../../../common/contexts/globalToast';
import styles from './FieldMapping.css';
import useIssueTypeFields from '../../../../../hooks/useIssueTypeFields';
import { IssueTypeField } from '../../../../../../common/utils/integrations-client/jira/getIssueTypeFields';
import JiraFieldMapper from '../../../../../../common/utils/integrations-client/jira/fieldMapping';

export interface SelectOption {
  key: string;
  label: string;
  value: string;
  required?: boolean;
  disabled?: boolean;
}

export interface FieldMappingProps {
  integrationProductSlug: SupportedIntegrationProductSlugs;
  setIsStepDataLoading: Dispatch<SetStateAction<boolean>>;
  connectionId: string;
  token: string;
  setIsStepDataError: Dispatch<SetStateAction<boolean>>;
}

export const getAxeFieldsOptions = (t: TFunction): SelectOption[] => [
  { key: '', label: '', value: '' },
  { label: t('Rule'), key: 'rule', value: 'ruleId' },
  { label: t('Description'), key: 'description', value: 'description' },
  { label: t('Help'), key: 'help', value: 'help' },
  { label: t('Help URL'), key: 'helpUrl', value: 'helpUrl' },
  { label: t('Summary'), key: 'summary', value: 'summary' },
  { label: t('Selector'), key: 'selector', value: 'selector' },
  { label: t('Source'), key: 'source', value: 'source' },
  {
    label: t('Issue Screenshot URL'),
    key: 'screenshotURL',
    value: 'screenshotURL'
  },
  { label: t('Tags'), key: 'tags', value: 'tags' },
  { label: t('Test name'), key: 'testName', value: 'testName' },
  {
    label: t('Issue Share URL'),
    key: 'shareURL',
    value: 'shareURL'
  },
  { label: t('Created at'), key: 'createdAt', value: 'createdAt' },
  {
    label: t('Issue Test URL'),
    key: 'testUrl',
    value: 'testUrl'
  },
  {
    label: t('Found by'),
    key: 'foundBy',
    value: 'foundBy'
  },
  {
    label: t('Test page title'),
    key: 'testPageTitle',
    value: 'testPageTitle'
  },
  {
    label: t('Axe version'),
    key: 'axeVersion',
    value: 'axeVersion'
  }
];

const getMappingFieldsOptions = (fields: IssueTypeField[]): SelectOption[] => {
  const mappedFields = fields.map<SelectOption>(
    ({ fieldId, name, required }) => {
      return {
        key: fieldId,
        value: fieldId,
        label: name,
        required
      };
    }
  );

  return [{ key: '', label: '', value: '' }, ...mappedFields];
};

/** Fields that are handled by server or integration*/
const ExcludedIntegrationFields = {
  [SupportedIntegrationProductSlugs.jiraIntegration]: [
    'issuetype',
    'project',
    'description',
    'reporter',
    'priority'
  ]
};

const FieldMapping = ({
  integrationProductSlug,
  setIsStepDataLoading,
  connectionId,
  token,
  setIsStepDataError
}: FieldMappingProps) => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const { control, getValues, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fieldMapping'
  });

  const projectId = getValues('project');
  const issueTypeId = getValues('issueType');

  const [mappableIntegrationFields, setMappableIntegrationFields] = useState<
    IssueTypeField[]
  >([]);
  const [chosenIntegrationFields, setChosenIntegrationFields] = useState<
    string[]
  >([]);

  const {
    data: issueMappingFields,
    error,
    loading
  } = useIssueTypeFields(projectId, issueTypeId, connectionId, token);

  useEffect(() => setIsStepDataLoading(loading), [loading]);

  useEffect(() => {
    setIsStepDataError(!!error);

    if (error) {
      setContents(
        t('There was an error fetching fields for this issue type.'),
        'error'
      );
    }
  }, [error]);

  useEffect(() => {
    if (!issueMappingFields || !issueMappingFields.length) {
      return;
    }

    const groupedFields = issueMappingFields.reduce<{
      mappable: IssueTypeField[];
      unmappable: IssueTypeField[];
    }>(
      (result, field) => {
        const canBeMapped = Object.values(JiraFieldMapper).some(mapper =>
          mapper.canMapThisField(issueMappingFields, field.key)
        );

        const isExcluded = ExcludedIntegrationFields[
          integrationProductSlug
        ].includes(field.fieldId);

        if (isExcluded) {
          return result;
        }

        if (canBeMapped) {
          result.mappable.push(field);
        } else {
          result.unmappable.push(field);
        }

        return result;
      },
      { mappable: [], unmappable: [] }
    );

    const unmappableRequiredFields = groupedFields.unmappable.filter(
      field => field.required && !field.hasDefaultValue
    );

    if (unmappableRequiredFields.length) {
      const fieldNames = unmappableRequiredFields
        .map(({ name }) => `'${name}'`)
        .join(', ');

      setContents(
        t(
          'This issue type has the following required fields that can not be mapped: {{ fieldNames }}. Please set these fields as optional or provide default values.',
          { fieldNames }
        ),
        'error'
      );
      setIsStepDataError(true);

      return;
    }

    setMappableIntegrationFields(groupedFields.mappable);
  }, [issueMappingFields]);

  useEffect(() => {
    if (fields.length || !mappableIntegrationFields?.length) {
      const usedMappingFieldValues = (
        fields as unknown as FieldMappingField[]
      ).map(field => field.mappingField);

      setChosenIntegrationFields(usedMappingFieldValues);
      return;
    }

    const requiredFields = mappableIntegrationFields?.reduce(
      (fieldsArray, field) => {
        if (field.required && !field.hasDefaultValue) {
          fieldsArray.push({
            axeField: '',
            mappingField: field.fieldId
          });
        }

        return fieldsArray;
      },
      [] as Array<FieldMappingField>
    );

    append(requiredFields);
  }, [mappableIntegrationFields, fields]);

  const onSelectIntegrationField = () => {
    const values: Array<FieldMappingField> = getValues('fieldMapping');
    const mappingFields = values.map(({ mappingField }) => mappingField);
    setChosenIntegrationFields(mappingFields);
  };

  const mappingFields: SelectOption[] = useMemo(
    () => getMappingFieldsOptions(mappableIntegrationFields),
    [mappableIntegrationFields]
  );

  const axeFields: SelectOption[] = useMemo(() => getAxeFieldsOptions(t), [t]);

  const isFieldRequired = (field: Record<string, string>) => {
    return !!mappingFields.find(
      mappingField => mappingField.value === field.mappingField
    )?.required;
  };

  const fieldsAreRemaining = fields.length < mappingFields.length - 1;

  const removeField = (index: number) => {
    setValue(
      // react-hook-form's `remove` method removes the field from the form's state, so it's not registered as touched
      // we need to manually set the value to empty string to register it as touched
      `fieldMapping[${index}]`,
      { axeField: '', mappingField: '' },
      { shouldValidate: true, shouldTouch: true }
    );

    remove(index);
  };

  if (loading) {
    return <Loader label={t('Loading...')} />;
  }
  const fieldsContent = fields
    .reduce<ReactNode[][]>(
      (result, field, i) => {
        const isRequired = isFieldRequired(field);

        const fieldComponent = (
          <Field
            key={field.id}
            required={isRequired}
            integrationProductSlug={integrationProductSlug}
            axeFields={axeFields}
            mappingFields={mappingFields}
            removeField={removeField}
            index={i}
            onSelectIntegrationField={onSelectIntegrationField}
            chosenIntegrationFields={chosenIntegrationFields}
          />
        );

        if (isRequired) {
          result[0].push(fieldComponent);
        } else {
          result[1].push(fieldComponent);
        }

        return result;
      },
      [[], []]
    )
    .flat();

  return (
    <div className={styles.fieldMappingContainer}>
      <div
        className={
          // Add border bottom if there are fields remaining
          fieldsAreRemaining && fields.length !== 0
            ? styles.borderBottom
            : undefined
        }
      >
        {fieldsContent}
      </div>
      {/* Add button to add a new field if there are any fields remaining to map */}
      {fieldsAreRemaining && (
        <Button
          onClick={() => append({ axeField: '', mappingField: '' })}
          color="primary"
          className={styles.addAxeFieldButton}
          thin
        >
          <Icon type="plus" />
          {t(`Add {{ axe }} Field`, {
            axe: ProductNames.axe
          })}
        </Button>
      )}
    </div>
  );
};

export default FieldMapping;
