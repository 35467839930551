import type { JiraFieldMapper, JiraFieldSchema } from './types';
import { findJiraFieldByKey } from './findJiraFieldByKey';

export class NumberMapper implements JiraFieldMapper {
  id = 'jira-number-mapper';

  canMapThisField(jiraSchema: JiraFieldSchema[], fieldKey: string): boolean {
    const targetField = findJiraFieldByKey(jiraSchema, fieldKey);

    if (!targetField) {
      return false;
    }

    const { type, custom } = targetField.schema;
    const isCustomType = !!custom;
    const isNumber = type === 'number';

    return isCustomType
      ? isNumber &&
          custom === 'com.atlassian.jira.plugin.system.customfieldtypes:float'
      : isNumber;
  }

  formatFieldValue(
    jiraSchema: JiraFieldSchema[],
    fieldKey: string,
    sourceValue: unknown
  ): number | undefined {
    const fieldName = findJiraFieldByKey(jiraSchema, fieldKey)?.name;

    if (
      typeof sourceValue === 'object' ||
      Array.isArray(sourceValue) ||
      sourceValue === null ||
      sourceValue === undefined ||
      isNaN(Number(sourceValue))
    ) {
      throw new Error(`Can not map to '${fieldName}'`);
    } else {
      return Number(sourceValue);
    }
  }
}
