import React, { useEffect, useState } from 'react';
import billingClient, {
  EnterpriseInvitations
} from '../../common/utils/billing-client/client-v2';
import type { v2 } from '@deque/billing-service-client';
import ContentToast from './ContentToast';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../contexts/auth';
import { useTranslation } from 'react-i18next';

interface Invitation extends EnterpriseInvitations.Invitation {
  enterprise: v2.Enterprise;
}

const InvitationsToast = (): JSX.Element | null => {
  const authContext = useAuthContext();
  const { t } = useTranslation();
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  const fetchInvitations = async (token: string) => {
    const data = await billingClient.me.getUserInvitations(token, false);
    const invitationsData = await Promise.all(
      data.map(async invitation => {
        const enterprise = await billingClient.enterprises.get({
          token: token,
          id: invitation.enterprise_id
        });
        return { ...invitation, enterprise };
      }) || []
    );
    setInvitations(invitationsData);
  };
  useEffect(() => {
    if (authContext?.user?.token) {
      fetchInvitations(authContext.user.token);
    }
  }, [authContext]);

  if (!invitations.length) {
    return null;
  }

  return (
    <ContentToast show={true} type="caution">
      {invitations.length > 1
        ? t('You have been invited to join the organizations')
        : t('You have been invited to join the organization')}
      {': '}
      {invitations.map((invitation, index) => (
        <span key={invitation.id}>
          {index ? ', ' : ''}
          <Link
            to={`/accept-invitation?invitation_id=${invitation.id}&enterprise_id=${invitation.enterprise_id}`}
          >
            {invitation.enterprise.name}
          </Link>
        </span>
      ))}
    </ContentToast>
  );
};
export default InvitationsToast;
