import type { v2, StripeTypes } from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Enterprises {
  export interface License {
    product_id: string;
    total: number | null;
    used: number;
    pending: number;
  }
}

interface GetParams {
  token: string;
  id: string;
}

interface ListPaymentMethodsParams {
  token: string;
  id: string;
}

interface CreateParams {
  token: string;
  name: string;
  admin_keycloak_id?: string;
  enterprise_stripe_email?: string;
  offline?: boolean;
  root_group?: string;
  auto_add_users?: boolean;
}

export default {
  /** Gets the licenses for the products the enterprise has a subscription to */
  listLicenses(
    token: string,
    enterpriseId: string
  ): Promise<Enterprises.License[]> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/licenses`,
      method: 'GET',
      token
    });
  },
  /** Get an enterprise by its `id`. */
  get({ token, id }: GetParams): Promise<v2.Enterprise> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${id}`,
      method: 'GET',
      token
    });
  },
  /** Create a new enterprise. */
  create({
    token,
    name,
    admin_keycloak_id,
    enterprise_stripe_email,
    offline,
    root_group,
    auto_add_users
  }: CreateParams): Promise<v2.Enterprise> {
    return apiRequest({
      endpoint: '/api/billing/v2/enterprises',
      method: 'POST',
      token,
      body: {
        name,
        admin_keycloak_id,
        enterprise_stripe_email,
        offline,
        root_group,
        auto_add_users
      }
    });
  },
  /** Get the list of payment methods which belong to an enterprise. */
  listPaymentMethods({
    token,
    id
  }: ListPaymentMethodsParams): Promise<StripeTypes.PaymentMethod[]> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${id}/payment_methods`,
      method: 'GET',
      token
    });
  },

  /**
   * Create a enterprise trial subscription for a specific product ID
   */
  async createTrialSubscription(
    productId: string,
    enterpriseId: string,
    token: string
  ): Promise<v2.EnterpriseSubscription> {
    const subscription: v2.EnterpriseSubscription = await apiRequest({
      method: 'POST',
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/trials/${productId}`,
      token
    });

    return subscription;
  },

  /**
   * Create a enterprise free subscription for a specific product ID
   */
  async createFreeSubscription(
    productId: string,
    enterpriseId: string,
    token: string
  ): Promise<v2.EnterpriseSubscription> {
    const subscription: v2.EnterpriseSubscription = await apiRequest({
      method: 'POST',
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/free-subscriptions/${productId}`,
      token
    });

    return subscription;
  }
};
