import qs from 'qs';
import type { v2 } from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';

interface TeamsEndpointParams {
  token: string;
  enterpriseId: string;
}

interface TeamParams {
  id: string;
  name: string;
}

type GetMembershipParams = TeamsEndpointParams & Omit<TeamParams, 'name'>;

type CreateMembershipParams = GetMembershipParams & {
  keycloakIds: string[];
};

type DeleteMembershipsParams = CreateMembershipParams;

export default {
  create: ({
    token,
    enterpriseId,
    id,
    keycloakIds
  }: CreateMembershipParams): Promise<void> => {
    const url_params = { keycloak_ids: keycloakIds };
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/teams/${id}/memberships?${qs.stringify(
        url_params
      )}`,
      method: 'POST',
      token
    });
  },

  get: ({
    token,
    enterpriseId,
    id
  }: GetMembershipParams): Promise<v2.TeamMembership[]> => {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/teams/${id}/memberships`,
      method: 'GET',
      token
    });
  },

  delete: ({
    token,
    enterpriseId,
    id,
    keycloakIds
  }: DeleteMembershipsParams): Promise<void> => {
    const url_params = { keycloak_ids: keycloakIds };
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/teams/${id}/memberships?${qs.stringify(
        url_params
      )}`,
      method: 'DELETE',
      token
    });
  }
};
