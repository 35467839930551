import type {
  UserWithKeycloakId,
  CreateUserParams,
  StripeTypes,
  TaxId
} from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';

export default {
  /**
   * Create a "billing service" user.
   *
   * This is different from a "regular" user. This is the object which will be associated with payment data/etc.
   */
  create(
    // The server adds `keycloak_id` on its own (deduced from the bearer token), so we don't need to pass it along.
    params: Omit<CreateUserParams, 'keycloak_id'>,
    token: string
  ): Promise<UserWithKeycloakId> {
    return apiRequest({
      method: 'POST',
      endpoint: '/api/billing/users',
      body: params,
      token
    });
  },
  /**
   * get a user's payment methods
   */
  listPaymentMethods(token: string): Promise<StripeTypes.PaymentMethod[]> {
    return apiRequest({
      method: 'GET',
      endpoint: '/api/billing/users/paymentMethods',
      token
    });
  },
  removePaymentMethod(
    token: string,
    payment_method_id: string
  ): Promise<StripeTypes.PaymentMethod> {
    return apiRequest({
      method: 'DELETE',
      endpoint: `/api/billing/users/paymentMethods/${payment_method_id}`,
      token
    });
  },
  /**
   * add a user payment method
   */
  addPaymentMethod(
    token: string,
    payment_method_id: string,
    tax_id?: TaxId
  ): Promise<StripeTypes.PaymentMethod> {
    return apiRequest({
      method: 'POST',
      endpoint: '/api/billing/users/paymentMethods',
      body: {
        payment_method_id,
        tax_id
      },
      token
    });
  }
};
