import React from 'react';
import styles from './InlineTag.css';
import classNames from 'classnames';

interface InlineTagProps {
  className?: string;
  children: React.ReactNode;
}
const InlineTag = ({
  className,
  children
}: InlineTagProps): React.ReactElement => {
  return (
    <span
      className={classNames(`${styles.tag}`, {
        [`${className}`]: className
      })}
    >
      {children}
    </span>
  );
};

export default InlineTag;
