import {
  ProductSlugs,
  SupportedIntegrationProductSlugs
} from '../../common/constants';

type ProductNamesUtilOptions = {
  capitalize?: boolean;
};
// Additional integration product names should be added here as needed
export type IntegrationTypes = 'jira';

// TODO - Integration
// Remove this function and use getIntegrationProductInfoFromSlug below instead
function getIntegrationProductName(
  slug: SupportedIntegrationProductSlugs,
  opts?: ProductNamesUtilOptions
): IntegrationTypes | Capitalize<IntegrationTypes> {
  const productName = slug.replace('-integration', '').toLowerCase();

  if (opts?.capitalize) {
    return (productName.charAt(0).toUpperCase() +
      productName.slice(1)) as Capitalize<IntegrationTypes>;
  }

  return productName as IntegrationTypes;
}

export default getIntegrationProductName;

export type IntegrationProductInfo = {
  displayName: string;
  paragonIntegrationName: string;
  integrationProductSlug: ProductSlugs;
};

export function getIntegrationProductInfoFromSlug(
  integrationProductSlug: ProductSlugs
): IntegrationProductInfo {
  switch (integrationProductSlug) {
    case ProductSlugs.jiraIntegration:
      return {
        displayName: 'Jira',
        paragonIntegrationName: 'jira',
        integrationProductSlug
      };
    default:
      throw new Error(
        `Unsupported integration product slug ${integrationProductSlug}`
      );
  }
}
