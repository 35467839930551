import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { SelectOption } from '@deque/cauldron-react';
import type { v2 } from '@deque/billing-service-client';
import Form from './Form';
import { useLoadedConfiguration } from '../../../common/contexts/Configuration';
import {
  SettingsSchemaGeneralMlValueEnum,
  SettingsSchemaAxeDevtoolsProAutoContrastValueEnum
} from '@deque/orgwide-settings-client';
import { getSettingType } from '../../../common/utils/configuration';
import SelectSetting from './SelectSetting';
import RadioSetting from './RadioSetting';
import type { EventData } from '../../../common/AxeAccountAnalytics';
import { useAxeAccountAnalytics } from '../../../common/contexts/analytics';
import { ProductSlugs } from '../../../common/constants';

interface AxeDevtoolsProConfigurationProps {
  product: v2.Product;
}

interface Form {
  autoContrast: SettingsSchemaAxeDevtoolsProAutoContrastValueEnum;
  allowUsersToChangeAutoContrast: boolean;
  igt: SettingsSchemaGeneralMlValueEnum;
  allowUsersToChangeIgt: boolean;
}

const analyticsProperties: Record<keyof Form, string> = {
  autoContrast: 'ccToolBehaviorDefault',
  allowUsersToChangeAutoContrast: 'ccToolAllowChange',
  igt: 'IGTDefault',
  allowUsersToChangeIgt: 'IGTAllowChange'
};

interface OptionItem<T extends string> extends SelectOption {
  value: T;
}

interface RadioItem<T extends string> {
  value: T;
  disabled?: boolean;
  label: string;
  id: string;
}

const AxeDevtoolsProConfiguration: React.FC<
  AxeDevtoolsProConfigurationProps
> = ({ product }) => {
  const { t } = useTranslation();
  const {
    settings,
    updateSettings,
    saving,
    isEnterpriseMember,
    isEnterpriseAdmin
  } = useLoadedConfiguration();
  const analytics = useAxeAccountAnalytics();

  const needsReviewEnabled = ['enabled', 'excluded'].includes(
    settings.general.needsReview.value
  );

  const devToolsSettings = settings.axeDevtoolsPro;
  const { control, handleSubmit, reset, formState } = useForm<Form>();
  const isReadOnly = isEnterpriseMember && !isEnterpriseAdmin;
  const disabled = isReadOnly || saving;

  const contrastOptions: OptionItem<SettingsSchemaAxeDevtoolsProAutoContrastValueEnum>[] =
    [
      {
        key: 'manual',
        value: 'manual',
        label: t('Choose when to run (recommended)')
      },
      {
        key: 'automatic',
        value: 'automatic',
        label: t('Run automatically after each scan')
      },
      {
        key: 'disabled',
        value: 'disabled',
        label: t('Disabled')
      }
    ];

  const igtOptions: RadioItem<SettingsSchemaGeneralMlValueEnum>[] = [
    {
      label: t('Enable'),
      value: 'enabled',
      id: 'enabled',
      disabled: disabled
    },
    {
      label: t('Disable'),
      value: 'disabled',
      id: 'disabled',
      disabled: disabled
    }
  ];

  useEffect(() => {
    if (devToolsSettings) {
      reset({
        autoContrast: devToolsSettings.autoContrast.value,
        allowUsersToChangeAutoContrast:
          devToolsSettings.autoContrast.type === 'default',
        igt: devToolsSettings.igt.value,
        allowUsersToChangeIgt: devToolsSettings.igt.type === 'default'
      });
    }
  }, [devToolsSettings]);
  const onSubmit = (data: Form) => {
    updateSettings({
      axeDevtoolsPro: {
        autoContrast: {
          value: data.autoContrast,
          type: isEnterpriseAdmin
            ? getSettingType(data.allowUsersToChangeAutoContrast)
            : devToolsSettings.autoContrast.type
        },
        igt: {
          value: data.igt,
          type: isEnterpriseAdmin
            ? getSettingType(data.allowUsersToChangeIgt)
            : devToolsSettings.igt.type
        }
      }
    });

    const changedProperties = Object.keys(
      formState.dirtyFields as Partial<Form>
    ).reduce<EventData>((acc, key) => {
      const field = key as keyof Form;
      if (field in analyticsProperties) {
        acc[analyticsProperties[field]] = data[field];
      }
      return acc;
    }, {});

    analytics.configSubmit(
      ProductSlugs.axeDevToolsExtension,
      changedProperties
    );
  };

  const onDiscard = () => {
    analytics.configDiscard(ProductSlugs.axeDevToolsExtension);
    reset();
  };

  return (
    <Form
      header={product.name}
      onDiscard={onDiscard}
      onSave={handleSubmit(onSubmit)}
      isDirty={formState.isDirty}
      isReadOnly={isReadOnly}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectSetting
          id="default-auto-contrast"
          name="autoContrast"
          allowChangeName="allowUsersToChangeAutoContrast"
          shouldShowAllowChange={isEnterpriseMember}
          label={t('Color Contrast Tool Behavior')}
          tooltip={t(
            "To edit, enable 'Needs Review Options' in the Global Settings."
          )}
          options={contrastOptions}
          formControl={control}
          disabled={disabled || !needsReviewEnabled}
        />
        <RadioSetting
          id="default-igt"
          name="igt"
          allowChangeName="allowUsersToChangeIgt"
          shouldShowAllowChange={isEnterpriseMember}
          label={t('Default Intelligent Guided Tests (IGT)')}
          options={igtOptions}
          formControl={control}
          disabled={disabled}
        />
      </form>
    </Form>
  );
};
export default AxeDevtoolsProConfiguration;
