import { TFunction } from 'i18next';
import isValidLicenseCount from './is-valid-license-count';

interface ValidateProps {
  fullName: HTMLInputElement | null;
  company: HTMLInputElement;
  licenseCount: HTMLInputElement | null;
  taxIdValue?: HTMLInputElement | null;
  taxIdType?: HTMLSelectElement | null;
  taxIdOpen?: boolean;
  hasTaxIdV1?: boolean;
  hasPaymentsV2?: boolean;
}

export interface Errors {
  [n: string]: string;
}

export default function (
  {
    fullName,
    company,
    licenseCount,
    taxIdValue,
    taxIdType,
    taxIdOpen,
    hasTaxIdV1 = false,
    hasPaymentsV2 = true
  }: ValidateProps,
  t: TFunction
): Errors {
  let firstErroneousInput: HTMLInputElement | null = null;

  const errors: Errors = {};

  // NOTE: order of the below validation checks matches DOM order which is important
  if (
    licenseCount &&
    (!licenseCount.value || !isValidLicenseCount(licenseCount.value))
  ) {
    errors.licenseCount = t('You must add at least 1 license');
    firstErroneousInput = licenseCount;
  }

  if (!hasPaymentsV2 && !fullName?.value) {
    errors.fullName = t('Full name is required');
    firstErroneousInput = fullName;
  }

  if (!company.value) {
    errors.company = t('Company is required');
    firstErroneousInput = firstErroneousInput || company;
  }

  if (hasTaxIdV1 && taxIdOpen && taxIdValue && taxIdType?.value !== 'none') {
    const minimumTaxIdLength = 5;
    const hasValue = taxIdValue?.value;

    if (!hasValue) {
      errors.taxId = t('Tax identifier value is required');
      firstErroneousInput = taxIdValue;
    }

    if (hasValue && taxIdValue.value.length < minimumTaxIdLength) {
      errors.taxId = t('Tax identifier value is too short');
      firstErroneousInput = taxIdValue;
    }
  }
  firstErroneousInput?.focus();

  return errors;
}
