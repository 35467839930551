import rulesets, { Standards } from '@deque/accessibility-standards';
import type { SettingsSchemaGeneralAxeCoreVersionValueEnum as AxeCoreVersion } from '@deque/orgwide-settings-client';

export const standardKeys = Object.values(rulesets).map(
  ruleset => ruleset.value
);

export const MINIMUM_TT_V5_AXE_CORE_VERSION: AxeCoreVersion = '4.7.1';
export const MINIMUM_EN_301_AXE_CORE_VERSION: AxeCoreVersion = '4.8.0';

export const recommendedStandard: Standards = 'wcag21aa';
