import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StepContainer from '../../common/components/StepContainer';
import billingClient, {
  EnterpriseMembers,
  Enterprises,
  memberHasAccepted
} from '../../common/utils/billing-client/client-v2';
import { v2 } from '@deque/billing-service-client';
import EditUser from '../components/EditUser';
import ConfirmUnload from '../components/ConfirmUnload';
import { Loader } from '@deque/cauldron-react';
import ErrorMessage from '../components/issue/ErrorMessage';
import NotFound from '../../common/pages/NotFound';
import { useGlobalToast } from '../../common/contexts/globalToast';
import ReviewUserChanges from '../components/ReviewUserChanges';
import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import { UserChanges } from '../utils/access-utils';

interface UseSubmitEditsOptions {
  token: string;
  enterpriseId: string;
}

export interface SubmitEdits {
  member: EnterpriseMembers.AcceptedMember;
  userChanges?: UserChanges;
}

export interface UseSubmitEdits {
  submitEdits: (options: SubmitEdits) => Promise<void>;
  submitting: boolean;
}

export const useSubmitEdits = ({
  token,
  enterpriseId
}: UseSubmitEditsOptions): UseSubmitEdits => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const submitEdits = async ({ member, userChanges }: SubmitEdits) => {
    setSubmitting(true);
    const memberName = `${member.first_name} ${member.last_name}`;

    try {
      if (userChanges) {
        // Add/remove admin access if necessary
        if (member.is_admin && !userChanges.isAdmin) {
          await billingClient.enterpriseAdmins.remove({
            token,
            enterpriseId,
            adminId: member.user_id
          });
        } else if (!member.is_admin && userChanges.isAdmin) {
          await billingClient.enterpriseInvitations.addMember({
            token,
            enterpriseId,
            email: member.email,
            isAdmin: true
          });
        }

        // Remove access to products that have been revoked
        for (const product of userChanges.removalProducts) {
          await billingClient.enterpriseMembers.removeProductAccess({
            token,
            enterpriseId,
            memberId: member.user_id,
            productId: product.id
          });
        }

        // Invite access to new products
        for (const product of userChanges.newProducts) {
          await billingClient.enterpriseInvitations.create({
            token,
            enterpriseId,
            productSlug: product.slug,
            email: member.email
          });
        }

        for (const accessChange of Object.values(userChanges.accessChanges)) {
          // A user cannot be both removed and have their access changed
          if (
            userChanges.removalProducts.some(
              p => p.id === accessChange.product.id
            )
          ) {
            continue;
          }
          await billingClient.enterpriseMembers.updateProductAccess({
            token,
            enterpriseId,
            memberId: member.user_id,
            productId: accessChange.product.id,
            accessType: accessChange.accessType
          });
        }
      }
      setContents(
        t('Changes saved for {{memberName}}.', { memberName }),
        'confirmation'
      );
    } catch {
      setContents(
        t('Unable to save changes for {{memberName}}.', { memberName }),
        'error'
      );
    } finally {
      // Make sure there is enough time for the ConfirmUpdate component to stop checking for unmounts
      window.setTimeout(() => {
        history.push('/user-access');
      }, 500);
    }
  };

  return {
    submitEdits,
    submitting
  };
};

interface EditUserProps {
  token: string;
  enterpriseId: string;
  userId: string;
  members: EnterpriseMembers.PendingOrAcceptedMember[];
  products: v2.Product[];
  licenses: Enterprises.License[];
  activeUser: v2.User;
  loading: boolean;
  error: Error | null;
}

export default function EditUserContainer({
  token,
  enterpriseId,
  userId,
  members,
  products,
  licenses,
  activeUser,
  loading,
  error
}: EditUserProps): JSX.Element {
  const { t } = useTranslation();
  const loaderRef = useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [userChanges, setUserChanges] = useState<UserChanges>();
  const { submitEdits, submitting } = useSubmitEdits({ token, enterpriseId });

  useEffect(() => {
    if (!loading && !submitting) {
      return;
    }
    /* istanbul ignore next */
    loaderRef.current?.focus();
  }, [loading, submitting]);

  if (loading) {
    return <Loader label={t('Loading...')} ref={loaderRef} tabIndex={-1} />;
  }

  if (error) {
    return (
      <ErrorMessage
        error={t('Unable to load edit user page. Please try again.')}
      />
    );
  }

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const member = members.find(
    m => memberHasAccepted(m) && m.user_id === userId
  ) as EnterpriseMembers.AcceptedMember;

  if (!member) {
    return <NotFound />;
  }

  const steps = [
    {
      title: t('Edit user access'),
      heading: `${member.first_name} ${member.last_name} • ${member.email}`,
      label: t('Edit User Access')
    },
    {
      title: t('Review your users'),
      heading: t('Summary of user changes'),
      label: t('Review')
    }
  ];

  return (
    <>
      <ConfirmUnload
        enabled={!!userChanges && !submitting}
        message={t(
          'Are you sure you want to navigate away from editing this user? All progress will be lost.'
        )}
      />
      {submitting && (
        <ScrimmedLoader label={t('Saving changes...')} loaderRef={loaderRef} />
      )}
      <StepContainer
        title={steps[currentStep].title}
        heading={steps[currentStep].heading}
        steps={steps.map((step, index) => ({
          status:
            currentStep === index
              ? 'current'
              : currentStep > index
              ? 'complete'
              : 'future',
          label: step.label
        }))}
      >
        {currentStep === 0 && (
          <EditUser
            member={member}
            members={members}
            licenses={licenses}
            products={products}
            onAccessChange={setUserChanges}
            userChanges={
              userChanges || {
                isAdmin: member.is_admin,
                newProducts: [],
                removalProducts: [],
                accessChanges: {}
              }
            }
            onSubmit={handleNextStep}
            activeUser={activeUser}
          />
        )}
        {currentStep === 1 && (
          <ReviewUserChanges
            members={members}
            changedUsers={[member.email]}
            changes={userChanges || {}}
            onComplete={() => {
              submitEdits({
                member,
                userChanges
              });
            }}
            onCancel={handlePreviousStep}
          />
        )}
      </StepContainer>
    </>
  );
}
