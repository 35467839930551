import { useCallback, useMemo } from 'react';

import getProjectsAndIssueTypes, {
  type ProjectsAndIssueTypes,
  type IssueType
} from '../../common/utils/integrations-client/jira/getProjectsAndIssueTypes';
import useAPI, { type State } from '../../common/hooks/useAPI';

export interface IntegrationProjectsMethods {
  getProjectById: (id: string | null) => ProjectsAndIssueTypes | undefined;
  getIssueTypesByProjectId: (id: string | null) => IssueType[] | undefined;
  getIssueTypeById: (id: string | null) => IssueType | undefined;
}

const useIntegrationProjects = (
  connectionId?: string,
  token?: string
): State<ProjectsAndIssueTypes[]> & IntegrationProjectsMethods => {
  const dataLoader = useCallback(() => {
    if (!connectionId) {
      return Promise.resolve([]);
    }

    return getProjectsAndIssueTypes({
      connectionId,
      token
    });
  }, [connectionId, token]);

  const { loading, error, data } = useAPI(dataLoader);

  const getProjectById = useCallback(
    (id: string | null): ProjectsAndIssueTypes | undefined =>
      id ? data?.find(p => p.id === id) : undefined,
    [data]
  );

  const getIssueTypesByProjectId = useCallback(
    (id: string | null): IssueType[] | undefined => {
      if (!id) {
        return;
      }

      const project = getProjectById(id);
      if (!project) {
        return;
      }

      return project.issueTypes;
    },
    [data]
  );

  const getIssueTypeById = useCallback(
    (id: string | null): IssueType | undefined =>
      id
        ? data
            ?.map(project => project.issueTypes || [])
            .flat()
            .find(issueType => issueType.id === id)
        : undefined,
    [data]
  );

  const results = useMemo(
    () => ({
      loading,
      error,
      data,
      getProjectById,
      getIssueTypesByProjectId,
      getIssueTypeById
    }),
    [
      loading,
      error,
      data,
      getProjectById,
      getIssueTypesByProjectId,
      getIssueTypeById
    ]
  );

  return results;
};

export default useIntegrationProjects;
