import type { v2 } from '@deque/billing-service-client';
import {
  Alert,
  AlertActions,
  AlertContent,
  Button,
  Icon,
  OptionsMenu,
  OptionsMenuItem,
  Panel,
  TextField,
  PanelContent
} from '@deque/cauldron-react';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ContentToast from '../../../common/components/ContentToast';
import { ProductSlugs } from '../../../common/constants';
import { EnterpriseMembers } from '../../../common/utils/billing-client/client-v2';
import type {
  FilterValues,
  FiltersLabelsAndValues
} from '../../hooks/useUserManagementFilters';
import type { AddUserFailedActionState } from '../AddUserProgress';
import FilterBar from './FilterBar/FilterBar';
import styles from './UserManagement.css';
import UserManagementTable from './UserManagementTable/UserManagementTable';
import ScrimmedLoader from '../../../common/components/ScrimmedLoader';
import SearchBar from './SearchBar/SearchBar';

export interface AvailableLicense {
  product_name?: string;
  product_slug?: string;
  remaining_count: number | null;
}

export interface UserManagementProps {
  filteredMembers: EnterpriseMembers.PendingOrAcceptedMember[];
  filteredPendingUsers: EnterpriseMembers.PendingUser[];
  currentUserId: string;
  availableLicenses: AvailableLicense[];
  getProductBySlug: (slug: string) => v2.Product | undefined;
  onAddUser: (
    type: 'product' | 'admin' | 'member',
    productSlug?: string
  ) => void;
  onResend: (member: EnterpriseMembers.PendingMember) => void;
  onRemoveUser: (member: EnterpriseMembers.PendingOrAcceptedMember) => void;
  onDismiss: () => void;
  loadingAction: boolean;
  hasError: boolean;
  errMessage: string | null;
  confirmationMsg: string | null;
  isMobile?: boolean;
  hiddenProductSlugs: ProductSlugs[];
  isMailerEnabled: boolean;
  handleApplyFilters: (filters: FilterValues) => void;
  appliedFilters: FilterValues;
  isFiltered: boolean;
  allowedFilterValuesAndLabels: FiltersLabelsAndValues;
  totalUsers?: number;
  totalPendingUsers?: number;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
}

const UserManagement = ({
  filteredMembers,
  currentUserId,
  availableLicenses,
  getProductBySlug,
  onAddUser,
  onResend,
  onRemoveUser,
  onDismiss,
  hasError,
  errMessage,
  loadingAction,
  confirmationMsg,
  isMobile = false,
  hiddenProductSlugs,
  isMailerEnabled,
  handleApplyFilters,
  appliedFilters,
  isFiltered,
  allowedFilterValuesAndLabels,
  totalUsers,
  totalPendingUsers,
  searchQuery,
  setSearchQuery
}: UserManagementProps): JSX.Element => {
  const { t } = useTranslation();
  const loaderRef = useRef<HTMLDivElement>(null);

  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [showResendAlert, setShowResendAlert] = useState(false);
  const [user, setUser] =
    useState<EnterpriseMembers.PendingOrAcceptedMember | null>(null);
  const location = useLocation();
  const failedUserAction: AddUserFailedActionState = location.state;
  const [showActionFailedAlert, setShowActionFailedAlert] = useState(
    !!failedUserAction?.action && failedUserAction?.failedUsers?.length
  );
  const dismissActionFailedAlert = () => setShowActionFailedAlert(false);

  const onResendClick = (member: EnterpriseMembers.PendingMember) => {
    setShowResendAlert(true);
    setUser(member);
  };

  const onRemoveClick = (member: EnterpriseMembers.PendingOrAcceptedMember) => {
    setShowRemoveAlert(true);
    setUser(member);
  };

  useEffect(() => {
    /* istanbul ignore next */
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.collapsed]: isMobile
      })}
    >
      {loadingAction && (
        <ScrimmedLoader label={t('Loading...')} loaderRef={loaderRef} />
      )}
      <Alert heading={t('resend invitation')} show={showResendAlert}>
        <AlertContent>
          {t('You’re about to send another invitation to: {{ email }}.', {
            email: user?.email
          })}
        </AlertContent>
        <AlertActions>
          <Button
            onClick={() => {
              onResend(user as EnterpriseMembers.PendingMember);
              setShowResendAlert(false);
            }}
          >
            {t('Resend')}
          </Button>
          <Button variant="secondary" onClick={() => setShowResendAlert(false)}>
            {t('Cancel')}
          </Button>
        </AlertActions>
      </Alert>
      <Alert
        heading={t('Remove User')}
        show={showRemoveAlert}
        variant="warning"
      >
        <AlertContent>
          {t(
            'You’re about to revoke user access for: {{ email }}. They will no longer be part of your managed users.',
            { email: user?.email }
          )}
        </AlertContent>
        <AlertActions>
          <Button
            variant="error"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onRemoveUser(user!);
              setShowRemoveAlert(false);
            }}
          >
            {t('Remove')}
          </Button>
          <Button variant="secondary" onClick={() => setShowRemoveAlert(false)}>
            {t('Cancel')}
          </Button>
        </AlertActions>
      </Alert>
      {hasError && (
        <ContentToast type="caution" show>
          {t('Failed to load users')}
        </ContentToast>
      )}
      {errMessage && (
        <ContentToast type="caution" show>
          {errMessage}
        </ContentToast>
      )}
      {confirmationMsg && (
        <ContentToast type="confirmation" show onDismiss={onDismiss}>
          {confirmationMsg}
        </ContentToast>
      )}
      {!hasError && (
        <div>
          <div className={styles.head}>
            <div className={styles.header}>
              <h1>{t('Users')}</h1>
              <Trans>
                <p>
                  Total Users:{' '}
                  <strong>
                    {{
                      total: (totalUsers || 0) + (totalPendingUsers || 0)
                    }}
                  </strong>
                  {{
                    pendingMessage: totalPendingUsers
                      ? totalPendingUsers === 1
                        ? t(', including 1 user who has not logged in yet')
                        : t(
                            ', including {{ pending }} users who have not logged in yet',
                            { pending: totalPendingUsers }
                          )
                      : ''
                  }}
                </p>
              </Trans>
            </div>
            <OptionsMenu
              className={styles.addUsersMenu}
              align={isMobile ? 'left' : 'right'}
              trigger={triggerProps => (
                <Button type="button" {...triggerProps} thin>
                  <Icon type="plus" />
                  {t('Add User(s)')}
                </Button>
              )}
            >
              {availableLicenses.map(license => (
                <OptionsMenuItem
                  key={license.product_slug}
                  disabled={
                    license.remaining_count !== null &&
                    license.remaining_count <= 0
                  }
                  onSelect={() => onAddUser('product', license.product_slug)}
                >
                  <p>
                    {t('...to {{ productName }}', {
                      productName: license.product_name
                    })}
                    {license.remaining_count !== null && (
                      <span>
                        {t(' ({{ count }} users available)', {
                          count: license.remaining_count
                        })}
                      </span>
                    )}
                  </p>
                </OptionsMenuItem>
              ))}
              <OptionsMenuItem onSelect={() => onAddUser('member')}>
                <p>{t('...to your axe Account')}</p>
              </OptionsMenuItem>
              <OptionsMenuItem onSelect={() => onAddUser('admin')}>
                <p>{t('...as an axe Account Admin')}</p>
              </OptionsMenuItem>
            </OptionsMenu>
          </div>
          <Panel>
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              isFiltered={isFiltered}
              totalMembers={filteredMembers.length}
            />
            <FilterBar
              appliedFilters={appliedFilters}
              handleApplyFilters={handleApplyFilters}
              allowedFilterValuesAndLabels={allowedFilterValuesAndLabels}
            />
            {!filteredMembers.length ? (
              <PanelContent padding className={styles.tableContainer}>
                <p role="status">
                  {isFiltered || !!searchQuery
                    ? t(
                        'No results found. Try adjusting your filters or searching for someone else.'
                      )
                    : t('Please add your first user!')}
                </p>
              </PanelContent>
            ) : (
              <PanelContent padding className={styles.tableContainer}>
                <UserManagementTable
                  filteredMembers={filteredMembers}
                  currentUserId={currentUserId}
                  getProductBySlug={getProductBySlug}
                  onResendClick={onResendClick}
                  onRemoveClick={onRemoveClick}
                  isMobile={isMobile}
                  hiddenProductSlugs={hiddenProductSlugs}
                  isMailerEnabled={isMailerEnabled}
                  searchQuery={searchQuery}
                />
              </PanelContent>
            )}
          </Panel>
        </div>
      )}
      {showActionFailedAlert && (
        <Alert
          heading={
            failedUserAction.action === 'add-users'
              ? t('Add User Complete')
              : t('Action Complete')
          }
          show
        >
          <AlertContent>
            {failedUserAction.failedReasons.length > 0 && (
              <ul>
                {failedUserAction.failedReasons.map(
                  (reason: string, index: number) => (
                    <li key={index}>{reason}</li>
                  )
                )}
              </ul>
            )}
            <p>
              {failedUserAction.failedUsers.length === 1
                ? t('We were unable to add (1) user:')
                : t('We were unable to add ({{ count }}) users:', {
                    count: failedUserAction.failedUsers.length
                  })}
            </p>
            <TextField
              label={t('Users not added')}
              value={failedUserAction.failedUsers.join(', ')}
              disabled
              multiline
            />
            <div className={styles.copyUsers}>
              <CopyToClipboard text={failedUserAction.failedUsers.join(', ')}>
                <Button variant="link">{t('copy list of users')}</Button>
              </CopyToClipboard>
            </div>
          </AlertContent>
          <AlertActions>
            <Button variant="secondary" onClick={dismissActionFailedAlert}>
              {t('Close')}
            </Button>
          </AlertActions>
        </Alert>
      )}
    </div>
  );
};
export default UserManagement;
