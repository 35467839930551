import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SortDirection, v2 } from '@deque/billing-service-client';
import {
  Icon,
  Link,
  Loader,
  Pagination,
  Panel,
  PanelContent,
  PanelHeader,
  Toast
} from '@deque/cauldron-react';
import { getPagination } from '@deque/pagination-utils';

import clamp from '../../common/utils/clamp';

import billingClient from '../../common/utils/billing-client/client-v2';
import useTeams from '../hooks/useTeams';

import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import TeamTable from '../components/teams/TeamTable';
import TeamSearch from '../components/teams/TeamSearch';
import DeleteTeamAlert from '../components/teams/DeleteTeamAlert';
import styles from './TeamList.css';

const PAGE_SIZE = 10;

export interface TeamsListProps {
  token: string;
  enterpriseId: string;
}

const TeamList: React.FC<TeamsListProps> = ({
  token,
  enterpriseId
}: TeamsListProps) => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);
  const [nameQuery, setNameQuery] = useState<string>();

  const [sort, setSort] = useState<[v2.TeamSortableColumn, SortDirection]>();

  const [error, setError] = useState<Error | null>(null);
  const [deleting, setDeleting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState<v2.Team | null>(null);

  const [sortBy, sortDir] = sort || [];

  const {
    teams,
    loading,
    error: teamsError,
    refresh
  } = useTeams({
    token,
    enterpriseId,
    name: nameQuery,
    page,
    per_page: PAGE_SIZE,
    sortBy,
    sortDir
  });

  const pagination = useMemo(() => {
    const newPagination = teams && getPagination(teams);

    const totalPages = newPagination?.total_pages || 1;
    const gotoPage = (value: number) => setPage(clamp(value, 1, totalPages));

    return {
      currentPage: page,
      itemsPerPage: PAGE_SIZE,
      totalItems: newPagination?.total || teams?.length || 0,
      onNextPageClick: () => gotoPage(page + 1),
      onPreviousPageClick: () => gotoPage(page - 1),
      onFirstPageClick: () => gotoPage(1),
      onLastPageClick: () => gotoPage(totalPages)
    };
  }, [page, teams]);

  const onNameQueryChange = (query?: string) => {
    setNameQuery(query);
    setPage(1);
  };

  const openAlert = (team: v2.Team) => {
    setTeamToDelete(team);
    setShowAlert(true);
  };

  const onCancel = () => {
    setTeamToDelete(null);
    setShowAlert(false);
  };

  const onDelete = async () => {
    setError(null);
    setDeleting(true);
    setShowAlert(false);
    try {
      if (teamToDelete) {
        await billingClient.teams.delete({
          token,
          enterpriseId,
          id: teamToDelete.id
        });
        refresh();
      }
    } catch (e) {
      setError(e as Error);
    } finally {
      setTeamToDelete(null);
      setDeleting(false);
    }
  };

  return (
    <div className={styles.container}>
      {(teamsError || error) && (
        <Toast show type="error">
          {teamsError?.message || error?.message}
        </Toast>
      )}
      {deleting && <ScrimmedLoader label={t('Deleting team...')} />}
      <div className={styles.header}>
        <h1>{t('Teams')}</h1>
        <Link href="/teams/new" variant="button">
          <Icon type="plus" />
          {t('Create Team')}
        </Link>
      </div>
      <Panel>
        <PanelHeader>
          <TeamSearch query={nameQuery} setQuery={onNameQueryChange} />
        </PanelHeader>
        <PanelContent className={styles.tableContainer}>
          {loading ? (
            <Loader tabIndex={-1} label={t('Loading teams')} />
          ) : teams && teams.length ? (
            <>
              <TeamTable
                teams={teams}
                sort={sort}
                deleteTeam={openAlert}
                setSort={setSort}
              />
              <Pagination className={styles.pagination} {...pagination} />
              <DeleteTeamAlert
                show={showAlert}
                team={teamToDelete}
                onDelete={onDelete}
                onCancel={onCancel}
              />
            </>
          ) : (
            <p className={styles.noMatchingTeams}>
              {t('No matching teams found')}
            </p>
          )}
        </PanelContent>
      </Panel>
    </div>
  );
};

export default TeamList;
