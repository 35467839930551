import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertActions,
  AlertContent,
  Button
} from '@deque/cauldron-react';
import type { v2, AxePurchaseState } from '@deque/billing-service-client';

import {
  ACTIVE_STATES,
  FREE_STATES,
  ProductSlugs
} from '../../common/constants';
import { dayjs } from '../../common/utils/dates';
import { useAuthContext } from '../../common/contexts/auth';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import ApiKeyManagementComponent from '../components/ApiKeyManagement';
import AddEditApiKey from '../components/AddEditApiKey';
import ContentToast from '../../common/components/ContentToast';
import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import useApiKey from '../hooks/useApiKey';
import { useProducts } from '../../common/contexts/products';
import type {
  UpdateApiKeyBody,
  CreateApiKeyBody
} from '../../common/api-client';
import type { EditParams } from '../components/AddEditApiKey';
import type { ApiKeyTableData } from '../components/ApiKeyTable';
import { AXE_WATCHER_PAID_PLANS_V1 } from '../../axe-watcher/constants';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import {
  type Subscription,
  getControllingProductSubscription
} from '@deque/billing-utils';

const ApiKeyManagement: React.FC = () => {
  const { t } = useTranslation();
  const wide = useMediaQuery('(min-width: 49rem)');
  const narrow = useMediaQuery('(max-width: 31rem)');
  const variant = wide ? 'wide' : narrow ? 'narrow' : 'medium';
  const { user, billingUser } = useAuthContext();
  // There will always be a user and with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  const {
    products,
    loading: loadingProducts,
    error: productsError
  } = useProducts();
  /* istanbul ignore next */
  const {
    apiKeys,
    error: apiKeyError,
    loading: loadingApiKeys,
    list,
    create,
    deleteById,
    updateById,
    regenerateApiKey
  } = useApiKey(token);
  const hasAxeWatcherPaidPlans = useFeatureFlagState(AXE_WATCHER_PAID_PLANS_V1);

  const [confirmation, setConfirmation] = useState('');
  const [apiKeyErr, setApiKeyErr] = useState('');
  const [isApiCall, setIsApiCall] = useState(true);
  const [confirmationMsg, setConfirmationMsg] = useState<string | null>(null);
  const [showApiKeyError, setShowApiKeyError] = useState(false);
  const [apiKeyErrMsg, setApiKeyErrMsg] = useState('');
  const [showApiKeyModal, setShowApiKeyModal] = useState(false);
  const [isAddEdit, setIsAddEdit] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editParams, setEditParams] = useState<EditParams | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertType, setAlertType] = useState<'delete' | 'regenerate'>();
  const [alertBtnText, setAlertBtnText] = useState('');
  const [apiKeyId, setApiKeyId] = useState('');
  const [focusReturn, setFocusReturn] = useState<Element | null>(null);

  const [developerHubFreePlan, setDeveloperHubFreePlan] =
    useState<Subscription | null>(null);

  const validateSubscription = (
    p: v2.Product,
    subscription: Subscription
  ): boolean => {
    if (p.slug === ProductSlugs.axeDevToolsWatcher && hasAxeWatcherPaidPlans) {
      /**
       * Note: for axe-devtools-watcher (axe Developer Hub) when paid-plans
       * is enabled we allow users/enterprises to have a free subscription
       * which allows them to create (but limited to 1) API key.
       */

      const validSubscription =
        subscription.product_id === p.id &&
        [...ACTIVE_STATES, ...FREE_STATES].includes(
          subscription.purchase_state as AxePurchaseState
        );

      setDeveloperHubFreePlan(
        subscription &&
          FREE_STATES.includes(subscription.purchase_state as AxePurchaseState)
          ? subscription
          : null
      );

      return !!validSubscription;
    }

    return (
      subscription.product_id === p.id &&
      ACTIVE_STATES.includes(subscription.purchase_state as AxePurchaseState)
    );
  };

  const findSubscriptions = (p: v2.Product): boolean => {
    const subscription = getControllingProductSubscription(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      billingUser!,
      p.slug as ProductSlugs
    );

    return subscription ? validateSubscription(p, subscription) : false;
  };

  const apiKeyProducts: v2.Product[] = useMemo(() => {
    // only display products with internal API key and a valid subscription from the user
    return products.filter(
      p =>
        p.api_key_generation &&
        p.api_key_generation === 'internal' &&
        findSubscriptions(p)
    );
  }, [products, billingUser]);

  const apiKeyTableData: ApiKeyTableData[] = useMemo(() => {
    return apiKeys.map(apiKey => {
      return {
        id: apiKey.id,
        api_key: apiKey.api_key,
        name: apiKey.name,
        product_name:
          /* istanbul ignore next */
          apiKeyProducts.find(product => product.slug === apiKey.product_slug)
            ?.name || '',
        product_slug: apiKey.product_slug,
        elapsed_time: dayjs(apiKey.updated_at || apiKey.created_at).fromNow(),
        tags: []
      };
    });
  }, [apiKeys, apiKeyProducts]);

  useEffect(() => {
    if (confirmation) {
      handleConfirmation(confirmation);
    }
  }, [confirmation]);

  useEffect(() => {
    if (apiKeyErr) {
      handleApiKeyErrorMsg(apiKeyErr);
    }
  }, [apiKeyErr]);

  useEffect(() => {
    list();
    setApiKeyErr(t`load`);
  }, []);

  useEffect(() => {
    if (!isAddEdit) {
      return;
    }

    setIsApiCall(true);
    if (apiKeyError?.status && apiKeyError.status >= 500) {
      setApiKeyErr(isEditMode ? t`edit` : t`add`);
    }
    setConfirmation(isEditMode ? t`edited` : t`added`);

    if (isAddEdit) {
      setIsAddEdit(false);
      setShowApiKeyModal(false);
    }
  }, [loadingApiKeys, apiKeyError, isAddEdit]);

  useEffect(() => {
    if (!showApiKeyModal && !showAlert && !loadingApiKeys && focusReturn) {
      (focusReturn as HTMLElement).focus();
      setFocusReturn(null);
    }
  }, [showApiKeyModal, showAlert, loadingApiKeys]);

  const handleConfirmation = (operation: string) => {
    if (!loadingApiKeys && !apiKeyError) {
      setConfirmationMsg(
        t('API key was successfully {{ operation }}', { operation })
      );
    }
  };

  const handleApiKeyErrorMsg = (operation = t`load`) => {
    if (!isApiCall || (apiKeyError && isApiCall)) {
      setShowApiKeyError(true);
      setApiKeyErrMsg(t('Failed to {{ operation }} API Key', { operation }));
    }
  };

  const handleAddApiKey = async (params: CreateApiKeyBody) => {
    // wait until api calls are done
    await create({
      name: params.name,
      product_slug: params.product_slug,
      tags: []
    });
    setIsAddEdit(true);
  };

  const handleEditApiProject = async (updates: UpdateApiKeyBody) => {
    /* istanbul ignore next */
    await updateById(editParams?.id || '', {
      name: updates.name
    });
    setIsAddEdit(true);
  };

  const handleRecreateApiKey = async (id: string) => {
    setShowAlert(false);
    setAlertType(undefined);
    await regenerateApiKey(id);
    setIsApiCall(true);
    setConfirmation(t`regenerated`);
    setApiKeyErr(t`regenerate`);
  };

  const handleDeleteApiKey = async (id: string) => {
    setShowAlert(false);
    setAlertType(undefined);
    await deleteById(id);
    setIsApiCall(true);
    setConfirmation(t`deleted`);
    setApiKeyErr(t`delete`);
  };

  const handleCopyApiKey = async (apiKey: string) => {
    setIsApiCall(false);
    try {
      await navigator.clipboard.writeText(apiKey);
      setConfirmation(t`copied`);
    } catch (err) {
      setApiKeyErr(t`copy`);
    }
  };

  const onAddClick = (): void => {
    setShowApiKeyModal(true);
    setIsEditMode(false);
  };

  const onEditSelect = (
    id: string,
    product_slug: string,
    name: string
  ): void => {
    /* istanbul ignore next */
    setFocusReturn(
      document.activeElement?.parentElement?.previousElementSibling || null
    );
    setShowApiKeyModal(true);
    setIsEditMode(true);
    setEditParams({
      id,
      product_slug,
      name
    });
  };

  const onRegenerateClick = (id: string): void => {
    /* istanbul ignore next */
    setFocusReturn(
      document.activeElement?.parentElement?.previousElementSibling || null
    );
    setApiKeyId(id);
    setAlertMsg(
      t`Regenerating your API key will delete your current API key and create a new one. You will need to replace any instances of your old key in your code with the new one.`
    );
    setAlertType('regenerate');
    setAlertBtnText(t`Regenerate`);
    setShowAlert(true);
  };

  const onDeleteClick = (id: string): void => {
    const key = apiKeys.find(s => s.id === id);
    const onDeleteMsg =
      key?.product_slug === ProductSlugs.axeDevToolsWatcher
        ? t`Deleting your API key will remove your API key and any projects all data associated with this API key. After deletion, any instances of the deleted API in your code will no longer be functional and you will no longer be able to access the project associated with it.`
        : t`Deleting your API key will remove your API key. After deletion, any instances of the deleted API in your code will no longer be functional.`;
    /* istanbul ignore next */
    setFocusReturn(
      document.activeElement?.parentElement?.previousElementSibling || null
    );
    setApiKeyId(id);
    setAlertMsg(onDeleteMsg);
    setAlertType('delete');
    setAlertBtnText(t`Delete`);
    setShowAlert(true);
  };

  const handleClose = () => {
    setShowApiKeyModal(false);
    if (apiKeyError) {
      // set error to null if a request is aborted due to an error
      list();
    }
  };

  const generateSelectOptions = () => {
    return apiKeyProducts.map(product => {
      if (
        product.slug === ProductSlugs.axeDevToolsWatcher &&
        hasAxeWatcherPaidPlans
      ) {
        const enterpriseId = (developerHubFreePlan as v2.EnterpriseSubscription)
          ?.enterprise_id;
        const axeDeveloperHubKeys = apiKeys.filter(apiKey => {
          if (apiKey.product_slug !== ProductSlugs.axeDevToolsWatcher) {
            return false;
          }

          // If the enterprise ID is truthy, we only want to return API keys that belong to the enterprise
          if (enterpriseId) {
            return apiKey.enterprise_id === enterpriseId;
          }

          // If the enterprise ID is falsy, we only want to return API keys that do not belong to an enterprise
          return !apiKey.enterprise_id;
        }).length;

        // On a trialing/paid plan users can create unlimited API keys
        const label = developerHubFreePlan
          ? `${product.name} (${
              axeDeveloperHubKeys === 0 ? '1' : '0'
            } Keys Remaining)`
          : `${product.name}`;

        return {
          key: product.id,
          value: product.slug,
          label,
          // On the free plan, users can only create 1 API key
          disabled: !!developerHubFreePlan && axeDeveloperHubKeys >= 1
        };
      }

      return {
        key: product.id,
        value: product.slug,
        label: product.name
      };
    });
  };

  const showLoader = loadingApiKeys || loadingProducts;

  return (
    <div>
      {showAlert && (
        <Alert
          heading={
            alertType === 'delete' ? t`Delete API key` : t`Regenerate API key`
          }
          onClose={() => setShowAlert(false)}
          show={showAlert}
          variant="warning"
        >
          <AlertContent>{alertMsg}</AlertContent>
          <AlertActions>
            <Button
              variant="error"
              onClick={() =>
                alertType === 'delete'
                  ? handleDeleteApiKey(apiKeyId)
                  : handleRecreateApiKey(apiKeyId)
              }
            >
              {alertBtnText}
            </Button>
            <Button variant="secondary" onClick={() => setShowAlert(false)}>
              {t('Cancel')}
            </Button>
          </AlertActions>
        </Alert>
      )}
      {showLoader && !showApiKeyModal && (
        <ScrimmedLoader label={t('Loading...')} />
      )}
      {confirmationMsg && (
        <ContentToast
          type="confirmation"
          show
          onDismiss={() => {
            setConfirmationMsg(null);
          }}
        >
          {confirmationMsg}
        </ContentToast>
      )}
      {!!productsError && (
        <ContentToast type="caution" show>
          {t`Failed to load Products`}
        </ContentToast>
      )}
      {showApiKeyError && (
        <ContentToast type="caution" show>
          {apiKeyErrMsg}
        </ContentToast>
      )}
      {showApiKeyModal && (
        <AddEditApiKey
          handleSaveAdd={handleAddApiKey}
          handleSaveEdit={handleEditApiProject}
          handleClose={handleClose}
          showApiKeyModal={showApiKeyModal}
          selectionOptions={generateSelectOptions()}
          isEditMode={isEditMode}
          editParams={isEditMode ? editParams : null}
          error={apiKeyError}
          showLoader={showLoader}
        />
      )}
      <ApiKeyManagementComponent
        apiKeys={apiKeyTableData}
        hasProducts={!!apiKeyProducts.length}
        handleCopyApiKey={handleCopyApiKey}
        onRegenerateClick={onRegenerateClick}
        onDeleteClick={onDeleteClick}
        onAddClick={onAddClick}
        onEditSelect={onEditSelect}
        variant={variant}
      />
    </div>
  );
};

export default ApiKeyManagement;
