import Analytics from './Analytics';

export enum CrossProductAmplitudeEvents {
  VIEW_PLANS = 'conversion:viewplans',
  TRIAL_START_CLICK = 'conversion:trialstartclick',
  DOWNGRADE = 'conversion:downgrade',
  MANAGE_BILLING_CLICK = 'conversion:managebilling',
  CLICK_PURCHASE = 'conversion:purchase',
  PAID_SUBSCRIPTION_CANCEL = 'conversion:cancel',
  TRIAL_SUBSCRIPTION_CANCEL = 'conversion:trialcancel',
  INVOICE_REQUEST_SUBMITTED = 'conversion:invoice-request',
  CLICK_CONTACT_US = 'conversion:click:contactUs',
  SIGNUP_BUY_NOW_LINK = 'conversion:click:signup:buyNow'
}

export default class CrossProductAnalytics<T extends string> extends Analytics<
  CrossProductAmplitudeEvents | T
> {
  public viewPlans() {
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.VIEW_PLANS
    });
  }

  public trialStartClick() {
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.TRIAL_START_CLICK
    });
  }

  public downgrade() {
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.DOWNGRADE
    });
  }

  public manageBillingClick() {
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.MANAGE_BILLING_CLICK
    });
  }

  public clickPurchase() {
    const cookieData = this.distinctIdCookie?.data;
    const data = {
      original_landing_page: cookieData?.original_landing_page as string,
      original_utm_source: cookieData?.original_utm_source as string
    };
    if (cookieData) {
      this.amplitude.setUserProperties(data);
    }
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.CLICK_PURCHASE,
      data
    });
  }

  public paidSubscriptionCancel(data: { cancelDate: Date }) {
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.PAID_SUBSCRIPTION_CANCEL,
      data
    });
  }

  public trialSubscriptionCancel() {
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.TRIAL_SUBSCRIPTION_CANCEL
    });
  }

  public invoiceRequestSubmitted(data: { hasAccount: boolean }) {
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.INVOICE_REQUEST_SUBMITTED,
      data
    });
  }
  public clickContactUs() {
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.CLICK_CONTACT_US
    });
  }
  public clickSignUpBuyNowLink() {
    return this.sendAmplitudeEvent({
      event: CrossProductAmplitudeEvents.SIGNUP_BUY_NOW_LINK
    });
  }
}
