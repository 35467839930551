import React from 'react';
import Configuration from '../components/configuration/Configuration';
import { useProducts } from '../../common/contexts/products';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { useConfiguration } from '../../common/contexts/Configuration';
import { useConfigurationAccess } from '../hooks/useConfigurationAccess';
import { useAuthContext } from '../../common/contexts/auth';
import { useIntegrations } from '../hooks/useIntegrations';

const ConfigurationPage: React.FC = () => {
  const narrow = useMediaQuery('(max-width: 31.25rem)');
  const { loading: authLoading, error: authError } = useAuthContext();
  const { loading: configurationLoading, loadingError: configurationError } =
    useConfiguration();
  const {
    loading: productsLoading,
    error: productsError,
    getProductBySlug
  } = useProducts();
  const {
    loading: integrationsLoading,
    error: integrationsError,
    enabledIntegrationProductSlugs,
    canAccessIntegrationConfiguration,
    activeEnterprise,
    isEnterpriseAdmin
  } = useIntegrations();

  const loading = !!(
    configurationLoading ||
    productsLoading ||
    integrationsLoading ||
    authLoading
  );

  const error =
    configurationError || productsError || authError || integrationsError
      ? new Error(authError)
      : null;

  const { checkUserAccessToProduct, userHasAccessToConfiguration } =
    useConfigurationAccess();

  return (
    <Configuration
      narrow={narrow}
      loading={loading}
      error={error}
      canAccessIntegrationConfiguration={canAccessIntegrationConfiguration}
      enabledIntegrationProductSlugs={enabledIntegrationProductSlugs}
      getProductBySlug={getProductBySlug}
      hasAccessToProduct={checkUserAccessToProduct}
      showNoActiveSubscriptionMessage={!userHasAccessToConfiguration}
      activeEnterprise={activeEnterprise}
      isEnterpriseAdmin={isEnterpriseAdmin}
    />
  );
};

export default ConfigurationPage;
