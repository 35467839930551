import { SupportedIntegrationProductSlugs } from '../../../constants';
import { apiRequest } from '../../../api-client';

export interface IntegrationConnection {
  id: string;
  product_slug: SupportedIntegrationProductSlugs;
  credential_id: string;
  base_url: string;
  enterprise_id: string;
  connected_user_id: string;
  status: 'connected' | 'disconnected' | 'deleted';
  created_at: string;
  updated_at: string;
}

interface RequestBody {
  enterpriseId: string;
  token?: string;
}

interface RequestByIdBody extends RequestBody {
  connectionId: string;
}

export interface ReconnectBody {
  credential_id: string;
  base_url: string;
}

export interface CreateBody extends ReconnectBody {
  product_slug: SupportedIntegrationProductSlugs;
}

interface CreateIntegrationConnection extends RequestBody {
  body: CreateBody;
}

interface ReconnectIntegrationConnection extends RequestByIdBody {
  body: ReconnectBody;
}

interface IntegrationConnectionRequest {
  enterpriseId: string;
  token?: string;
  path?: string;
  method?: string;
  body?: CreateBody | ReconnectBody | { status: string };
}

const integrationConnectionRequest = <T,>({
  enterpriseId,
  token,
  path = '',
  method,
  body
}: IntegrationConnectionRequest): Promise<T> => {
  return apiRequest<T>({
    endpoint: `/api/integrations/enterprises/${enterpriseId}/connections${path}`,
    method,
    body,
    token
  });
};

export const getIntegrationConnections = ({
  enterpriseId,
  token
}: {
  enterpriseId: string;
  token?: string;
}) => {
  return integrationConnectionRequest<IntegrationConnection[]>({
    enterpriseId,
    token
  });
};

export const getIntegrationConnectionById = ({
  enterpriseId,
  connectionId,
  token
}: RequestByIdBody) => {
  return integrationConnectionRequest<IntegrationConnection>({
    enterpriseId,
    path: `/${connectionId}`,
    token
  });
};

export const createIntegrationConnection = ({
  enterpriseId,
  token,
  body
}: CreateIntegrationConnection) => {
  return integrationConnectionRequest<IntegrationConnection>({
    enterpriseId,
    method: 'POST',
    body,
    token
  });
};

export const reconnectIntegrationConnection = ({
  enterpriseId,
  connectionId,
  token,
  body
}: ReconnectIntegrationConnection) => {
  return integrationConnectionRequest<IntegrationConnection>({
    enterpriseId,
    path: `/${connectionId}`,
    method: 'PUT',
    body: { ...body, status: 'connected' },
    token
  });
};

export const disconnectIntegrationConnection = ({
  enterpriseId,
  connectionId,
  token
}: RequestByIdBody) => {
  return integrationConnectionRequest<IntegrationConnection>({
    enterpriseId,
    path: `/${connectionId}`,
    method: 'PUT',
    body: { status: 'disconnected' },
    token
  });
};

export const deleteIntegrationConnection = ({
  enterpriseId,
  connectionId,
  token
}: RequestByIdBody) => {
  return integrationConnectionRequest<IntegrationConnection>({
    enterpriseId,
    path: `/${connectionId}`,
    method: 'DELETE',
    token
  });
};
