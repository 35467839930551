import type { v2 } from '@deque/billing-service-client';
import queryString from 'query-string';
import { useEffect, useState, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ProductSlugs } from '../../common/constants';
import { useProducts } from '../../common/contexts/products';
import {
  memberHasAccepted,
  memberIsPending,
  type EnterpriseMembers
} from '../../common/utils/billing-client/enterprises/enterprise-members-v2';

export type FilterCategory = 'product' | 'role' | 'status';
export type ProductFilter = ProductSlugs | 'axe-account';
export type RoleFilter = 'admin' | 'general-access';
export type StatusFilter = 'email-sent' | 'active';
export type AllFilterValues = ProductFilter[] & RoleFilter[] & StatusFilter[];
export type CategoryFilter<T extends FilterCategory> = T extends 'product'
  ? ProductFilter
  : T extends 'role'
  ? RoleFilter
  : StatusFilter;
export type FilterValue = ProductFilter | RoleFilter | StatusFilter;
export type FilterValues = {
  product: ProductFilter[];
  role: RoleFilter[];
  status: StatusFilter[];
};
export interface LabelAndValue<T extends FilterCategory> {
  label: string;
  value: CategoryFilter<T>;
}
export type FiltersLabelsAndValues = {
  product: LabelAndValue<'product'>[];
  role: LabelAndValue<'role'>[];
  status: LabelAndValue<'status'>[];
};

export interface useUserManagementFiltersProps {
  members: EnterpriseMembers.PendingOrAcceptedMember[] | null;
  pendingUsers: EnterpriseMembers.PendingUser[] | null;
  hiddenProductSlugs: ProductSlugs[];
  activeEnterprise?: v2.Enterprise | null;
}

export const filterCategories: FilterCategory[] = ['product', 'role', 'status'];

// Using a switch rather than ternaries or conditionals in case we want to add more categories in the future
export const getCategoryLabel = (
  t: TFunction,
  categoryValue: FilterCategory
) => {
  switch (categoryValue) {
    case 'product':
      return t('Product');
    case 'role':
      return t('Role');
    case 'status':
      return t('Status');
  }
};

function filterMembers(
  members: EnterpriseMembers.Member[] | null,
  appliedFilters: FilterValues,
  searchQuery: string
) {
  if (!members) {
    return [];
  }

  let filteredMembers = members;

  const adminOnly =
    appliedFilters['role'].includes('admin') &&
    !appliedFilters['role'].includes('general-access');
  const generalAccessOnly =
    !appliedFilters['role'].includes('admin') &&
    appliedFilters['role'].includes('general-access');

  const noProductFilters = appliedFilters['product'].length === 0;
  const hasProductFilters = !noProductFilters;
  const hasAxeAccount = appliedFilters['product'].includes('axe-account');
  const hasOnlyProducts = !hasAxeAccount && hasProductFilters;
  const hasAxeAccountAndProducts =
    hasAxeAccount && appliedFilters['product'].length > 1;

  const activeOnly =
    appliedFilters['status'].includes('active') &&
    !appliedFilters['status'].includes('email-sent');
  const pendingOnly =
    !appliedFilters['status'].includes('active') &&
    appliedFilters['status'].includes('email-sent');

  // If only products are specified for product filters
  if (hasOnlyProducts) {
    filteredMembers = filteredMembers.filter(member =>
      // If "admin" is the only role filter selected
      adminOnly
        ? false // Filter out all users, because we do not currently support an "admin" role for products
        : // Else, filter out users who do not have access to any of the products specified in the filters
          member.product_access?.some(access =>
            appliedFilters['product'].includes(
              access.product_slug as ProductSlugs
            )
          )
    );
  } else if (hasAxeAccountAndProducts) {
    // If both "axe account" and other products are specified for the product filters
    filteredMembers = filteredMembers.filter(member => {
      const productFilteredMember = member.product_access?.some(access =>
        appliedFilters['product'].includes(access.product_slug as ProductSlugs)
      );
      // If "general access" is the only role filter selected
      if (generalAccessOnly) {
        return productFilteredMember || !member.is_admin; // Filter out all users who are admins or do not have access to any of the products specified in the filters
        // If "admin" is the only role filter selected
      } else if (adminOnly) {
        return member.is_admin; // Filter out all users who are not admins and do not have access to any of the products specified in the filters
      }
      // Else do not filter any members because all members in the user management table have axe account general access
      return true;
    });
  } else if (adminOnly) {
    filteredMembers = filteredMembers.filter(member => member.is_admin);
  } else if (generalAccessOnly) {
    filteredMembers = filteredMembers.filter(member => !member.is_admin);
  }

  if (activeOnly) {
    filteredMembers = filteredMembers.filter(member =>
      memberHasAccepted(member)
    );
  } else if (pendingOnly) {
    filteredMembers = filteredMembers.filter(member => memberIsPending(member));
  }

  if (searchQuery) {
    filteredMembers = filteredMembers.filter(
      member =>
        (memberIsPending(member) &&
          member.email.toLowerCase().includes(searchQuery)) ||
        (memberHasAccepted(member) &&
          (`${member.first_name} ${member.last_name}`
            .toLowerCase()
            .includes(searchQuery) ||
            member.email.toLowerCase().includes(searchQuery)))
    );
  }
  return filteredMembers;
}

export const useUserManagementFilters = ({
  members,
  pendingUsers,
  hiddenProductSlugs,
  activeEnterprise
}: useUserManagementFiltersProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { getProductBySlug } = useProducts();

  const [queryPrams, setQueryParams] = useState(history.location.search);
  const [appliedFilters, setAppliedFilters] = useState<FilterValues>({
    product: [],
    role: [],
    status: []
  });
  const [searchQuery, setSearchQuery] = useState('');
  const isFiltered =
    Object.values(appliedFilters).flat().length > 0 || !!searchQuery;

  const visibleProducts =
    activeEnterprise?.subscriptions.reduce((acc, sub) => {
      const productSlug = sub.product_slug as ProductSlugs;
      if (!hiddenProductSlugs.includes(productSlug)) {
        acc.push(productSlug);
      }
      return acc;
    }, [] as ProductSlugs[]) || [];

  const productFilters: LabelAndValue<'product'>[] = [
    { label: `axe ${t('Account')}`, value: 'axe-account' as const },
    ...visibleProducts.map(slug => ({
      label: getProductBySlug(slug)?.name || '',
      value: slug
    }))
  ];

  const roleFilters = [
    { label: t('General Access'), value: 'general-access' as const },
    { label: t('Admin'), value: 'admin' as const }
  ];

  const statusFilters = [
    { label: t('Active'), value: 'active' as const },
    { label: t('Email Sent'), value: 'email-sent' as const }
  ];

  const allowedFilterValuesAndLabels: FiltersLabelsAndValues = {
    product: productFilters,
    role: roleFilters,
    status: statusFilters
  };

  const allowedFilterValues: FilterValues = {
    product: productFilters.map(filter => filter.value),
    role: roleFilters.map(filter => filter.value),
    status: statusFilters.map(filter => filter.value)
  };

  const parseQueryParams = () => {
    const parsedParams = queryString.parse(history.location.search);
    const filters: FilterValues = {
      product: [],
      role: [],
      status: []
    };

    filterCategories.forEach(category => {
      const categoryParams = parsedParams[category];
      if (categoryParams) {
        const categoryValues = Array.isArray(categoryParams)
          ? categoryParams
          : categoryParams.split(',');

        allowedFilterValues[category].forEach(value => {
          if (categoryValues.includes(value)) {
            (
              filters[category] as (ProductFilter | RoleFilter | StatusFilter)[]
            ).push(value);
          }
        });
      }
    });

    return filters;
  };

  const handleApplyFilters = (filters: FilterValues) => {
    const currentSearch = new URLSearchParams(history.location.search);
    filterCategories.forEach(category => {
      currentSearch.delete(category);
      if (filters[category].length > 0) {
        currentSearch.set(category, filters[category].join(','));
      }
    });
    history.replace({ search: currentSearch.toString() });
    setQueryParams(history.location.search);
  };

  // Effect to parse query params and set filters
  useEffect(() => {
    const filters = parseQueryParams();
    const newFilters: FilterValues = {
      product: [],
      role: [],
      status: []
    };
    filterCategories.forEach(category => {
      const newCategoryFilters = (filters[category] as AllFilterValues).filter(
        filter => (allowedFilterValues[category] as string[]).includes(filter)
      ) as AllFilterValues;
      newFilters[category] = newCategoryFilters;
    });

    setAppliedFilters(newFilters);
  }, [queryPrams]);

  const filteredMembers = useMemo(
    () =>
      Array.isArray(members)
        ? (filterMembers(
            members,
            appliedFilters,
            searchQuery
          ) as EnterpriseMembers.PendingOrAcceptedMember[])
        : [],
    [members, appliedFilters, visibleProducts, searchQuery]
  );

  const filteredPendingUsers = useMemo(
    () =>
      Array.isArray(pendingUsers)
        ? (filterMembers(
            pendingUsers,
            appliedFilters,
            searchQuery
          ) as EnterpriseMembers.PendingUser[])
        : [],
    [pendingUsers, appliedFilters, visibleProducts, searchQuery]
  );

  return {
    appliedFilters,
    handleApplyFilters,
    isFiltered,
    filteredMembers,
    filteredPendingUsers,
    allowedFilterValuesAndLabels,
    searchQuery,
    setSearchQuery
  };
};
