import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  createApiKey,
  getApiKeysByUserToken,
  updateApiKeyById,
  deleteApiKeyById
} from '../../common/api-client';
import type {
  CreateApiKeyBody,
  UpdateApiKeyBody,
  HttpError
} from '../../common/api-client';
import type { ApiKey } from '@deque/api-key-client';

interface State {
  loading: boolean;
  error: HttpError | null;
  apiKeys: ApiKey[];
}

interface Methods {
  list: () => void;
  create: (data: CreateApiKeyBody) => void;
  updateById: (id: string, data: UpdateApiKeyBody) => void;
  deleteById: (id: string) => void;
  regenerateApiKey: (id: string) => void;
}

export type UseApiKeyReturnedShape = State & Methods;

const useApiKey = (token: string): UseApiKeyReturnedShape => {
  const [loading, setLoading] = React.useState(false);
  const [apiKeys, setApiKeys] = React.useState<ApiKey[]>([]);
  const [error, setError] = React.useState<HttpError | null>(null);

  const callAPI = async (fn: () => Promise<unknown>) => {
    setLoading(true);
    setError(null);

    try {
      await fn();
      // all the methods involve loading or reloading apiKeys
      const data = await getApiKeysByUserToken(token);
      setApiKeys(data);
    } catch (err) {
      setError(err as HttpError);
    }

    setLoading(false);
  };

  // Create an API key, then re-fetch the list of all keys.
  const create = React.useCallback(
    (data: CreateApiKeyBody) => callAPI(() => createApiKey(token, data)),
    [token]
  );

  // List all API keys.
  const list = React.useCallback(
    () =>
      /* callAPI loads api keys anyway, therefore the function
    passed in does not need to do anything */
      callAPI(() => Promise.resolve()),
    [token]
  );

  // Update an API key by its ID, then re-fetch the list of all keys.
  const updateById = React.useCallback(
    (id: string, updates: UpdateApiKeyBody) =>
      callAPI(() => updateApiKeyById(token, id, updates)),
    [token]
  );

  // Delete an API key by its ID, then re-fetch the list of all keys.
  const deleteById = React.useCallback(
    (id: string) => callAPI(() => deleteApiKeyById(token, id)),
    [token]
  );

  // Regenerate an API key without changing its ID, then re-fetch the list of all keys.
  const regenerateApiKey = React.useCallback(
    (id: string) =>
      callAPI(async () => {
        const newKey = uuid();
        await updateApiKeyById(token, id, { api_key: newKey });
      }),
    [token]
  );

  return {
    loading,
    error,
    apiKeys,
    list,
    create,
    updateById,
    deleteById,
    regenerateApiKey
  };
};

export default useApiKey;
