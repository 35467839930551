import { useCallback, useMemo } from 'react';

import {
  type IntegrationTemplate,
  getEnterpriseIntegrationTemplates
} from '../../common/api-client';
import { SupportedIntegrationProductSlugs } from '../../common/constants';
import useAPI, { type State, type Methods } from '../../common/hooks/useAPI';

export interface IntegrationTemplateMethods extends Methods {
  getTemplatesByProjectAndIssueTypeId: (
    projectId: string,
    issueTypeId: string
  ) => IntegrationTemplate[] | undefined;
}

const useIntegrationTemplates = (
  connectionId?: string,
  enterpriseId?: string,
  token?: string,
  productSlug?: SupportedIntegrationProductSlugs
): State<IntegrationTemplate[]> & IntegrationTemplateMethods => {
  const dataLoader = useCallback(() => {
    if (!connectionId || !enterpriseId) {
      return Promise.resolve([]);
    }

    return getEnterpriseIntegrationTemplates(
      enterpriseId,
      connectionId,
      token,
      productSlug
    );
  }, [token, enterpriseId, connectionId, productSlug]);

  const { loading, error, data, refresh } = useAPI(dataLoader);

  const getTemplatesByProjectAndIssueTypeId = useCallback(
    (
      projectId: string,
      issueTypeId: string
    ): IntegrationTemplate[] | undefined => {
      return data?.filter(
        template =>
          template.project_id === projectId &&
          template.issue_type_id === issueTypeId
      );
    },
    [data]
  );

  const result = useMemo(
    () => ({
      loading,
      error,
      data,
      refresh,
      getTemplatesByProjectAndIssueTypeId
    }),
    [loading, error, data, refresh, getTemplatesByProjectAndIssueTypeId]
  );

  return result;
};

export default useIntegrationTemplates;
