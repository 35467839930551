import React from 'react';
import { Toast } from '@deque/cauldron-react';

const ContentToast = ({
  children,
  show,
  type,
  onDismiss,
  ...other
}: {
  children?: React.ReactNode;
  show: boolean;
  type: 'confirmation' | 'caution' | 'action-needed' | 'info';
  onDismiss?: () => void;
}): React.ReactElement => (
  <Toast show={show} type={type} {...other} onDismiss={onDismiss}>
    {children}
  </Toast>
);

export default ContentToast;
