import React from 'react';
import { useTranslation } from 'react-i18next';
import UserManagement from '../containers/UserManagement';
import PageTitle from '../../common/components/PageTitle';
import globalStyles from '../app.css';

const UserManagementPage = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={globalStyles.wrap}>
      <PageTitle title={t('Deque Products User Management')} />
      <UserManagement />
    </div>
  );
};

export default UserManagementPage;
