import { useCallback, useMemo } from 'react';

import getIssueTypeFields, {
  type IssueTypeField,
  type AllowedValue
} from '../../common/utils/integrations-client/jira/getIssueTypeFields';
import useAPI, { type State } from '../../common/hooks/useAPI';

export interface IssueTypeFieldsState extends State<IssueTypeField[]> {
  priorities: IssueTypeField | null;
}

export interface IssueTypeFieldsMethods {
  getPriorityById: (id: string) => AllowedValue | null;
}

const useIssueTypeFields = (
  projectId: string,
  issueTypeId: string,
  connectionId?: string,
  token?: string
): IssueTypeFieldsState & IssueTypeFieldsMethods => {
  const dataLoader = useCallback(() => {
    if (!connectionId) {
      throw new Error('Connection ID not found');
    }

    return getIssueTypeFields({
      connectionId,
      projectId,
      issueTypeId,
      token
    });
  }, [projectId, issueTypeId, token]);

  const state = useAPI(dataLoader);

  const priorities = useMemo(() => {
    return state.data?.filter(field => field.key === 'priority')?.[0] || null;
  }, [state.data]);

  const getPriorityById = useCallback(
    (priorityValueId: string) =>
      priorities?.allowedValues?.find(({ id }) => id === priorityValueId) ||
      null,
    [priorities]
  );

  return {
    data: state.data,
    loading: state.loading,
    error: state.error,
    priorities,
    getPriorityById
  };
};

export default useIssueTypeFields;
