import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import type {
  IssueType,
  ProjectsAndIssueTypes
} from '../../../../common/utils/integrations-client/jira/getProjectsAndIssueTypes';
import type { IntegrationTemplate } from '../../../../common/api-client';
import { useTranslation } from 'react-i18next';
import NotFound from '../../../../common/pages/NotFound';
import TemplateStepper from './TemplateStepper/TemplateStepper';
import getTemplateSteps from '../../../utils/get-template-steps';
import TemplateWizardForm from './TemplateWizardForm/TemplateWizardForm';
import { SupportedIntegrationProductSlugs } from '../../../../common/constants';
import PageTitle from '../../../../common/components/PageTitle';

type QueryParamsType = {
  id: string;
};

interface TemplateWizardProps {
  token: string;
  instanceUrl: string;
  isEditMode?: boolean;
  enterpriseId: string;
  connectionId: string;
  refreshTemplates: () => void;
  templates: IntegrationTemplate[];
  projects: ProjectsAndIssueTypes[];
  getIssueTypeById: (id: string) => IssueType | undefined;
  integrationProductSlug: SupportedIntegrationProductSlugs;
  getProjectById: (id: string) => ProjectsAndIssueTypes | undefined;
  getIssueTypesByProjectId: (id: string | null) => IssueType[] | undefined;
  userKeycloakId?: string;
  isUserAdmin?: boolean;
}

const TemplateWizard = ({
  token,
  projects,
  templates,
  instanceUrl,
  connectionId,
  enterpriseId,
  getProjectById,
  getIssueTypeById,
  refreshTemplates,
  isEditMode = false,
  integrationProductSlug,
  getIssueTypesByProjectId,
  userKeycloakId = '',
  isUserAdmin = false
}: TemplateWizardProps) => {
  const { t } = useTranslation();
  const { id } = useParams<QueryParamsType>();
  const [currentStep, setCurrentStep] = useState(0);
  const [isStepDataLoading, setIsStepDataLoading] = useState(false);
  const [isStepDataError, setIsStepDataError] = useState(false);

  const steps = useMemo(
    () =>
      getTemplateSteps({
        t,
        isPriorityEnabled: true,
        integrationProductSlug,
        instanceUrl,
        setIsStepDataLoading,
        setCurrentStep,
        projects,
        getProjectById,
        getIssueTypeById,
        getIssueTypesByProjectId,
        connectionId,
        token,
        setIsStepDataError
      }),
    [isEditMode, integrationProductSlug]
  );

  useEffect(() => {
    if (isEditMode) {
      setCurrentStep(steps.length - 1);
    }
  }, [isEditMode, steps]);

  const initialTemplate = templates.find(template => template.id === id);
  const canManageTemplate = isEditMode
    ? isUserAdmin || initialTemplate?.created_by === userKeycloakId
    : true;

  if (isEditMode && (!initialTemplate || !canManageTemplate)) {
    return <NotFound />;
  }

  return (
    <>
      <PageTitle
        title={t('{{action}} Template - Step {{step}}: {{stepTitle}}', {
          action: isEditMode ? t('Edit') : t('Add'),
          step: currentStep + 1,
          stepTitle: steps[currentStep].header
        })}
      />
      <TemplateStepper currentStep={currentStep} steps={steps} />
      <TemplateWizardForm
        integrationProductSlug={integrationProductSlug}
        initialTemplate={initialTemplate}
        connectionId={connectionId}
        token={token}
        enterpriseId={enterpriseId}
        templates={templates}
        refreshTemplates={refreshTemplates}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
        isStepDataLoading={isStepDataLoading}
        projects={projects}
        isStepDataError={isStepDataError}
      />
    </>
  );
};

export default TemplateWizard;
