import type {
  UserWithKeycloakId,
  StripeTypes
} from '@deque/billing-service-client';
import { TaxIdType } from '@deque/billing-utils';
import { apiRequest } from '../../../api-client';
import { ProductSlugs } from '../../../constants';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Subscriptions {
  export interface CreateParams {
    token: string;
    price_id: string;
    payment_method_id: string;
    coupon?: string;
    licenses?: number;
    tax_id?: {
      type: TaxIdType;
      value: string;
    };
    product_slug: ProductSlugs;
  }
}

interface UpdateSubscriptionParams {
  token: string;
  enterpriseId: string;
  subscriptionId: string;
  licenseCount: number;
}

export default {
  /**
   * Create and pay for a subscription with or without a discount code
   */
  create({
    token,
    price_id,
    payment_method_id,
    licenses,
    coupon,
    tax_id,
    product_slug
  }: Subscriptions.CreateParams): Promise<UserWithKeycloakId> {
    return apiRequest<UserWithKeycloakId>({
      endpoint: '/api/billing/subscriptions',
      method: 'POST',
      body: {
        price_id,
        payment_method_id,
        coupon,
        licenses,
        tax_id,
        product_slug
      },
      token
    });
  },
  update({
    token,
    enterpriseId,
    subscriptionId,
    licenseCount
  }: UpdateSubscriptionParams): Promise<StripeTypes.Subscription> {
    return apiRequest({
      method: 'PUT',
      token,
      endpoint: `/api/billing/enterprises/${enterpriseId}/subscriptions/${subscriptionId}`,
      body: {
        licenseCount
      }
    });
  },
  /**
   * Pay a subscription
   */
  pay(
    token: string,
    subscription_id: string,
    payment_method_id: string
  ): Promise<StripeTypes.Subscription> {
    return apiRequest<StripeTypes.Subscription>({
      method: 'POST',
      endpoint: `/api/billing/subscriptions/${subscription_id}/payments`,
      token: token,
      body: {
        payment_method_id
      }
    });
  },
  /**
   * Retry a subscription payment
   */
  retryPayment(
    token: string,
    subscription_id: string,
    payment_method_id: string
  ): Promise<StripeTypes.Invoice> {
    return apiRequest<StripeTypes.Invoice>({
      method: 'PATCH',
      endpoint: `/api/billing/subscriptions/${subscription_id}/payments`,
      token: token,
      body: {
        payment_method_id
      }
    });
  }
};
