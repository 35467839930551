import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Offscreen
} from '@deque/cauldron-react';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type {
  FilterValues,
  FiltersLabelsAndValues
} from '../../../../hooks/useUserManagementFilters';
import type { FilterConfigs } from '../FilterBar';
import FilterGroup from './FilterGroup/FilterGroup';
import styles from './FilterModal.css';

export interface FilterModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  allowedFilterValuesAndLabels: FiltersLabelsAndValues;
  appliedFilters: FilterValues;
  handleApplyFilters: (filters: FilterValues) => void;
}

const FilterModal = ({
  setIsOpen,
  allowedFilterValuesAndLabels,
  appliedFilters,
  handleApplyFilters
}: FilterModalProps) => {
  const { t } = useTranslation();
  const [selectedFilters, setSelectedFilters] =
    useState<FilterValues>(appliedFilters);

  const productFilterConfig: FilterConfigs<'product'> = {
    categoryValue: 'product',
    filters: allowedFilterValuesAndLabels.product,
    selectedFilters: selectedFilters.product
  };

  const roleFilterConfig: FilterConfigs<'role'> = {
    categoryValue: 'role',
    filters: allowedFilterValuesAndLabels.role,
    selectedFilters: selectedFilters.role
  };

  const statusFilterConfig: FilterConfigs<'status'> = {
    categoryValue: 'status',
    filters: allowedFilterValuesAndLabels.status,
    selectedFilters: selectedFilters.status
  };

  const filterConfigs = [
    productFilterConfig,
    roleFilterConfig,
    statusFilterConfig
  ];

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      heading={<span className={styles.modalHeader}>{t('Filters')}</span>}
      onClose={onClose}
      show
    >
      <ModalContent className={styles.modalContent}>
        {filterConfigs.map(config => (
          <FilterGroup
            key={config.categoryValue}
            categoryValue={config.categoryValue}
            filters={config.filters}
            selectedFilters={config.selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        ))}
      </ModalContent>
      <ModalFooter>
        <Button
          variant="primary"
          onClick={() => {
            handleApplyFilters(selectedFilters);
            setIsOpen(false);
          }}
        >
          <Trans>
            Apply<Offscreen> filters</Offscreen>
          </Trans>
        </Button>
        <Button variant="secondary" onClick={onClose}>
          <Trans>
            Cancel<Offscreen> applying filters</Offscreen>
          </Trans>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FilterModal;
