import { Loader } from '@deque/cauldron-react';
import React, { useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useEnterprises } from '../../common/contexts/enterprises';

interface NoEnterpriseProps {
  children: React.ReactNode;
}

const NoEnterprise = ({ children }: NoEnterpriseProps): ReactElement | null => {
  const { t } = useTranslation();
  const { isAdmin, activeEnterprise, loading } = useEnterprises();
  const loaderRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  if (loading && !activeEnterprise) {
    return (
      <Loader
        label={t('Loading...')}
        tabIndex={-1}
        ref={loaderRef}
        data-testid="no-enterprise-loader"
      />
    );
  }

  // a user cannot manage any aspect of their account if they are part of an enterprise account
  // so we redirect them to the plans page
  if (activeEnterprise && !isAdmin) {
    return <Redirect to="/plans?utm_campaign=webapp_plans" />;
  }

  return <>{children}</>;
};

export default NoEnterprise;
