import { useCallback } from 'react';
import billingClient, {
  Enterprises
} from '../../common/utils/billing-client/client-v2';
import useAPI, { State, Methods } from './useAPI';

export interface UseV2ListLicensesState
  extends Omit<State<Enterprises.License[]>, 'data'> {
  licenses: Enterprises.License[] | null;
}

const useV2ListLicenses = (
  token: string,
  { enterpriseId }: { enterpriseId: string | null }
): UseV2ListLicensesState & Methods => {
  if (!enterpriseId) {
    return {
      loading: false,
      error: null,
      licenses: null,
      refresh: () => null
    };
  }

  const dataLoader = useCallback(
    () => billingClient.enterprises.listLicenses(token, enterpriseId),
    [token, enterpriseId]
  );
  const state = useAPI(dataLoader);
  return {
    loading: state.loading,
    error: state.error,
    licenses: state.data,
    refresh: state.refresh
  };
};

export default useV2ListLicenses;
