import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import Header from '../../common/components/Header';
import MainLayout from '../../common/components/MainLayout';
import ProductLogo from '../../common/components/ProductLogo';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import AppNavBar from './AppNavBar';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const hasProductsV2 = useFeatureFlagState('products_v2');

  const isInvoice = !!useRouteMatch({
    path: '/billing/invoices/'
  });
  const isIssueSharing = !!useRouteMatch({
    path: '/issues/'
  });
  const isAcceptingInvitation = !!useRouteMatch({
    path: '/accept-invitation'
  });
  const isSigningUp = !!useRouteMatch({
    path: '/signup'
  });
  const shouldShowHeader = !isInvoice;
  const shouldShowFooter = !isInvoice;
  const hasBottomNav =
    shouldShowHeader &&
    !(isIssueSharing || isAcceptingInvitation) &&
    !(isSigningUp && hasProductsV2);

  const navBar = hasBottomNav ? <AppNavBar /> : null;
  const home = <ProductLogo title={t('axe Account')} homeURL="/" />;

  const header = shouldShowHeader ? (
    <Header navBar={navBar} home={home} />
  ) : null;

  return (
    <MainLayout
      header={header}
      hasBottomNav={hasBottomNav}
      shouldShowFooter={shouldShowFooter}
    >
      {children}
    </MainLayout>
  );
};

export default Layout;
