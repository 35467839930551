import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, FieldWrap } from '@deque/cauldron-react';
import ContentToast from '../../common/components/ContentToast';
import TermsAndConditionsCheckbox from '../../common/components/TermsAndConditionsCheckbox';
import FormHeader from './FormHeader';
import InlineTag from './InlineTag';
import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import styles from './Payment.css';
import CreditCard from './CreditCard';
import { StripeTypes } from '@deque/billing-service-client';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import type {
  PreviewInvoice,
  TaxRate,
  TotalTaxAmount
} from '@deque/billing-utils';
import { formatTaxDescription, getFormattedAmount } from '@deque/billing-utils';

interface Errors {
  alert?: string;
  fullName?: string;
  company?: string;
  coupon?: string;
  agree?: string;
  billing?: string;
  promoCode?: string;
}

type InputRef = React.RefObject<HTMLInputElement>;

interface PaymentConfirmProps {
  onSubmit: (e: React.ChangeEvent<HTMLFormElement>) => void;
  onEditClicked: (e: React.MouseEvent<HTMLButtonElement>) => void;
  majorHeading: string;
  minorHeading?: string;
  defaultFullName: string | null | undefined;
  defaultCompany: string | null | undefined;
  previewInvoice: PreviewInvoice | undefined;
  amount: string;
  discountAmount?: string;
  promoAmount?: string;
  promoPercent?: string;
  couponName?: string;
  subscribing: boolean;
  staticPrice: boolean;
  billingPeriod: string;
  yearlySavings: number;
  errors: Errors;
  agreeCheckbox: InputRef;
  paymentMethod: StripeTypes.PaymentMethod;
  loaderRef: React.RefObject<HTMLDivElement>;
  submitRef: React.RefObject<HTMLButtonElement>;
  userCountText: string;
  paymentOnly?: boolean;
  productName?: string;
}

const PaymentConfirmForm = ({
  onSubmit,
  onEditClicked,
  majorHeading,
  minorHeading,
  defaultFullName,
  defaultCompany,
  previewInvoice,
  amount,
  discountAmount,
  promoAmount,
  promoPercent,
  couponName,
  subscribing,
  staticPrice,
  billingPeriod,
  yearlySavings,
  errors,
  agreeCheckbox,
  paymentMethod,
  loaderRef,
  submitRef,
  paymentOnly = false,
  userCountText,
  productName
}: PaymentConfirmProps): React.ReactElement => {
  const { t } = useTranslation();
  const hasPaymentsV2 = useFeatureFlagState('payments_v2');

  const {
    total_tax_amounts = [],
    total_discount_amounts = 0,
    subtotal = 0
  } = previewInvoice || {};

  const canDisplayTaxInfo = !!(
    hasPaymentsV2 &&
    previewInvoice &&
    total_tax_amounts.length > 0
  );

  let disclaimerDescribedBy: string;

  let submitText: string;
  if (subscribing) {
    submitText = t('Submitting...');
  } else if (paymentOnly) {
    submitText = t('Save');
  } else {
    submitText = t('Pay & Subscribe');
  }

  if (hasPaymentsV2) {
    disclaimerDescribedBy = 'tax-disclaimer';
    if (promoAmount) {
      disclaimerDescribedBy += ' promo-disclaimer';
    }
  } else {
    disclaimerDescribedBy = 'vat-disclaimer promo-disclaimer';
  }

  return (
    <div className={styles.container}>
      {errors.billing && (
        <ContentToast type="caution" show>
          {errors.billing}
        </ContentToast>
      )}
      <div>
        <FormHeader majorHeading={majorHeading} minorHeading={minorHeading} />
        <p className={styles.mainMessage}>
          {t('Please review and confirm your payment information.')}
        </p>
        <form onSubmit={onSubmit} noValidate className={styles.form}>
          {subscribing && (
            <ScrimmedLoader label={t('Loading...')} loaderRef={loaderRef} />
          )}
          <h3 className={styles.formSection}>{t('Payment Information')}</h3>
          <FieldWrap>
            <CreditCard
              defaultFullName={defaultFullName || undefined}
              defaultCompany={defaultCompany || undefined}
              lastFour={paymentMethod.card?.last4}
              expMonth={paymentMethod.card?.exp_month}
              expYear={paymentMethod.card?.exp_year}
              postCode={paymentMethod.billing_details?.address?.postal_code}
            />
            {!paymentOnly ? (
              <>
                <h3 className={styles.formSection}>
                  {staticPrice ? t('Invoice Summary') : t('Order Summary')}
                </h3>
                <dl className={styles.priceItems}>
                  <div className={styles.priceItem}>
                    <dt>
                      {productName} {userCountText}
                    </dt>
                    <dd>
                      {hasPaymentsV2 && subtotal
                        ? getFormattedAmount(subtotal)
                        : t('${{amount}}', { amount })}
                    </dd>
                  </div>
                  {discountAmount ||
                  (hasPaymentsV2 &&
                    total_discount_amounts &&
                    total_discount_amounts.length) ? (
                    <div className={`${styles.priceItem} ${styles.promo_item}`}>
                      <dt>{t('Promo ({{couponName}})', { couponName })}</dt>
                      <dd>
                        -{promoPercent}% (
                        {hasPaymentsV2
                          ? getFormattedAmount(
                              (total_discount_amounts &&
                                total_discount_amounts.length &&
                                total_discount_amounts[0].amount) ||
                                0
                            )
                          : t('${{discountAmount}}', { discountAmount })}
                        )
                      </dd>
                    </div>
                  ) : null}

                  {canDisplayTaxInfo &&
                    (total_tax_amounts as TotalTaxAmount[])
                      .filter(v => v.amount > 0)
                      .map((tax_amount: TotalTaxAmount) => {
                        const rate = tax_amount.tax_rate as TaxRate;
                        /** TODO: update this function in `billing-utils` to use `effective_percentage` instead of `percentage` */
                        const taxDescription = formatTaxDescription({
                          ...rate,
                          percentage:
                            rate.effective_percentage ?? rate.percentage
                        });

                        return (
                          <div className={styles.priceItem} key={rate.id}>
                            <dt>{taxDescription}</dt>
                            <dd>{getFormattedAmount(tax_amount.amount)}</dd>
                          </div>
                        );
                      })}
                </dl>

                <p className={styles.totalParagraph} role="alert">
                  {canDisplayTaxInfo ? (
                    <>{t('Your total including tax is...')}</>
                  ) : (
                    <>{t('Your total is...')}</>
                  )}
                  <br />
                  <span className={styles.totalPrice}>
                    {hasPaymentsV2 && previewInvoice?.amount_remaining
                      ? getFormattedAmount(previewInvoice.amount_remaining)
                      : t('${{amount}}', {
                          amount: promoAmount || amount
                        })}
                    {t('/{{billingPeriod}}', {
                      billingPeriod:
                        billingPeriod === 'yearly' ? t('year') : t('month')
                    })}
                  </span>{' '}
                  {billingPeriod === 'yearly' && !!yearlySavings ? (
                    <InlineTag className={styles.discountTag}>
                      <Trans>
                        $ YOU&apos;RE SAVING{' '}
                        {{ savings: yearlySavings.toFixed(0) }}%
                      </Trans>
                    </InlineTag>
                  ) : null}
                </p>
              </>
            ) : null}
            <div className={styles.agreeSection}>
              <TermsAndConditionsCheckbox
                id="agreeCheckbox"
                name="agreeCheckbox"
                checkboxRef={agreeCheckbox}
                required={true}
                disabled={!!subscribing}
                error={errors.agree}
                invalid={!!errors.agree}
                describedBy={disclaimerDescribedBy}
              />
              {hasPaymentsV2 && (
                <div className={styles.taxDisclaimer} id="tax-disclaimer">
                  <Trans>
                    By completing this transaction you certify that the software
                    will be used in the location provided in the billing
                    information for the purposes of tax calculation and
                    collection. If you have any questions please contact{' '}
                    <a rel="noopener noreferrer" href="mailto:sales@deque.com">
                      sales@deque.com
                    </a>
                  </Trans>
                </div>
              )}
              {!hasPaymentsV2 && (
                <div className={styles.euAgree} id="vat-disclaimer">
                  {t(
                    'If you are in a jurisdiction where VAT or similar taxes apply, you are responsible for paying those taxes'
                  )}
                </div>
              )}
              {!hasPaymentsV2 || (hasPaymentsV2 && promoAmount) ? (
                <div className={styles.euAgree} id="promo-disclaimer">
                  {t(
                    'If you are using a promo code, it might only apply to the first billing period. Check the promo code communication you received for details.'
                  )}
                </div>
              ) : null}
            </div>
          </FieldWrap>
          <div className={styles.actionGroup}>
            <Button
              type="submit"
              disabled={!!subscribing}
              buttonRef={submitRef}
            >
              {submitText}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={onEditClicked}
              disabled={!!subscribing}
            >
              {t('edit payment details')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentConfirmForm;
