import React from 'react';
import {
  Alert,
  AlertActions,
  AlertContent,
  Button
} from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';

import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../../common/analyticsInstances';
import { useGlobalToast } from '../../../../common/contexts/globalToast';
import { deleteIntegrationTemplate } from '../../../../common/api-client';
import { SupportedIntegrationProductSlugs } from '../../../../common/constants';

export interface DeleteTemplateAlertProps {
  token: string;
  project: string;
  issueType: string;
  templateId: string;
  connectionId: string;
  templateName: string;
  enterpriseId: string;
  refreshTemplates: () => void;
  setShowConfirmDelete: (show: boolean) => void;
  integrationProductSlug: SupportedIntegrationProductSlugs;
}

const DeleteTemplateAlert = ({
  token,
  project,
  issueType,
  templateId,
  connectionId,
  templateName,
  enterpriseId,
  refreshTemplates,
  setShowConfirmDelete,
  integrationProductSlug
}: DeleteTemplateAlertProps) => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const analytics =
    analyticsInstances[getFallbackAnalyticsInstanceId(integrationProductSlug)];

  const analyticsProps = {
    project,
    issueType,
    enterpriseId,
    template: templateName,
    connectionId: connectionId,
    integration: integrationProductSlug
  };

  const handleDelete = async () => {
    analytics.integrationTemplateDeleteClick(analyticsProps);

    try {
      const res = await deleteIntegrationTemplate(
        enterpriseId,
        connectionId,
        templateId,
        token
      );
      if (res.deleted) {
        analytics.integrationTemplateDeleteSuccess(analyticsProps);
        refreshTemplates();
        setContents(t('Template deleted successfully'), 'confirmation');
        return;
      }
      analytics.integrationTemplateDeleteFail(analyticsProps);
      setContents(t('Failed to delete template'), 'error');
    } catch (error) {
      analytics.integrationTemplateDeleteFail(analyticsProps);
      const errorMessage: string = (error as Error).message;
      setContents(errorMessage, 'error');
    } finally {
      setShowConfirmDelete(false);
    }
  };

  return (
    <Alert
      heading={t('Delete Template')}
      show
      onClose={() => setShowConfirmDelete(false)}
      variant="warning"
    >
      <AlertContent>
        {t(
          'Are you sure you want to delete this template? This action cannot be undone.'
        )}
      </AlertContent>
      <AlertActions>
        <Button variant="error" onClick={handleDelete}>
          {t('Delete')}
        </Button>
        <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>
          {t('Cancel')}
        </Button>
      </AlertActions>
    </Alert>
  );
};

export default DeleteTemplateAlert;
