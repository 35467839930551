import React, { createContext, useContext, useEffect } from 'react';
import type { ReactNode } from 'react';
import {
  ProductSlugs,
  getAllUserSubscriptions,
  listProductAccess
} from '@deque/billing-utils';

import { LOGIN_INITIATED_FLAG_KEY, useAuthContext } from './auth';
import {
  type AnalyticsInstances,
  type AnalyticsInstanceId,
  getFallbackAnalyticsInstanceId
} from '../analyticsInstances';
import type AxeAccountAnalytics from '../AxeAccountAnalytics';

interface State {
  analyticsInstances: AnalyticsInstances;
}

export interface AnalyticsProviderProps {
  analytics: AnalyticsInstances;
  children: ReactNode;
}

const Context = createContext<State>({
  analyticsInstances: {} as AnalyticsInstances
});

export const AnalyticsProvider = ({
  analytics,
  children
}: AnalyticsProviderProps) => {
  const { user, billingUser } = useAuthContext();

  useEffect(() => {
    if (user) {
      Object.entries(analytics).forEach(([instanceId, instance]) => {
        if (instanceId !== 'axe-account' && billingUser) {
          const subscriptions = getAllUserSubscriptions(billingUser);
          const access = listProductAccess(subscriptions);
          instance.identify({
            user,
            billingStatus: access[instanceId as ProductSlugs]
          });
        } else {
          instance
            .identify({
              user
            })
            .then(() => {
              // after identifying axe account analytics instance track login event with the help of login initiated flag
              if (instanceId === 'axe-account') {
                const isLoginRedirect = window.localStorage.getItem(
                  LOGIN_INITIATED_FLAG_KEY
                ); // This key is being set from the login flow before redirection
                if (isLoginRedirect) {
                  (instance as AxeAccountAnalytics).login();
                  window.localStorage.removeItem(LOGIN_INITIATED_FLAG_KEY);
                }
              }
            });
        }
      });
    }
  }, [user, billingUser]);

  return (
    <Context.Provider value={{ analyticsInstances: analytics }}>
      {children}
    </Context.Provider>
  );
};

export const useAnalytics = (): AnalyticsInstances =>
  useContext(Context).analyticsInstances;

export const useAnalyticsInstance = <T extends AnalyticsInstanceId>(
  instanceId: T
): AnalyticsInstances[T] => {
  const analytics = useAnalytics();
  return analytics[instanceId];
};

export const useCrossProductAnalytics = (instanceId: unknown) =>
  useAnalyticsInstance(getFallbackAnalyticsInstanceId(instanceId));

export const useAxeAccountAnalytics = () => useAnalyticsInstance('axe-account');

export const useAxeDevtoolsProAnalytics = () =>
  useAnalyticsInstance(ProductSlugs.axeDevToolsExtension);

export const useAxeDevtoolsMobileAnalytics = () =>
  useAnalyticsInstance(ProductSlugs.axeDevToolsMobile);

export const useAxeDevtoolsWatcherAnalytics = () =>
  useAnalyticsInstance(ProductSlugs.axeDevToolsWatcher);

export const useAxeSpiderAnalytics = () =>
  useAnalyticsInstance(ProductSlugs.axeSpider);
