import type { TFunction } from 'react-i18next';

type StepStatus = 'current' | 'complete' | 'future';

const getStepStatus = (currentStepIndex: number, i: number) =>
  currentStepIndex === i
    ? 'current'
    : currentStepIndex > i
    ? 'complete'
    : 'future';

export const getCapitalizedStepStatus = (
  t: TFunction,
  stepStatus: StepStatus
) => {
  switch (stepStatus) {
    case 'current':
      return t('Current');
    case 'complete':
      return t('Complete');
    case 'future':
      return t('Future');
    default:
      return '';
  }
};

export default getStepStatus;
