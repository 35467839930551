import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchField } from '@deque/cauldron-react';

import styles from './TeamSearch.css';

export interface TeamSearchProps {
  query: string | undefined;
  setQuery: (query: string | undefined) => void;
}

const TeamSearch: React.FC<TeamSearchProps> = ({
  query,
  setQuery
}: TeamSearchProps) => {
  const { t } = useTranslation();

  const onChange = (value: string) => setQuery(value.trimStart() || undefined);

  return (
    <SearchField
      className={styles.search}
      label={t('Search by team name')}
      value={query || ''}
      onChange={onChange}
      hideLabel
    />
  );
};

export default TeamSearch;
