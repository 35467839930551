import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    /**
     * This is where translations go. Example:
     *
     * ```
     * resources: {
     *   en: { translation: { "Hello world": "Hello world" } },
     *   es: { translation: { "Hello world": "Hola el mundo" } }
     * }
     * ```
     */
    resources: {},
    lng: 'en',
    interpolation: {
      escapeValue: false // react is already xss-safe
    },
    nsSeparator: false
  });

export default i18n;
