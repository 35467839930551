import { useCallback } from 'react';
import { getRuleHelp, RuleHelp } from '../../common/api-client';
import useAPI, { State } from '../../common/hooks/useAPI';

export interface RuleHelpState extends Omit<State<RuleHelp>, 'data'> {
  ruleHelp: RuleHelp | null;
}

const useRuleHelp = (language: string, ruleId: string): RuleHelpState => {
  const dataLoader = useCallback(() => {
    return getRuleHelp(language, ruleId);
  }, [language, ruleId]);
  const state = useAPI(dataLoader);
  return { loading: state.loading, error: state.error, ruleHelp: state.data };
};

export default useRuleHelp;
