import React, { useEffect } from 'react';
import { useAuthContext } from '../../common/contexts/auth';
import { useEnterprises } from '../../common/contexts/enterprises';
import NotFound from '../pages/NotFound';
import { Loader } from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';

interface ProtectedProps {
  children: React.ReactNode;
  isEnterpriseAdmin?: boolean;
}

const Protected: React.FC<ProtectedProps> = ({
  children,
  isEnterpriseAdmin = false
}) => {
  const { isAuthenticated, login, loading: authLoading } = useAuthContext();
  const { t } = useTranslation();
  const { isAdmin, loading: enterprisesLoading } = useEnterprises();
  const loaderRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (!authLoading && !isAuthenticated) {
      login();
    }
  }, [authLoading]);

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  if (!isAuthenticated) {
    return null;
  }

  if (isEnterpriseAdmin) {
    if (enterprisesLoading) {
      return <Loader label={t('Loading...')} ref={loaderRef} tabIndex={-1} />;
    }
    if (!isAdmin) {
      return <NotFound />;
    }
  }

  return <>{children}</>;
};

export default Protected;
