import React from 'react';
import { useTranslation } from 'react-i18next';
import { PanelContent, SearchField } from '@deque/cauldron-react';
import styles from './SearchBar.css';

interface SearchBarProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  isFiltered: boolean;
  totalMembers: number;
}
const SearchBar = ({
  setSearchQuery,
  isFiltered,
  totalMembers
}: SearchBarProps) => {
  const { t } = useTranslation();
  const handleSearchChange = (value: string) => {
    setSearchQuery(value.toLowerCase());
  };
  return (
    <PanelContent padding className={styles.searchFieldContainer}>
      <SearchField
        label={t('Search by name or email')}
        placeholder={t('Search by name or email')}
        hideLabel
        onChange={handleSearchChange}
      />
      {isFiltered && (
        <span className={styles.totalText} role="status">
          {t('Total users found: {{totalMembers}}', {
            totalMembers
          })}
        </span>
      )}
    </PanelContent>
  );
};

export default SearchBar;
