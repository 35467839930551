import type { v2 } from '@deque/billing-service-client';
import { ProductSlugs } from '../constants';

export interface GetPlansResult {
  product?: v2.Product;
  planSlugs?: string[] | null;
}

const getPlans = (
  products: v2.Product[] | undefined,
  productSlug?: string | null
): GetPlansResult => {
  if (!products || !products.length) {
    return {};
  }

  const slugToFind = productSlug || ProductSlugs.axeDevToolsExtension;
  const product = products.find(p => p.slug === slugToFind);

  if (!product) {
    return {};
  }

  const planSlugsSet = new Set<string>();
  product.stripe_data?.pricing.forEach(function (p) {
    if (p.metadata.plan) {
      planSlugsSet.add(p.metadata.plan);
    }
  });

  const planSlugs = Array.from(planSlugsSet.values()).sort();

  return { product, planSlugs };
};

export default getPlans;
