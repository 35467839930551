import React from 'react';
import {
  ExpandCollapsePanel,
  PanelTrigger,
  TableHeader,
  Offscreen,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Loader,
  Button,
  Table
} from '@deque/cauldron-react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  FeatureFlag,
  useFeatureFlags
} from '../../../common/contexts/featureFlags';
import ContentToast from '../../../common/components/ContentToast';
import { useProducts } from '../../../common/contexts/products';
import PageTitle from '../../../common/components/PageTitle';
import { ProductSlugs } from '../../../common/constants';
import useSorter from '../../hooks/useSorter';
import styles from './Features.css';

const Features: React.FC = () => {
  const { t } = useTranslation();
  const { getProductBySlug } = useProducts();

  const loaderRef = React.useRef<HTMLDivElement>(null);

  const { featureFlags, loadError, loading, updateError, updateFeatureFlags } =
    useFeatureFlags();

  const [features, setFeatures] = React.useState<FeatureFlag[]>(featureFlags);

  React.useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  React.useEffect(() => {
    setFeatures(featureFlags);
  }, [featureFlags]);

  const sorter = useSorter<FeatureFlag, 'id' | 'state' | 'product_name'>({
    items: features,
    initialSortDirection: 'ascending',
    initialSortBy: 'id',
    sortHandlers: {
      id: (a, b) => {
        const aid = a.id.toLowerCase();
        const bid = b.id.toLowerCase();
        return aid?.localeCompare(bid ?? '');
      },
      state: (a, b) => {
        const astate = String(Number(a.state));
        const bstate = String(Number(b.state));
        return astate?.localeCompare(bstate ?? '');
      },
      product_name: (a, b) => {
        const aproduct = a.product_name.toLowerCase();
        const bproduct = b.product_name.toLowerCase();
        return aproduct?.localeCompare(bproduct ?? '');
      }
    }
  });

  const productNames = [
    {
      productSlug: 'axe-account',
      productName: 'axe Account',
      sortedItems: sorter.sortedItems.filter(
        feature => feature.product_name === 'axe-account'
      )
    },
    ...Object.values(ProductSlugs)
      // Sort products by name alphabetically
      .sort(
        (a, b) =>
          getProductBySlug(a)?.name?.localeCompare(
            getProductBySlug(b)?.name ?? ''
          ) ?? 0
      )
      .map(productSlug => ({
        productSlug,
        productName: getProductBySlug(productSlug)?.name,
        sortedItems: sorter.sortedItems.filter(
          feature => feature.product_name === productSlug
        )
      }))
      // Sort products with feature flags to the beginning of the list
      .sort((a, b) => b.sortedItems.length - a.sortedItems.length)
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target as HTMLInputElement;
    const index = features.findIndex(f => f.id === id);
    const next = [...features];
    next[index].state = checked;
    setFeatures(next);
  };

  const handleUpdate = (e: React.MouseEvent) => {
    e.preventDefault();
    updateFeatureFlags(features);
  };

  return (
    <div className={styles.container}>
      <PageTitle title={t('Axe Feature Flags')} />
      <header className={styles.header}>
        <h1>{t('Axe Feature Flags')}</h1>
        <strong>
          {t('This URL is internal only. Do not share with customers!')}
        </strong>
        <p>
          <Trans>
            To add/edit feature flags, visit the{' '}
            <Link to="/internal/features/admin">features admin panel</Link>.
          </Trans>
        </p>
      </header>

      <h2>{t('Enable and disable features within the axe application.')}</h2>
      <p>
        {t(
          'Check the box to enable a feature. Uncheck the box to disable the feature. Click “Update” for the feature values to persist.'
        )}
      </p>

      {(loadError || updateError) && (
        <ContentToast type="caution" show>
          <strong>{t('Error -')}</strong>
          <code>{loadError?.message || updateError?.message}</code>
        </ContentToast>
      )}

      {loading && (
        <Loader
          label={t('Loading feature flag data')}
          tabIndex={-1}
          ref={loaderRef}
        />
      )}

      {productNames.map(({ productSlug, productName, sortedItems }) =>
        sortedItems.length > 0 ? (
          <>
            <ExpandCollapsePanel key={productSlug} animationTiming={false}>
              <PanelTrigger className={styles.featureFlagProductTrigger}>
                {sortedItems.length > 1
                  ? t(`{{ productName }} ({{ count }} Feature Flags)`, {
                      productName,
                      count: sortedItems.length
                    })
                  : t(`{{ productName }} (1 Feature Flag)`, {
                      productName
                    })}
                <hr />
              </PanelTrigger>

              <div className={styles.main}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader
                        onSort={() => sorter.updateSortBy('id')}
                        sortDirection={
                          sorter.sortBy === 'id' ? sorter.sortDirection : 'none'
                        }
                        sortAscendingAnnouncement={t('sorted ascending')}
                        sortDescendingAnnouncement={t('sorted descending')}
                        scope="col"
                      >
                        {t('Feature')}
                      </TableHeader>
                      <TableHeader
                        className={styles.valueColumn}
                        scope="col"
                        onSort={() => sorter.updateSortBy('state')}
                        sortDirection={
                          sorter.sortBy === 'state'
                            ? sorter.sortDirection
                            : 'none'
                        }
                        sortAscendingAnnouncement={t('sorted ascending')}
                        sortDescendingAnnouncement={t('sorted descending')}
                      >
                        {t('State')}
                      </TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedItems.map(feature => (
                      <TableRow key={feature.id}>
                        <TableCell>
                          {!feature.description ? (
                            feature.id
                          ) : (
                            <ExpandCollapsePanel animationTiming={0}>
                              <PanelTrigger
                                className={styles.featureDescriptionTrigger}
                              >
                                {feature.id}
                              </PanelTrigger>
                              <em>{feature.description}</em>
                            </ExpandCollapsePanel>
                          )}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            id={feature.id}
                            name={feature.id}
                            label={t('Enabled')}
                            checked={feature.state}
                            disabled={loading}
                            onChange={handleChange}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <br />
                <Button disabled={loading} onClick={handleUpdate}>
                  {t('Update')}
                </Button>
              </div>
            </ExpandCollapsePanel>
          </>
        ) : (
          <div
            key={productSlug}
            className={styles.disabledFeatureFlagProductTrigger}
          >
            <span className={styles.disabledFeatureFlagProductTriggerText}>
              {t(`{{ productName }} (0 Feature Flags)`, {
                productName
              })}
              <Offscreen>{t('Disabled')}</Offscreen>
            </span>
            <hr />
          </div>
        )
      )}
    </div>
  );
};

export default Features;
