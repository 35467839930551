import { SettingTypeEnum } from '@deque/orgwide-settings-client';

export const getSettingType = (
  allowedToChange: boolean
): Exclude<SettingTypeEnum, 'oneOf'> =>
  allowedToChange ? SettingTypeEnum.Default : SettingTypeEnum.Fixed;

export const getOneOfSettingType = (
  allowedToChange: boolean
): Exclude<SettingTypeEnum, 'default'> =>
  allowedToChange ? SettingTypeEnum.OneOf : SettingTypeEnum.Fixed;
