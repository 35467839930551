import { StripeTypes } from '@deque/billing-service-client';
import { useCallback } from 'react';
import billingClient from '../../common/utils/billing-client/client-v1';
import useAPI, { State } from '../../common/hooks/useAPI';

export interface InvoiceState extends Omit<State<StripeTypes.Invoice>, 'data'> {
  invoice: StripeTypes.Invoice | null;
}

const useInvoice = (token: string, invoiceId: string): InvoiceState => {
  const dataLoader = useCallback(
    () => billingClient.invoices.get(token, invoiceId),
    [token, invoiceId]
  );
  const state = useAPI(dataLoader);
  return { loading: state.loading, error: state.error, invoice: state.data };
};
export default useInvoice;
