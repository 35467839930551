import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, TextField } from '@deque/cauldron-react';
import { Controller, useFormContext } from 'react-hook-form';

import type {
  IssueType,
  ProjectsAndIssueTypes
} from '../../../../../../common/utils/integrations-client/jira/getProjectsAndIssueTypes';
import { useGlobalToast } from '../../../../../../common/contexts/globalToast';
import { defaultTemplateValues } from '../../TemplateWizardForm/TemplateWizardForm';
import styles from './TemplateInformation.css';

export interface TemplateInformationProps {
  projects: ProjectsAndIssueTypes[];
  getIssueTypesByProjectId: (id: string | null) => IssueType[] | undefined;
}

const TemplateInformation = ({
  projects,
  getIssueTypesByProjectId
}: TemplateInformationProps) => {
  const { t } = useTranslation();
  const {
    control,
    getValues,
    formState: { errors },
    reset,
    resetField
  } = useFormContext();
  const { setContents } = useGlobalToast();

  const [selectedProject, setSelectedProject] = useState('');

  const [initialProject, setInitialProject] = useState('');

  useEffect(() => {
    if (!initialProject) {
      return;
    }

    const initialProjectId = projects.find(p => p.id === initialProject)?.id;
    setSelectedProject(initialProjectId || '');
  }, [projects, initialProject]);

  useEffect(() => {
    const projectFieldValue = getValues('project');

    if (projectFieldValue) {
      setInitialProject(projectFieldValue);
      setContents(
        t(
          'Changing Project or Issue Type will result in loss of Field and Impact Mappings.'
        ),
        'caution'
      );
      setSelectedProject(initialProject);
    }
    // Leave dependency array empty to only run on mount
  }, []);

  const resetNextSteps = () => {
    if (initialProject) {
      const { impactMapping, fieldMapping } = defaultTemplateValues;
      const values = getValues();

      reset({
        ...values,
        fieldMapping,
        impactMapping
      });
    }
  };

  const onProjectSelect = (
    { target }: React.ChangeEvent<HTMLSelectElement>,
    onChange: (e: string) => void
  ) => {
    setSelectedProject(target.value);

    if (selectedProject !== target.value) {
      resetField('issueType', { defaultValue: '' });
    }

    onChange(target.value);
  };

  return (
    <div className={styles.templateInformationContainer}>
      <Controller
        name="templateName"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            label={t('Template Name')}
            error={errors.templateName?.message}
            required
            fieldRef={ref}
          />
        )}
      />
      <Controller
        name="project"
        control={control}
        render={({ field }) => (
          <div className={styles.projectContainer}>
            <Select
              {...field}
              onChange={e => onProjectSelect(e, field.onChange)}
              label={t('Project')}
              error={errors.project?.message as string}
              required
            >
              <option key="" value="">
                {t('Select Project')}
              </option>
              <>
                {projects.map(project => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </>
            </Select>
          </div>
        )}
      />
      <Controller
        name="issueType"
        control={control}
        render={({ field }) => (
          <div className={styles.issueTypeContainer}>
            <Select
              {...field}
              label={t('Issue Type')}
              error={errors.issueType?.message as string}
              disabled={!selectedProject}
              onChange={event => {
                field.onChange(event);
                resetNextSteps();
              }}
              required
            >
              <option key="" value="">
                {t('Select Issue Type')}
              </option>
              <>
                {getIssueTypesByProjectId(selectedProject)?.map(issueType => (
                  <option key={issueType.id} value={issueType.id}>
                    {issueType.name}
                  </option>
                ))}
              </>
            </Select>
            {!selectedProject && (
              <p className={styles.issueTypeHelperText}>
                {t('Select a project to see available issue types.')}
              </p>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default TemplateInformation;
