import { Checkbox } from '@deque/cauldron-react';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  CategoryFilter,
  FilterCategory,
  FilterValue,
  FilterValues
} from '../../../../../hooks/useUserManagementFilters';
import { getCategoryLabel } from '../../../../../hooks/useUserManagementFilters';
import styles from './FilterGroup.css';

interface CheckboxOption<T extends FilterCategory> {
  label: string;
  value: CategoryFilter<T>;
}

export interface FilterGroupProps<T extends FilterCategory> {
  categoryValue: T;
  filters: CheckboxOption<T>[];
  selectedFilters: CategoryFilter<T>[];
  setSelectedFilters: Dispatch<SetStateAction<FilterValues>>;
}

const FilterGroup = <T extends FilterCategory>({
  categoryValue,
  filters,
  selectedFilters,
  setSelectedFilters
}: FilterGroupProps<T>) => {
  const { t } = useTranslation();

  return (
    <div role="group" aria-labelledby={`${categoryValue}-filters-label`}>
      <h3 id={`${categoryValue}-filters-label`} className={styles.header}>
        {getCategoryLabel(t, categoryValue)}
      </h3>
      {filters.map(option => (
        <Checkbox
          key={option.label}
          id={option.label}
          label={option.label}
          value={option.value}
          checked={selectedFilters.includes(option.value)}
          onChange={() =>
            setSelectedFilters((prev: FilterValues) => {
              const prevFilters = prev[categoryValue] as FilterValue[];
              return {
                ...prev,
                [categoryValue]: prevFilters.includes(option.value)
                  ? prevFilters.filter(filter => filter !== option.value)
                  : prevFilters.concat(option.value)
              };
            })
          }
        />
      ))}
    </div>
  );
};

export default FilterGroup;
