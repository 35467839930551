import type { v2 } from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';
import { ProductSlugs } from '../../../constants';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EnterpriseInvitations {
  export interface Invitation {
    id: string;
    email: string;
    accepted_at: Date | null;
    sender_email: string;
    enterprise_id: string;
    is_admin: boolean | null;
    product_slugs: ProductSlugs[];
  }
}

interface CreateInvitationRequest {
  token: string;
  enterpriseId: string;
  productSlug: string;
  email: string;
}

interface CreateEnterpriseInvitationRequest {
  token: string;
  enterpriseId: string;
  email: string;
  isAdmin: boolean;
}

interface GetInvitationParams {
  token: string;
  enterpriseId: string;
  invitationId: string;
}

type DeleteInvitationParams = GetInvitationParams;
type AcceptInvitationParams = GetInvitationParams;
type ResendInvitationParams = GetInvitationParams;

export default {
  /** Creates an invitation for the given email and product */
  create({
    token,
    enterpriseId,
    productSlug,
    email
  }: CreateInvitationRequest): Promise<void> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/invitations`,
      method: 'POST',
      token,
      body: {
        email,
        product_slug: productSlug
      }
    });
  },
  /** Creates an axe admin invitation for the given email */
  addMember({
    token,
    enterpriseId,
    isAdmin,
    email
  }: CreateEnterpriseInvitationRequest): Promise<void> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/member_invitations`,
      method: 'POST',
      token,
      body: {
        email,
        isAdmin
      }
    });
  },
  /** Deletes an enterprise membership invitation */
  delete({
    token,
    enterpriseId,
    invitationId
  }: DeleteInvitationParams): Promise<void> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/invitations/${invitationId}`,
      method: 'DELETE',
      token
    });
  },
  /** Get an invitation by its `invitationId` and `enterpriseId`. */
  get({
    enterpriseId,
    invitationId,
    token
  }: GetInvitationParams): Promise<EnterpriseInvitations.Invitation> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/invitations/${invitationId}`,
      method: 'GET',
      token
    });
  },
  /** Accept an invitation using its `invitationId` and `enterpriseId`, returning the updated billing user. */
  accept({
    enterpriseId,
    invitationId,
    token
  }: AcceptInvitationParams): Promise<v2.User> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/invitations/${invitationId}`,
      method: 'POST',
      token
    });
  },
  /** Resend an invitation using its `invitationId` and `enterpriseId`, the invitation includes all the products the user was invited to */
  resend({
    enterpriseId,
    invitationId,
    token
  }: ResendInvitationParams): Promise<void> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/invitations/${invitationId}/resend`,
      method: 'POST',
      token
    });
  }
};
