import React, { ReactElement, useRef, useState } from 'react';
import * as EmailValidator from 'email-validator';
import { invoiceRequest } from '../../common/api-client';
import { useAuthContext } from '../../common/contexts/auth';
import InvoiceRequestForm from '../components/InvoiceRequestForm';
import { useTranslation } from 'react-i18next';
import { useAxeDevtoolsProAnalytics } from '../../common/contexts/analytics';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { useProducts } from '../../common/contexts/products';
import { Loader } from '@deque/cauldron-react';

export interface Errors {
  [n: string]: string;
}

const InvoiceRequest = (): ReactElement => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { products, loading: productsLoading } = useProducts();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const productName = useRef<HTMLInputElement>(null);
  const quantity = useRef<HTMLInputElement>(null);
  const firstName = useRef<HTMLInputElement>(null);
  const lastName = useRef<HTMLInputElement>(null);
  const email = useRef<HTMLInputElement>(null);
  const specialRequests = useRef<HTMLInputElement>(null);
  const orgName = useRef<HTMLInputElement>(null);
  const orgEmail = useRef<HTMLInputElement>(null);
  const address1 = useRef<HTMLInputElement>(null);
  const address2 = useRef<HTMLInputElement>(null);
  const city = useRef<HTMLInputElement>(null);
  const state = useRef<HTMLInputElement>(null);
  const zip = useRef<HTMLInputElement>(null);
  const country = useRef<HTMLInputElement>(null);
  const poNumber = useRef<HTMLInputElement>(null);
  const analytics = useAxeDevtoolsProAnalytics();

  // Error messages
  const GENERIC_ERROR = t('Something went wrong. Please try again.');
  const PRODUCT_ERROR = t('Please enter a valid product');
  const QUANTITY_ERROR = t('Please enter a quantity (1 or more)');
  const FIRST_NAME_ERROR = t('Please enter your first name');
  const LAST_NAME_ERROR = t('Please enter your last name');
  const EMAIL_ERROR = t('Please enter a valid email address');
  const ORG_NAME_ERROR = t('Please enter the name of your organization');
  const ORG_EMAIL_ERROR = t('Please enter a valid organization email address');
  const ORG_ADDRESS_ERROR = t('Please enter the address of your organization');
  const ORG_CITY_ERROR = t('Please enter the city of your organization');
  const ORG_STATE_ERROR = t('Please enter the state of your organization');
  const ORG_ZIP_ERROR = t('Please enter the zip code of your organization');
  const ORG_COUNTRY_ERROR = t('Please enter the country of your organization');

  const hasDisplayInvoiceRequestProduct = useFeatureFlagState(
    'display_invoice_request_product'
  );

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Ensure we have all of our input refs
    /* istanbul ignore if */
    if (
      (hasDisplayInvoiceRequestProduct && !productName.current) ||
      !quantity.current ||
      !firstName.current ||
      !lastName.current ||
      !email.current ||
      !specialRequests.current ||
      !orgName.current ||
      !orgEmail.current ||
      !orgName.current ||
      !address1.current ||
      !address2.current ||
      !city.current ||
      !state.current ||
      !zip.current ||
      !country.current ||
      !poNumber.current
    ) {
      setErrors({
        alert: GENERIC_ERROR
      });
      return;
    }

    const newErrors: Errors = {};
    let firstErroneousInput: React.RefObject<HTMLInputElement> | null = null;
    // sets `firstErroneousInput` once, and never overrides it
    const handleErroneousInput = (ref: React.RefObject<HTMLInputElement>) => {
      if (firstErroneousInput) {
        return;
      }
      firstErroneousInput = ref;
    };

    if (hasDisplayInvoiceRequestProduct && !productName.current?.value) {
      newErrors.productName = PRODUCT_ERROR;
      handleErroneousInput(productName);
    }

    if (
      !quantity.current.value ||
      // ensure quantity is greater than 0
      Number(quantity.current.value) <= 0 ||
      // ensure quantity is a whole number
      Number(quantity.current.value) % 1 !== 0
    ) {
      newErrors.quantity = QUANTITY_ERROR;
      handleErroneousInput(quantity);
    }

    if (!firstName.current.value) {
      newErrors.firstName = FIRST_NAME_ERROR;
      handleErroneousInput(firstName);
    }

    if (!lastName.current.value) {
      newErrors.lastName = LAST_NAME_ERROR;
      handleErroneousInput(lastName);
    }

    if (!email.current.value || !EmailValidator.validate(email.current.value)) {
      newErrors.email = EMAIL_ERROR;
      handleErroneousInput(email);
    }

    if (!orgName.current.value) {
      newErrors.orgName = ORG_NAME_ERROR;
      handleErroneousInput(orgName);
    }

    if (
      !orgEmail.current.value ||
      !EmailValidator.validate(orgEmail.current.value)
    ) {
      newErrors.orgEmail = ORG_EMAIL_ERROR;
      handleErroneousInput(orgEmail);
    }

    if (!address1.current.value) {
      newErrors.address1 = ORG_ADDRESS_ERROR;
      handleErroneousInput(address1);
    }

    if (!city.current.value) {
      newErrors.city = ORG_CITY_ERROR;
      handleErroneousInput(city);
    }

    if (!state.current.value) {
      newErrors.state = ORG_STATE_ERROR;
      handleErroneousInput(state);
    }

    if (!zip.current.value) {
      newErrors.zip = ORG_ZIP_ERROR;
      handleErroneousInput(zip);
    }

    if (!country.current.value) {
      newErrors.country = ORG_COUNTRY_ERROR;
      handleErroneousInput(country);
    }

    if (Object.keys(newErrors).length) {
      // focus the first erroneous input
      (
        firstErroneousInput as React.RefObject<HTMLInputElement> | null
      )?.current?.focus();
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      await invoiceRequest({
        product_name: productName.current?.value || 'axe DevTools Extension',
        quantity: Number(quantity.current.value),
        special_request: specialRequests.current.value,
        first_name: firstName.current.value,
        last_name: lastName.current.value,
        email: email.current.value,
        org_name: orgName.current.value,
        org_email: orgEmail.current.value,
        address_1: address1.current.value,
        address_2: address2.current.value,
        city: city.current.value,
        state: state.current.value,
        zip: zip.current.value,
        country: country.current.value,
        po_number: poNumber.current.value
      });
      setSuccess(true);
      analytics.invoiceRequestSubmitted({ hasAccount: !!user });
    } catch (error) {
      setErrors({ alert: GENERIC_ERROR });
    } finally {
      setLoading(false);
    }
  };

  if (productsLoading) {
    return <Loader label={t('Loading...')} />;
  }

  return (
    <InvoiceRequestForm
      loading={loading}
      products={products}
      errors={errors}
      user={user}
      success={success}
      productNameRef={productName}
      quantity={quantity}
      firstName={firstName}
      lastName={lastName}
      email={email}
      specialRequests={specialRequests}
      orgName={orgName}
      orgEmail={orgEmail}
      address1={address1}
      address2={address2}
      city={city}
      state={state}
      zip={zip}
      country={country}
      poNumber={poNumber}
      onSubmit={onSubmit}
    />
  );
};

export default InvoiceRequest;
