import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../contexts/auth';
import { useFeatureFlagState } from '../contexts/featureFlags';
import { useEnterprises } from '../contexts/enterprises';
import {
  TopBar,
  TopBarMenu,
  OptionsMenuList,
  OptionsMenuItem,
  MenuBar,
  TopBarItem,
  SkipLink,
  Icon,
  Link,
  Tooltip
} from '@deque/cauldron-react';
import styles from './Header.css';
import { useHistory } from 'react-router-dom';

interface HeaderProps {
  home: React.ReactNode;
  navBar?: React.ReactNode;
}

const Header = ({ home, navBar }: HeaderProps): ReactElement => {
  const { isAuthenticated, login, user, createAccountUrl } = useAuthContext();
  const { activeEnterprise } = useEnterprises();
  const history = useHistory();
  const { t } = useTranslation();
  const topMenuItemSupport = useRef<HTMLLIElement>();

  const hasProductsV2 = useFeatureFlagState('products_v2');
  const hasUserSettingsV1 = useFeatureFlagState('user_settings_v1');

  const handleLogin = (): void => {
    login();
  };

  // override the company name with the enterprise name
  const company = activeEnterprise?.name || user?.company;

  const supportUrl = window.appConfig.SUPPORT_URL || '';

  return (
    <header className={styles.header}>
      <SkipLink target="#main-content" aria-label={t('Skip')} />
      <TopBar>
        <MenuBar>
          <TopBarItem>{home}</TopBarItem>
          <TopBarItem
            className={`MenuItem--align-right MenuItem--separator ${styles.needHelp}`}
            menuItemRef={menuItemRef => {
              if (menuItemRef) {
                topMenuItemSupport.current = menuItemRef;
              }
            }}
          >
            <Link
              href={supportUrl}
              rel="noopener noreferrer"
              target="_blank"
              aria-label={t('Need Help?')}
              tabIndex={-1}
            >
              <Icon
                type="question-circle-alt"
                label={t('opens support link')}
                className={styles.needHelpIcon}
              />
              <span className={styles.needHelpText}>{t('Need Help?')}</span>
            </Link>
          </TopBarItem>
          {!isAuthenticated ? (
            <TopBarItem
              onClick={handleLogin}
              className={`MenuItem--separator ${styles.signIn}`}
            >
              {t('Sign in')}
            </TopBarItem>
          ) : (
            <TopBarMenu
              id="account-menu"
              className={`MenuItem--separator ${styles.userMenu}`}
            >
              {user && (
                <div className={styles.accountWrap}>
                  <div className={styles.accountName}>
                    {user.firstName && user.lastName
                      ? `${user.firstName} ${user.lastName}`
                      : user.email}
                  </div>
                  {company && (
                    <div className={styles.companyName}>{company}</div>
                  )}
                </div>
              )}
              <Icon type="chevron-down" />
              <OptionsMenuList>
                {hasProductsV2 && (
                  <OptionsMenuItem
                    onSelect={() => {
                      window.location.href = '/';
                    }}
                  >
                    {t('axe Account')}
                  </OptionsMenuItem>
                )}
                {hasUserSettingsV1 && (
                  <OptionsMenuItem
                    onSelect={() => {
                      history.push('/account-settings');
                    }}
                  >
                    {t('Account Settings')}
                  </OptionsMenuItem>
                )}
                {!hasUserSettingsV1 && (
                  <OptionsMenuItem
                    onSelect={() => {
                      /* istanbul ignore else */
                      if (!createAccountUrl) {
                        return;
                      }
                      /* istanbul ignore next */
                      window.location.href = createAccountUrl();
                    }}
                  >
                    {hasProductsV2
                      ? t('Edit My Information')
                      : t('axe Account')}
                  </OptionsMenuItem>
                )}
                <OptionsMenuItem
                  onSelect={() => {
                    window.open(supportUrl, '_blank', 'noopener,noreferrer');
                  }}
                >
                  {t('Need Help?')}
                  <Icon
                    type="external-link"
                    className={styles.externalLink}
                    label={t('opens in new window')}
                  />
                </OptionsMenuItem>
                <OptionsMenuItem
                  onSelect={() => {
                    user?.logout({
                      redirectUri:
                        window.location.href.split('?socialProvider')[0]
                    });
                  }}
                >
                  {t('Sign Out')}
                </OptionsMenuItem>
              </OptionsMenuList>
            </TopBarMenu>
          )}
        </MenuBar>
      </TopBar>
      {topMenuItemSupport.current && (
        <Tooltip
          variant="info"
          target={topMenuItemSupport.current}
          placement="bottom"
          className={styles.supportTooltip}
        >
          {t('Click to open support page in a new tab.')}
        </Tooltip>
      )}
      {navBar || null}
    </header>
  );
};

export default Header;
