import { v2 } from '@deque/billing-service-client';
import { ProductSlugs, Subscription } from '@deque/billing-utils';

const sortProductsOrSubscriptions = <T extends (v2.Product | Subscription)[]>(
  productsOrSubscriptions: T
): T => {
  // If there are no products or subscriptions, return the original array
  if (!productsOrSubscriptions.length) {
    return productsOrSubscriptions as T;
  }
  // If the first item in the array is a subscription, sort by product_slug, otherwise sort by slug
  const [sortedProductsOrSubscriptions, slugKey] =
    'product_slug' in productsOrSubscriptions[0]
      ? [productsOrSubscriptions as Subscription[], 'product_slug']
      : [productsOrSubscriptions as v2.Product[], 'slug'];

  return sortedProductsOrSubscriptions.sort((a, b) => {
    const aSlug = a[slugKey as keyof typeof a];
    const bSlug = b[slugKey as keyof typeof b];

    // Sort axe-devtools-pro first, axe-devtools-html second, and everything else alphabetically by slug
    if (aSlug === ProductSlugs.axeDevToolsExtension) {
      return -1;
    } else if (bSlug === ProductSlugs.axeDevToolsExtension) {
      return 1;
    } else if (aSlug === ProductSlugs.axeDevToolsHTML) {
      return -1;
    } else if (bSlug === ProductSlugs.axeDevToolsHTML) {
      return 1;
    } else {
      const keyIsNotNull = aSlug !== null && bSlug !== null;
      if (keyIsNotNull) {
        return aSlug < bSlug ? -1 : 1;
      } else {
        return 0;
      }
    }
  }) as T;
};

export default sortProductsOrSubscriptions;
