import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { v2 } from '@deque/billing-service-client';

import ErrorMessage from '../components/issue/ErrorMessage';
import NotFound from '../../common/pages/NotFound';
import StepContainer from '../../common/components/StepContainer';
import { useAuthContext } from '../../common/contexts/auth';
import { useProducts } from '../../common/contexts/products';
import { useEnterprises } from '../../common/contexts/enterprises';
import useListLicenses from '../../common/hooks/useV2ListLicenses';
import useV2EnterpriseMembers from '../../common/hooks/useV2EnterpriseMembers';
import ConfirmUnload from '../components/ConfirmUnload';
import AddUserProgress from '../components/AddUserProgress';
import {
  AddUsersStep,
  ReviewAddUsersStep,
  StepData
} from '../components/UsersSteps';
import { getHiddenProductSlugsFromSubs } from '../utils/get-hidden-product-slugs-from-subs';
import { ProductSlugs } from '../../common/constants';
import { AXE_REPORTS_TEAMS_V1 } from '../../axe-reports/constants';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import styles from './AddUsersToProduct.css';

interface PathParams {
  product_slug: string;
}

const AddUserToProduct = (): JSX.Element => {
  const [currentStepIndex, setStepIndex] = useState(0);
  const [product, setProduct] = useState<v2.Product>();
  const [newUsers, setNewUsers] = useState<string[]>([]);
  const { t } = useTranslation();
  const { product_slug: productSlug } = useParams<PathParams>();
  const [availableLicenses, setAvailableLicenses] = useState<number>(0);
  const [unlimitedLicenses, setUnlimitedLicenses] = useState<boolean>(false);
  const { user } = useAuthContext();
  const {
    activeEnterprise,
    loading: enterpriseLoading,
    error: enterpriseError
  } = useEnterprises();
  const hasAxeReportsTeamsV1 = useFeatureFlagState(AXE_REPORTS_TEAMS_V1);
  // There will always be a user and with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  // TODO: When multi-enterprise is supported, we should fetch the appropriate enterprise_id
  // There should always be an active enterprise because of <Protected isEnterpriseAdmin>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const enterpriseId = activeEnterprise!.id;

  const {
    loading: productsLoading,
    error: productsError,
    getProductBySlug
  } = useProducts();
  const {
    loading: licensesLoading,
    error: licensesError,
    licenses
  } = useListLicenses(token, {
    enterpriseId
  });
  const {
    loading: enterpriseMembersLoading,
    error: enterpriseMembersError,
    pendingOrAcceptedMembers: enterpriseMembers
  } = useV2EnterpriseMembers({ token, enterpriseId });
  const isLoading =
    productsLoading ||
    licensesLoading ||
    enterpriseMembersLoading ||
    enterpriseLoading;
  const hasError =
    productsError || licensesError || enterpriseMembersError || enterpriseError;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const apiProduct = getProductBySlug(productSlug);
    if (!apiProduct) {
      return;
    }
    setProduct(apiProduct);

    if (!Array.isArray(licenses)) {
      return;
    }
    const licenseData = licenses.find(
      license => license.product_id === apiProduct.id
    );
    setAvailableLicenses(
      /* istanbul ignore next */
      !licenseData || licenseData.total === null
        ? /* it shouldn't be possible that there's no license data for a product, but just in case... */
          0
        : Math.max(
            licenseData.total - (licenseData.pending + licenseData.used),
            0
          )
    );
    setUnlimitedLicenses(licenseData?.total === null);
  }, [isLoading]);

  if (isLoading) {
    return <></>;
  }

  if (hasError) {
    return (
      <div className={styles.error}>
        <ErrorMessage error={t('Unable to add users. Please try again.')} />
      </div>
    );
  }

  // Products that are expired and do not have a free tier

  //make axe Reports visible in constants.ts but filter it from products with the feature flag
  //remove these changes and the axe_reports_teams_v1 feature flag in a future release
  const hiddenProductSlugs = activeEnterprise
    ? getHiddenProductSlugsFromSubs(
        activeEnterprise.subscriptions,
        getProductBySlug,
        !hasAxeReportsTeamsV1 ? [ProductSlugs.axeReports] : []
      )
    : [];

  if (
    hiddenProductSlugs.includes(productSlug as ProductSlugs) ||
    !product ||
    (availableLicenses < 1 && !unlimitedLicenses) ||
    !activeEnterprise
  ) {
    return <NotFound />;
  }

  const steps = [
    {
      title: t('Add users to {{name}}', { name: product.name }),
      heading: t('List the users you’d like to add to {{name}}', {
        name: product.name
      }),
      name: t('Add Users'),
      component: AddUsersStep
    },
    {
      title: t('Review your users'),
      heading: t('Summary of user changes'),
      name: t('Review'),
      component: ReviewAddUsersStep
    }
  ];

  const stepData: StepData = {
    newUsers,
    product,
    availableLicenses,
    unlimitedLicenses
  };

  const StepComponent = steps[currentStepIndex]?.component;

  const handleStepComplete = () => setStepIndex(currentStepIndex + 1);

  const handlePreviousStep = () => {
    setStepIndex(currentStepIndex - 1);
  };

  // Final step after user has reviewed all the users
  if (currentStepIndex >= steps.length) {
    // Filter out users that already have matching product access
    const productAccessMap = new Map<string, string[]>();
    enterpriseMembers?.forEach(m =>
      productAccessMap.set(
        m.email,
        m.product_access.map(p => p.product_slug)
      )
    );
    const userEmails = newUsers.filter(
      email => !productAccessMap.get(email)?.includes(product.slug)
    );
    return (
      <AddUserProgress
        emails={userEmails}
        product={product}
        enterprise={activeEnterprise}
      />
    );
  }

  return (
    <>
      <ConfirmUnload
        message={t(
          'Are you sure you want to navigate away from adding users? All progress will be lost.'
        )}
      />
      <StepContainer
        className={styles.addUsers}
        title={steps[currentStepIndex].title}
        heading={<>{steps[currentStepIndex].heading}</>}
        steps={steps.map((step, index) => ({
          status:
            currentStepIndex === index
              ? 'current'
              : currentStepIndex > index
              ? 'complete'
              : 'future',
          label: step.name
        }))}
      >
        <StepComponent
          {...stepData}
          members={enterpriseMembers || []}
          setNewUsers={setNewUsers}
          onStepComplete={handleStepComplete}
          onPreviousStep={handlePreviousStep}
        />
      </StepContainer>
    </>
  );
};

export default AddUserToProduct;
