import type { AxePurchaseState, v2 } from '@deque/billing-service-client';
import React, { ReactElement, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from '@deque/cauldron-react';
import { ProductSlugs } from '@deque/billing-utils';
import PlanName from './PlanName';
import FormattedCount from '../../common/components/FormattedCount';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { useProducts } from '../../common/contexts/products';
import ChangePlanModal from './ChangePlanModel/ChangePlanModal';
import billingStyles from './V2BillingProductCard.css';
import { useAnalyticsInstance } from '../../common/contexts/analytics';
import { getFallbackAnalyticsInstanceId } from '../../common/analyticsInstances';
import { Link } from 'react-router-dom';
import { FAILED_PAYMENT_STATES, hasPlanName } from '../../common/constants';
import { getSubscriptionPlan } from '../../common/utils/get-stripe-subscription-plan';

export interface BillingProductCardContentProps {
  subscription: v2.EnterpriseSubscription | v2.UserSubscription;
  perUnitAmount?: number;
  billingAmount?: string;
  billingTerm?: v2.SubscriptionPricing['interval'];
  lastPaymentAmount?: string;
  lastPaymentDate?: string;
  subscriptionEndDate?: string;
  isBillingAdmin: boolean;
  totalLicenses: number;
  licensesUsed: number;
  onAssignLicenses: (
    subscription: v2.EnterpriseSubscription,
    count: number | null
  ) => Promise<void>;
  onAssignEnterpriseName: (n: string) => Promise<v2.Enterprise | null>;
  onCancelPlan: () => void;
  canChangePlan: boolean;
  activeEnterprise: v2.Enterprise | null;
  companyText: string;
  setError: (error: string) => void;
}

const isOfflineSubscription = (
  subscription: v2.EnterpriseSubscription | v2.UserSubscription
): boolean => !subscription.stripe_id;

const BillingProductCardContent = ({
  subscription,
  perUnitAmount,
  billingAmount,
  billingTerm,
  lastPaymentAmount,
  lastPaymentDate,
  subscriptionEndDate,
  totalLicenses,
  licensesUsed,
  isBillingAdmin,
  onAssignLicenses,
  onCancelPlan,
  canChangePlan,
  activeEnterprise,
  onAssignEnterpriseName,
  companyText,
  setError
}: BillingProductCardContentProps): ReactElement => {
  const { t } = useTranslation();
  const { products } = useProducts();
  const hasEnterpriseConversion = useFeatureFlagState('enterprise_conversion');
  const { getProductBySlug } = useProducts();
  const analyticsProductSlug = getFallbackAnalyticsInstanceId(
    subscription.product_slug
  );
  const analytics = useAnalyticsInstance(analyticsProductSlug);
  const [dialogOpen, setDialogOpen] = useState(false);

  const isOffline = isOfflineSubscription(subscription);
  const onTriggerClick = () => {
    /* istanbul ignore next */
    if (isBillingAdmin) {
      analytics?.clickChangePlan();
    } else {
      analytics?.clickAddUsers();
    }

    setDialogOpen(true);
  };
  const failedPayment = !!(
    (isBillingAdmin || !activeEnterprise) &&
    FAILED_PAYMENT_STATES.includes(
      subscription.purchase_state as AxePurchaseState
    )
  );
  const planName = getSubscriptionPlan(products, subscription);

  const productName =
    getProductBySlug(subscription.product_slug)?.name ||
    'axe DevTools Extension';

  return (
    <>
      {hasPlanName(subscription.product_slug as ProductSlugs) && (
        <dl>
          <dt>{t('Plan:')}</dt>
          <dd>
            <PlanName subscription={subscription} />
          </dd>
        </dl>
      )}
      {'license_count' in subscription &&
        subscription.license_count !== null && (
          <div className={billingStyles.planDetails}>
            {subscription.license_count === 1
              ? t('{{ licenses }} user', {
                  licenses: subscription.license_count
                })
              : t('{{ licenses }} users', {
                  licenses: subscription.license_count
                })}
          </div>
        )}
      {subscription.product_slug === ProductSlugs.axeLinter &&
        subscription.unit_count !== null && (
          <div className={billingStyles.planDetails}>
            <FormattedCount count={subscription.unit_count} />{' '}
            {subscription.unit_count === 1
              ? t('line of code')
              : t('lines of code')}
          </div>
        )}
      {!isOffline ? (
        <>
          {billingAmount && billingTerm && (
            <div className={billingStyles.paymentDetails}>
              {t('Billed ${{ billingAmount }}/{{ term }} (excl. tax)', {
                billingAmount,
                term: billingTerm === 'yearly' ? t('year') : t('month')
              })}
            </div>
          )}
          {lastPaymentAmount && lastPaymentDate && (
            <div className={billingStyles.paymentDetails}>
              {t(
                'Last payment was ${{ lastPaymentAmount }} (incl. tax) on {{ lastPaymentDate }}',
                { lastPaymentAmount, lastPaymentDate }
              )}
            </div>
          )}
        </>
      ) : (
        subscriptionEndDate && (
          <div className={billingStyles.paymentDetails}>
            {t('Subscription is paid until {{ subscriptionEndDate }}', {
              subscriptionEndDate
            })}
          </div>
        )
      )}
      {isOffline && (
        <>
          <hr />
          <p>
            <Trans>
              Contact our{' '}
              <a href="mailto:helpdesk@deque.com">support helpdesk</a> to make
              changes to your plan.
            </Trans>
          </p>
        </>
      )}
      {(!isOffline || subscription.purchase_state === 'trial_ended') &&
        canChangePlan &&
        !subscription.cancel_at &&
        billingTerm &&
        perUnitAmount && (
          <div className={billingStyles.planActions}>
            {!failedPayment &&
              (isBillingAdmin ||
                (!activeEnterprise && hasEnterpriseConversion)) && (
                <>
                  <Button
                    className={billingStyles.changePlanText}
                    thin
                    variant="secondary"
                    onClick={onTriggerClick}
                  >
                    {isBillingAdmin
                      ? t('Change {{productName}} plan', { productName })
                      : t('Add Users')}
                  </Button>
                  <ChangePlanModal
                    currentLicenseCount={totalLicenses}
                    currentUnallocatedLicenses={totalLicenses - licensesUsed}
                    onAssignLicenses={onAssignLicenses}
                    onAssignEnterpriseName={onAssignEnterpriseName}
                    term={billingTerm}
                    price={perUnitAmount}
                    isBillingAdmin={isBillingAdmin}
                    productName={productName}
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    activeEnterprise={activeEnterprise}
                    companyText={companyText}
                    setError={setError}
                    analyticsProductSlug={analyticsProductSlug}
                    productSlug={subscription.product_slug as ProductSlugs}
                  />
                </>
              )}
            {failedPayment && (
              <Link
                to={`/purchase?product=${subscription.product_slug}${
                  subscription.product_slug === ProductSlugs.dequeUniversity &&
                  planName
                    ? `&plan=${planName}`
                    : ''
                }`}
                className="Button--primary Button--thin"
              >
                {t('Update Payment Method')}
              </Link>
            )}
            <Button
              variant="link"
              className={billingStyles.cancelPlan}
              onClick={onCancelPlan}
            >
              {t('Cancel Plan')}
            </Button>
          </div>
        )}
    </>
  );
};

export default BillingProductCardContent;
