import React from 'react';
import { Loader } from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';
import { listUserProductAccess } from '@deque/billing-utils';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { toError } from '../../common/utils/toError';
import { useAuthContext } from '../../common/contexts/auth';
import { INTEGRATION_PRODUCT_SLUGS } from '../../common/constants';
import { useEnterprises } from '../../common/contexts/enterprises';
import { useFeatureFlags } from '../../common/contexts/featureFlags';
import useIntegrationProjects from '../hooks/useIntegrationProjects';
import useIntegrationTemplates from '../hooks/useIntegrationTemplates';
import useIntegrationConnection from '../hooks/useIntegrationConnection';
import ListIntegrationTemplates from '../components/integrations/ListIntegrationTemplates';
import TemplateWizard from '../components/integrations/IntegrationTemplateWizard/TemplateWizard';

function IntegrationTemplates() {
  const { t } = useTranslation();
  const { connectionId } = useParams<{
    connectionId: string;
  }>();

  const {
    user,
    billingUser,
    loading: authLoading,
    error: authError
  } = useAuthContext();
  const {
    activeEnterprise,
    isAdmin,
    loading: enterpriseLoading,
    error: enterpriseError
  } = useEnterprises();
  const {
    featureFlags,
    loading: featureFlagsLoading,
    loadError: featureFlagsError
  } = useFeatureFlags();

  const {
    data: connection,
    loading: connectionLoading,
    error: connectionError
  } = useIntegrationConnection(
    connectionId,
    activeEnterprise?.id ?? '',
    user?.token ?? ''
  );

  const {
    data: templates,
    loading: templatesLoading,
    error: templatesError,
    refresh: refreshTemplates
  } = useIntegrationTemplates(
    connectionId,
    activeEnterprise?.id ?? '',
    user?.token ?? '',
    connection?.product_slug
  );

  const {
    data: projects,
    getProjectById,
    getIssueTypeById,
    getIssueTypesByProjectId,
    loading: projectsLoading,
    error: projectsError
  } = useIntegrationProjects(connectionId, user?.token ?? '');

  const baseUrl = `/configuration/integrations/:connectionId/templates`;

  const loading =
    authLoading ||
    enterpriseLoading ||
    featureFlagsLoading ||
    connectionLoading ||
    templatesLoading ||
    projectsLoading;

  if (loading) {
    return <Loader label={t('Loading...')} tabIndex={-1} />;
  }

  const error =
    toError(authError) ||
    toError(enterpriseError) ||
    featureFlagsError ||
    connectionError ||
    templatesError ||
    projectsError;

  const isSomeDataAbsent =
    !user || !billingUser || !activeEnterprise || !templates || !projects;

  const hasIntegrationsV1 = featureFlags.some(
    flag => flag.id === 'integrations_v1' && flag.state === true
  );

  const canAccessIntegrations =
    billingUser &&
    hasIntegrationsV1 &&
    INTEGRATION_PRODUCT_SLUGS.filter(
      slug => listUserProductAccess(billingUser)[slug] === 'paid'
    ).length > 0;

  if (
    !canAccessIntegrations ||
    !connection ||
    connection?.status !== 'connected'
  ) {
    return <Redirect to="/configuration/integrations" />;
  }

  if (error || isSomeDataAbsent) {
    const message =
      error instanceof Error ? error.message : t('some data absent');
    return <div>{t(`Error: {{ message }}`, { message })}</div>;
  }

  const templateProps = {
    templates,
    connectionId,
    getProjectById,
    getIssueTypeById,
    refreshTemplates,
    token: user.token,
    enterpriseId: activeEnterprise.id,
    integrationProductSlug: connection.product_slug,
    instanceUrl: connection.base_url
  };

  return (
    <Switch>
      <Route exact path={baseUrl}>
        <ListIntegrationTemplates
          {...templateProps}
          canManageIntegrations={!!canAccessIntegrations && isAdmin}
          userKeycloakId={billingUser.keycloak_id}
        />
      </Route>
      <Route exact path={`${baseUrl}/new`}>
        <TemplateWizard
          {...templateProps}
          projects={projects}
          getIssueTypesByProjectId={getIssueTypesByProjectId}
        />
      </Route>
      <Route path={`${baseUrl}/:id`}>
        <TemplateWizard
          {...templateProps}
          projects={projects}
          getIssueTypesByProjectId={getIssueTypesByProjectId}
          isEditMode={true}
          isUserAdmin={isAdmin}
          userKeycloakId={billingUser.keycloak_id}
        />
      </Route>
    </Switch>
  );
}

export default IntegrationTemplates;
