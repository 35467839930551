import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon, Loader } from '@deque/cauldron-react';

import { ProductSlugs } from '../../common/constants';
import Link from '../../common/components/Link';
import type { AnalyticsInstanceId } from '../../common/analyticsInstances';
import { useAnalyticsInstance } from '../../common/contexts/analytics';
import fallbackSignUpImg from '../assets/images/fallback-signup-img.png';
import axeMobileSignUpImg from '../assets/images/axe-mobile-signup-img.png';
import axeDevtoolsProSignUpImg from '../assets/images/axe-devtools-pro-signup-img.png';
import axeDevtoolsWatcherSignUpImg from '../assets/images/axe-devtools-watcher-signup-img.png';
import axeDevtoolsLinterSignUpImg from '../assets/images/axe-devtools-linter-signup-img.png';
import styles from './SignUpDetails.css';
import type CrossProductAnalytics from '../../common/CrossProductAnalytics';
import { useProducts } from '../../common/contexts/products';
import useAPI from '../../common/hooks/useAPI';
import { getProductTrialMetadata } from '../../common/api-client';
import { useServerInfo } from '../../common/contexts/serverInfo';

// `img` is a value that contains two keys, src and alt. src is the path to the image you'd like displayed, and alt is it's alternative text.
interface Img {
  src: string;
  alt: string;
}

// `title` is a value with three keys, mainText, supportingText1, and supportingText2, each representing text and decreasing sizes displayed underneath the image.
interface Title {
  mainText: string;
  supportingText1?: string;
  supportingText2?: string;
}

interface ProductDetails {
  img: Img;
  title: Title;
  // `highlightBullets` is an array of strings that will display as bullet points with checkmark bullets on your signup page to highlight unique features about your product.
  highlightBullets?: string[];
  // `footerText` is a string that will appear on the bottom of the details section of the sign-up meant to provide additional information
  footerText: string;
  // `footerCTA` is a react element meant to direct the user to further action, such as contacting support or learning more
  footerCTA?: React.ReactNode;
}

export interface SignUpDetailsProps {
  productSlug: AnalyticsInstanceId;
  isNarrow?: boolean;
  isMobileViewPort?: boolean;
}

const SignUpDetails = ({
  productSlug,
  isNarrow = false,
  isMobileViewPort = false
}: SignUpDetailsProps) => {
  const { t } = useTranslation();
  const { getProductBySlug, loading: loadingProducts } = useProducts();
  const product = getProductBySlug(productSlug);

  const { serverInfo } = useServerInfo();

  const analytics = useAnalyticsInstance(productSlug) as CrossProductAnalytics<
    typeof productSlug
  >;

  const trialDataLoader = useCallback(
    () => getProductTrialMetadata(productSlug),
    [productSlug]
  );
  const { data: trialMetadata, loading: loadingTrialMetadata } =
    useAPI(trialDataLoader);

  let productDetails: ProductDetails;
  switch (productSlug) {
    case ProductSlugs.axeDevToolsExtension:
      productDetails = {
        img: {
          src: axeDevtoolsProSignUpImg,
          alt: t(
            'A stylized illustration of {{productName}} with various icons to represent machine learning, accessibility.',
            { productName: product?.name }
          )
        },
        title: {
          mainText: product?.name || '',
          supportingText1: trialMetadata?.duration
            ? t('Free {{trialDays}}-day trial', {
                trialDays: trialMetadata.duration
              })
            : t('Free trial'),
          supportingText2: t('No credit card required.')
        },
        highlightBullets: [
          t(
            'Unlock the full power of the best accessibility testing browser extension'
          ),
          t('Export, save, and share reports with one click'),
          t(
            'AI-powered tests save time and provide more coverage than any other tool'
          )
        ],
        footerCTA: (
          <>
            {/* For the sake of translation separated the whole footerCTA, this can be changed once conditional statements are well handled in `Trans` component  */}
            {serverInfo?.isSelfProvisioningEnabled ? (
              <Trans t={t}>
                <Link
                  url={'/purchase'}
                  onClick={() => {
                    analytics.clickSignUpBuyNowLink();
                  }}
                >
                  Buy online now
                </Link>
                <span>or</span>
                <Link
                  url={'https://www.deque.com/company/contact/'}
                  onClick={() => {
                    analytics.clickContactUs();
                  }}
                >
                  Contact us
                </Link>
                <span>for more information</span>
              </Trans>
            ) : (
              <Trans t={t}>
                <Link
                  url={'https://www.deque.com/company/contact/'}
                  onClick={() => {
                    analytics.clickContactUs();
                  }}
                >
                  Contact us
                </Link>
                <span>for more information</span>
              </Trans>
            )}
          </>
        ),
        footerText: t('No accessibility training needed.')
      };
      break;
    case ProductSlugs.axeDevToolsMobile:
      productDetails = {
        img: {
          src: axeMobileSignUpImg,
          alt: t(
            'A stylized illustration of {{productName}} with various icons to represent machine learning, accessibility, and support on mobile platforms.',
            { productName: product?.name }
          )
        },
        title: {
          mainText: 'axe DevTools Mobile',
          supportingText1: trialMetadata?.duration
            ? t('Free {{trialDays}}-day trial', {
                trialDays: trialMetadata.duration
              })
            : t('Free trial'),
          supportingText2: t('No credit card required.')
        },
        highlightBullets: [
          t(
            'Get up and running in minutes -- test native and hybrid mobile apps'
          ),
          t(
            'Enforce consistency with results you can trust and no false positives'
          ),
          t('Create custom rules and only focus on your requirements')
        ],
        footerText: t('Ground-breaking technology powered by AI/ML.')
      };
      break;
    case ProductSlugs.axeDevToolsWatcher:
      productDetails = {
        img: {
          src: axeDevtoolsWatcherSignUpImg,
          alt: t(
            'A stylized illustration of {{productName}} with various icons to represent machine learning, accessibility, multiple pass and fail indicators for automated test runs',
            { productName: product?.name }
          )
        },
        title: {
          mainText: 'axe Developer Hub',
          // Note: developer hub has an existing `_TRIAL_DAYS` env but for paid-plans there will no self-serve trial.
          // For now only individuals can self-serve the free plan.
          // In the future if we add self-serve trials we can add the `trialMetadata` behind a feature flag.
          supportingText1: t('Try for free.'),
          supportingText2: t('No credit card required.')
        },
        highlightBullets: [
          t('Integrate into your existing end-to-end tests easily'),
          t(
            'Use GitHub actions to automatically stop release of inaccessible code'
          ),
          t(
            'Know if a pull request (PR) will make accessibility better or worse'
          )
        ],
        footerText: t('No accessibility training needed.')
      };
      break;
    case ProductSlugs.axeLinter:
      productDetails = {
        img: {
          src: axeDevtoolsLinterSignUpImg,
          alt: t(
            'A stylized illustration of {{productName}} with various icons to represent machine learning, accessibility and support for automated testing.',
            { productName: product?.name }
          )
        },
        title: {
          mainText: product?.name || '',
          supportingText1: trialMetadata?.duration
            ? t('Free {{trialDays}}-day trial', {
                trialDays: trialMetadata.duration
              })
            : t('Free trial'),
          supportingText2: t('No credit card required.')
        },
        highlightBullets: [
          t('Static code analysis stops accessibility bugs immediately'),
          t('Scan and block bad code in GitHub, Jenkins, SonarQube, and CI/CD'),
          t('Track and report on your code quality over time')
        ],
        footerText: t('No accessibility training needed.')
      };
      break;
    default:
      productDetails = {
        img: {
          src: fallbackSignUpImg,
          alt: t(
            'A stylized illustration of axe DevTools with various icons to represent machine learning and accessibility.'
          )
        },
        title: {
          mainText: t('Create your account'),
          supportingText1: t('to explore all the benefits.')
        },
        footerText: t('No credit card required.'),
        footerCTA: (
          <>
            <span>{t('Get started with')}</span>
            <Link
              name={t('axe DevTools')}
              url={'https://deque.com/axe/devtools'}
              openInNewTab
              onClick={() => analytics.clickAxeDevtools()}
            />
          </>
        )
      };
  }

  const { img, title, highlightBullets, footerText, footerCTA } =
    productDetails;

  return (
    <div className={styles.wrapper}>
      {loadingProducts || loadingTrialMetadata ? (
        <Loader label={t('Loading...')} />
      ) : (
        <>
          {!isNarrow && !isMobileViewPort && (
            <img className={styles.img} src={img.src} alt={img.alt} />
          )}
          <h2 className={styles.mainText}>{title.mainText}</h2>
          {!!title.supportingText1 && (
            <p className={styles.supportingText1}>{title.supportingText1}</p>
          )}
          {!!title.supportingText2 && (
            <p className={styles.supportingText2}>{title.supportingText2}</p>
          )}
          {!!highlightBullets?.length && (
            <ul className={styles.highlightBullets}>
              {highlightBullets.map((bullet, i) => (
                <li key={`highlight-bullet-${i}`}>
                  <Icon type="check-circle" className={styles.checkCircle} />
                  {bullet}
                </li>
              ))}
            </ul>
          )}
          <p className={styles.footerText}>{footerText}</p>
          <div className={styles.ctaText}>
            {footerCTA || (
              <>
                <Link
                  name={t('Contact us')}
                  url={'https://www.deque.com/company/contact/'}
                  onClick={() => {
                    analytics.clickContactUs();
                  }}
                />
                <span>{t('for more information')}</span>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SignUpDetails;
