const selectorToString = (
  selectors: (string | string[])[],
  separator = ' '
): string => {
  return selectors
    .reduce((prev: string[], curr) => prev.concat(curr), [])
    .join(separator);
};

export default selectorToString;
