import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { TextField, Button, FieldWrap } from '@deque/cauldron-react';

import { INAPPLICABLE_SIGNUP_PASSWORD_REQUIREMENTS } from '../../common/constants';
import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import PageTitle from '../../common/components/PageTitle';
import { RealmInfo, SignedUpUser } from '../../common/api-client';
import { useAnalyticsInstance } from '../../common/contexts/analytics';
import type { AnalyticsInstanceId } from '../../common/analyticsInstances';
import ContentToast from '../../common/components/ContentToast';
import googleLogo from '../assets/images/google-logo.svg';
import gitHubLogo from '../assets/images/github-logo.svg';
import PasswordRequirements from '../components/PasswordRequirements';
import PostSubmitSuccess from '../components/PostSubmitSuccess';
import { IDP } from '../containers/SignUp';
import globalStyles from '../app.css';
import styles from './SignUpForm.css';

interface Errors {
  alert?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  password?: string;
  confirmPassword?: string;
}

type InputRef = React.RefObject<HTMLInputElement>;

interface SignUpProps {
  loading: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  errors: Errors;
  realmInfo?: RealmInfo;
  firstName?: InputRef;
  lastName?: InputRef;
  email?: InputRef;
  company?: InputRef;
  password?: InputRef;
  confirmPassword?: InputRef;
  focusTarget: React.RefObject<HTMLDivElement>;
  newUser?: SignedUpUser;
  handleLogin: (idp?: IDP) => void;
  product: AnalyticsInstanceId;
}

const SignUpForm = ({
  loading,
  onSubmit,
  errors,
  realmInfo,
  firstName,
  lastName,
  email,
  company,
  password,
  confirmPassword,
  newUser,
  focusTarget,
  handleLogin,
  product
}: SignUpProps): React.ReactElement => {
  const { t } = useTranslation();
  const analytics = useAnalyticsInstance(product);

  const lowerCaseIdp = realmInfo?.identityProviders?.map(idp =>
    idp.toLowerCase()
  );

  const requirements = Object.entries(realmInfo?.passwordPolicy || {})
    // filter out inapplicable requirements to signing up
    .filter(([key]) => !INAPPLICABLE_SIGNUP_PASSWORD_REQUIREMENTS.includes(key))
    .map(([key, value]) => {
      switch (key) {
        case 'specialCharacters':
          return value === 1
            ? t('At least 1 special character')
            : t('At least {{value}} special characters', { value });
        case 'upperCase':
          return value === 1
            ? t('At least 1 upper case character')
            : t('At least {{value}} upper case characters', { value });
        case 'lowerCase':
          return value === 1
            ? t('At least 1 lower case character')
            : t('At least {{value}} lower case characters', { value });
        case 'length':
          return value === 1
            ? t('At least 1 character')
            : t('At least {{value}} characters', { value });
        case 'digits':
          return value === 1
            ? t('At least 1 digit')
            : t('At least {{value}} digits', { value });
        case 'notUsername':
          return t('Can not match the username');
        default:
          return t('{{key}}: {{value}}', {
            key,
            value
          });
      }
    });

  return (
    <div className={styles.wrapper}>
      <PageTitle title={t('Sign up')} />
      {newUser ? (
        <PostSubmitSuccess heading={t('Your account has been created')}>
          <Trans>
            A confirmation email has been sent to{' '}
            <div className={globalStyles.emailBlock}>
              {{ email: newUser.email }}
            </div>
          </Trans>
          <p>
            {t(
              'Please check the email you provided for instructions to complete your Deque account registration.'
            )}
          </p>
        </PostSubmitSuccess>
      ) : (
        <>
          {errors.alert && (
            <ContentToast type="caution" show>
              {errors.alert}
            </ContentToast>
          )}
          <form onSubmit={onSubmit} noValidate>
            {loading && (
              <ScrimmedLoader label={t('Loading')} loaderRef={focusTarget} />
            )}
            <FieldWrap className={styles.container}>
              <h1 className={styles.header}>{t('Create your account!')}</h1>
              {(lowerCaseIdp?.includes('google') ||
                lowerCaseIdp?.includes('github')) && (
                <>
                  <p className={styles.optionText}>{t('Sign up with:')}</p>
                  <div className={styles.idpContainer}>
                    {lowerCaseIdp?.includes('google') && (
                      <Button
                        className={styles.idpButton}
                        variant="secondary"
                        aria-label="Sign up with Google"
                        onClick={async () => {
                          try {
                            await analytics.clickGoogle();
                          } catch {
                            // fail silently
                          }
                          handleLogin('google');
                        }}
                      >
                        <img src={googleLogo} alt="" />
                        Google
                      </Button>
                    )}
                    {lowerCaseIdp?.includes('github') && (
                      <Button
                        className={styles.idpButton}
                        variant="secondary"
                        aria-label="Sign up with GitHub"
                        onClick={async () => {
                          try {
                            await analytics.clickGitHub();
                          } catch {
                            // fail silently
                          }
                          handleLogin('github');
                        }}
                      >
                        <img src={gitHubLogo} alt="" />
                        GitHub
                      </Button>
                    )}
                  </div>
                  <p className={styles.optionText}>{t('Or')}</p>
                </>
              )}
              <TextField
                required
                id="first-name"
                type="text"
                label={t('First name')}
                error={errors.firstName}
                fieldRef={firstName}
                autoComplete="given-name"
              />
              <TextField
                required
                id="last-name"
                type="text"
                label={t('Last name')}
                error={errors.lastName}
                fieldRef={lastName}
                autoComplete="family-name"
              />
              <TextField
                required
                id="email"
                label={t('Business email')}
                error={errors.email}
                fieldRef={email}
                autoComplete="email"
              />
              <TextField
                required
                id="company"
                label={t('Company name')}
                error={errors.company}
                fieldRef={company}
                autoComplete="organization"
              />
              <TextField
                required
                id="password"
                label={t('Password')}
                type="password"
                error={errors.password}
                fieldRef={password}
                autoComplete="new-password"
              />
              {password && (
                <PasswordRequirements
                  namespace="password"
                  target={password}
                  requirements={requirements}
                />
              )}
              <TextField
                required
                id="confirm-password"
                label={t('Confirm password')}
                type="password"
                error={errors.confirmPassword}
                fieldRef={confirmPassword}
                autoComplete="new-password"
              />
              {confirmPassword && (
                <PasswordRequirements
                  namespace="confirm-password"
                  target={confirmPassword}
                  requirements={requirements}
                />
              )}
              <Button
                type="submit"
                onClick={() => {
                  analytics.clickCreateAccount();
                }}
              >
                {t('Create Account')}
              </Button>
              <div className={styles.signInTextContainer}>
                <span>{t('Already have an account?')} </span>
                <Button variant="link" onClick={() => handleLogin()}>
                  {t('Sign in')}
                </Button>
              </div>
            </FieldWrap>
          </form>
        </>
      )}
    </div>
  );
};

export default SignUpForm;
