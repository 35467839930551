import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { NavBar, NavItem } from '@deque/cauldron-react';
import { listProductAccess } from '@deque/billing-utils';

import { FREE_STATES, ProductSlugs } from '../../common/constants';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { useEnterprises } from '../../common/contexts/enterprises';
import { useAuthContext } from '../../common/contexts/auth';
import { useOnPremValue } from '../../common/contexts/onPrem';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { useConfigurationAccess } from '../hooks/useConfigurationAccess';
import { useServerInfo } from '../../common/contexts/serverInfo';

const AppNavBar = (): ReactElement => {
  const { activeEnterprise, isAdmin: isEnterpriseAdmin } = useEnterprises();
  const { billingUser } = useAuthContext();
  const narrow = useMediaQuery('(max-width: 37.5rem)');
  const isOnPrem = useOnPremValue();
  const { serverInfo } = useServerInfo();
  const hasBillingV1 = useFeatureFlagState('billing_v1');
  const hasMultibuy = useFeatureFlagState('multi_buy_v1');
  const hasProductsV2 = useFeatureFlagState('products_v2');
  const hasApiKey = useFeatureFlagState('api_keys_v1');
  const hasUserManagement = useFeatureFlagState('user_management_v1');
  const hasTeams = useFeatureFlagState('teams_v1');
  const { t } = useTranslation();

  const location = useLocation();

  const paths = useMemo(() => {
    const { pathname } = location;
    return {
      homeIsActive: pathname === '/',
      plansIsActive: pathname === '/plans',
      billingIsActive: pathname === '/billing',
      issueSharingIsActive: pathname.startsWith('/issues'),
      gapTestsIsActive: pathname.startsWith('/coverage'),
      siteTestsIsActive: pathname.endsWith('coverage-site'),
      pageTestsIsActive: pathname.endsWith('coverage-page'),
      pageStateTestsIsActive: pathname.endsWith('coverage-page-state'),
      userAccessIsActive: pathname === '/user-access',
      acceptInvitationIsActive: pathname === '/accept-invitation',
      settingsIsActive: pathname === '/settings',
      configurationIsActive: pathname.startsWith('/configuration'),
      teamsIsActive: pathname.startsWith('/teams')
    };
  }, [location]);

  const isBillingEnabled = serverInfo?.billingServiceEnabled;

  const subscriptions =
    (activeEnterprise
      ? activeEnterprise.subscriptions
      : billingUser?.subscriptions) || [];

  const productAccess = listProductAccess(subscriptions);
  const isFreeUser = FREE_STATES.includes(
    productAccess[ProductSlugs.axeDevToolsExtension]
  );
  const isPaidOrFailedPaid = ['paid', 'paid_payment_failed'].includes(
    productAccess[ProductSlugs.axeDevToolsExtension]
  );

  const showGapTestsMenu = paths.gapTestsIsActive && (!isFreeUser || isOnPrem);
  const showPlans =
    hasBillingV1 &&
    serverInfo?.isSelfProvisioningEnabled &&
    !showGapTestsMenu &&
    !hasProductsV2;
  const isPaidBillingAdmin = isEnterpriseAdmin && isPaidOrFailedPaid;

  let showBilling = false;
  if (
    serverInfo?.isSelfProvisioningEnabled &&
    !showGapTestsMenu &&
    (hasBillingV1 || hasProductsV2)
  ) {
    showBilling =
      !activeEnterprise?.id || (isEnterpriseAdmin && subscriptions.length > 0);
  }

  /* We only show User Management (not User Access) for admins with a free plan */
  const showUserAccess =
    hasMultibuy &&
    !showGapTestsMenu &&
    ((isEnterpriseAdmin && hasUserManagement) || isPaidBillingAdmin);
  const showHome =
    (productAccess[ProductSlugs.axeDevToolsExtension] !== 'none' ||
      (hasProductsV2 && isBillingEnabled)) &&
    !showGapTestsMenu;
  const showGapTests = false;
  const showSettings = hasApiKey && !showGapTestsMenu && isBillingEnabled;

  const { userHasAccessToConfiguration } = useConfigurationAccess();

  const showConfigurations =
    !showGapTestsMenu && userHasAccessToConfiguration && isBillingEnabled;

  /* Only monitor customers can see the Teams page */
  const showTeams =
    productAccess[ProductSlugs.axeMonitor] !== 'none' &&
    hasTeams &&
    isEnterpriseAdmin;

  return (
    <NavBar collapsed={narrow}>
      {showHome && (
        <NavItem active={paths.homeIsActive}>
          <Link to="/" aria-current={paths.homeIsActive}>
            {hasProductsV2 ? t('PRODUCTS') : t('WELCOME')}
          </Link>
        </NavItem>
      )}
      {showPlans && (
        <NavItem active={paths.plansIsActive}>
          <Link
            to="/plans?utm_campaign=webapp_plans"
            aria-current={paths.plansIsActive}
          >
            {t('PLANS')}
          </Link>
        </NavItem>
      )}
      {showBilling && (
        <NavItem active={paths.billingIsActive}>
          <Link to="/billing" aria-current={paths.billingIsActive}>
            {t('BILLING')}
          </Link>
        </NavItem>
      )}
      {showUserAccess && (
        <NavItem active={paths.userAccessIsActive}>
          <Link to="/user-access" aria-current={paths.userAccessIsActive}>
            {hasUserManagement ? t('USERS') : t('USER ACCESS')}
          </Link>
        </NavItem>
      )}
      {showTeams && (
        <NavItem active={paths.teamsIsActive}>
          <Link to="/teams" aria-current={paths.teamsIsActive}>
            {t('TEAMS')}
          </Link>
        </NavItem>
      )}
      {showSettings && (
        <NavItem active={paths.settingsIsActive}>
          <Link to="/settings" aria-current={paths.settingsIsActive}>
            {t('API KEYS')}
          </Link>
        </NavItem>
      )}
      {showConfigurations && (
        <NavItem active={paths.configurationIsActive}>
          <Link to="/configuration" aria-current={paths.configurationIsActive}>
            {t('CONFIGURATION')}
          </Link>
        </NavItem>
      )}
      {showGapTests && (
        <NavItem active={paths.gapTestsIsActive}>
          <Link
            to="/advanced-testing-coverage/site"
            aria-current={paths.gapTestsIsActive}
          >
            {t('GAP TESTS')}
          </Link>
        </NavItem>
      )}
      {showGapTestsMenu && (
        <NavItem active={paths.siteTestsIsActive}>
          <Link to="/coverage-site" aria-current={paths.siteTestsIsActive}>
            {t('SITE TESTS')}
          </Link>
        </NavItem>
      )}
      {showGapTestsMenu && (
        <NavItem active={paths.pageTestsIsActive}>
          <Link to="/coverage-page" aria-current={paths.pageTestsIsActive}>
            {t('PAGE TESTS')}
          </Link>
        </NavItem>
      )}
      {showGapTestsMenu && (
        <NavItem active={paths.pageStateTestsIsActive}>
          <Link
            to="/coverage-page-state"
            aria-current={paths.pageStateTestsIsActive}
          >
            {t('PAGE STATE TESTS')}
          </Link>
        </NavItem>
      )}
    </NavBar>
  );
};

export default AppNavBar;
