import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Loader } from '@deque/cauldron-react';

import { getProductUrl } from '../constants';
import {
  useTrialActivate,
  type UseTrialActivateProps
} from '../hooks/useTrialActivate';
import Header from './Header';
import MainLayout from './MainLayout';
import ProductLogo from './ProductLogo';

type AxeProductTrialProps = UseTrialActivateProps;

const AxeProductTrial = ({ productSlug }: AxeProductTrialProps) => {
  const { t } = useTranslation();
  const { loading, error } = useTrialActivate({ productSlug });

  const loaderRef = useRef<HTMLDivElement>(null);

  const redirectURL = (!error && getProductUrl(productSlug, 'welcome')) || '/';

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  return (
    <MainLayout
      header={
        <Header home={<ProductLogo title={t('axe Account')} homeURL="/" />} />
      }
      shouldShowFooter={false}
    >
      {loading ? (
        <Loader
          label={t('Loading...')}
          tabIndex={-1}
          ref={loaderRef}
          data-testid="no-enterprise-loader"
        />
      ) : (
        <Redirect to={redirectURL} />
      )}
    </MainLayout>
  );
};

export default AxeProductTrial;
