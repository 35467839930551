import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ColumnHeader, Notice, Toast } from '@deque/cauldron-react';
import ConfirmUnload from '../ConfirmUnload';
import styles from './Form.css';
import { useLoadedConfiguration } from '../../../common/contexts/Configuration';
import { usePrevious } from '../../../common/hooks/usePrevious';

export interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  isDirty: boolean;
  isReadOnly?: boolean;
  onSave: () => void;
  onDiscard: () => void;
}

const Form: React.FC<Props> = ({
  children,
  header,
  isDirty,
  isReadOnly = false,
  onSave,
  onDiscard
}) => {
  const { t } = useTranslation();
  const { saving, savingError, clearSavingError } = useLoadedConfiguration();
  const [showDiscardedToast, setShowDiscardedToast] = useState(false);
  const [saved, setSaved] = useState(false);
  const wasSaving = usePrevious(saving);

  const clearToasts = () => {
    setShowDiscardedToast(false);
    clearSavingError();
    setSaved(false);
  };

  useEffect(() => {
    if (wasSaving && !saving && !savingError) {
      clearToasts();
      setSaved(true);
    }
  }, [saving]);

  const handleDiscard = () => {
    clearToasts();
    setShowDiscardedToast(true);
    onDiscard();
  };

  const onDiscardedDismiss = () => {
    setShowDiscardedToast(false);
  };

  const onSavedDismiss = () => {
    setSaved(false);
  };

  const disabled = saving || isReadOnly || !isDirty;

  return (
    <>
      <Toast
        type="info"
        show={showDiscardedToast}
        onDismiss={onDiscardedDismiss}
        focus
      >
        {t('Changes discarded')}
      </Toast>
      <Toast show={!!savingError} type="error">
        {t('An error occurred updating the configuration. Please try again.')}
      </Toast>
      <Toast show={saved} type="confirmation" onDismiss={onSavedDismiss} focus>
        {t('Configuration saved.')}
      </Toast>
      <ColumnHeader>
        <h2>{header}</h2>
      </ColumnHeader>
      <ConfirmUnload
        enabled={isDirty}
        message={t(
          'You have unsaved settings. Are you sure you want to discard changes?'
        )}
      />
      {isReadOnly && (
        <div className={styles.banner}>
          <Notice
            type="info"
            title={t('These settings are managed by your admin.')}
          />
        </div>
      )}
      {children}
      <div className={styles.configurationFooter}>
        <Button disabled={disabled} onClick={onSave}>
          {t('Save')}
        </Button>
        <Button disabled={disabled} onClick={handleDiscard} variant="secondary">
          {t('Discard')}
        </Button>
      </div>
    </>
  );
};

export default Form;
