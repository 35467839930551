import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  StripeTypes,
  v2,
  UserWithKeycloakId
} from '@deque/billing-service-client';
import {
  Alert,
  AlertActions,
  AlertContent,
  Button,
  FieldWrap
} from '@deque/cauldron-react';
import ContentToast from '../../common/components/ContentToast';
import CreditCard from './CreditCard';
import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import styles from './Billing.css';
import { Link, Redirect } from 'react-router-dom';
import EditCompany from './EditCompany';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import PaymentInformation from './PaymentInformation';
import BillingProducts from './BillingProducts';
import sortProductsOrSubscriptions from '../utils/sort-products-or-subscriptions';
import type { Enterprises } from '../../common/utils/billing-client/client-v2';

export interface BillingProps {
  removePaymentMethodClick: () => void;
  confirmRemoveClick: () => Promise<void>;
  cancelRemoveClick: () => void;
  confirming: boolean;
  majorHeading: string;
  companyText: string;
  subscriptionEnd: string;
  paymentMethod?: StripeTypes.PaymentMethod;
  performingRequest: boolean;
  loaderRef: React.RefObject<HTMLDivElement>;
  error?: string;
  subscriptions: v2.EnterpriseSubscription[] | v2.UserSubscription[];
  products: v2.Product[];
  licenses: Enterprises.License[];
  onAssignLicenses: (
    subscription: v2.EnterpriseSubscription,
    count: number | null
  ) => Promise<void>;
  onAssignEnterpriseName: (n: string) => Promise<v2.Enterprise | null>;
  invoices: StripeTypes.Invoice[];
  onCompanyEditSubmit: (value: string) => void;
  isBillingAdmin: boolean;
  onCancelSubscriptionClick: (
    subscription: v2.EnterpriseSubscription | v2.UserSubscription
  ) => void;
  setError: (error: string) => void;
  billingUser?: UserWithKeycloakId;
  hideExpiredProducts?: boolean;
  activeEnterprise?: v2.Enterprise | null;
}

const BillingComponent = ({
  removePaymentMethodClick,
  confirmRemoveClick,
  cancelRemoveClick,
  confirming,
  majorHeading,
  subscriptionEnd,
  companyText,
  paymentMethod,
  error,
  subscriptions,
  products,
  loaderRef,
  performingRequest,
  licenses,
  onAssignLicenses,
  onAssignEnterpriseName,
  invoices,
  onCompanyEditSubmit,
  isBillingAdmin,
  onCancelSubscriptionClick,
  billingUser,
  setError,
  hideExpiredProducts = false,
  activeEnterprise = null
}: BillingProps): React.ReactElement => {
  const { t } = useTranslation();
  const narrow = useMediaQuery('(max-width: 31rem)');
  const alertPortal = useRef<HTMLDivElement>(null);
  const removeRef = useRef<HTMLButtonElement>(null);

  const hasOnlineSubscription = subscriptions.some(s => !!s.stripe_id);
  const hasOfflineSubscription = subscriptions.some(s => !s.stripe_id);
  const hasOnlyOfflineSubscriptions =
    !hasOnlineSubscription && hasOfflineSubscription;

  const handleClose = () => {
    cancelRemoveClick();
    /* istanbul ignore next */
    removeRef?.current?.focus();
  };

  if (!subscriptions.length) {
    return <Redirect to={{ pathname: '/plans' }} />;
  }

  return (
    <>
      <div className={styles.container}>
        {error && (
          <ContentToast type="caution" show>
            {error}
          </ContentToast>
        )}
        <div ref={alertPortal}>
          {performingRequest && (
            <ScrimmedLoader label={t('Loading...')} loaderRef={loaderRef} />
          )}
          <h1 className={styles.heading}>{majorHeading}</h1>
          <p className={styles.mainMessage}>
            {t(
              'Use the information below to manage your plan and payment information.'
            )}
          </p>
          {billingUser && (
            <BillingProducts
              subscriptions={sortProductsOrSubscriptions(subscriptions)}
              products={products}
              isBillingAdmin={isBillingAdmin}
              licenses={licenses}
              onAssignLicenses={onAssignLicenses}
              onAssignEnterpriseName={onAssignEnterpriseName}
              onCancelPlan={onCancelSubscriptionClick}
              hideExpiredProducts={hideExpiredProducts}
              activeEnterprise={activeEnterprise}
              companyText={companyText}
              setError={setError}
            />
          )}

          {!hasOnlyOfflineSubscriptions && (
            <>
              <h2 className={styles.formSection}>{t('Payment Method')}</h2>
              {paymentMethod ? (
                <CreditCard
                  defaultFullName={paymentMethod.billing_details.name}
                  defaultCompany={companyText}
                  lastFour={paymentMethod.card?.last4}
                  expMonth={paymentMethod.card?.exp_month}
                  expYear={paymentMethod.card?.exp_year}
                  postCode={
                    /* istanbul ignore next */
                    paymentMethod.billing_details?.address?.postal_code
                  }
                  removePaymentMethodClick={removePaymentMethodClick}
                />
              ) : (
                <FieldWrap>
                  <p className={styles.noPaymentMethod}>
                    {t('You do not have a valid payment method.')}
                  </p>
                  <Link to="/addpayment" className="Button--secondary">
                    {t('Add payment method')}
                  </Link>
                </FieldWrap>
              )}
            </>
          )}
          <h2 className={styles.formSection}>{t('Company Information')}</h2>
          <p>{companyText}</p>

          <EditCompany
            companyText={companyText}
            onCompanyEditSubmit={onCompanyEditSubmit}
          />

          <PaymentInformation
            invoices={invoices}
            products={products}
            hasOfflineSubscription={hasOfflineSubscription}
            narrow={narrow}
          />
        </div>
      </div>
      <Alert
        heading={t('Remove Payment Method')}
        onClose={handleClose}
        show={confirming}
        variant="warning"
        portal={alertPortal}
      >
        <AlertContent>
          <Trans>
            Your subscription is{' '}
            <strong>
              {{ subscriptionEnd: subscriptionEnd.toLowerCase() }}
            </strong>
            . If you do not have a valid payment method by the renewal
            date,&nbsp;
            <strong>your subscription plan will revert to Free.</strong>
          </Trans>
        </AlertContent>
        <AlertActions>
          <Button variant="error" onClick={confirmRemoveClick}>
            {t('Remove')}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
        </AlertActions>
      </Alert>
    </>
  );
};

export default BillingComponent;
