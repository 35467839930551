import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Link, Step, Stepper, Toast } from '@deque/cauldron-react';

import type { HttpError, Team } from '../../common/api-client';
import { MAX_TEAM_NAME_LENGTH } from '../../common/constants';
import { EditVariant, StepMap } from '../types';

import clamp from '../../common/utils/clamp';
import getStepStatus from '../../common/utils/get-step-status';
import ConfirmUnload from '../components/ConfirmUnload';
import {
  BasicData,
  TeamMembers,
  Review,
  SettingStepsProps
} from '../components/teams/SettingSteps';
import ScrimmedLoader from '../../common/components/ScrimmedLoader';
import styles from './TeamSettings.css';

export interface TeamSettingsProps {
  variant: EditVariant;
  initialTeam: Team;
  submit: (team: Team) => Promise<void>;
  initialStep?: number;
}

const TeamSettings: React.FC<TeamSettingsProps> = ({
  variant,
  initialTeam,
  initialStep,
  submit
}: TeamSettingsProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [team, setTeam] = useState<Team>(initialTeam);

  const [error, setError] = useState<Error | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [currentStep, setCurrentStep] = useState(initialStep || 0);

  const changeTeam = (newTeam: Team) => {
    setTeam(newTeam);
    setHasChanges(true);
  };

  const fromHttpError = (err: HttpError) => {
    switch (err.error_code) {
      case 'TEAM_NAME_EXISTS': {
        return new Error(
          t('Team with name "{{name}}" already exists for your enterprise', {
            name: team.name
          })
        );
      }
      case 'TEAM_NAME_BLANK': {
        return new Error(t('Team name cannot be blank or empty'));
      }
      case 'TEAM_NAME_TOO_LONG': {
        return new Error(
          t('Team name cannot be longer than {{maxLength}} characters', {
            maxLength: MAX_TEAM_NAME_LENGTH
          })
        );
      }
      default:
        return new Error(
          t('An unknown error occurred while trying to save the team')
        );
    }
  };

  const attemptSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Only the final step should trigger submission
    // This prevents the enter key from submitting the form early
    if (currentStep !== steps.length - 1) {
      return;
    }

    setError(null);
    setSubmitting(true);
    try {
      await submit(team);
      history.push('/teams');
    } catch (err) {
      setError(fromHttpError(err as HttpError));
    } finally {
      setSubmitting(false);
    }
  };

  const gotoStep = (step: number) =>
    setCurrentStep(clamp(step, 0, steps.length - 1));

  const stepProps: SettingStepsProps = {
    variant,
    team,
    setTeam: changeTeam,
    gotoStep,
    nextStep: () => gotoStep(currentStep + 1),
    prevStep: () => gotoStep(currentStep - 1)
  };

  const steps: StepMap[] = [
    {
      header: t('Team Name'),
      stepFields: [],
      content: <BasicData {...stepProps} />
    },
    {
      header: t('Team Members'),
      stepFields: [],
      content: <TeamMembers {...stepProps} />
    },
    {
      header: t('Review'),
      stepFields: [],
      content: <Review {...stepProps} />
    }
  ];

  return (
    <>
      {error && (
        <Toast show type="caution">
          {error?.message}
        </Toast>
      )}
      {submitting && <ScrimmedLoader label={t('Saving changes...')} />}
      <ConfirmUnload
        enabled={!!hasChanges && !submitting}
        message={t(
          'Are you sure you want to navigate away from editing this team? All progress will be lost.'
        )}
      />
      <Stepper>
        {steps.map((step, idx) => (
          <Step
            key={`step-${step.header}-${idx}`}
            status={getStepStatus(currentStep, idx)}
          >
            {step.header}
          </Step>
        ))}
      </Stepper>
      <form onSubmit={attemptSubmit}>{steps[currentStep].content}</form>
      <Link href="/teams" className={styles.cancelLink}>
        {t('cancel and exit wizard')}
      </Link>
    </>
  );
};

export default TeamSettings;
