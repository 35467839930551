import type { v2 } from '@deque/billing-service-client';
import { PreviewInvoice } from '@deque/billing-utils';
import { apiRequest, encodeQueryParams } from '../../../api-client';

export default {
  async getPreview(
    data: Omit<v2.PreviewInvoiceParams, 'stripe_id'>,
    token: string
  ): Promise<PreviewInvoice> {
    const invoice = await apiRequest<PreviewInvoice>({
      method: 'GET',
      endpoint: `/api/billing/v2/invoices/preview?${encodeQueryParams(data)}`,
      token
    });
    return invoice;
  }
};
