import { ProductSlugs } from '@deque/billing-utils';
import { Select, SelectOption } from '@deque/cauldron-react';
import React from 'react';

import styles from './FeatureFlagProductSelect.css';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  FieldPathByValue,
  FieldValues
} from 'react-hook-form';

const productNameOptions: SelectOption[] = [
  ...Object.values(ProductSlugs),
  'axe-account'
].map(productSlug => {
  return {
    key: productSlug,
    value: productSlug
  };
});

const productNamePattern = new RegExp(
  `^(${Object.values(ProductSlugs).join('|')}|axe-account)$`
);

type FeatureFlagProductSelectProps<
  NameType extends FieldPathByValue<ControlType, string>,
  ControlType extends FieldValues
> = {
  name: NameType;
  control: Control<ControlType>;
};

const FeatureFlagProductSelect = <
  ControlType extends FieldValues,
  NameType extends FieldPathByValue<ControlType, string>
>({
  name,
  control
}: FeatureFlagProductSelectProps<NameType, ControlType>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: true,
          message: t('A flag’s product name is required!')
        },
        pattern: {
          value: productNamePattern,
          message: t(
            'Product names should be one of the ProductSlugs or axe-account'
          )
        }
      }}
      render={({ field, fieldState }) => {
        return (
          <div className={styles.productNameWrapper}>
            <Select
              label={t('Product name')}
              error={fieldState.error?.message}
              required
              options={[
                {
                  key: 'undefined',
                  label: t('Please choose'),
                  value: 'undefined'
                },
                ...productNameOptions
              ]}
              {...field}
            />
          </div>
        );
      }}
    />
  );
};

export default FeatureFlagProductSelect;
