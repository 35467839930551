import React from 'react';
import {
  Checkbox,
  Icon,
  Select,
  SelectOption,
  TooltipTabstop
} from '@deque/cauldron-react';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './Configuration.css';

export interface Props<T extends FieldValues> {
  groupLabel: string;
  groupDescription?: string;
  defaultLabel: string;
  name: FieldPath<T>;
  id: string;
  allowChangeName?: FieldPath<T>;
  shouldShowAllowChange?: boolean;
  disabled: boolean;
  formControl: Control<T>;
  options: (SelectOption & { disableSelectOptionOnly?: boolean })[];
  optionsName: FieldPath<T>;
  showOneOfOptions: boolean;
  tooltip?: React.ReactNode;
}

const SelectOneOfSetting = <T extends FieldValues>({
  groupLabel,
  groupDescription,
  defaultLabel,
  formControl,
  name,
  id,
  allowChangeName,
  shouldShowAllowChange,
  disabled,
  options,
  optionsName,
  showOneOfOptions,
  tooltip
}: Props<T>) => {
  const { t } = useTranslation();
  const selectedDefaultValue = useWatch({ name, control: formControl });
  const selectedOneOfSettings = useWatch({
    name: optionsName,
    control: formControl
  });
  const validDefaultOptions = showOneOfOptions
    ? options.filter(
        ({ value, disabled: optionDisabled }) =>
          selectedOneOfSettings?.includes(value) && !optionDisabled
      )
    : options;
  const invalidDefaultSelection =
    showOneOfOptions &&
    !validDefaultOptions.some(option => option.value === selectedDefaultValue);

  if (invalidDefaultSelection) {
    validDefaultOptions.unshift({ key: 'none-selected', value: '', label: '' });
  }

  const error = invalidDefaultSelection
    ? validDefaultOptions.some(option => option.key !== 'none-selected')
      ? t('You must select at least one option.')
      : t(
          'You must check one {{groupLabel}} checkbox and select at least one option.',
          { groupLabel }
        )
    : undefined;

  return (
    <div role="group" aria-labelledby={id}>
      <div className="Field__label" id={id}>
        {groupLabel}
      </div>
      {tooltip && (
        <TooltipTabstop tooltip={tooltip} placement="top" variant="info">
          <Icon
            type="question-circle"
            label={t('{{settingName}} help', { settingName: groupLabel })}
          />
        </TooltipTabstop>
      )}
      {groupDescription && <p>{groupDescription}</p>}

      {shouldShowAllowChange && allowChangeName && (
        <Controller
          control={formControl}
          name={allowChangeName}
          render={({ field: { value, ...field } }) => (
            <Checkbox
              {...field}
              aria-controls={`${id}-options`}
              checked={value}
              id={`allow-users-to-change-${id}`}
              label={t('Allow users to change {{label}}', {
                label: groupLabel
              })}
              disabled={disabled}
            />
          )}
        />
      )}

      {showOneOfOptions && optionsName && (
        <div id={`${id}-options`} className={styles.oneOfWrap}>
          <span className="Field__label">{groupLabel}</span>
          <p>{t('Select at least 1 option')}</p>
          <Controller
            control={formControl}
            name={optionsName}
            rules={{
              validate: value => value.length >= 1
            }}
            render={({ field }) => (
              <>
                {options.map(
                  ({
                    key,
                    value,
                    label,
                    disabled: optionDisabled,
                    disableSelectOptionOnly
                  }) => (
                    <Checkbox
                      key={key}
                      id={`select-one-of-${id}-${key}`}
                      label={label}
                      value={value}
                      checked={field.value.includes(value)}
                      disabled={
                        disabled || (optionDisabled && !disableSelectOptionOnly)
                      }
                      onChange={e => {
                        const currentValue = e.target.value;
                        if (e.target.checked) {
                          field.onChange(
                            Array.from(new Set([...field.value, currentValue]))
                          );
                        } else {
                          field.onChange(
                            field.value.filter(
                              (fieldValue: string) =>
                                fieldValue !== currentValue
                            )
                          );
                        }
                      }}
                    />
                  )
                )}
              </>
            )}
          />
        </div>
      )}

      <Controller
        name={name}
        control={formControl}
        rules={{
          validate: () => !invalidDefaultSelection
        }}
        render={({ field }) => (
          <Select
            {...field}
            id={`default-${id}`}
            label={defaultLabel}
            options={validDefaultOptions}
            disabled={disabled}
            error={error}
          />
        )}
      />
    </div>
  );
};

export default SelectOneOfSetting;
