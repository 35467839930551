import React, { ReactElement, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Loader } from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../contexts/auth';
import PageTitle from './PageTitle';
import MainContent from './MainContent';
import styles from './MainLayout.css';

interface MainLayoutProps {
  header?: React.ReactNode;
  // TODO: Consider moving away from the fixed-positioning layout model so that
  // elements can take up their natural space on the page. This would allow the
  // MainLayout component to not have to be aware if the nav is present.
  hasBottomNav?: boolean;
  shouldShowFooter?: boolean;
  children: React.ReactNode;
}

const MainLayout = ({
  header,
  hasBottomNav,
  shouldShowFooter,
  children
}: MainLayoutProps): ReactElement => {
  const { t } = useTranslation();
  const { loading } = useAuthContext();
  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  let offsetClassName;
  if (!header) {
    offsetClassName = styles.noNavLayout;
  } else if (!hasBottomNav) {
    offsetClassName = styles.topNavOnlyLayout;
  } else {
    offsetClassName = styles.fullNavLayout;
  }

  return (
    <div className={styles.rootContent}>
      <div className={classNames(styles.wrap, offsetClassName)}>
        <PageTitle title="axe DevTools" />
        {header || null}
        {loading ? (
          <Loader
            className={styles.loader}
            label={t('Loading')}
            tabIndex={-1}
            ref={loaderRef}
          />
        ) : (
          <MainContent shouldShowFooter={shouldShowFooter}>
            {children}
          </MainContent>
        )}
      </div>
    </div>
  );
};

export default MainLayout;
