import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../components/issue/ErrorMessage';
import NotFound from '../../common/pages/NotFound';
import StepContainer from '../../common/components/StepContainer';
import { useAuthContext } from '../../common/contexts/auth';
import { useEnterprises } from '../../common/contexts/enterprises';
import useV2EnterpriseMembers from '../../common/hooks/useV2EnterpriseMembers';
import ConfirmUnload from '../components/ConfirmUnload';
import AddUserProgress from '../components/AddUserProgress';
import styles from './AddUsersToProduct.css';
import {
  AddUsersStep,
  ReviewAddUsersStep,
  StepData,
  MAX_NEW_USERS
} from '../components/UsersSteps';

const AddUserToEnterprise: React.FC<unknown> = () => {
  const [currentStepIndex, setStepIndex] = useState(0);

  const [newUsers, setNewUsers] = useState<string[]>([]);
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { activeEnterprise } = useEnterprises();
  // There will always be a user and with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  // TODO: When multi-enterprise is supported, we should fetch the appropriate enterprise_id
  // There should always be an active enterprise because of <Protected isEnterpriseAdmin>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const enterpriseId = activeEnterprise!.id;

  const {
    loading: enterpriseMembersLoading,
    error: enterpriseMembersError,
    pendingOrAcceptedMembers: enterpriseMembers
  } = useV2EnterpriseMembers({ token, enterpriseId });
  const isLoading = enterpriseMembersLoading;
  const hasError = enterpriseMembersError;

  if (isLoading) {
    return null;
  }

  if (hasError) {
    return (
      <div className={styles.error}>
        <ErrorMessage error={t('Unable to add users. Please try again.')} />
      </div>
    );
  }

  if (!activeEnterprise) {
    return <NotFound />;
  }

  const steps = [
    {
      title: t('Add users to your axe Account'),
      heading: t('List the users you’d like to add as members'),
      name: t('Add Users'),
      component: AddUsersStep
    },
    {
      title: t('Review your users'),
      heading: t('Summary of user changes'),
      name: t('Review'),
      component: ReviewAddUsersStep
    }
  ];

  const stepData: StepData = {
    availableLicenses: MAX_NEW_USERS,
    unlimitedLicenses: true,
    newUsers
  };

  const StepComponent = steps[currentStepIndex]?.component;

  const handleStepComplete = () => setStepIndex(currentStepIndex + 1);

  const handlePreviousStep = () => {
    setStepIndex(currentStepIndex - 1);
  };

  // Final step after user has reviewed all the users
  if (currentStepIndex >= steps.length) {
    // Filter out users that already are in the enterprise
    const memberEmails = enterpriseMembers?.map(m => m.email) || [];
    const userEmails = newUsers.filter(email => !memberEmails.includes(email));
    return (
      <AddUserProgress emails={userEmails} enterprise={activeEnterprise} />
    );
  }

  return (
    <>
      <ConfirmUnload
        message={t(
          'Are you sure you want to navigate away from adding users? All progress will be lost.'
        )}
      />
      <StepContainer
        className={styles.addUsers}
        title={steps[currentStepIndex].title}
        heading={<>{steps[currentStepIndex].heading}</>}
        steps={steps.map((step, index) => ({
          status:
            currentStepIndex === index
              ? 'current'
              : currentStepIndex > index
              ? 'complete'
              : 'future',
          label: step.name
        }))}
      >
        <StepComponent
          {...stepData}
          members={enterpriseMembers || []}
          setNewUsers={setNewUsers}
          onStepComplete={handleStepComplete}
          onPreviousStep={handlePreviousStep}
        />
      </StepContainer>
    </>
  );
};

export default AddUserToEnterprise;
