import type { v2, StripeTypes } from '@deque/billing-service-client';
import React, { createContext, useState, useEffect } from 'react';

import type { ErrorOrNull } from '../../../typings/utils';
import billingClient from '../utils/billing-client/client-v2';
import { useServerInfo } from './serverInfo';
import { useAuthContext } from './auth';

/**
 * State stored within the context.
 */

export interface State {
  enterprises: v2.Enterprise[];
  activeEnterprise?: v2.Enterprise | null;
  paymentMethods: StripeTypes.PaymentMethod[];
  defaultPaymentMethod?: StripeTypes.PaymentMethod;
  isAdmin: boolean;
  loading: boolean;
  error: Error | string | null;
}

const Context = createContext<State>({
  enterprises: [],
  activeEnterprise: null,
  paymentMethods: [],
  isAdmin: false,
  loading: true,
  error: null
});

export interface ProviderProps {
  children: React.ReactNode;
  initialEnterprises?: v2.Enterprise[];
  initialIsAdmin?: boolean;
  initialActiveEnterprise?: v2.Enterprise | null;
  initialPaymentMethods?: StripeTypes.PaymentMethod[];
  initialLoading?: boolean;
  initialError?: ErrorOrNull;
}

export const EnterprisesProvider: React.FC<ProviderProps> = ({
  children,
  initialEnterprises = [],
  initialIsAdmin = false,
  initialActiveEnterprise = null,
  initialPaymentMethods = [],
  initialLoading = false,
  initialError = null
}) => {
  const [enterprises, setEnterprises] =
    useState<v2.Enterprise[]>(initialEnterprises);
  const [activeEnterprise, setActiveEnterprise] =
    useState<v2.Enterprise | null>(initialActiveEnterprise);
  const [paymentMethods, setPaymentMethods] = useState<
    StripeTypes.PaymentMethod[]
  >(initialPaymentMethods);
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<StripeTypes.PaymentMethod>();
  const [isAdmin, setIsAdmin] = useState(initialIsAdmin);
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState<ErrorOrNull>(initialError);
  const {
    user,
    isAuthenticated,
    billingUser,
    loading: authLoading,
    error: authError
  } = useAuthContext();

  const { serverInfo } = useServerInfo();

  useEffect(() => {
    (async () => {
      // If the auth context is loading, the enterprise context needs to also load.
      // Without this, we could get a flash of content that does not represent the current
      // user's enterprise access.
      if (authLoading) {
        setLoading(true);
        return;
      }

      if (
        !isAuthenticated ||
        !user ||
        !billingUser?.enterprises.length ||
        initialEnterprises.length
      ) {
        setEnterprises(initialEnterprises);
        setActiveEnterprise(initialActiveEnterprise);
        setIsAdmin(initialIsAdmin);
        setError(initialError);
        setLoading(initialLoading);
        return;
      }

      setLoading(true);
      setEnterprises(billingUser.enterprises);
      // TODO: Once multi-enterprise is supported, this context can be used to
      // provide an enterprise switcher to allow a user to change their active enterprise
      const newActiveEnterpriseId = billingUser.enterprises[0].id;

      try {
        const [isEnterpriseAdmin, newActiveEnterprise] = await Promise.all([
          billingClient.me.isEnterpriseAdmin(user.token, newActiveEnterpriseId),
          billingClient.enterprises.get({
            token: user.token,
            id: newActiveEnterpriseId
          })
        ]);

        // Only enterprise admins are allowed to view payment methods only when self-provisioning is enabled
        if (isEnterpriseAdmin && serverInfo?.isSelfProvisioningEnabled) {
          const pms = await billingClient.enterprises.listPaymentMethods({
            token: user.token,
            id: newActiveEnterpriseId
          });
          setPaymentMethods(pms);
          setDefaultPaymentMethod(
            pms.find(
              pm => pm.id === newActiveEnterprise.default_payment_method_id
            )
          );
        }

        setIsAdmin(isEnterpriseAdmin);
        setActiveEnterprise(newActiveEnterprise);
        setError(null);
      } catch (ex) {
        setActiveEnterprise(initialActiveEnterprise);
        setIsAdmin(initialIsAdmin);
        setError(ex as Error);
      } finally {
        setLoading(initialLoading);
      }
    })();
  }, [authLoading, billingUser]);

  return (
    <Context.Provider
      value={{
        enterprises,
        activeEnterprise,
        paymentMethods,
        defaultPaymentMethod,
        isAdmin,
        loading: loading || authLoading,
        error: error || authError
      }}
    >
      {children}
    </Context.Provider>
  );
};

/**
 * Use the Enterprises context.
 */

export const useEnterprises = (): State => React.useContext(Context);
