import type { v2 } from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';
import { EnterpriseInvitations } from '../client-v2';

export default {
  /**
   * Get the billing data for the logged-in user.
   */
  get(token: string): Promise<v2.User> {
    return apiRequest<v2.User>({
      endpoint: '/api/billing/v2/me',
      method: 'GET',
      token
    });
  },
  /**
   * Returns whether or not the active user is an enterprise admin for the
   * provided enterpriseId
   */
  async isEnterpriseAdmin(
    token: string,
    enterpriseId: string
  ): Promise<boolean> {
    const { is_admin } = await apiRequest<{ is_admin: boolean }>({
      endpoint: `/api/billing/v2/me/enterprises/${enterpriseId}/is_admin`,
      method: 'GET',
      token
    });

    return is_admin;
  },

  /* Return the invitations associated for the logged in user */
  async getUserInvitations(
    token: string,
    isAccepted?: boolean
  ): Promise<EnterpriseInvitations.Invitation[]> {
    const invitations = await apiRequest<EnterpriseInvitations.Invitation[]>({
      endpoint: `/api/billing/v2/me/invitations?is_accepted=${isAccepted}`,
      method: 'GET',
      token
    });
    return invitations;
  },

  /* Rejects the user invitation with the `invitation_id` */
  rejectUserInvitation(invitationId: string, token: string): Promise<unknown> {
    return apiRequest({
      endpoint: `/api/billing/v2/me/invitations/${invitationId}`,
      method: 'DELETE',
      token
    });
  },

  /**
   * Create a user trial subscription for a specific product ID
   */
  async createTrialSubscription(
    productId: string,
    token: string
  ): Promise<v2.UserSubscription> {
    const subscription: v2.UserSubscription = await apiRequest({
      method: 'POST',
      endpoint: `/api/billing/v2/me/trials/${productId}`,
      token
    });
    return subscription;
  },

  /**
   * Create a user free subscription for a specific product ID
   */
  async createFreeSubscription(
    productId: string,
    token: string
  ): Promise<v2.UserSubscription> {
    const subscription: v2.UserSubscription = await apiRequest({
      method: 'POST',
      endpoint: `/api/billing/v2/me/free-subscriptions/${productId}`,
      token
    });
    return subscription;
  }
};
