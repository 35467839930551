import { SortDirection } from '@deque/billing-service-client';

export type UISortDirection = 'ascending' | 'descending' | 'none';

export const getUISortDirection = (
  sortDir?: SortDirection
): UISortDirection => {
  switch (sortDir) {
    case 'asc':
      return 'ascending';
    case 'desc':
      return 'descending';
    default:
      return 'none';
  }
};

export type Sort<T> = [T, SortDirection];

interface SortableColumn {
  sortDirection: UISortDirection;
  onSort: () => void;
}

type GetSortableColumn<T> = (column: T) => SortableColumn;

export interface SortableProps<T> {
  sort?: Sort<T>;
  setSort: (value?: Sort<T>) => void;
}

export default function useSortableCol<T>({
  sort,
  setSort
}: SortableProps<T>): GetSortableColumn<T> {
  const [sortBy, sortDir] = sort || [];

  return (column: T) => {
    const isCurrent = column === sortBy;
    const nextSortDir = isCurrent && sortDir === 'asc' ? 'desc' : 'asc';
    return {
      sortDirection: isCurrent ? getUISortDirection(sortDir) : 'none',
      onSort: () => setSort([column, nextSortDir])
    };
  };
}
