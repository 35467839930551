import { dayjs, thirty_one_days_in_the_future } from '../../common/utils/dates';

export const dateTimeLanguage = navigator.language;

const getExpiryDate = (expiryDate: Date) => {
  const expiry = new Intl.DateTimeFormat(dateTimeLanguage).format(expiryDate);
  return expiryDate > thirty_one_days_in_the_future
    ? expiry
    : dayjs(expiryDate).fromNow();
};

export default getExpiryDate;
