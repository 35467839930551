import type { Version3Models } from 'jira.js';

import { apiRequest } from '../../../api-client';

export interface AllowedValue {
  id: string;
  name: string;
  self?: string;
  description?: string;
}

export interface IssueTypeField {
  allowedValues?: AllowedValue[];
  autoCompleteUrl?: string;
  configuration: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  fieldId: string;
  hasDefaultValue?: boolean;
  key: string;
  name: string;
  operations: string[];
  required: boolean;
  schema: Version3Models.JsonType;
}

const getIssueTypeFields = ({
  connectionId,
  projectId,
  issueTypeId,
  token
}: {
  connectionId: string;
  projectId: string;
  issueTypeId: string;
  token?: string;
}) => {
  return apiRequest<IssueTypeField[]>({
    endpoint: `/api/integrations/jira/connections/${connectionId}/fields?project_id=${projectId}&issue_type_id=${issueTypeId}`,
    token
  });
};

export default getIssueTypeFields;
