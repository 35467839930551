import { TFunction } from 'i18next';
import * as EmailValidator from 'email-validator';
import { PasswordRequirements } from './parsePasswordRequirements';

interface ValidateProps {
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
  email: HTMLInputElement;
  company: HTMLInputElement;
  password?: HTMLInputElement;
  confirmPassword?: HTMLInputElement;
  agree?: HTMLInputElement;
}

const rDigits = /\d/g;
const rLowerCase = /[a-z]/g;
const rUpperCase = /[A-Z]/g;
const rSpecialCharacters = /[@#!$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;

const countDigits = (s: string): number => s.match(rDigits)?.length || 0;
const countLowerCase = (s: string): number => s.match(rLowerCase)?.length || 0;
const countUpperCase = (s: string): number => s.match(rUpperCase)?.length || 0;
const countSpecialCharacters = (s: string): number =>
  s.match(rSpecialCharacters)?.length || 0;

export interface Errors {
  [n: string]: string;
}

export default function (
  {
    firstName,
    lastName,
    email,
    company,
    password,
    confirmPassword,
    agree
  }: ValidateProps,
  passwordRequirements: PasswordRequirements,
  t: TFunction
): Errors {
  let firstErroneousInput: HTMLInputElement | null = null;

  const errors: Errors = {};

  // NOTE: order of the below validation checks matches DOM order which is important
  if (!firstName.value) {
    errors.firstName = t('First name is required');
    firstErroneousInput = firstName;
  }

  if (!lastName.value) {
    errors.lastName = t('Last name is required');
    firstErroneousInput = firstErroneousInput || lastName;
  }

  if (!email.value) {
    errors.email = t('Business email is required');
    firstErroneousInput = firstErroneousInput || email;
  } else if (!EmailValidator.validate(email.value)) {
    errors.email = t('Valid email is required');
    firstErroneousInput = firstErroneousInput || email;
  }

  if (!company.value) {
    errors.company = t('Company is required');
    firstErroneousInput = firstErroneousInput || company;
  }

  if (password && confirmPassword) {
    if (!password.value) {
      errors.password = t('Password is required');
      firstErroneousInput = firstErroneousInput || password;
    }

    if (!confirmPassword.value) {
      errors.confirmPassword = t('Confirm password is required');
      firstErroneousInput = firstErroneousInput || confirmPassword;
    }

    if (
      !errors.password &&
      !errors.confirmPassword &&
      password.value !== confirmPassword.value
    ) {
      errors.password = t('Passwords must match');
      firstErroneousInput = firstErroneousInput || password;
    }

    // Assert the password contains N digits.
    if (!errors.password && passwordRequirements.digits) {
      const count = countDigits(password.value);
      if (count < passwordRequirements.digits) {
        errors.password = t('Password must contain {{n}} digits', {
          n: passwordRequirements.digits
        });
        firstErroneousInput = firstErroneousInput || password;
      }
    }

    // Assert the password is of sufficient length.
    if (!errors.password && passwordRequirements.length) {
      if (password.value.length < passwordRequirements.length) {
        errors.password = t('Password must be {{n}} characters in length', {
          n: passwordRequirements.length
        });
        firstErroneousInput = firstErroneousInput || password;
      }
    }

    // Assert password contains N lower case chars.
    if (!errors.password && passwordRequirements.lowerCase) {
      const count = countLowerCase(password.value);
      if (count < passwordRequirements.lowerCase) {
        errors.password = t(
          'Password must be contain {{n}} lower case characters',
          {
            n: passwordRequirements.lowerCase
          }
        );
        firstErroneousInput = firstErroneousInput || password;
      }
    }

    // Assert password contains N upper case chars.
    if (!errors.password && passwordRequirements.upperCase) {
      const count = countUpperCase(password.value);
      if (count < passwordRequirements.upperCase) {
        errors.password = t(
          'Password must be contain {{n}} upper case characters',
          {
            n: passwordRequirements.upperCase
          }
        );
        firstErroneousInput = firstErroneousInput || password;
      }
    }

    // Assert password contains N special characters.
    if (!errors.password && passwordRequirements.specialCharacters) {
      const count = countSpecialCharacters(password.value);
      if (count < passwordRequirements.specialCharacters) {
        errors.password = t(
          'Password must be contain {{n}} special characters',
          {
            n: passwordRequirements.specialCharacters
          }
        );
        firstErroneousInput = firstErroneousInput || password;
      }
    }

    // Assert password does not match email.
    if (!errors.password && passwordRequirements.notUsername) {
      if (password.value === email.value) {
        errors.password = t('Password and email may not match');
        firstErroneousInput = firstErroneousInput || password;
      }
    }
  }

  if (agree && !agree.checked) {
    errors.agree = t('You must agree to continue');
    firstErroneousInput = firstErroneousInput || agree;
  }

  firstErroneousInput?.focus();

  return errors;
}
