import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@deque/cauldron-react';
import styles from './CreditCard.css';

interface CreditCardProps {
  defaultFullName?: string | null;
  defaultCompany?: string;
  lastFour?: string;
  expMonth?: number;
  expYear?: number;
  postCode?: string | null;
  removePaymentMethodClick?: () => void;
}

const CreditCard = ({
  defaultFullName,
  defaultCompany,
  lastFour,
  expMonth,
  expYear,
  postCode,
  removePaymentMethodClick
}: CreditCardProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles.creditCardDisplay}>
      <dl>
        {defaultFullName && (
          <>
            <dt>{t('Name')}</dt>
            <dd>{defaultFullName}</dd>
          </>
        )}
        {defaultCompany && (
          <>
            <dt>{t('Company')}</dt>
            <dd>{defaultCompany}</dd>
          </>
        )}
        <dt>{t('Card number')}</dt>
        <dd>
          {'XXXX XXXX XXXX '}
          {lastFour || 'XXXX'}
        </dd>
        <dt>{t('Expiration')}</dt>
        <dd>
          {expMonth || t('MM')}&nbsp;/&nbsp;
          {expYear || t('YYYY')}
        </dd>
        {postCode && (
          <>
            <dt>{t('Postal code')}</dt>
            <dd>{postCode}</dd>
          </>
        )}
      </dl>
      {removePaymentMethodClick ? (
        <Button variant="link" onClick={removePaymentMethodClick}>
          {t('Remove')}
        </Button>
      ) : null}
    </div>
  );
};

export default CreditCard;
