import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { v2 } from '@deque/billing-service-client';
import { LoaderOverlay } from '@deque/cauldron-react';
import billingClient, {
  EnterpriseInvitations
} from '../../common/utils/billing-client/client-v2';
import { useAuthContext } from '../../common/contexts/auth';
import useAPI from '../../common/hooks/useAPI';
import ErrorMessage from '../components/issue/ErrorMessage';
import Form from '../components/AcceptInvitationForm';
import { FREE_STATES, ProductSlugs } from '../../common/constants';
import type { AxePurchaseState } from '@deque/billing-service-client';
import { AXE_WATCHER_PAID_PLANS_V1 } from '../../axe-watcher/constants';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';

export interface InvitationWithEnterprise
  extends EnterpriseInvitations.Invitation {
  enterprise: v2.Enterprise;
}
interface Data {
  invitation: EnterpriseInvitations.Invitation;
  enterprise: v2.Enterprise;
  products: v2.Product[];
  otherInvitations: InvitationWithEnterprise[];
}

interface Props {
  invitationId: string;
  enterpriseId: string;
  token: string;
  onSubmit: () => void;
  onReject: () => void;
  onAlreadyAccepted: () => void;
  // Optional dependency injection for tests.
  billing?: typeof billingClient;
}

const AcceptInvitationForm: React.FC<Props> = ({
  token,
  enterpriseId,
  invitationId,
  onSubmit,
  onReject,
  onAlreadyAccepted,
  billing = billingClient
}) => {
  const { t } = useTranslation();
  const { billingUser } = useAuthContext();
  const [accepting, setAccepting] = React.useState(false);
  const [rejecting, setRejecting] = React.useState(false);
  const [acceptError, setAcceptError] = React.useState<Error | null>(null);
  const hasAxeWatcherPaidPlans = useFeatureFlagState(AXE_WATCHER_PAID_PLANS_V1);

  const fetchData = React.useCallback(async (): Promise<Data> => {
    const [i, e, p, invitationsData] = await Promise.all([
      billing.enterpriseInvitations.get({
        enterpriseId,
        invitationId,
        token
      }),
      billing.enterprises.get({ token: token, id: enterpriseId }),
      billing.products.list(),
      billing.me.getUserInvitations(token, false)
    ]);

    const invitations = await Promise.all(
      invitationsData.map(async invitation => {
        const enterprise = await billing.enterprises.get({
          token: token,
          id: invitation.enterprise_id
        });
        return { ...invitation, enterprise };
      })
    );

    const otherInvitations = invitations.filter(
      invitation => invitation.id !== invitationId
    );

    return {
      invitation: i,
      enterprise: e,
      products: p,
      otherInvitations
    };
  }, [invitationId, enterpriseId, token]);

  const handleSubmit = React.useCallback(async () => {
    setAcceptError(null);
    setAccepting(true);

    try {
      await billing.enterpriseInvitations.accept({
        enterpriseId,
        invitationId,
        token: token
      });
      onSubmit();
    } catch (err) {
      setAcceptError(err as Error);
      setAccepting(false);
    }
  }, [invitationId, enterpriseId, token]);

  const handleReject = React.useCallback(async () => {
    setAcceptError(null);
    setRejecting(true);

    try {
      await billingClient.me.rejectUserInvitation(invitationId, token);
      onReject();
    } catch (err) {
      setAcceptError(err as Error);
      setRejecting(false);
    }
  }, [invitationId, enterpriseId, token]);

  const { data, error, loading } = useAPI(fetchData);

  const loadingText = useMemo(() => {
    if (loading) {
      return t('Loading invitation...');
    } else if (accepting) {
      return t('Accepting invitation...');
    } else if (rejecting) {
      return t('Declining invitation...');
    } else {
      return '';
    }
  }, [loading, accepting, rejecting]);

  if (loadingText) {
    return <LoaderOverlay label={loadingText} focusOnInitialRender />;
  }

  if (error || acceptError) {
    return <ErrorMessage error={((error || acceptError) as Error).message} />;
  }

  // If the billingUser is not set, the user should be prompted to create their account
  // before proceeding.
  if (!billingUser) {
    return null;
  }

  // When neither loading nor in an error state, `data` will never be `null`.
  const { enterprise, invitation, products, otherInvitations } = data as Data;

  if (invitation.accepted_at) {
    onAlreadyAccepted();
    return null;
  }

  // Note: if this list changes, the accept invitation endpoint should be updated to match
  const personalSubscriptions = billingUser.subscriptions.filter(
    subscription => {
      // If the user has a free axe devhub subscription, we should "remove" it.
      // Note: "removal" in this context means we will prioritize the billing user's
      // enterprise subscription over personal subscriptions once the user accepts the
      // invitation.
      if (
        hasAxeWatcherPaidPlans &&
        subscription.product_slug === ProductSlugs.axeDevToolsWatcher &&
        FREE_STATES.includes(subscription.purchase_state as AxePurchaseState)
      ) {
        return true;
      }

      return !['none', ...FREE_STATES].includes(
        subscription.purchase_state as AxePurchaseState
      );
    }
  );

  return (
    <Form
      enterprise={enterprise}
      invitation={invitation}
      personalSubscriptions={personalSubscriptions}
      previousEnterprise={billingUser.enterprises[0] || null}
      products={products}
      onSubmit={handleSubmit}
      onReject={handleReject}
      otherInvitations={otherInvitations}
    />
  );
};

export default AcceptInvitationForm;
