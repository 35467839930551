import type { v2 } from '@deque/billing-service-client';
import { Trans, useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  IconButton,
  Offscreen,
  TooltipTabstop,
  OptionsMenu,
  OptionsMenuItem,
  PanelContent
} from '@deque/cauldron-react';

import { ProductSlugs } from '../../../../common/constants';
import {
  AccessTypes,
  EnterpriseMembers,
  memberHasAccepted,
  memberIsPending,
  ProductAccess
} from '../../../../common/utils/billing-client/client-v2';
import styles from './UserManagementTable.css';
import HighlightText from '../../../../common/components/HighlightText';

export interface UserManagementTableProps {
  filteredMembers: EnterpriseMembers.PendingOrAcceptedMember[];
  currentUserId: string;
  getProductBySlug: (slug: string) => v2.Product | undefined;
  onResendClick: (member: EnterpriseMembers.PendingMember) => void;
  onRemoveClick: (member: EnterpriseMembers.PendingOrAcceptedMember) => void;
  isMobile?: boolean;
  membersPerPage?: number;
  hiddenProductSlugs: ProductSlugs[];
  isMailerEnabled: boolean;
  searchQuery?: string;
}

type SortBy = null | 'name' | 'email' | 'status';
type SortDirection = 'none' | 'ascending' | 'descending';

const UserManagementTable = ({
  filteredMembers,
  searchQuery,
  currentUserId,
  onResendClick,
  onRemoveClick,
  getProductBySlug,
  isMobile = false,
  membersPerPage = 50,
  hiddenProductSlugs,
  isMailerEnabled
}: UserManagementTableProps): JSX.Element => {
  const { t } = useTranslation();
  const [sort, setSort] = useState<[SortBy, SortDirection]>([null, 'none']);
  const [sortBy, sortDirection] = sort;
  const [currentPage, setCurrentPage] = useState(1);

  const totalMembers = filteredMembers.length;
  const memberStart = currentPage * membersPerPage - membersPerPage + 1;
  const memberEnd = Math.min(memberStart + membersPerPage - 1, totalMembers);

  const onNextPageClick = () => setCurrentPage(currentPage + 1);
  const onFirstPageClick = () => setCurrentPage(1);
  const onPreviousPageClick = () => setCurrentPage(currentPage - 1);
  const onLastPageClick = () =>
    setCurrentPage(Math.ceil(totalMembers / membersPerPage));

  // default sorting
  const sortByDefault = (
    data: EnterpriseMembers.PendingOrAcceptedMember[]
  ): EnterpriseMembers.PendingOrAcceptedMember[] =>
    data?.sort((a, b) => {
      if (memberHasAccepted(a) && memberIsPending(b)) {
        return 1;
      }
      if (memberIsPending(a) && memberHasAccepted(b)) {
        return -1;
      }
      return a.email.localeCompare(b.email);
    });

  const sortByName = (
    data: EnterpriseMembers.PendingOrAcceptedMember[]
  ): EnterpriseMembers.PendingOrAcceptedMember[] =>
    data.sort((a, b) => {
      if (memberHasAccepted(a) && memberIsPending(b)) {
        return sortDirection === 'ascending' ? 1 : -1;
      } else if (memberIsPending(a) && memberHasAccepted(b)) {
        return sortDirection === 'ascending' ? -1 : 1;
      } else if (memberHasAccepted(a) && memberHasAccepted(b)) {
        return sortDirection === 'ascending'
          ? `${a.first_name} ${a.last_name}`.localeCompare(
              `${b.first_name} ${b.last_name}`
            )
          : `${b.first_name} ${b.last_name}`.localeCompare(
              `${a.first_name} ${a.last_name}`
            );
      }
      return 0;
    });

  const sortByEmail = (
    data: EnterpriseMembers.PendingOrAcceptedMember[]
  ): EnterpriseMembers.PendingOrAcceptedMember[] =>
    data.sort((a, b) =>
      (sortDirection === 'ascending' ? a.email : b.email).localeCompare(
        sortDirection === 'ascending' ? b.email : a.email
      )
    );

  const sortByStatus = (
    data: EnterpriseMembers.PendingOrAcceptedMember[]
  ): EnterpriseMembers.PendingOrAcceptedMember[] =>
    data.sort((a, b) => {
      if (memberHasAccepted(a) && memberIsPending(b)) {
        return sortDirection === 'ascending' ? 1 : -1;
      }
      if (memberIsPending(a) && memberHasAccepted(b)) {
        return sortDirection === 'ascending' ? -1 : 1;
      }
      return 0;
    });

  const handleSort = (
    data: EnterpriseMembers.PendingOrAcceptedMember[]
  ): EnterpriseMembers.PendingOrAcceptedMember[] =>
    !sortBy
      ? sortByDefault(data)
      : sortBy === 'email'
      ? sortByEmail(data)
      : sortBy === 'name'
      ? sortByName(data)
      : sortByStatus(data);

  const sortedUsers = handleSort(filteredMembers);
  const getCurrentSortDirection = (column: string) =>
    column === sortBy ? sortDirection : 'none';
  const getNextSortDirection = (column: string) =>
    column === sortBy && sortDirection === 'ascending'
      ? 'descending'
      : 'ascending';

  const paginatedUsers = sortedUsers.slice(memberStart - 1, memberEnd);

  const isAdmin = (is_admin: boolean) =>
    is_admin ? t('Admin') : t('General Access');

  const getProductAccess = (access: ProductAccess): string | undefined => {
    const product = getProductBySlug(access.product_slug);
    if (!product) {
      return;
    }
    const accessType =
      access.access_type === AccessTypes.ADMIN
        ? t('Admin')
        : t('General Access');
    return t('{{ productName }}: {{accessType}}', {
      productName: product.name,
      accessType
    });
  };

  const actionIcons = (member: EnterpriseMembers.PendingOrAcceptedMember) => (
    <div className={styles.actionIcons}>
      {memberIsPending(member) ? (
        <IconButton
          disabled
          tooltipProps={{ variant: 'info' }}
          icon="pencil"
          label={
            <>
              {t('Edit Access')}
              <Offscreen> {member.email}</Offscreen>
            </>
          }
        />
      ) : (
        <IconButton
          tooltipProps={{ variant: 'info' }}
          icon="pencil"
          as={Link}
          to={`/user-access/edit-user/${member.user_id}`}
          label={
            <>
              {t('Edit Access')}
              <Offscreen> {member.email}</Offscreen>
            </>
          }
        />
      )}
      <OptionsMenu
        className={styles.optionsMenu}
        align={isMobile ? 'left' : 'right'}
        trigger={triggerProps => (
          <IconButton
            icon="kabob"
            label={
              <Trans>
                More Options
                <Offscreen> for {{ memberEmail: member.email }}</Offscreen>
              </Trans>
            }
            tooltipProps={{ variant: 'info' }}
            className={styles.optionsButton}
            {...triggerProps}
          />
        )}
      >
        <OptionsMenuItem
          onSelect={() =>
            onResendClick(member as EnterpriseMembers.PendingMember)
          }
          disabled={!isMailerEnabled || memberHasAccepted(member)}
          className={styles.optionsMenuItem}
        >
          <Trans>
            Resend Invitation
            <Offscreen> to {{ memberEmail: member.email }}</Offscreen>
          </Trans>
        </OptionsMenuItem>
        <OptionsMenuItem
          onSelect={() => onRemoveClick(member)}
          disabled={
            memberHasAccepted(member) && member.user_id === currentUserId
          }
          className={styles.optionsMenuItem}
        >
          Remove Member
          <Offscreen> {member.email}</Offscreen>
        </OptionsMenuItem>
      </OptionsMenu>
    </div>
  );

  const getMemberDetails = (
    member: EnterpriseMembers.PendingOrAcceptedMember
  ) => {
    const productAccess = member.product_access.filter(access => {
      return (
        !hiddenProductSlugs.includes(access.product_slug as ProductSlugs) &&
        !!getProductBySlug(access.product_slug)
      );
    });

    const memberName = (
      <HighlightText
        text={
          memberHasAccepted(member)
            ? `${member.first_name} ${member.last_name}`
            : '--'
        }
        highlight={searchQuery}
      />
    );

    const memberEmail = (
      <HighlightText text={member.email} highlight={searchQuery} />
    );

    const productAccessTooltip = (
      <TooltipTabstop
        placement="top"
        className={styles.productAccessCount}
        tooltip={
          <ul className={styles.productAccessList}>
            <li>
              axe {t('Account')}: {isAdmin(member.is_admin)}
            </li>
            {productAccess.map(access => (
              <li key={`${access.product_slug}-${member.email}`}>
                {getProductAccess(access)}
              </li>
            ))}
          </ul>
        }
      >
        {/* +1 to account for axe Account access */}
        {productAccess.length + 1}
      </TooltipTabstop>
    );

    const memberStatus = memberIsPending(member)
      ? t('Email Sent')
      : t('Active');

    const memberActions = actionIcons(member);

    return {
      key: member.email,
      memberName,
      memberEmail,
      productAccessTooltip,
      memberStatus,
      memberActions
    };
  };

  const memberDetails = useMemo(
    () => paginatedUsers.map(member => getMemberDetails(member)),
    [paginatedUsers]
  );

  return (
    <div
      className={classNames(styles.userManagementBody, {
        [styles.userManagementBodyMobile]: isMobile
      })}
    >
      {!isMobile ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader
                scope="col"
                sortDirection={getCurrentSortDirection('name')}
                sortAscendingAnnouncement={t('sorted ascending')}
                sortDescendingAnnouncement={t('sorted descending')}
                onSort={() => {
                  setSort(['name', getNextSortDirection('name')]);
                }}
              >
                {t('Name')}
              </TableHeader>
              <TableHeader
                scope="col"
                sortDirection={getCurrentSortDirection('email')}
                sortAscendingAnnouncement={t('sorted ascending')}
                sortDescendingAnnouncement={t('sorted descending')}
                onSort={() => {
                  setSort(['email', getNextSortDirection('email')]);
                }}
              >
                {t('Email')}
              </TableHeader>
              <TableHeader scope="col">{t('Products')}</TableHeader>
              <TableHeader
                scope="col"
                sortDirection={getCurrentSortDirection('status')}
                sortAscendingAnnouncement={t('sorted ascending')}
                sortDescendingAnnouncement={t('sorted descending')}
                onSort={() => {
                  setSort(['status', getNextSortDirection('status')]);
                }}
              >
                {t('Status')}
              </TableHeader>
              <TableHeader scope="col">{t('Actions')}</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberDetails.map(
              ({
                key,
                memberName,
                memberEmail,
                productAccessTooltip,
                memberStatus,
                memberActions
              }) => (
                <TableRow key={`${key}-tr`}>
                  <TableCell>{memberName}</TableCell>
                  <TableCell>{memberEmail}</TableCell>
                  <TableCell>{productAccessTooltip}</TableCell>
                  <TableCell>{memberStatus}</TableCell>
                  <TableCell>{memberActions}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      ) : (
        <ul className={styles.mobileUserList}>
          {memberDetails.map(
            ({
              key,
              memberName,
              memberEmail,
              productAccessTooltip,
              memberStatus,
              memberActions
            }) => (
              <li key={key}>
                <PanelContent>
                  <h2>{t('Name')}</h2>
                  <p>{memberName}</p>
                  <h2>{t('Email')}</h2>
                  <p>{memberEmail}</p>
                  <h2>{t('Products')}</h2>
                  {productAccessTooltip}
                  <h2>{t('Status')}</h2>
                  <p>{memberStatus}</p>
                  <h2>{t('Actions')}</h2>
                  {memberActions}
                </PanelContent>
              </li>
            )
          )}
        </ul>
      )}

      <Pagination
        totalItems={totalMembers}
        itemsPerPage={membersPerPage}
        currentPage={currentPage}
        statusLabel={
          <Trans>
            <span>
              {{ showing: !isMobile ? t('Showing') : '' }}{' '}
              <strong>{{ memberStart }}</strong> -{' '}
              <strong>{{ memberEnd }}</strong> of{' '}
              <strong>{{ totalMembers }}</strong>
            </span>
          </Trans>
        }
        onNextPageClick={onNextPageClick}
        onFirstPageClick={onFirstPageClick}
        onPreviousPageClick={onPreviousPageClick}
        onLastPageClick={onLastPageClick}
      />
    </div>
  );
};

export default UserManagementTable;
