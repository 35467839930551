import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOnPremValue } from '../contexts/onPrem';
import PageTitle from './PageTitle';
import styles from './NotOnPrem.css';

/** Default fallback content. */
const Fallback: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.error}>
      <PageTitle title={t('Unavailable for on-premises deployments')} />
      <h1>{t('This page is not available for on-premises deployments')}</h1>
    </div>
  );
};

interface Props {
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

/** Utility component to disable content when deployed on-premises. */
const NotOnPrem: React.FC<Props> = ({ children, fallback = <Fallback /> }) => {
  const isOnPrem = useOnPremValue();
  if (isOnPrem) {
    return <>{fallback}</>;
  }
  return <>{children}</>;
};

export default NotOnPrem;
