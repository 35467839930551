import { useCallback } from 'react';

import { v2 } from '@deque/billing-service-client';

import { Team } from '../../common/api-client';
import billingClient from '../../common/utils/billing-client/client-v2';
import useAPI, { State } from '../../common/hooks/useAPI';

export interface UseTeamState extends Omit<State<v2.Team>, 'data'> {
  team: Team | null;
}

interface UseTeamParams {
  token: string;
  teamId: string;
  enterpriseId: string;
}

const useTeam = ({
  token,
  teamId,
  enterpriseId
}: UseTeamParams): UseTeamState => {
  const dataLoader = useCallback(async (): Promise<Team> => {
    const team = await billingClient.teams.get({
      token,
      id: teamId,
      enterpriseId
    });

    const memberships = await billingClient.teamMemberships.get({
      token,
      id: teamId,
      enterpriseId
    });

    return {
      id: team.id,
      name: team.name,
      user_ids: memberships.map(membership => membership.user_keycloak_id),
      user_ids_to_add: [],
      user_ids_to_remove: []
    };
  }, [token, teamId, enterpriseId]);

  const state = useAPI(dataLoader);

  return {
    team: state.data || null,
    loading: state.loading,
    error: state.error
  };
};

export default useTeam;
