import { TextField } from '@deque/cauldron-react';
import React from 'react';
import {
  Control,
  Controller,
  FieldPathByValue,
  FieldValues
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './FeatureFlagDescriptionField.css';

type FeatureFlagDescriptionFieldProps<
  NameType extends FieldPathByValue<ControlType, string>,
  ControlType extends FieldValues
> = {
  name: NameType;
  control: Control<ControlType>;
};

const FeatureFlagDescriptionField = <
  ControlType extends FieldValues,
  NameType extends FieldPathByValue<ControlType, string>
>({
  name,
  control
}: FeatureFlagDescriptionFieldProps<NameType, ControlType>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: true,
          message: t('A flag’s description is required!')
        },
        maxLength: {
          value: 500,
          message: t('A flag’s description should be less than 500 characters.')
        }
      }}
      render={({ field, fieldState }) => {
        return (
          <TextField
            label={t('Description')}
            aria-describedby="feature_description_help"
            error={fieldState.error?.message}
            className={styles.featureDescriptionField}
            required
            multiline
            {...field}
          />
        );
      }}
    />
  );
};

export default FeatureFlagDescriptionField;
