import React from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { Button, Icon, Select } from '@deque/cauldron-react';

import type { SelectOption } from '../FieldMapping';
import {
  ProductNames,
  SupportedIntegrationProductSlugs
} from '../../../../../../../common/constants';
import useMediaQuery from '../../../../../../../common/hooks/useMediaQuery';
import getIntegrationProductName from '../../../../../../../common/utils/get-integration-product-name-from-slug';
import styles from './Field.css';

export interface FieldProps {
  integrationProductSlug: SupportedIntegrationProductSlugs;
  axeFields: SelectOption[];
  mappingFields: SelectOption[];
  index: number;
  removeField: (index: number) => void;
  required?: boolean;
  onSelectIntegrationField: () => void;
  chosenIntegrationFields: string[];
}

const Field = ({
  integrationProductSlug,
  axeFields,
  mappingFields,
  index,
  removeField,
  required = false,
  onSelectIntegrationField,
  chosenIntegrationFields
}: FieldProps) => {
  const { t } = useTranslation();
  const narrow = useMediaQuery('(max-width: 37.5rem)');
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext();

  const getOptions = (options: SelectOption[], field: { value: string }) => {
    return options.map(option => ({
      ...option,
      disabled:
        chosenIntegrationFields.includes(option.value) &&
        field.value !== option.value &&
        option.value !== ''
    }));
  };

  const getError = (
    field: { value: string },
    fieldError: typeof errors,
    i: number
  ) =>
    (errors &&
      // Only display the error on the empty field
      (!field.value || field.value === '') &&
      // The inferred type is incorrect, so we need to cast it
      (fieldError.fieldMapping as unknown as FieldError[])?.[i]?.message) ||
    undefined;

  return (
    <div
      className={
        narrow
          ? classNames(styles.fieldContainer, styles.narrow)
          : styles.fieldContainer
      }
    >
      <div
        className={
          narrow
            ? classNames(styles.inputsContainer, styles.narrow)
            : styles.inputsContainer
        }
      >
        <Trans>
          <div
            className={
              narrow
                ? classNames(styles.inputContainer, styles.narrow)
                : styles.inputContainer
            }
            aria-labelledby={`map-${index} axe-field-${index}`}
          >
            <p className={styles.map} id={`map-${index}`}>
              Map
            </p>
            <Controller
              name={`fieldMapping[${index}].mappingField`}
              control={control}
              aria-labelledby={`integration-field-${index}`}
              render={({ field }) => (
                <Select
                  {...field}
                  id={`integration-field-${index}`}
                  value={field.value}
                  label={t(
                    `{{ required }}{{ integration }} {{ fieldType }} Field`,
                    {
                      required: required ? 'Required ' : '',
                      integration: getIntegrationProductName(
                        integrationProductSlug,
                        { capitalize: true }
                      ),
                      fieldType: required
                        ? mappingFields.find(
                            mappingField => mappingField.value === field.value
                          )?.label
                        : ''
                    }
                  )}
                  disabled={required}
                  options={getOptions(mappingFields, field)}
                  onChange={({ target: { value } }) => {
                    setValue(`fieldMapping[${index}].mappingField`, value);
                    onSelectIntegrationField();
                  }}
                  error={getError(field, errors, index)}
                  aria-label={
                    required
                      ? undefined
                      : t(`{{ integration }} field {{ index }}`, {
                          integration: getIntegrationProductName(
                            integrationProductSlug,
                            { capitalize: true }
                          ),
                          index: index + 1
                        })
                  }
                />
              )}
            />
          </div>
          <div
            className={
              narrow
                ? classNames(styles.inputContainer, styles.narrow)
                : styles.inputContainer
            }
            aria-labelledby={`from-${index} integration-field-${index}`}
          >
            <p className={styles.from} id={`from-${index}`}>
              from
            </p>
            <Controller
              name={`fieldMapping[${index}].axeField`}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t(`{{ axe }} Field`, {
                    axe: ProductNames.axe
                  })}
                  options={axeFields}
                  error={getError(field, errors, index)}
                  id={`axe-field-${index}`}
                  aria-label={t(`{{ axe }} field {{ index }}`, {
                    axe: ProductNames.axe,
                    index: index + 1
                  })}
                />
              )}
            />
          </div>
        </Trans>
      </div>
      {!required && (
        <div className={styles.buttonContainer}>
          <Button
            className={styles.removeButton}
            variant="secondary"
            onClick={() => removeField(index)}
          >
            <Icon
              type="minus"
              label={t('Remove mapping fields {{ index }}', { index })}
            />
            {narrow && t('Remove')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Field;
