import {
  SettingTypeEnum,
  SettingsSchemaGeneralAxeCoreVersionValueEnum as AxeCoreVersion,
  SettingsSchemaGeneralAccessibilityStandardValueEnum as AccessibilityStandard,
  SettingsSchemaGeneralNeedsReviewValueEnum as NeedsReview,
  SettingsSchemaGeneralExpiredProductVisibilityValueEnum as ExpiredProductVisibility,
  SettingsSchemaGeneralSharedReportAccessControlValueEnum as SharedReportAccessControl
} from '@deque/orgwide-settings-client';
import React, { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm, useWatch, type Control } from 'react-hook-form';
import { Button, TextField, Notice } from '@deque/cauldron-react';
import { useHistory, useLocation } from 'react-router-dom';
import semverGte from 'semver/functions/gte';
import Form from './Form';
import { useLoadedConfiguration } from '../../../common/contexts/Configuration';
import {
  getSettingType,
  getOneOfSettingType
} from '../../../common/utils/configuration';
import SelectSetting, { type OptionWithValueOfType } from './SelectSetting';
import { axeCoreVersions } from '../../../common/utils/axeCoreVersions';
import {
  recommendedStandard,
  MINIMUM_TT_V5_AXE_CORE_VERSION,
  MINIMUM_EN_301_AXE_CORE_VERSION
} from '../../../common/utils/rulesets';
import rulesets from '@deque/accessibility-standards';
import RadioSetting, { type RadioItemWithValueOfType } from './RadioSetting';
import type { EventData } from '../../../common/AxeAccountAnalytics';
import { useAxeAccountAnalytics } from '../../../common/contexts/analytics';
import SelectOneOfSetting from './SelectOneOfSetting';
import { ProductSlugs } from '../../../common/constants';
import styles from './Configuration.css';
import { useProducts } from '../../../common/contexts/products';
import { useFeatureFlagState } from '../../../common/contexts/featureFlags';

// Ideally, we would import the type from the settings client, but it appears to
// re-use the same types by default (e.g. issueScreenshotSharing is of type ML).
// To avoid this, we would likely have to declare a separate schema for each one.
export type EnabledDisabled = 'enabled' | 'disabled';

export interface GlobalConfigurationForm {
  accessibilityStandard: AccessibilityStandard;
  accessibilityStandardOptions: AccessibilityStandard[];
  allowUsersToChangeAccessibilityStandard: boolean;
  axeCoreVersion: AxeCoreVersion;
  axeCoreVersionOptions: AxeCoreVersion[];
  allowUsersToChangeAxeCoreVersion: boolean;
  needsReview: NeedsReview;
  allowUsersToChangeNeedsReview: boolean;
  bestPractices: EnabledDisabled;
  allowUsersToChangeBestPractices: boolean;
  experimentalRules: EnabledDisabled;
  allowUsersToChangeExperimentalRules: boolean;
  issueScreenshotSharing: EnabledDisabled;
  allowUsersToChangeIssueScreenshotSharing: boolean;
  ml: EnabledDisabled;
  allowUsersToChangeMl: boolean;
  usageService: EnabledDisabled;
  usageServiceUrl: string;
  usageServiceOrganization: string;
  usageServiceDepartment: string;
  usageServiceApplication: string;
  sharedReportAccessControl: SharedReportAccessControl;
  expiredProducts: ExpiredProductVisibility;
}

const focusableFields: (keyof GlobalConfigurationForm)[] = ['expiredProducts'];

export type InputsWithAnalytics = Omit<
  GlobalConfigurationForm,
  | 'usageService'
  | 'usageServiceUrl'
  | 'usageServiceOrganization'
  | 'usageServiceDepartment'
  | 'usageServiceApplication'
>;

export const analyticsProperties: Record<keyof InputsWithAnalytics, string> = {
  accessibilityStandard: 'AccessibilityStandardDefault',
  accessibilityStandardOptions: 'AccessibilityStandardOptions',
  allowUsersToChangeAccessibilityStandard: 'AccessibilityStandardAllowChange',
  axeCoreVersion: 'axeCoreVersionDefault',
  axeCoreVersionOptions: 'AxeCoreVersionOptions',
  allowUsersToChangeAxeCoreVersion: 'axeCoreVersionAllowChange',
  needsReview: 'needsReviewDefault',
  allowUsersToChangeNeedsReview: 'needsReviewAllowChange',
  bestPractices: 'bestPracticesDefault',
  allowUsersToChangeBestPractices: 'bestPracticesAllowChange',
  experimentalRules: 'experimentalDefault',
  allowUsersToChangeExperimentalRules: 'experimentalAllowChange',
  issueScreenshotSharing: 'shareIssueScreenshotsDefault',
  allowUsersToChangeIssueScreenshotSharing: 'shareIssueScreenshotsAllowChange',
  ml: 'MLDefault',
  allowUsersToChangeMl: 'MLAllowChange',
  sharedReportAccessControl: 'sharedReportAccess',
  expiredProducts: 'showExpiredProducts'
} as const;

const doesSupportStandard = ({
  version,
  minimumVersion
}: {
  version: AxeCoreVersion;
  minimumVersion: AxeCoreVersion;
}) => {
  const adjustedVersion = /^[0-9]+.[0-9]+$/.test(version)
    ? `${version}.${Number.MAX_SAFE_INTEGER}`
    : version;

  return (
    adjustedVersion === 'latest' || semverGte(adjustedVersion, minimumVersion)
  );
};

const partiallySupportedStandards = [
  {
    key: AccessibilityStandard.Ttv5,
    name: rulesets.TTv5.name,
    minimumVersion: MINIMUM_TT_V5_AXE_CORE_VERSION
  },
  {
    key: AccessibilityStandard.En301549,
    name: rulesets['EN-301-549'].name,
    minimumVersion: MINIMUM_EN_301_AXE_CORE_VERSION
  }
];

const useAccessibilityStandardOptions = ({
  formControl,
  hasEN301SupportV1,
  isEnterpriseMember
}: {
  formControl: Control<GlobalConfigurationForm>;
  hasEN301SupportV1: boolean;
  isEnterpriseMember: boolean;
}) => {
  const { t } = useTranslation();
  const [
    axeCoreVersion,
    axeCoreVersionOptions,
    allowUsersToChangeAxeCoreVersion
  ] = useWatch({
    control: formControl,
    name: [
      'axeCoreVersion',
      'axeCoreVersionOptions',
      'allowUsersToChangeAxeCoreVersion'
    ]
  });
  const availableAccessibilityStandardOptions =
    useMemo((): OptionWithValueOfType<AccessibilityStandard> => {
      const accessibilityStandardOptions: OptionWithValueOfType<AccessibilityStandard> =
        [];
      Object.entries(rulesets).map(([key, ruleset]) => {
        let disabled = false;
        let disableSelectOptionOnly = false;

        // Don't disable until the user has selected a version
        const partiallySupportedData = partiallySupportedStandards.find(
          standard => standard.key === key
        );
        if (axeCoreVersion && axeCoreVersionOptions && partiallySupportedData) {
          // Disable the partially supported standards if the axe-core version is
          // not compatible or the available axe-core versions don't support standard
          const optionsSupportStandard = axeCoreVersionOptions.some(version =>
            doesSupportStandard({
              version,
              minimumVersion: partiallySupportedData.minimumVersion
            })
          );
          disabled =
            !doesSupportStandard({
              version: axeCoreVersion,
              minimumVersion: partiallySupportedData.minimumVersion
            }) || !optionsSupportStandard;
          /* Only disable partially supported standards as default accessibility standard if it is unsupported by the available axe core versions */
          disableSelectOptionOnly =
            isEnterpriseMember &&
            allowUsersToChangeAxeCoreVersion &&
            optionsSupportStandard;
        }

        const rulesetName = partiallySupportedData
          ? t('{{ name }} (requires axe-core v{{minimum}} or higher)', {
              name: partiallySupportedData.name,
              minimum: partiallySupportedData.minimumVersion
            })
          : ruleset.name;

        if (key !== AccessibilityStandard.En301549 || hasEN301SupportV1) {
          accessibilityStandardOptions.push({
            key,
            label:
              key === recommendedStandard
                ? t('{{ rulesetName }} (Recommended)', {
                    rulesetName
                  })
                : rulesetName,
            value: key as AccessibilityStandard,
            disabled,
            disableSelectOptionOnly
          });
        }
      });
      return accessibilityStandardOptions;
    }, [
      t,
      axeCoreVersion,
      axeCoreVersionOptions,
      allowUsersToChangeAxeCoreVersion,
      hasEN301SupportV1
    ]);

  return {
    accessibilityStandardOptions: availableAccessibilityStandardOptions
  };
};

const GlobalConfiguration = () => {
  const { t } = useTranslation();
  const {
    settings,
    updateSettings,
    saving,
    isEnterpriseMember,
    isEnterpriseAdmin
  } = useLoadedConfiguration();
  const generalSettings = settings.general;
  const {
    handleSubmit,
    reset,
    resetField,
    setFocus,
    control,
    formState,
    setValue,
    getValues,
    watch
  } = useForm<GlobalConfigurationForm>();
  const analytics = useAxeAccountAnalytics();
  const history = useHistory();
  const { search } = useLocation();
  const focus = new URLSearchParams(search).get('focus');
  const { getProductBySlug } = useProducts();
  const [axeCoreVersion, accessibilityStandard] = useWatch({
    control,
    name: ['axeCoreVersion', 'accessibilityStandard']
  });

  const focusRefHandler = (el: HTMLDivElement) => {
    if (focus && el) {
      if (focusableFields.includes(focus as keyof GlobalConfigurationForm)) {
        const focusField = el;

        setTimeout(() => {
          focusField?.focus({ preventScroll: true });
          focusField?.scrollIntoView({
            block: 'end'
          });
        }, 0);
      } else {
        history.replace('/configuration');
      }
    }
  };

  useEffect(() => {
    if (generalSettings) {
      // Set the default values for the form
      reset({
        accessibilityStandard: generalSettings.accessibilityStandard.value,
        accessibilityStandardOptions:
          generalSettings.accessibilityStandard.type === SettingTypeEnum.OneOf
            ? generalSettings.accessibilityStandard.options
            : (Object.keys(rulesets) as AccessibilityStandard[]),
        allowUsersToChangeAccessibilityStandard: (
          [SettingTypeEnum.OneOf, SettingTypeEnum.Default] as SettingTypeEnum[]
        ).includes(generalSettings.accessibilityStandard.type),
        axeCoreVersion: generalSettings.axeCoreVersion.value,
        axeCoreVersionOptions:
          generalSettings.axeCoreVersion.type === SettingTypeEnum.OneOf
            ? generalSettings.axeCoreVersion.options
            : axeCoreVersions,
        allowUsersToChangeAxeCoreVersion: (
          [SettingTypeEnum.OneOf, SettingTypeEnum.Default] as SettingTypeEnum[]
        ).includes(generalSettings.axeCoreVersion.type),
        needsReview: generalSettings.needsReview.value,
        allowUsersToChangeNeedsReview:
          generalSettings.needsReview.type === SettingTypeEnum.Default,
        bestPractices: generalSettings.bestPractices.value,
        allowUsersToChangeBestPractices:
          generalSettings.bestPractices.type === SettingTypeEnum.Default,
        experimentalRules: generalSettings.experimentalRules.value,
        allowUsersToChangeExperimentalRules:
          generalSettings.experimentalRules.type === SettingTypeEnum.Default,
        issueScreenshotSharing: generalSettings.issueScreenshotSharing.value,
        allowUsersToChangeIssueScreenshotSharing:
          generalSettings.issueScreenshotSharing.type ===
          SettingTypeEnum.Default,
        ml: generalSettings.ml.value,
        allowUsersToChangeMl:
          generalSettings.ml.type === SettingTypeEnum.Default,
        sharedReportAccessControl:
          generalSettings.sharedReportAccessControl.value,
        usageService: generalSettings.usageService.value.status,
        usageServiceUrl: generalSettings.usageService.value.url || '',
        usageServiceOrganization:
          generalSettings.usageService.value.organization || '',
        usageServiceDepartment:
          generalSettings.usageService.value.department || '',
        usageServiceApplication:
          generalSettings.usageService.value.application || '',
        expiredProducts: generalSettings.expiredProductVisibility.value
      });
    }
  }, [generalSettings]);

  const onSubmit = (data: GlobalConfigurationForm) => {
    updateSettings({
      general: {
        accessibilityStandard: {
          value: data.accessibilityStandard,
          type: isEnterpriseAdmin
            ? getOneOfSettingType(data.allowUsersToChangeAccessibilityStandard)
            : generalSettings.accessibilityStandard.type,
          options:
            getOneOfSettingType(
              data.allowUsersToChangeAccessibilityStandard
            ) === SettingTypeEnum.OneOf
              ? data.accessibilityStandardOptions
              : undefined
        },
        axeCoreVersion: {
          value: data.axeCoreVersion,
          type: isEnterpriseAdmin
            ? getOneOfSettingType(data.allowUsersToChangeAxeCoreVersion)
            : generalSettings.axeCoreVersion.type,
          options:
            getOneOfSettingType(data.allowUsersToChangeAxeCoreVersion) ===
            SettingTypeEnum.OneOf
              ? data.axeCoreVersionOptions
              : undefined
        },
        needsReview: {
          value: data.needsReview,
          type: isEnterpriseAdmin
            ? getSettingType(data.allowUsersToChangeNeedsReview)
            : generalSettings.needsReview.type
        },
        bestPractices: {
          value: data.bestPractices,
          type: isEnterpriseAdmin
            ? getSettingType(data.allowUsersToChangeBestPractices)
            : generalSettings.bestPractices.type
        },
        experimentalRules: {
          value: data.experimentalRules,
          type: isEnterpriseAdmin
            ? getSettingType(data.allowUsersToChangeExperimentalRules)
            : generalSettings.experimentalRules.type
        },
        issueScreenshotSharing: {
          value: data.issueScreenshotSharing,
          type: isEnterpriseAdmin
            ? getSettingType(data.allowUsersToChangeIssueScreenshotSharing)
            : generalSettings.issueScreenshotSharing.type
        },
        ml: {
          value: data.ml,
          type: isEnterpriseAdmin
            ? getSettingType(data.allowUsersToChangeMl)
            : generalSettings.ml.type
        },
        sharedReportAccessControl: {
          value: data.sharedReportAccessControl,
          type: generalSettings.sharedReportAccessControl.type
        },
        usageService: {
          value: {
            status: data.usageService,
            ...(data.usageService === 'enabled'
              ? {
                  url:
                    data.usageServiceUrl ||
                    generalSettings.usageService.value.url,
                  organization: data.usageServiceOrganization || null,
                  department: data.usageServiceDepartment || null,
                  application: data.usageServiceApplication || null
                }
              : {})
          },
          type: generalSettings.usageService.type
        },
        expiredProductVisibility: {
          value: data.expiredProducts,
          type: generalSettings.expiredProductVisibility.type
        }
      }
    });

    const changedProperties = Object.keys(
      formState.dirtyFields as Partial<GlobalConfigurationForm>
    ).reduce<EventData>((acc, key) => {
      const field = key as keyof InputsWithAnalytics;
      if (field === 'expiredProducts') {
        acc[analyticsProperties[field]] = data[field] === 'all' ? true : false;
      } else if (field in analyticsProperties) {
        acc[analyticsProperties[field]] = Array.isArray(data[field])
          ? (data[field] as unknown[]).join(',')
          : (data[field] as string);
      }

      return acc;
    }, {});

    analytics.configSubmit('global', changedProperties);
  };

  useEffect(() => {
    analytics.configView(isEnterpriseAdmin);
  }, []);

  const isUsageServiceEnabled = watch('usageService') === 'enabled';
  const isAllowUsersToChangeAccessibilityStandard =
    watch('allowUsersToChangeAccessibilityStandard') && isEnterpriseAdmin;
  const isAllowUsersToChangeAxeCoreVersion =
    watch('allowUsersToChangeAxeCoreVersion') && isEnterpriseAdmin;
  const isReadOnly = isEnterpriseMember && !isEnterpriseAdmin;
  const disabled = isReadOnly || saving;
  const hasEN301SupportV1 = useFeatureFlagState('en_301_549_v1');

  const { accessibilityStandardOptions } = useAccessibilityStandardOptions({
    formControl: control,
    hasEN301SupportV1,
    isEnterpriseMember
  });

  useEffect(() => {
    partiallySupportedStandards.forEach(partiallySupportedData => {
      const partiallySupportedOption = accessibilityStandardOptions.find(
        option => option.key === partiallySupportedData.key
      );

      // If partially supported option is disabled as a oneOf option, we want to make sure it gets unselected
      if (
        partiallySupportedOption?.disabled &&
        !partiallySupportedOption?.disableSelectOptionOnly
      ) {
        setValue(
          'accessibilityStandardOptions',
          getValues('accessibilityStandardOptions').filter(
            value => value !== partiallySupportedOption.value
          )
        );
      }
    });
  }, [accessibilityStandardOptions]);

  useEffect(() => {
    const partiallySupportedData = partiallySupportedStandards.find(
      standard => accessibilityStandard === standard.key
    );
    if (
      partiallySupportedData &&
      !doesSupportStandard({
        version: axeCoreVersion,
        minimumVersion: partiallySupportedData.minimumVersion
      })
    ) {
      setValue('accessibilityStandard', recommendedStandard);
    }
  }, [axeCoreVersion, accessibilityStandard]);

  const axeCoreVersionOptions = useMemo(
    (): OptionWithValueOfType<AxeCoreVersion> =>
      axeCoreVersions.map(version => ({
        key: version,
        label: version === 'latest' ? t('Latest (4.x, Recommended)') : version,
        value: version
      })),
    [t]
  );

  const needsReviewOptions = useMemo(
    (): OptionWithValueOfType<NeedsReview> => [
      {
        key: 'enabled',
        label: t('Enable needs review issues and include in auto issue total'),
        value: 'enabled'
      },
      {
        key: 'excluded',
        label: t(
          'Enable needs review issues but exclude from auto issue total'
        ),
        value: 'excluded'
      },
      {
        key: 'disabled',
        label: t('Disabled'),
        value: 'disabled'
      }
    ],
    [t]
  );

  const enabledDisabledOptions = useMemo(
    (): RadioItemWithValueOfType<EnabledDisabled> => [
      {
        label: t('Enable'),
        value: 'enabled'
      },
      {
        label: t('Disable'),
        value: 'disabled'
      }
    ],
    [t]
  );

  const sharedReportAccessControlOptions = useMemo(
    (): OptionWithValueOfType<SharedReportAccessControl> => [
      {
        key: 'anyone',
        label: t('Anyone with the link'),
        value: 'anyone'
      },
      {
        key: 'users',
        label: t('Any logged in user'),
        value: 'users'
      },
      ...(isEnterpriseMember
        ? ([
            {
              key: 'enterprise',
              label: t('Any logged in enterprise user'),
              value: 'enterprise'
            }
          ] as OptionWithValueOfType<SharedReportAccessControl>)
        : [])
    ],
    [t, isEnterpriseMember]
  );

  const expiredProductsOptions = useMemo(
    (): RadioItemWithValueOfType<ExpiredProductVisibility> => [
      {
        label: t('Show all expired products'),
        value: 'all'
      },
      {
        label: t('Hide all expired products'),
        value: 'none'
      }
    ],
    [t]
  );

  const resetUsageServiceURL = () => {
    resetField('usageServiceUrl');
    setFocus('usageServiceUrl');
  };

  const onDiscard = () => {
    analytics.configDiscard('global');
    reset();
  };

  return (
    <Form
      header={t('Global')}
      isDirty={formState.isDirty}
      isReadOnly={isReadOnly}
      onSave={handleSubmit(onSubmit)}
      onDiscard={onDiscard}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.notice}>
          <Notice
            type="info"
            title={t(
              `{{ isEnterpriseAdmin }} will affect the following products, but not all:`,
              {
                isEnterpriseAdmin: isEnterpriseAdmin
                  ? t('Changing settings')
                  : t('Settings')
              }
            )}
          >
            <Trans>
              <ul>
                <li>
                  {{
                    axeExtension: getProductBySlug(
                      ProductSlugs.axeDevToolsExtension
                    )?.name
                  }}
                </li>
                <li>
                  {{
                    axeMobile: getProductBySlug(ProductSlugs.axeDevToolsMobile)
                      ?.name
                  }}{' '}
                  (&ldquo;Usage Service&ldquo; settings only)
                </li>
              </ul>
            </Trans>
          </Notice>
        </div>
        <h3>{t('Rules and Issues Settings')}</h3>

        {isEnterpriseAdmin ? (
          <SelectOneOfSetting
            id="accessibility-standard"
            name="accessibilityStandard"
            allowChangeName="allowUsersToChangeAccessibilityStandard"
            shouldShowAllowChange={isEnterpriseMember}
            groupLabel={t('Accessibility Standard')}
            groupDescription={t(
              'Determine the default Accessibility Standard for your organization to use.'
            )}
            defaultLabel={t('Default Accessibility Standard')}
            tooltip={t(
              'WCAG has three levels of conformance: A, AA, and AAA. Level A refers to the lowest level of conformance (minimum) and Level AAA is the highest (maximum). Trusted Tester v5 is also an option, which is a separate conformance testing process.'
            )}
            options={accessibilityStandardOptions}
            optionsName="accessibilityStandardOptions"
            showOneOfOptions={isAllowUsersToChangeAccessibilityStandard}
            formControl={control}
            disabled={disabled}
          />
        ) : (
          <SelectSetting
            id="default-wcag-level"
            name="accessibilityStandard"
            allowChangeName="allowUsersToChangeAccessibilityStandard"
            shouldShowAllowChange={isEnterpriseMember}
            label={t('Default Accessibility Standard')}
            tooltip={t(
              'WCAG has three levels of conformance: A, AA, and AAA. Level A refers to the lowest level of conformance (minimum) and Level AAA is the highest (maximum). Trusted Tester v5 is also an option, which is a separate conformance testing process.'
            )}
            options={accessibilityStandardOptions}
            formControl={control}
            disabled={disabled}
          />
        )}

        {isEnterpriseAdmin ? (
          <SelectOneOfSetting
            id="axe-version"
            name="axeCoreVersion"
            allowChangeName="allowUsersToChangeAxeCoreVersion"
            shouldShowAllowChange={isEnterpriseMember}
            groupLabel={t('axe-core Version')}
            groupDescription={t(
              'Determine the default axe-core version and available options for your organization to use.'
            )}
            defaultLabel={t('Default axe-core Version')}
            options={axeCoreVersionOptions}
            optionsName="axeCoreVersionOptions"
            showOneOfOptions={isAllowUsersToChangeAxeCoreVersion}
            formControl={control}
            disabled={disabled}
          />
        ) : (
          <SelectSetting
            id="default-axe-core-version"
            name="axeCoreVersion"
            allowChangeName="allowUsersToChangeAxeCoreVersion"
            shouldShowAllowChange={isEnterpriseMember}
            label={t('Default axe-core Version')}
            tooltip={t(
              'Selecting latest will ensure you are always using the version of axe-core with the most rules and latest fixes.'
            )}
            options={axeCoreVersionOptions}
            formControl={control}
            disabled={disabled}
          />
        )}

        <SelectSetting
          id="default-needs-review"
          name="needsReview"
          allowChangeName="allowUsersToChangeNeedsReview"
          shouldShowAllowChange={isEnterpriseMember}
          label={t('Default Needs Review')}
          options={needsReviewOptions}
          formControl={control}
          disabled={disabled}
        />

        <RadioSetting
          id="default-best-practices"
          name="bestPractices"
          allowChangeName="allowUsersToChangeBestPractices"
          shouldShowAllowChange={isEnterpriseMember}
          label={t('Default Best Practices')}
          tooltip={t(
            'Rules that do not necessarily conform to WCAG success criterion but are industry accepted practices that improve the user experience.'
          )}
          options={enabledDisabledOptions}
          formControl={control}
          disabled={disabled}
        />

        <RadioSetting
          id="default-experimental-rules"
          name="experimentalRules"
          allowChangeName="allowUsersToChangeExperimentalRules"
          shouldShowAllowChange={isEnterpriseMember}
          label={t('Default Experimental Rules')}
          tooltip={t(
            'Rules we are still testing and developing. They are disabled by default in axe-core.'
          )}
          options={enabledDisabledOptions}
          formControl={control}
          disabled={disabled}
        />

        <RadioSetting
          id="default-issue-screenshot-sharing"
          name="issueScreenshotSharing"
          allowChangeName="allowUsersToChangeIssueScreenshotSharing"
          shouldShowAllowChange={isEnterpriseMember}
          label={t('Default Shared Issue Screenshots')}
          tooltip={t('Allows for screenshots to be added to shared issues.')}
          options={enabledDisabledOptions}
          formControl={control}
          disabled={disabled}
        />

        <RadioSetting
          id="default-ml"
          name="ml"
          allowChangeName="allowUsersToChangeMl"
          shouldShowAllowChange={isEnterpriseMember}
          label={t('Default Machine Learning')}
          tooltip={t(
            'Some IGTs use Machine Learning (ML) to optimize testing. This will take screenshots and send them to our ML servers to be processed.'
          )}
          options={enabledDisabledOptions}
          formControl={control}
          disabled={disabled}
        />

        <h3>{t('Data Management')}</h3>

        <SelectSetting
          id="shared-report-access-control"
          name="sharedReportAccessControl"
          shouldShowAllowChange={false}
          label={t('Shared Report Access Control')}
          tooltip={t(
            'Controls who can access shared reports like Shared Issues and Shared Saved Test Records.'
          )}
          options={sharedReportAccessControlOptions}
          formControl={control}
          disabled={disabled}
        />

        <RadioSetting
          id="usage-service"
          name="usageService"
          shouldShowAllowChange={false}
          label={t('Usage Service')}
          options={enabledDisabledOptions}
          formControl={control}
          disabled={disabled}
        >
          {isUsageServiceEnabled && (
            <div className={styles.textFieldGroup}>
              <Controller
                control={control}
                name="usageServiceUrl"
                rules={{
                  required: true,
                  validate: value => {
                    try {
                      new URL(value);
                      return true;
                    } catch {
                      return false;
                    }
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    fieldRef={ref}
                    id="usage-service-url"
                    aria-label={t('Usage Service URL')}
                    label={t('URL')}
                    required
                    requiredText={t('Required')}
                    disabled={disabled}
                    error={
                      formState.errors.usageServiceUrl ? (
                        <div className={styles.errorWithReset}>
                          <Trans>
                            <span>A valid URL is required.</span>
                            <Button
                              id="reset-usage-service-url"
                              aria-label={t('Reset Usage Service URL')}
                              variant="link"
                              onClick={resetUsageServiceURL}
                            >
                              Reset
                            </Button>
                          </Trans>
                        </div>
                      ) : undefined
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="usageServiceOrganization"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    fieldRef={ref}
                    id="usage-service-organization"
                    aria-label={t('Usage Service Organization')}
                    label={t('Organization')}
                    disabled={disabled}
                  />
                )}
              />

              <Controller
                control={control}
                name="usageServiceDepartment"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    fieldRef={ref}
                    id="usage-service-department"
                    aria-label={t('Usage Service Department')}
                    label={t('Department')}
                    disabled={disabled}
                  />
                )}
              />

              <Controller
                control={control}
                name="usageServiceApplication"
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    fieldRef={ref}
                    id="usage-service-application"
                    aria-label={t('Usage Service Application')}
                    label={t('Application')}
                    disabled={disabled}
                  />
                )}
              />
            </div>
          )}
        </RadioSetting>

        <h3>{t('Billing and Products')}</h3>
        <RadioSetting
          id="expired-products"
          name="expiredProducts"
          shouldShowAllowChange={false}
          label={t('Expired Products')}
          options={expiredProductsOptions}
          formControl={control}
          disabled={disabled}
          focusRef={focusRefHandler}
        />
      </form>
    </Form>
  );
};

export default GlobalConfiguration;
