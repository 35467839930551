import { apiRequest } from '../../../api-client';

interface DeleteAdminProps {
  token: string;
  enterpriseId: string;
  adminId: string;
}

export default {
  /** Downgrades an enterprise admin to a general access member */
  remove({ token, enterpriseId, adminId }: DeleteAdminProps): Promise<void> {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/admins/${adminId}`,
      method: 'DELETE',
      token
    });
  }
};
