import React, { useLayoutEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Screenshot.css';
import type { BoundingBox } from '../../../common/api-client';
import { getClientDims, scrollElement } from '../../utils/scrollUtils';
import useScreenshot from '../../hooks/useScreenshot';
import useScreenshotMetadata from '../../hooks/useScreenshotMetadata';
import { Loader } from '@deque/cauldron-react';
import { useAuthContext } from '../../../common/contexts/auth';
import { SECURE_SCREENSHOTS_API_FEATURE_FLAG } from '../../../common/constants';
import { useFeatureFlagState } from '../../../common/contexts/featureFlags';

interface ScreenshotProps {
  screenshotId: string;
  boundingBox?: BoundingBox | null;
}

const parentBorder = 3;
const ourBorder = 3;

const Screenshot = ({
  screenshotId,
  boundingBox = { x: 0, y: 0, width: 0, height: 0 }
}: ScreenshotProps): React.ReactElement => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const secureApiFF = useFeatureFlagState(SECURE_SCREENSHOTS_API_FEATURE_FLAG);
  const scrollRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useState<HTMLDivElement | null>(null);
  const {
    loading: screenshotLoading,
    blob,
    error: screenshotError
  } = useScreenshot(screenshotId, secureApiFF ? user?.token : undefined);
  const { loading: metadataLoading, metadata } = useScreenshotMetadata(
    screenshotId,
    secureApiFF ? user?.token : undefined
  );
  const loading = screenshotLoading || metadataLoading;

  const { imageRight, imageBottom, left, top, bottom, right, styleObject } =
    useMemo(() => {
      if (!metadata || !boundingBox) {
        return {
          imageRight: 0,
          imageBottom: 0,
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          styleObject: {}
        };
      }
      const nextImageRight = metadata.width + ourBorder * 2;
      const nextImageBottom = metadata.height + ourBorder * 2;
      const nextLeft = Math.min(
        boundingBox.x + parentBorder,
        metadata.width + parentBorder
      );
      const nextTop = Math.min(
        boundingBox.y + parentBorder,
        metadata.height + parentBorder
      );
      const nextBottom = Math.min(
        boundingBox.y + parentBorder + boundingBox.height + ourBorder * 2,
        nextImageBottom
      );
      const nextRight = Math.min(
        boundingBox.x + parentBorder + boundingBox.width + ourBorder * 2,
        nextImageRight
      );
      const nextStyleObject = {
        minHeight: `${nextBottom - nextTop}px`,
        minWidth: `${nextRight - nextLeft}px`,
        top: `${nextTop}px`,
        left: `${nextLeft}px`
      };
      return {
        imageRight: nextImageRight,
        imageBottom: nextImageBottom,
        left: nextLeft,
        top: nextTop,
        bottom: nextBottom,
        right: nextRight,
        styleObject: nextStyleObject
      };
    }, [metadata, boundingBox]);

  useLayoutEffect(() => {
    if (!scroll) {
      return;
    }
    const dims = getClientDims(scroll);
    if (bottom <= dims.clientHeight && right <= dims.clientWidth) {
      // already in the view
      return;
    }
    const width = right - left;
    const height = bottom - top;
    const halfExtraWidth = Math.max((scroll.clientWidth - width) / 2, 0);
    const halfExtraHeight = Math.max((scroll.clientHeight - height) / 2, 0);
    scrollElement(scroll, {
      left: Math.min(left - halfExtraWidth, imageRight - scroll.clientWidth),
      top: Math.min(top - halfExtraHeight, imageBottom - scroll.clientHeight)
    });
  }, [scroll]);

  useLayoutEffect(() => {
    if (!scrollRef?.current || scroll) {
      return;
    }
    setTimeout(() => {
      setScroll(scrollRef.current);
    }, 100);
  }, [scrollRef.current]);

  React.useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  return loading ? (
    <Loader label={t('Loading screenshot')} tabIndex={-1} ref={loaderRef} />
  ) : blob && !screenshotError ? (
    <div className={styles.imageContainer}>
      <div className={styles.scrollContainer} tabIndex={0} ref={scrollRef}>
        <img
          src={URL.createObjectURL(blob)}
          alt={t('screenshot of the page at the time this issue was generated')}
        />
        {boundingBox && (
          <div className={styles.highlight} style={styleObject}></div>
        )}
      </div>
    </div>
  ) : (
    <p>{t('Could not load the screenshot')}</p>
  );
};

export default Screenshot;
