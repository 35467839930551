import type { Version3Models } from 'jira.js';

import { apiRequest } from '../../../api-client';

export type IssueType = Version3Models.IssueTypeDetails;

export interface ProjectsAndIssueTypes extends Version3Models.Project {
  canCreateIssue: boolean;
}

const getProjectsAndIssueTypes = async ({
  connectionId,
  token
}: {
  connectionId: string;
  token?: string;
}) => {
  const projectsAndIssueTypes = await apiRequest<ProjectsAndIssueTypes[]>({
    endpoint: `/api/integrations/jira/connections/${connectionId}/projects`,
    token
  });

  return (
    projectsAndIssueTypes
      // Filter out issueTypes that have a property 'subtask' set to true
      .map(project => ({
        ...project,
        issueTypes: project.issueTypes?.filter(issueType => !issueType.subtask)
      }))
      // Filter out projects that don't have any issueTypes left after the previous filter or that don't have the canCreateIssue property set to true
      .filter(
        project =>
          project.issueTypes &&
          project.issueTypes.length > 0 &&
          project.canCreateIssue
      )
  );
};

export default getProjectsAndIssueTypes;
