import { findJiraFieldByKey } from './findJiraFieldByKey';
import type { JiraFieldMapper, JiraFieldSchema } from './types';

export class ShortTextMapper implements JiraFieldMapper {
  id = 'jira-short-text-mapper';

  canMapThisField(jiraSchema: JiraFieldSchema[], fieldKey: string): boolean {
    const targetField = findJiraFieldByKey(jiraSchema, fieldKey);

    if (!targetField) {
      return false;
    }

    const { type, custom } = targetField.schema;
    const isCustomType = !!custom;
    const isString = type === 'string';

    return isCustomType
      ? isString &&
          custom ===
            'com.atlassian.jira.plugin.system.customfieldtypes:textfield'
      : isString;
  }

  formatFieldValue(
    jiraSchema: JiraFieldSchema[],
    fieldKey: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sourceValue: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    const maxShortTextLength = 255;
    const fieldName = findJiraFieldByKey(jiraSchema, fieldKey)?.name;

    const formattedValue =
      typeof sourceValue === 'object'
        ? JSON.stringify(sourceValue)
        : String(sourceValue);

    if (formattedValue.length > maxShortTextLength) {
      throw new Error(
        `Can not map to '${fieldName}': mapped value length more than ${maxShortTextLength}`
      );
    }

    return formattedValue;
  }
}
