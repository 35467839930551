import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Button } from '@deque/cauldron-react';

import { useAuthContext } from '../../../common/contexts/auth';
import useAdminFeatureFlags from '../../hooks/useAdminFeatureFlags';
import type { AdminFeatureFlag } from '../../../common/api-client';
import styles from './FeaturesAdmin.css';
import CreateAdminFeatureModal from '../../containers/CreateAdminFeatureFlagModal';
import EditAdminFeatureFlagModal from '../../containers/EditAdminFeatureFlagModal';
import FeatureFlagTableAdmin from '../../components/FeatureFlagsTableAdmin';

const ADMIN_GROUP = window.appConfig.FEATURE_FLAG_ADMIN_GROUP;

if (!ADMIN_GROUP) {
  throw new Error('Missing FEATURE_FLAG_ADMIN_GROUP variable');
}

interface ErrorMessageProps {
  title: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ title, children }) => {
  return (
    <div>
      <details>
        <summary>
          <strong>{title}</strong>
        </summary>
        <pre>
          <code>{children}</code>
        </pre>
      </details>
    </div>
  );
};

const FeaturesAdmin: React.FC = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = useAuthContext().user!;
  const { loading, error, features } = useAdminFeatureFlags(user.token);
  //TODO: add a custom hook to work with modal state
  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const editableIdRef = React.useRef<AdminFeatureFlag | null>(null);
  const loaderRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  const closeCreate = () => setShowCreate(false);
  const closeEdit = () => setShowEdit(false);
  const toggleShowCreate = () => setShowCreate(!showCreate);
  const toggleShowEdit = (feature: AdminFeatureFlag) => {
    editableIdRef.current = feature;
    setShowEdit(!showCreate);
  };

  const isTogglingAllowed = user.groups.includes(ADMIN_GROUP);

  return (
    <div className={styles.page}>
      <h1>{t('Feature Flag Admin Panel')}</h1>

      {!isTogglingAllowed ? (
        <h2>
          {t(
            'You do not have permission to make changes. This page is “readonly”.'
          )}
        </h2>
      ) : null}

      <CreateAdminFeatureModal show={showCreate} onClose={closeCreate} />
      <EditAdminFeatureFlagModal
        feature={editableIdRef.current}
        show={showEdit}
        onClose={closeEdit}
      />

      {loading ? (
        <Loader label={t('Loading features')} tabIndex={-1} ref={loaderRef} />
      ) : error ? (
        <ErrorMessage title={t('Unable to load features')}>
          {error.stack}
        </ErrorMessage>
      ) : (
        <div>
          <FeatureFlagTableAdmin
            featureFlags={features}
            onEditClick={toggleShowEdit}
            isTogglingAllowed={isTogglingAllowed}
          />
          <div className={styles.action}>
            <Button
              type="button"
              onClick={toggleShowCreate}
              disabled={!isTogglingAllowed}
            >
              {t('Create')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeaturesAdmin;
