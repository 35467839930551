import CrossProductAnalytics from '../common/CrossProductAnalytics';
import {
  ConfigBestPractices,
  ConfigNeedsReview,
  Igt,
  Impact
} from '../common/hooks/useIssuesFilter';
import { Standards } from '@deque/accessibility-standards';

export interface SharedTestParams {
  testId?: string;
  isEnterprise: boolean;
  isLoggedIn: boolean;
  isCreator: boolean;
}

export interface UserFlowParams {
  userFlowId?: string;
  isEnterprise: boolean;
  isLoggedIn: boolean;
  isCreator: boolean;
}

export interface SharedTestFilterData {
  igtFilter?: Igt;
  severityFilter?: Impact;
  bestPractices: boolean;
  needsReview: boolean;
  automatic: boolean;
  configBestPractices: ConfigBestPractices;
  configNeedsReview: ConfigNeedsReview;
  configWCAGLevel?: Standards;
  configAccessibilityStandard: Standards;
}

interface WalkthroughData {
  currentStep: number;
}

export enum AxeDevToolsProAmplitudeEvents {
  EXTENSION_UNINSTALL = 'conversion:uninstall',
  VIEW_INSTALL_PAGE = 'conversion:installSuccessPageView',
  VIEW_PLANS_PAGE = 'conversion:plansPageView',
  VIEW_WELCOME_PAGE = 'conversion:welcomePageView',
  CONTACT_SALES_EXPERT_CLICK = 'conversion:contactsalesexperts',
  ANALYSIS_WHATS_LEFT = 'analysis:whatsleft',
  ANALYSIS_WHATS_LEFT_SECTION = 'analysis:whatsLeftSection',
  VIEW_SHARED_TEST = 'test:viewSharedTest',
  SHARED_TEST_COPY_URL = 'test:copySharedTestURL',
  VIEW_SHARED_TEST_ISSUES = 'test:viewSharedTestIssues', // once per page view of entire shared test issue
  VIEW_SHARED_TEST_ISSUE = 'test:viewSharedTestIssue', // once per individual issue view (only fired once navigated to issue other than the default loaded issue)
  SHARED_TEST_ISSUES_EXPORT = 'test:sharedTestIssuesExport',
  SHARED_TEST_OVERVIEW_EXPORT = 'test:sharedTestOverviewExport',
  ISSUE_VIEW_SHARED_ISSUE = 'issue:viewSharedIssue',
  ISSUE_VIEW_DESCRIPTION = 'issue:viewDescription',
  ISSUE_COPY_TEXT = 'issue:copyIssueText',
  WALKTHROUGH_START = 'walkthrough:start',
  WALKTHROUGH_NEXT = 'walkthrough:next',
  WALKTHROUGH_PREVIOUS = 'walkthrough:previous',
  WALKTHROUGH_SKIP = 'walkthrough:skip',
  WALKTHROUGH_COMPLETE = 'walkthrough:complete',
  WALKTHROUGH_STEP_ALREADY_DONE = 'walkthrough:stepAlreadyDone',
  VIEW_UFA_REPORT = 'test:viewUFATest', // once per UFA report view
  UFA_REPORT_COPY_URL = 'test:copyUFATestURL', // once per click of the copy URL button
  VIEW_UFA_REPORT_ISSUES = 'test:viewUFAReportIssues', // once per page view of entire UFA report
  VIEW_UFA_REPORT_ISSUE = 'test:viewUFAReportIssue', // once per individual issue view (only fired once navigated to issue other than the default loaded issue)
  UFA_REPORT_ISSUES_COPY_URL = 'test:copyUFAIssuesURL', // once per click of the copy URL button in issues view
  UFA_REPORT_ISSUE_COPY_URL = 'test:copyUFAIssueURL' // once per click of the "COPY ISSUE LINK" button in issues view
}

export default class AxeDevtoolsProAnalytics extends CrossProductAnalytics<AxeDevToolsProAmplitudeEvents> {
  public extensionUninstalled() {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.EXTENSION_UNINSTALL
    });
  }

  public contactSalesExpertsClicked() {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.CONTACT_SALES_EXPERT_CLICK
    });
  }

  public analysisWhatsLeft(data: { test: 'Page' | 'Page States' }) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.ANALYSIS_WHATS_LEFT,
      data
    });
  }

  public analysisWhatsLeftSection(data: { section: string }) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.ANALYSIS_WHATS_LEFT_SECTION,
      data
    });
  }

  public viewSharedTest(data: SharedTestParams) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.VIEW_SHARED_TEST,
      data
    });
  }

  public sharedTestCopyUrl(
    data: SharedTestParams | (SharedTestParams & SharedTestFilterData)
  ) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.SHARED_TEST_COPY_URL,
      data
    });
  }

  public viewSharedTestIssues(data: SharedTestParams & SharedTestFilterData) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.VIEW_SHARED_TEST_ISSUES,
      data
    });
  }

  public viewSharedTestIssue(data: SharedTestParams & { issueId: string }) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.VIEW_SHARED_TEST_ISSUE,
      data
    });
  }

  public sharedTestIssuesExport(
    data: SharedTestParams & {
      exportFormat: 'csv' | 'json' | 'xml';
      exportSchema: 'axeDevToolsPro' | 'axeDevToolsReporter';
    }
  ) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.SHARED_TEST_ISSUES_EXPORT,
      data
    });
  }

  public issueViewSharedIssue(data: { ruleName: string }) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.ISSUE_VIEW_SHARED_ISSUE,
      data
    });
  }

  public issueViewDescriptionClicked(data: { ruleName: string }) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.ISSUE_VIEW_DESCRIPTION,
      data
    });
  }

  public issueCopyTextClicked(data: { ruleName: string }) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.ISSUE_COPY_TEXT,
      data
    });
  }

  public sharedTestOverviewExport() {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.SHARED_TEST_OVERVIEW_EXPORT
    });
  }

  public walkthroughStart() {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.WALKTHROUGH_START
    });
  }

  public walkthroughNext(data: WalkthroughData) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.WALKTHROUGH_NEXT,
      data
    });
  }

  public walkthroughPrevious(data: WalkthroughData) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.WALKTHROUGH_PREVIOUS,
      data
    });
  }

  public walkthroughSkip(data: WalkthroughData) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.WALKTHROUGH_SKIP,
      data
    });
  }

  public walkthroughStepAlreadyDone(data: WalkthroughData) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.WALKTHROUGH_STEP_ALREADY_DONE,
      data
    });
  }

  public walkthroughComplete() {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.WALKTHROUGH_COMPLETE
    });
  }

  public viewUfaReport(data: SharedTestParams) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.VIEW_UFA_REPORT,
      data
    });
  }

  public ufaReportCopyUrlClick(data: SharedTestParams) {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.UFA_REPORT_COPY_URL,
      data
    });
  }

  public viewUfaReportIssues() {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.VIEW_UFA_REPORT_ISSUES
    });
  }

  public viewUfaReportIssue() {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.VIEW_UFA_REPORT_ISSUE
    });
  }

  public ufaReportIssuesCopyUrlClick() {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.UFA_REPORT_ISSUES_COPY_URL
    });
  }

  public ufaReportIssueCopyUrlClick() {
    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.UFA_REPORT_ISSUE_COPY_URL
    });
  }

  public async viewInstallPage(href: string) {
    const data = await this.processDistinctIdCookieData(
      href,
      'organic-install-success'
    );

    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.VIEW_INSTALL_PAGE,
      data
    });
  }

  public async viewPlansPage(href: string) {
    const data = await this.processDistinctIdCookieData(
      href,
      'webapp_organic_plans'
    );

    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.VIEW_PLANS_PAGE,
      data
    });
  }

  public async viewWelcomePage(href: string) {
    const data = await this.processDistinctIdCookieData(
      href,
      'webapp_organic_welcome'
    );

    return this.sendAmplitudeEvent({
      event: AxeDevToolsProAmplitudeEvents.VIEW_WELCOME_PAGE,
      data
    });
  }
}
