import type { v2 } from '@deque/billing-service-client';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Offscreen } from '@deque/cauldron-react';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';

import Products from './Products';
import ProductCard from './V2ProductCard';
import styles from './AvailableProducts.css';
import {
  PRODUCT_URLS,
  getProductUrl,
  ProductSlugs
} from '../../common/constants';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { AXE_WATCHER_PAID_PLANS_V1 } from '../../axe-watcher/constants';

export interface AvailableProductsProps {
  availableProducts: v2.Product[];
  isEnterprise?: boolean;
  isAdmin?: boolean;
  enterpriseAdmins?: string[];
}

type ProductURLsKeys = 'plans' | 'docs' | 'docsAnalyzer' | 'docsSDK' | 'site';

export const getProductResources = (
  t: TFunction,
  availableProducts: v2.Product[]
) => {
  const linkKeys: ProductURLsKeys[] = [
    'plans',
    'docs',
    'docsAnalyzer',
    'docsSDK',
    'site'
  ];
  const resources: Partial<
    Record<
      ProductSlugs,
      { name: string; url: string; openInNewTab?: boolean }[]
    >
  > = {};

  for (const product of availableProducts) {
    resources[product.slug as ProductSlugs] = [];
    const productUrls = PRODUCT_URLS[product.slug as keyof typeof PRODUCT_URLS];

    for (const linkKey of linkKeys) {
      let linkName;
      switch (linkKey) {
        case 'plans':
          linkName = t(`{{productName}} Plans`, {
            productName: product.name
          });
          break;
        case 'docs':
          linkName = t(`{{productName}} Docs`, {
            productName: product.name
          });
          break;
        case 'docsAnalyzer':
          linkName = t(`{{productName}} Analyzer Docs`, {
            productName: product.name
          });
          break;
        case 'docsSDK':
          linkName = t(`{{productName}} SDK Docs`, {
            productName: product.name
          });
          break;
        case 'site':
          linkName = t(`{{productName}} Site`, {
            productName: product.name
          });
          break;
      }

      if (productUrls && linkKey in productUrls) {
        const productUrl = productUrls[
          linkKey as keyof typeof productUrls
        ] as string;
        resources[product.slug as ProductSlugs]?.push({
          name: linkName,
          url: productUrl,
          openInNewTab: productUrl.startsWith('http')
        });
      }
    }
  }

  return resources;
};

type ProductAdditionalField = {
  description?: string;
};

const prepareProductFieldsBySlug =
  (t: TFunction) =>
  (product: v2.Product): ProductAdditionalField => {
    const productsFields: Partial<
      Record<ProductSlugs, Partial<ProductAdditionalField>>
    > = {
      'axe-devtools-pro': {
        description: t(
          'Unlock the full power of the best accessibility testing browser extension. Start immediately, find issues, and learn how to fix them.'
        )
      },
      'axe-devtools-linter': {
        description: t(
          'Static code analysis stops accessibility bugs immediately. Scan and block bad code in {{gh}} pull requests and CI/CD systems.',
          { gh: 'GitHub' }
        )
      },
      'axe-devtools-mobile': {
        description: t(
          'Test any app on {{iOS}}, {{android}}, {{reactNative}}, or other cross-platform technology. No SDK or code access required.',
          { iOS: 'iOS', android: 'Android', reactNative: 'React Native' }
        )
      },
      'axe-devtools-watcher': {
        description: t(
          'Integrate into your existing end-to-end tests easily. Connect with {{cypress}}, {{playwright}}, {{puppetteer}}, {{wdIO}}, and more.',
          {
            cypress: 'Cypress',
            playwright: 'Playwright',
            puppetteer: 'Puppeteer',
            wdIO: 'WebDriverIO'
          }
        )
      }
    };
    const productFields = productsFields[product.slug as ProductSlugs];
    return {
      description: productFields?.description
    };
  };

const AvailableProducts = ({
  availableProducts,
  isEnterprise = false,
  isAdmin = false,
  enterpriseAdmins = []
}: AvailableProductsProps): ReactElement => {
  const { t } = useTranslation();
  const hasAxeWatcherPaidPlans = useFeatureFlagState(AXE_WATCHER_PAID_PLANS_V1);
  const resources = getProductResources(t, availableProducts);
  const getProductFieldsBySlug = prepareProductFieldsBySlug(t);
  return (
    <Products
      heading={t('Available Products')}
      helperText={t(
        'These are products for which you do not have an active trial or subscription'
      )}
    >
      {availableProducts.map(product => {
        const trialUrl = getProductUrl(product.slug as ProductSlugs, 'trial');
        let subtitle, linkText;

        if (trialUrl) {
          subtitle = t('Try for free');
          linkText =
            product.slug === ProductSlugs.axeDevToolsWatcher &&
            hasAxeWatcherPaidPlans
              ? t('Start Free Plan')
              : t('Start Free Trial');
        } else {
          subtitle = t('Access NOW');
        }
        const { description } = getProductFieldsBySlug(product);
        const title = product.name;

        return (
          <ProductCard
            key={product.id}
            title={title}
            description={description}
            subtitle={subtitle}
            resources={resources[product.slug as ProductSlugs]}
          >
            {!isEnterprise || (isEnterprise && isAdmin) ? (
              <div className={styles.ctaButtonWrap}>
                <div className={styles.ctaButtonContainer}>
                  {trialUrl && (
                    <Link
                      to={`${trialUrl}?utm_source=webapp_products&utm_medium=referral&utm_campaign=${product.slug}`}
                      className={`${styles.ctaButton} Button--secondary`}
                    >
                      {linkText}
                      <Offscreen> {title}</Offscreen>
                    </Link>
                  )}
                </div>
              </div>
            ) : (
              <>
                <p className={styles.admins}>
                  {t(
                    'Ask your enterprise admin to start a trial for your organization:'
                  )}
                </p>
                <ul>
                  {enterpriseAdmins.map(admin => (
                    <li key={admin}>{admin}</li>
                  ))}
                </ul>
              </>
            )}
          </ProductCard>
        );
      })}
    </Products>
  );
};

export default AvailableProducts;
