import { ProductSlugs } from '@deque/billing-utils';

import AxeAccountAnalytics from './AxeAccountAnalytics';
import AxeDevtoolsProAnalytics from '../axe-devtools/AxeDevtoolsProAnalytics';
import AxeDevtoolsWatcherAnalytics from '../axe-watcher/AxeDevtoolsWatcherAnalytics';
import AxeDevtoolsMobileAnalytics from '../axe-devtools-mobile/AxeDevToolsMobileAnalytics';
import AxeSpiderAnalytics from '../axe-spider/Analytics';
import CrossProductAnalytics from './CrossProductAnalytics';

const {
  AMPLITUDE_API_KEY_AXE_ACCOUNT,
  AMPLITUDE_API_KEY_AXE_DEVTOOLS_PRO,
  AMPLITUDE_API_KEY_AXE_DEVTOOLS_WATCHER,
  AMPLITUDE_API_KEY_AXE_DEVTOOLS_LINTER,
  AMPLITUDE_API_KEY_AXE_DEVTOOLS_MOBILE,
  AMPLITUDE_API_KEY_AXE_SPIDER,
  GTM_ID
} = window.appConfig;

const analyticsInstances = {
  'axe-account': new AxeAccountAnalytics({
    instanceId: 'axe-account',
    amplitudeApiKey: AMPLITUDE_API_KEY_AXE_ACCOUNT,
    gtmId: GTM_ID
  }),
  [ProductSlugs.axeDevToolsWatcher]: new AxeDevtoolsWatcherAnalytics({
    instanceId: ProductSlugs.axeDevToolsWatcher,
    amplitudeApiKey: AMPLITUDE_API_KEY_AXE_DEVTOOLS_WATCHER
  }),
  [ProductSlugs.axeDevToolsExtension]: new AxeDevtoolsProAnalytics({
    instanceId: ProductSlugs.axeDevToolsExtension,
    amplitudeApiKey: AMPLITUDE_API_KEY_AXE_DEVTOOLS_PRO
  }),
  [ProductSlugs.axeLinter]: new CrossProductAnalytics({
    instanceId: ProductSlugs.axeLinter,
    amplitudeApiKey: AMPLITUDE_API_KEY_AXE_DEVTOOLS_LINTER
  }),
  [ProductSlugs.axeDevToolsMobile]: new AxeDevtoolsMobileAnalytics({
    instanceId: ProductSlugs.axeDevToolsMobile,
    amplitudeApiKey: AMPLITUDE_API_KEY_AXE_DEVTOOLS_MOBILE
  }),
  [ProductSlugs.axeSpider]: new AxeSpiderAnalytics({
    instanceId: ProductSlugs.axeSpider,
    amplitudeApiKey: AMPLITUDE_API_KEY_AXE_SPIDER
  })
};

export type AnalyticsInstances = typeof analyticsInstances;

export type AnalyticsInstanceId = keyof AnalyticsInstances;

export const isAnalyticsInstanceId = (
  analyticsSlug: unknown
): analyticsSlug is AnalyticsInstanceId =>
  typeof analyticsSlug === 'string' &&
  Object.keys(analyticsInstances).includes(analyticsSlug);

export const getFallbackAnalyticsInstanceId = (analyticsSlug: unknown) =>
  isAnalyticsInstanceId(analyticsSlug) ? analyticsSlug : 'axe-account';

export const getCrossProductAnalytics = (analyticsSlug: unknown) =>
  analyticsInstances[getFallbackAnalyticsInstanceId(analyticsSlug)];

export default analyticsInstances;
