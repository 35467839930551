import { useCallback, useEffect, useMemo, useState } from 'react';

interface PaginationOptions<T> {
  items: T[];
  itemsPerPage?: number;
  initialPage?: number;
  totalItems?: number;
}

export interface Pagination<T> {
  currentPageItems: T[];
  totalItems: number;
  itemRangeStart: number;
  itemRangeEnd: number;
  currentPage: number;
  itemsPerPage: number;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  handleFirstPage: () => void;
  handleLastPage: () => void;
}

const usePagination = <T>({
  items,
  itemsPerPage = 5,
  initialPage = 1,
  totalItems = items.length
}: PaginationOptions<T>): Pagination<T> => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const itemRangeStart = Math.max(
    currentPage * itemsPerPage - itemsPerPage + 1,
    0
  );
  const itemRangeEnd = Math.min(itemRangeStart + itemsPerPage - 1, totalItems);

  const currentPageItems = useMemo(
    () => items.slice(itemRangeStart - 1, itemRangeEnd),
    [items, itemRangeStart, itemRangeEnd]
  );

  const handleNextPage = useCallback(() => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  }, [currentPage, totalPages]);

  const handlePreviousPage = useCallback(() => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  }, [currentPage]);

  const handleFirstPage = useCallback(() => {
    setCurrentPage(1);
  }, []);

  const handleLastPage = useCallback(() => {
    setCurrentPage(totalPages);
  }, [totalPages]);

  // make sure initialPage is set as currentPage when hook rerenders
  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    if (currentPage > totalPages) {
      handleLastPage();
    } else if (currentPage === 0 && totalPages > 0) {
      handleFirstPage();
    }
  }, [totalPages, handleLastPage, handleFirstPage]);

  return {
    currentPageItems,
    totalItems,
    itemRangeStart,
    itemRangeEnd,
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleFirstPage,
    handleLastPage
  };
};

export default usePagination;
