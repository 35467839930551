import { Subscription } from '@deque/billing-utils';
import { v2 } from '@deque/billing-service-client';

import { apiRequest } from '../../../api-client';

interface CancelSubscriptionProps {
  token: string;
  subscription: Subscription;
  periodEnd?: boolean;
}

const isEnterpriseSubscription = (
  subscription: Subscription
): subscription is v2.EnterpriseSubscription =>
  'enterprise_id' in subscription && 'license_count' in subscription;

export default {
  cancel({
    token,
    subscription,
    periodEnd
  }: CancelSubscriptionProps): Promise<unknown> {
    let endpoint: string;
    const periodEndQuery = periodEnd ? '?periodEnd=true' : '';
    if (isEnterpriseSubscription(subscription)) {
      endpoint = `/api/billing/v2/enterprises/${subscription.enterprise_id}/subscriptions/${subscription.id}${periodEndQuery}`;
    } else {
      endpoint = `/api/billing/v2/me/subscriptions/${subscription.id}${periodEndQuery}`;
    }

    return apiRequest({
      endpoint,
      method: 'DELETE',
      token
    });
  }
};
