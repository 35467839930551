import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../common/components/PageTitle';

import { EditVariant } from '../types';
import { useAuthContext } from '../../common/contexts/auth';
import { useEnterprises } from '../../common/contexts/enterprises';

import { Team } from '../../common/api-client';
import billingClient from '../../common/utils/billing-client/client-v2';

import TeamSettings from '../containers/TeamSettings';
import globalStyles from '../app.css';

const CreateTeam: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { activeEnterprise } = useEnterprises();

  // There will always be a user with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  // TODO: When multi-enterprise is supported, we should fetch the appropriate enterprise_id
  // There should always be an active enterprise because of <Protected isEnterpriseAdmin>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const enterpriseId = activeEnterprise!.id;

  const initialTeam: Team = {
    name: '',
    user_ids: [],
    user_ids_to_add: [],
    user_ids_to_remove: []
  };

  const submit = async (team: Team) => {
    const created_team = await billingClient.teams.create({
      token,
      enterpriseId,
      name: team.name
    });

    if (team.user_ids_to_add.length) {
      await billingClient.teamMemberships.create({
        token,
        enterpriseId,
        id: created_team.id,
        keycloakIds: team.user_ids_to_add
      });
    }
  };

  return (
    <div className={globalStyles.wrap}>
      <PageTitle title={t('Create New Team')} />
      <TeamSettings
        variant={EditVariant.create}
        initialTeam={initialTeam}
        submit={submit}
      />
    </div>
  );
};

export default CreateTeam;
