import {
  INTEGRATION_PRODUCT_SLUGS,
  ProductSlugs,
  SupportedIntegrationProductSlugs
} from '../../common/constants';
import { useFeatureFlags } from '../../common/contexts/featureFlags';
import { useEnterprises } from '../../common/contexts/enterprises';
import { getControllingProductSubscription } from '@deque/billing-utils';
import { v2 } from '@deque/billing-service-client';
import { toError } from '../../common/utils/toError';

export interface UseIntegrationsState {
  enabledIntegrationProductSlugs: SupportedIntegrationProductSlugs[];
  canAccessIntegrationConfiguration: boolean;
  activeEnterprise: v2.Enterprise | null;
  isEnterpriseAdmin: boolean;
  loading: boolean;
  error: Error | null;
}

const createEarlyReturn = (
  error: Error | string | null,
  loading: boolean
): UseIntegrationsState => ({
  enabledIntegrationProductSlugs: [],
  canAccessIntegrationConfiguration: false,
  activeEnterprise: null,
  isEnterpriseAdmin: false,
  loading: loading,
  error: toError(error)
});

export const useIntegrations = (): UseIntegrationsState => {
  const {
    loading: areFeatureFlagsLoading,
    loadError: featureFlagsLoadError,
    featureFlags
  } = useFeatureFlags();

  const {
    activeEnterprise,
    isAdmin: isEnterpriseAdmin,
    loading: areEnterprisesLoading,
    error: enterpriseLoadError
  } = useEnterprises();

  // Return early if loading or in case of an error
  if (areFeatureFlagsLoading || areEnterprisesLoading) {
    return createEarlyReturn(null, true);
  }
  if (featureFlagsLoadError) {
    return createEarlyReturn(featureFlagsLoadError, false);
  }
  if (enterpriseLoadError) {
    return createEarlyReturn(enterpriseLoadError, false);
  }

  if (!activeEnterprise) {
    return createEarlyReturn(null, false);
  }

  const hasIntegrationsV1 = featureFlags.some(
    flag => flag.id === 'integrations_v1' && flag.state === true
  );
  const enabledIntegrationProductSlugs: SupportedIntegrationProductSlugs[] =
    INTEGRATION_PRODUCT_SLUGS.filter(
      slug =>
        getControllingProductSubscription(
          activeEnterprise,
          slug as unknown as ProductSlugs
        )?.purchase_state === 'paid'
    );
  const canAccessIntegrationConfiguration =
    enabledIntegrationProductSlugs.length > 0 && hasIntegrationsV1;

  return {
    enabledIntegrationProductSlugs,
    canAccessIntegrationConfiguration,
    activeEnterprise,
    isEnterpriseAdmin,
    loading: false,
    error: null
  };
};
