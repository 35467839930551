import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../common/components/PageTitle';
import globalStyles from '../app.css';
import TeamList from '../containers/TeamList';

import { useEnterprises } from '../../common/contexts/enterprises';
import { useAuthContext } from '../../common/contexts/auth';

const ViewTeams: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { activeEnterprise } = useEnterprises();

  // There will always be a user with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  // TODO: When multi-enterprise is supported, we should fetch the appropriate enterprise_id
  // There should always be an active enterprise because of <Protected isEnterpriseAdmin>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const enterpriseId = activeEnterprise!.id;

  return (
    <div className={globalStyles.wrap}>
      <PageTitle title={t('Teams')} />
      <TeamList token={token} enterpriseId={enterpriseId} />
    </div>
  );
};

export default ViewTeams;
