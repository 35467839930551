import React, { ReactElement } from 'react';
import { StripeTypes, v2 } from '@deque/billing-service-client';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionListItem,
  DescriptionTerm,
  IconButton,
  Pagination,
  Panel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@deque/cauldron-react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import usePagination from '../../common/hooks/usePagination';
import useSorter from '../hooks/useSorter';
import { formatPrices } from '@deque/billing-utils';
import { Link } from 'react-router-dom';
import styles from './PaymentInformation.css';

interface PaymentInformationProps {
  invoices: StripeTypes.Invoice[];
  products: v2.Product[];
  hasOfflineSubscription: boolean;
  narrow: boolean;
}

interface ViewInvoiceActionProps {
  invoice: StripeTypes.Invoice;
}

const getFormattedCreatedDate = (invoice: StripeTypes.Invoice) => {
  const d = new Date(invoice.created * 1000);
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
};

const getProductName = (invoice: StripeTypes.Invoice, products: v2.Product[]) =>
  products.find(
    product => product.stripe_id === invoice.lines.data[0]?.price?.product
  )?.name;

const getFormattedTotal = (invoice: StripeTypes.Invoice) =>
  `${formatPrices(invoice.total / 100)} ${invoice.currency.toUpperCase()}`;

const getPaymentStatus = (invoice: StripeTypes.Invoice, t: TFunction) =>
  invoice.paid ? t('Paid') : t('Unpaid');

const ViewInvoiceAction = ({
  invoice
}: ViewInvoiceActionProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <IconButton
      icon="magnifying-glass"
      label={t('View invoice ({{number}}). Invoice opens in a new tab.', {
        number: invoice.number
      })}
      as={Link}
      target="_blank"
      rel="noopener noreferrer"
      to={`/billing/invoices/${invoice.id}`}
      className="IconButton IconButton--secondary"
    />
  );
};

const PaymentInformation = ({
  invoices,
  products,
  hasOfflineSubscription,
  narrow
}: PaymentInformationProps): ReactElement | null => {
  const { t } = useTranslation();
  const sorter = useSorter({
    items: invoices,
    initialSortBy: 'created' as string,
    sortHandlers: {
      payment_date: (a, b) => a.created - b.created,
      amount: (a, b) => a.total - b.total
    }
  });
  const pagination = usePagination({ items: sorter.sortedItems });

  return (
    <Panel
      className={styles.wrapper}
      collapsed={narrow}
      aria-labelledby="invoices_heading"
    >
      <h2
        id="invoices_heading"
        aria-describedby={
          hasOfflineSubscription ? 'offline_invoice_footnote' : undefined
        }
      >
        {t('Invoices')}
        {hasOfflineSubscription && '*'}
      </h2>
      {invoices.length > 0 && (
        <div className={styles.invoices}>
          {!narrow && (
            <Table data-testid="invoice-table">
              <TableHead>
                <TableRow>
                  <TableHeader
                    scope="col"
                    sortDirection={
                      sorter.sortBy === 'payment_date'
                        ? sorter.sortDirection
                        : 'none'
                    }
                    sortAscendingAnnouncement={t('sorted ascending')}
                    sortDescendingAnnouncement={t('sorted descending')}
                    onSort={() => sorter.updateSortBy('payment_date')}
                  >
                    {t('Payment Date')}
                  </TableHeader>
                  <TableHeader
                    scope="col"
                    sortAscendingAnnouncement={t('sorted ascending')}
                    sortDescendingAnnouncement={t('sorted descending')}
                    sortDirection={
                      sorter.sortBy === 'amount' ? sorter.sortDirection : 'none'
                    }
                    onSort={() => sorter.updateSortBy('amount')}
                  >
                    {t('Amount')}
                  </TableHeader>
                  <TableHeader scope="col">{t('Product')}</TableHeader>
                  <TableHeader scope="col">{t('Status')}</TableHeader>
                  <TableHeader scope="col">{t('Actions')}</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagination.currentPageItems.map(invoice => (
                  <TableRow key={invoice.id}>
                    <TableCell>{getFormattedCreatedDate(invoice)}</TableCell>
                    <TableCell>{getFormattedTotal(invoice)}</TableCell>
                    <TableCell>{getProductName(invoice, products)}</TableCell>
                    <TableCell>{getPaymentStatus(invoice, t)}</TableCell>
                    <TableCell>
                      <ViewInvoiceAction invoice={invoice} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {narrow && (
            <>
              {pagination.currentPageItems.map(invoice => (
                <DescriptionList
                  key={invoice.id}
                  collapsed
                  data-testid="invoice-dl"
                >
                  <DescriptionListItem>
                    <DescriptionTerm>{t('Payment date')}</DescriptionTerm>
                    <DescriptionDetails>
                      {getFormattedCreatedDate(invoice)}
                    </DescriptionDetails>
                  </DescriptionListItem>
                  <DescriptionListItem>
                    <DescriptionTerm>{t('Amount')}</DescriptionTerm>
                    <DescriptionDetails>
                      {getFormattedTotal(invoice)}
                    </DescriptionDetails>
                  </DescriptionListItem>
                  <DescriptionListItem>
                    <DescriptionTerm>{t('Product')}</DescriptionTerm>
                    <DescriptionDetails>
                      {getProductName(invoice, products)}
                    </DescriptionDetails>
                  </DescriptionListItem>
                  <DescriptionListItem>
                    <DescriptionTerm>{t('Status')}</DescriptionTerm>
                    <DescriptionDetails>
                      {getPaymentStatus(invoice, t)}
                    </DescriptionDetails>
                  </DescriptionListItem>
                  <DescriptionListItem>
                    <DescriptionTerm>{t('Actions')}</DescriptionTerm>
                    <DescriptionDetails>
                      <ViewInvoiceAction invoice={invoice} />
                    </DescriptionDetails>
                  </DescriptionListItem>
                </DescriptionList>
              ))}
            </>
          )}
        </div>
      )}

      {!invoices.length && <p>{t('You do not have any invoices.')}</p>}

      {hasOfflineSubscription && (
        <p
          id="offline_invoice_footnote"
          className={styles.offlineInvoiceFootnote}
        >
          *{t('Invoices not shown for offline purchases')}
        </p>
      )}

      {invoices.length > 0 && (
        <Pagination
          totalItems={pagination.totalItems}
          itemsPerPage={pagination.itemsPerPage}
          currentPage={pagination.currentPage}
          statusLabel={
            <span>
              <Trans>
                {{ showing: !narrow ? t('Showing ') : '' }}
                <strong>{{ start: pagination.itemRangeStart }}</strong> -{' '}
                <strong>{{ end: pagination.itemRangeEnd }}</strong> of{' '}
                <strong>{{ total: pagination.totalItems }}</strong>
              </Trans>
            </span>
          }
          onNextPageClick={pagination.handleNextPage}
          onFirstPageClick={pagination.handleFirstPage}
          onPreviousPageClick={pagination.handlePreviousPage}
          onLastPageClick={pagination.handleLastPage}
        />
      )}
    </Panel>
  );
};

export default PaymentInformation;
