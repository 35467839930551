import type { v2 } from '@deque/billing-service-client';
import CrossProductAnalytics from '../common/CrossProductAnalytics';
import type { Automation } from './constants';
import type { ExportFormat } from './constants';

export enum AmplitudeEvents {
  VIEWED_CREATE_PROJECT_PAGE = 'page:create',
  CREATED_PROJECT = 'project:create',
  VIEWED_INSTRUCTIONS_PAGE = 'page:completedInstructions',
  VIEWED_PROJECTS_PAGE = 'page:projects',
  VIEWED_BRANCHES_PAGE = 'page:branches',
  SHARED_PAGE = 'share',
  VIEWED_COMMITS_PAGE = 'page:commits',
  VIEWED_GITLESS_PAGE = 'page:gitless',
  VIEWED_ISSUES_PAGE = 'page:issues',
  EXPORT_ISSUES_PAGE = 'page:exportIssues'
}

class AxeDevtoolsWatcherAnalytics extends CrossProductAnalytics<AmplitudeEvents> {
  private enterprise: v2.Enterprise | null = null;

  public setEnterprise(enterprise: v2.Enterprise) {
    this.enterprise = enterprise;
  }

  public viewedCreateProjectPage(referrer: string) {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.VIEWED_CREATE_PROJECT_PAGE,
      data: { ...this.defaultData, referrer }
    });
  }

  /**
   * @param platform Which platform was the project created for?
   */

  public createdProject(platform: Automation) {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.CREATED_PROJECT,
      data: { ...this.defaultData, platform }
    });
  }

  /**
   * @param projectId Project Id
   */

  public viewedInstructionsPage(projectId: string) {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.VIEWED_INSTRUCTIONS_PAGE,
      data: { projectId, ...this.defaultData }
    });
  }

  public viewedProjectsPage() {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.VIEWED_PROJECTS_PAGE,
      data: { ...this.defaultData }
    });
  }

  /**
   * @param owner Is the viewer of the page the owner of the project?
   * @param projectId Project Id
   */

  public viewedBranchesPage(owner: boolean, projectId: string) {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.VIEWED_BRANCHES_PAGE,
      data: { owner, projectId, ...this.defaultData }
    });
  }

  /**
   * @param page Which page was shared?
   * @param projectId Project Id
   * @param whole Was the entire record shared?
   */

  public sharedPage(page: string, projectId: string, whole?: boolean) {
    const data: Record<string, unknown> = {
      page,
      projectId,
      ...this.defaultData
    };

    if (whole !== undefined) {
      data.whole = whole;
    }

    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.SHARED_PAGE,
      data
    });
  }

  /**
   * @param owner Is the viewer of the page the owner of the project?
   * @param projectId Project Id
   */

  public viewedCommitsPage(owner: boolean, projectId: string) {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.VIEWED_COMMITS_PAGE,
      data: { owner, projectId, ...this.defaultData }
    });
  }

  /**
   * @param owner Is the viewer of the page the owner of the project?
   * @param projectId Project Id
   */

  public viewedGitlessPage(owner: boolean, projectId: string) {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.VIEWED_GITLESS_PAGE,
      data: { owner, projectId, ...this.defaultData }
    });
  }

  /**
   * @param owner Is the viewer of the page the owner of the project?
   * @param whole Was the entire record shared?
   * @param projectId Project Id
   */

  public viewedIssuesPage(owner: boolean, whole: boolean, projectId: string) {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.VIEWED_ISSUES_PAGE,
      data: { owner, whole, projectId, ...this.defaultData }
    });
  }

  public exportIssues(projectId: string, format: ExportFormat) {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.EXPORT_ISSUES_PAGE,
      data: { projectId, format, ...this.defaultData }
    });
  }

  private get defaultData() {
    if (!this.enterprise) {
      return {};
    }

    return {
      // We want `organization` set, but that's handled by the base class (in identify).
      rootGroup: this.enterprise.root_group
    };
  }
}

export default AxeDevtoolsWatcherAnalytics;
