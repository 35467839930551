import type { v2 } from '@deque/billing-service-client';
import { Subscription } from '@deque/billing-utils';
import {
  isSubscriptionExpired,
  HIDDEN_USER_MANAGEMENT_PRODUCTS,
  ProductSlugs
} from '../../common/constants';

// NOTE: Subscriptions to pre-8.0 monitor instances have their `instance_url` set to null
// Those subscriptions should be filtered out to prevent pre-8.0 monitor customers utilizing axe account user management
export const isUnmigratedMonitorSubscription = (subscription: Subscription) => {
  return (
    subscription.product_slug === ProductSlugs.axeMonitor &&
    !(subscription as v2.EnterpriseSubscription).instance_url
  );
};

export const getHiddenProductSlugsFromSubs = (
  subs: Subscription[],
  getProductBySlug: (slug: string) => v2.Product | undefined,
  excludedProductSlugs: ProductSlugs[] = [] //explicitly added product slugs to hide
): ProductSlugs[] => {
  return subs.reduce((acc, sub) => {
    const product = getProductBySlug(sub.product_slug);
    if (
      (product &&
        ((isSubscriptionExpired(sub) && !product.has_free_tier) ||
          HIDDEN_USER_MANAGEMENT_PRODUCTS.includes(
            product.slug as ProductSlugs
          ) ||
          isUnmigratedMonitorSubscription(sub))) ||
      (excludedProductSlugs.length &&
        excludedProductSlugs.includes(product?.slug as ProductSlugs))
    ) {
      acc.push(product?.slug as ProductSlugs);
    }
    return acc;
  }, [] as ProductSlugs[]);
};
