import React, { useEffect } from 'react';
import type { v2 } from '@deque/billing-service-client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LoaderOverlay } from '@deque/cauldron-react';
import { useAuthContext } from '../../common/contexts/auth';
import billingClient from '../../common/utils/billing-client/client-v2';
import { useGlobalToast } from '../../common/contexts/globalToast';

interface AddUserProgressProps {
  emails: string[];
  enterprise: v2.Enterprise;
  product?: v2.Product;
}

export interface AddUserFailedActionState {
  action?: string;
  failedUsers: string[];
  failedReasons: string[];
}

const AddUserProgress = ({
  emails,
  product,
  enterprise
}: AddUserProgressProps): JSX.Element => {
  const { setContents } = useGlobalToast();
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  // There will always be a user and with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  const failedUsers: string[] = [];
  const failedReasons: string[] = [];

  useEffect(() => {
    (async () => {
      const sendInvitation = (email: string) => {
        if (product) {
          return billingClient.enterpriseInvitations.create({
            token,
            enterpriseId: enterprise.id,
            productSlug: product.slug,
            email
          });
        } else {
          return billingClient.enterpriseInvitations.addMember({
            token,
            enterpriseId: enterprise.id,
            email,
            isAdmin: false
          });
        }
      };

      for (const email of emails) {
        try {
          await sendInvitation(email);
        } catch (ex) {
          failedUsers.push(email);
          failedReasons.push((ex as Error).message || t('Unknown Error.'));
        }
      }

      const failureState: AddUserFailedActionState = {
        action: failedUsers.length ? 'add-users' : undefined,
        failedUsers,
        failedReasons: Array.from(new Set(failedReasons))
      };
      const addedUsersCount = emails.length - failedUsers.length;

      if (addedUsersCount) {
        if (product) {
          setContents(
            addedUsersCount === 1
              ? t('1 user was successfully added to {{ productName }}.', {
                  productName: product.name
                })
              : t(
                  '{{ count }} users were successfully added to {{ productName }}.',
                  { count: addedUsersCount, productName: product.name }
                ),
            'confirmation'
          );
        } else {
          setContents(
            addedUsersCount === 1
              ? t('1 user was successfully added to your axe Account.')
              : t(
                  '{{ count }} users were successfully added to your axe Account.',
                  { count: addedUsersCount }
                ),
            'confirmation'
          );
        }
      }

      history.push('/user-access', failureState);
    })();
  }, []);

  return (
    <div>
      <LoaderOverlay focusOnInitialRender>
        <h2>{t('Saving changes...')}</h2>
        <p>{t('Just a moment while we save the changes to your users.')}</p>
      </LoaderOverlay>
    </div>
  );
};

export default AddUserProgress;
