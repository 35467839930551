import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../common/components/PageTitle';
import InvoiceRequestContainer from '../containers/InvoiceRequest';

const InvoiceRequest = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle title={t('Purchase Order Invoice Request')} />
      <InvoiceRequestContainer />
    </>
  );
};

export default InvoiceRequest;
