import React, { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ColumnHeader,
  Select,
  SelectOption,
  TextField
} from '@deque/cauldron-react';
import { AuthUser } from '../../../common/contexts/auth';
import { AllSettingsSchema } from '../../../common/contexts/Configuration';
import styles from './AccountSettings.css';
import { Errors } from '../../../common/utils/validate-general-settings-form';

interface GeneralAccountSettingsProps {
  user?: AuthUser;
  settings: AllSettingsSchema | null;
  handleGeneralSubmit?: (e: React.ChangeEvent<HTMLFormElement>) => void;
  firstNameRef?: React.RefObject<HTMLInputElement>;
  lastNameRef?: React.RefObject<HTMLInputElement>;
  emailRef?: React.RefObject<HTMLInputElement>;
  jobFunctionRef?: React.RefObject<HTMLSelectElement>;
  submitting?: boolean;
  errors: Errors;
}

const GeneralAccountSettings = ({
  user,
  settings,
  handleGeneralSubmit,
  firstNameRef,
  lastNameRef,
  emailRef,
  jobFunctionRef,
  submitting,
  errors
}: GeneralAccountSettingsProps): ReactElement => {
  const { t } = useTranslation();

  const jobFunctionOptions: SelectOption[] = [
    ...(settings?.axeAccount.jobFunction.value
      ? []
      : [
          {
            key: '',
            value: '',
            label: t('Select a job function')
          }
        ]),
    {
      key: 'developer',
      value: 'developer',
      label: t('Developer')
    },
    {
      key: 'product manager/owner',
      value: 'product manager/owner',
      label: t('Product Manager/Owner')
    },
    {
      key: 'user experience designer',
      value: 'user experience designer',
      label: t('User Experience Designer')
    },
    {
      key: 'quality assurance tester',
      value: 'quality assurance tester',
      label: t('Quality Assurance Tester')
    },
    {
      key: 'business analyst',
      value: 'business analyst',
      label: t('Business Analyst')
    }
  ];

  return (
    <>
      <ColumnHeader>
        <h2>{t('General')}</h2>
      </ColumnHeader>
      <form onSubmit={handleGeneralSubmit}>
        <div className={styles.formWrap}>
          <TextField
            label={t('Email')}
            name="email"
            type="email"
            defaultValue={user?.email}
            fieldRef={emailRef}
            error={errors.email}
            required
          />
          <TextField
            label={t('First name')}
            name="first-name"
            type="text"
            defaultValue={user?.firstName}
            fieldRef={firstNameRef}
            error={errors.firstName}
            required
          />
          <TextField
            label={t('Last name')}
            name="last-name"
            type="text"
            defaultValue={user?.lastName}
            fieldRef={lastNameRef}
            error={errors.lastName}
            required
          />
          <Select
            label={t('Job function')}
            name="job-function"
            ref={jobFunctionRef}
            options={jobFunctionOptions}
            defaultValue={settings?.axeAccount.jobFunction.value || ''}
          />
        </div>
        <div className={styles.formActions}>
          <Button type="submit" disabled={submitting}>
            {t('Save')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default GeneralAccountSettings;
