import React from 'react';
import type { v2 } from '@deque/billing-service-client';
import { useTranslation } from 'react-i18next';

import { isSubscriptionExpired, ProductSlugs } from '../../common/constants';
import { useProducts } from '../../common/contexts/products';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { AXE_WATCHER_PAID_PLANS_V1 } from '../../axe-watcher/constants';

interface PlanNameProps {
  subscription: v2.EnterpriseSubscription | v2.UserSubscription;
}

const PlanName: React.FC<PlanNameProps> = ({ subscription }) => {
  const { t } = useTranslation();
  const subIsExpired = isSubscriptionExpired(subscription);
  const { products } = useProducts();
  const hasAxeWatcherPaidPlansV1 = useFeatureFlagState(
    AXE_WATCHER_PAID_PLANS_V1
  );

  let planName: string;
  if (subscription.product_slug === ProductSlugs.axeDevToolsWatcher) {
    if (hasAxeWatcherPaidPlansV1) {
      if (subscription.purchase_state === 'paid') {
        /**
         * Note: As part of paid plans, `paid` devhub subscription is only available
         * to enterprises and is only currently provisioned with the axe-devtools-html
         * bundle.
         */
        planName = t('Basic');
      } else if (subscription.purchase_state === 'free') {
        planName = t('Free');
      } else if (subscription.purchase_state === 'trialing') {
        planName = t('Trial');
      } else {
        // Is in one of the FREE_STATES, but not 'free' - show as 'Trial expired'
        planName = t('Trial expired');
      }
    } else if (subIsExpired) {
      planName = t('Trial expired');
    } else {
      planName = t('Trial');
    }
  } else if (subscription.product_slug === ProductSlugs.axeLinter) {
    if (subIsExpired) {
      planName = t('Expired');
    } else if (subscription.purchase_state === 'trialing') {
      planName = t('Trial');
    } else {
      planName = t('Enterprise');
    }
  } else if (subscription.product_slug === ProductSlugs.axeDevToolsHTML) {
    if (subIsExpired) {
      planName = t('Expired');
    } else {
      planName = t('Enterprise');
    }
  } else if (subscription.product_slug === ProductSlugs.axeDevToolsMobile) {
    if (subscription.purchase_state === 'trialing') {
      planName = t('Trial');
    } else if (subscription.purchase_state === 'paid') {
      planName = t('Enterprise');
    } else {
      planName = t('Expired');
    }
  } else if (subscription.product_slug === ProductSlugs.dequeUniversity) {
    const priceId = subscription.pricing?.stripe_id;

    const matchingProduct = products.find(
      product =>
        product.slug === ProductSlugs.dequeUniversity &&
        product.stripe_data?.pricing.find(p => p.id === priceId)
    );

    if (!matchingProduct) {
      planName = 'unknown';
    } else {
      planName =
        matchingProduct.stripe_data?.pricing.find(p => p.id === priceId)
          ?.metadata.plan || 'unknown';
    }
  } else if (subIsExpired) {
    planName = t('Free');
  } else if (
    (subscription as v2.EnterpriseSubscription).enterprise_id &&
    subscription.purchase_state === 'trialing'
  ) {
    planName = t('Pro enterprise trial');
  } else if ((subscription as v2.EnterpriseSubscription).enterprise_id) {
    planName = t('Pro enterprise');
  } else if (subscription.purchase_state === 'trialing') {
    planName = t('Pro trial');
  } else {
    planName = t('Pro');
  }

  return <>{planName}</>;
};

export default PlanName;
