import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useSSOConfig from '../hooks/useSSOConfig';
import { Loader } from '@deque/cauldron-react';

interface UserLinkProps {
  id: string;
}

const UserIdLink: FC<UserLinkProps> = ({ id }) => {
  const { t } = useTranslation();
  const { data, error, loading } = useSSOConfig();
  const loaderRef = useCallback(
    (element: HTMLDivElement | null) => {
      if ((loading || !data) && element) {
        element.focus();
      }
    },
    [loading, data]
  );

  if (error) {
    return (
      <ErrorMessage title={t('Unable to load SSO configuration')}>
        {error.stack}
      </ErrorMessage>
    );
  }

  if (loading || !data) {
    return <Loader label={t('Loading')} tabIndex={-1} ref={loaderRef} />;
  }

  return (
    <a
      href={`${data.url}/auth/admin/master/console/#/realms/${data.realm}/users/${id}`}
    >
      {id}
    </a>
  );
};

interface ErrorMessageProps {
  title: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ title, children }) => {
  return (
    <div>
      <details>
        <summary>
          <strong>{title}</strong>
        </summary>
        <pre>
          <code>{children}</code>
        </pre>
      </details>
    </div>
  );
};

export default UserIdLink;
