import React from 'react';
import { Checkbox } from '@deque/cauldron-react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import {
  LicenseAgreementLink,
  PrivacyPolicyLink
} from './AnalyticsEnabledLink';
import styles from './TermsAndConditionsCheckbox.css';

export interface TsNCsProps {
  id: string;
  name?: string;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  describedBy?: string;
  error?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxRef: React.Ref<HTMLInputElement>;
}

const TermsAndConditionsCheckbox = ({
  id,
  name,
  checked,
  required,
  disabled,
  invalid,
  error,
  className,
  onChange,
  checkboxRef,
  describedBy
}: TsNCsProps): React.ReactElement => {
  return (
    <Checkbox
      id={id}
      className={classNames(styles.Checkbox, className)}
      label={
        <span>
          <Trans>
            I accept the&nbsp;
            <LicenseAgreementLink />
            &nbsp;and have read the&nbsp;
            <PrivacyPolicyLink />
          </Trans>
        </span>
      }
      name={name}
      checked={checked}
      required={required}
      disabled={disabled}
      checkboxRef={checkboxRef}
      aria-invalid={!!invalid}
      aria-describedby={describedBy}
      error={error}
      onChange={onChange}
    />
  );
};

export default TermsAndConditionsCheckbox;
