import qs from 'qs';
import type { SortOptions, v2 } from '@deque/billing-service-client';
import { Paginated } from '@deque/pagination-utils';
import { PaginationOptions, apiRequest } from '../../../api-client';

interface TeamsEndpointParams {
  token: string;
  enterpriseId: string;
}

interface TeamParams {
  id: string;
  name: string;
}

type ListParams = PaginationOptions &
  TeamsEndpointParams &
  SortOptions<v2.TeamSortableColumn> &
  Partial<Omit<TeamParams, 'id'>>;

type CreateParams = TeamsEndpointParams & Omit<TeamParams, 'id'>;

type DeleteParams = TeamsEndpointParams & Omit<TeamParams, 'name'>;

type GetParams = TeamsEndpointParams & Omit<TeamParams, 'name'>;

type UpdateParams = TeamsEndpointParams & TeamParams;

export default {
  list: async ({
    token,
    enterpriseId,
    name,
    page,
    per_page,
    sortBy,
    sortDir
  }: ListParams): Promise<Paginated<v2.Team[]>> => {
    const urlParams = { name, sort_by: sortBy, sort_dir: sortDir };
    const data = await apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/teams?${qs.stringify(
        urlParams
      )}`,
      method: 'GET',
      page,
      per_page,
      token
    });
    return data as Paginated<v2.Team[]>;
  },

  create: ({ token, enterpriseId, name }: CreateParams): Promise<v2.Team> => {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/teams`,
      method: 'POST',
      token,
      body: {
        name
      }
    });
  },

  delete: async ({ token, enterpriseId, id }: DeleteParams): Promise<void> => {
    await apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/teams/${id}`,
      method: 'DELETE',
      token
    });
  },

  get: ({ token, enterpriseId, id }: GetParams): Promise<v2.Team> => {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/teams/${id}`,
      method: 'GET',
      token
    });
  },

  update: ({
    token,
    enterpriseId,
    id,
    name
  }: UpdateParams): Promise<v2.Team> => {
    return apiRequest({
      endpoint: `/api/billing/v2/enterprises/${enterpriseId}/teams/${id}`,
      method: 'PATCH',
      token,
      body: {
        name
      }
    });
  }
};
