import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAxeDevtoolsProAnalytics } from '../../common/contexts/analytics';
import LoadableGapTesting from '../components/LoadableGapTesting';
import PageTitle from '../../common/components/PageTitle';

interface AdvancedTestingCoverageProps {
  category: 'site' | 'page' | 'page-state';
}

const COVERAGE_PAGE = '/coverage-page';
const COVERAGE_PAGE_STATE = '/coverage-page-state';

const AdvancedTestingCoverage: React.FC<AdvancedTestingCoverageProps> = ({
  category
}): ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const analytics = useAxeDevtoolsProAnalytics();

  useEffect(() => {
    switch (pathname) {
      case COVERAGE_PAGE:
        analytics.analysisWhatsLeft({ test: 'Page' });
        break;
      case COVERAGE_PAGE_STATE:
        analytics.analysisWhatsLeft({ test: 'Page States' });
        break;
    }
  }, [pathname]);

  return (
    <>
      <PageTitle title={t('Advanced Testing Coverage')} />
      <div className="centeredContainer">
        <LoadableGapTesting category={category} />
      </div>
    </>
  );
};

export default AdvancedTestingCoverage;
