import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import type { AvailableLicense } from '../components/UserManagement/UserManagement';
import {
  EnterpriseMembers,
  memberHasAccepted
} from '../../common/utils/billing-client/enterprises/enterprise-members-v2'; // separate the imports from client-v2 for convenience in testing
import { ProductSlugs } from '../../common/constants';
import { useAuthContext } from '../../common/contexts/auth';
import { useProducts } from '../../common/contexts/products';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { useServerInfo } from '../../common/contexts/serverInfo';
import { useEnterprises } from '../../common/contexts/enterprises';
import useV2ListLicenses from '../../common/hooks/useV2ListLicenses';
import billingClient from '../../common/utils/billing-client/client-v2';
import UserManagement from '../components/UserManagement/UserManagement';
import { useUserManagementFilters } from '../hooks/useUserManagementFilters';
import useV2EnterpriseMembers from '../../common/hooks/useV2EnterpriseMembers';
import { getHiddenProductSlugsFromSubs } from '../utils/get-hidden-product-slugs-from-subs';
import { AXE_REPORTS_TEAMS_V1 } from '../../axe-reports/constants';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';

const UserManagementContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const wide = useMediaQuery('(min-width: 49rem)');
  const narrow = useMediaQuery('(max-width: 31rem)');
  const history = useHistory();
  const { user } = useAuthContext();
  const {
    activeEnterprise,
    loading: loadingEnterprises,
    error: enterprisesError
  } = useEnterprises();
  const hasAxeReportsTeamsV1 = useFeatureFlagState(AXE_REPORTS_TEAMS_V1);
  /* there is always a user and enterprise id due to the Protected Route */
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const enterpriseId = activeEnterprise!.id;
  const loaderRef = React.useRef<HTMLDivElement>(null);

  const {
    getProductBySlug,
    getProductById,
    loading: loadingProducts,
    error: productError
  } = useProducts();
  const {
    licenses,
    loading: loadingLicenses,
    error: licenseError,
    refresh: refreshLicenses
  } = useV2ListLicenses(token, {
    enterpriseId: enterpriseId || null
  });
  const {
    pendingOrAcceptedMembers,
    pendingUsers,
    loading: loadingMembers,
    error: memberError,
    refresh: refreshMembers
  } = useV2EnterpriseMembers({ token, enterpriseId });

  const { serverInfo, loading: loadingServerInfo } = useServerInfo();

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  // Products that are expired and do not have a free tier

  //make axe Reports visible in constants.ts but filter it from products with the feature flag
  //remove these changes and the axe_reports_teams_v1 feature flag in a future release
  const hiddenProductSlugs = activeEnterprise
    ? getHiddenProductSlugsFromSubs(
        activeEnterprise.subscriptions,
        getProductBySlug,
        !hasAxeReportsTeamsV1 ? [ProductSlugs.axeReports] : []
      )
    : [];

  const {
    isFiltered,
    filteredMembers,
    filteredPendingUsers,
    allowedFilterValuesAndLabels,
    appliedFilters,
    handleApplyFilters,
    searchQuery,
    setSearchQuery
  } = useUserManagementFilters({
    members: pendingOrAcceptedMembers,
    pendingUsers: pendingUsers,
    activeEnterprise,
    hiddenProductSlugs
  });

  const isMailerEnabled = useMemo(() => {
    return !!(!loadingServerInfo && serverInfo?.mailerEnabled);
  }, [loadingServerInfo, serverInfo]);

  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string | null>(null);
  const [confirmationMsg, setConfirmationMsg] = useState<string | null>(null);

  const variant = wide ? 'wide' : narrow ? 'narrow' : 'medium';
  const isMobile = ['narrow', 'medium'].includes(variant);
  const isLoading =
    loadingLicenses || loadingProducts || loadingEnterprises || loadingMembers;

  const hasError = [
    licenseError,
    productError,
    memberError,
    enterprisesError
  ].some(error => error instanceof Error);

  // process licenses for table
  const availableLicenses: AvailableLicense[] =
    licenses?.reduce((acc, license) => {
      const product = getProductById(license.product_id);
      if (
        product &&
        !hiddenProductSlugs.includes(product.slug as ProductSlugs)
      ) {
        acc.push({
          product_name: product.name,
          product_slug: product.slug,
          remaining_count:
            license.total !== null
              ? license.total - license.used - license.pending
              : null
        });
      }
      return acc;
    }, [] as AvailableLicense[]) || [];

  const onAddUser = (
    type: 'product' | 'admin' | 'member',
    productSlug?: string
  ): void => {
    switch (type) {
      case 'product':
        if (!productSlug) {
          throw new Error('Type "product" requires a product slug');
        }
        return history.push(`/user-access/${productSlug}/add-users`);
      case 'admin':
        return history.push('/user-access/axe-account/add-admin');
      case 'member':
        return history.push('/user-access/axe-account/add-member');
      default:
        throw new Error(`Type "${type}" is not a valid type`);
    }
  };

  const onResend = async (
    member: EnterpriseMembers.PendingMember
  ): Promise<void> => {
    setErrMessage(null);
    setConfirmationMsg(null);
    setLoadingAction(true);

    try {
      await billingClient.enterpriseInvitations.resend({
        token,
        enterpriseId,
        invitationId: member.invitation_id
      });
      setConfirmationMsg(
        t('The invitation to {{ email }} was successfully resent.', {
          email: member.email
        })
      );
    } catch (err) {
      setErrMessage(
        t('Failed to resend the invitation to {{ email }}. Please try again.', {
          email: member.email
        })
      );
    }

    setLoadingAction(false);
  };

  const onRemoveUser = async (
    member: EnterpriseMembers.PendingOrAcceptedMember
  ): Promise<void> => {
    setErrMessage(null);
    setConfirmationMsg(null);
    setLoadingAction(true);

    try {
      if (memberHasAccepted(member)) {
        await billingClient.enterpriseMembers.remove({
          token,
          enterpriseId,
          memberId: member.user_id
        });
        refreshMembers();
        refreshLicenses();
      } else {
        await billingClient.enterpriseInvitations.delete({
          token,
          enterpriseId,
          invitationId: member.invitation_id
        });
        refreshMembers();
        refreshLicenses();
      }
      setConfirmationMsg(
        t('{{ email }} was successfully removed.', { email: member.email })
      );
    } catch (err) {
      setErrMessage(
        t('Failed to remove {{ email }}. Please try again.', {
          email: member.email
        })
      );
    }

    setLoadingAction(false);
  };

  const onDismiss = () => {
    setConfirmationMsg(null);
  };

  if (isLoading) {
    return <Loader label={t('Loading...')} ref={loaderRef} tabIndex={-1} />;
  }

  return (
    <UserManagement
      isFiltered={isFiltered}
      totalUsers={pendingOrAcceptedMembers?.length}
      totalPendingUsers={pendingUsers?.length}
      filteredMembers={filteredMembers || []}
      filteredPendingUsers={filteredPendingUsers || []}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentUserId={user!.id}
      availableLicenses={availableLicenses}
      getProductBySlug={getProductBySlug}
      onAddUser={onAddUser}
      onResend={onResend}
      onRemoveUser={onRemoveUser}
      onDismiss={onDismiss}
      //unlike general toasts, action toasts do not block the table
      loadingAction={loadingAction}
      hasError={hasError}
      errMessage={errMessage}
      confirmationMsg={confirmationMsg}
      isMobile={isMobile}
      hiddenProductSlugs={hiddenProductSlugs}
      isMailerEnabled={isMailerEnabled}
      handleApplyFilters={handleApplyFilters}
      appliedFilters={appliedFilters}
      allowedFilterValuesAndLabels={allowedFilterValuesAndLabels}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

export default UserManagementContainer;
