import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  Checkbox,
  Icon,
  TextField,
  TooltipTabstop
} from '@deque/cauldron-react';
import type { v2 } from '@deque/billing-service-client';
import { useLoadedConfiguration } from '../../../common/contexts/Configuration';
import { useFeatureFlagState } from '../../../common/contexts/featureFlags';
import {
  AXE_DEVHUB_SHOW_SESSION_END_TIMEOUT,
  type SeveritiesType
} from '../../../axe-watcher/constants';
import ImpactLabel from '../../../axe-watcher/components/issues/ImpactLabel';
import Form from './Form';
import RadioSetting from './RadioSetting';
import styles from './Configuration.css';

const impacts: ReadonlyArray<SeveritiesType> = [
  'critical',
  'serious',
  'moderate',
  'minor'
];

enum IssuesIncluded {
  all_issues = 'all_issues',
  new_issues_only = 'new_issues_only'
}

interface AxeDeveloperConfigurationForm {
  onlyNewIssues: IssuesIncluded;
  impacts: {
    [key in SeveritiesType]: boolean;
  };
  sessionCloseTimeout: number;
}

interface AxeDeveloperHubConfigurationProps {
  product: v2.Product;
}

const AxeDeveloperHubConfiguration: React.FC<
  AxeDeveloperHubConfigurationProps
> = ({ product }) => {
  const { t } = useTranslation();
  const {
    settings,
    updateSettings,
    saving,
    isEnterpriseAdmin,
    isEnterpriseMember
  } = useLoadedConfiguration();
  const { handleSubmit, reset, control, formState } =
    useForm<AxeDeveloperConfigurationForm>();
  const shouldShowSessionTimeout = useFeatureFlagState(
    AXE_DEVHUB_SHOW_SESSION_END_TIMEOUT
  );

  const developerHubSettings = settings.axeDevtoolsWatcher;
  // Only allow editing / saving if the user is an enterprise admin
  const isReadOnly = isEnterpriseMember && !isEnterpriseAdmin;
  const disabled = saving || isReadOnly;

  React.useEffect(() => {
    if (developerHubSettings) {
      reset({
        onlyNewIssues: developerHubSettings.onlyNewIssues.value
          ? IssuesIncluded.new_issues_only
          : IssuesIncluded.all_issues,
        impacts: {
          critical: developerHubSettings.impacts.value.critical,
          serious: developerHubSettings.impacts.value.serious,
          moderate: developerHubSettings.impacts.value.moderate,
          minor: developerHubSettings.impacts.value.minor
        },
        sessionCloseTimeout: developerHubSettings.sessionCloseTimeout.value
      });
    }
  }, [developerHubSettings]);

  const onSubmit = (data: AxeDeveloperConfigurationForm) => {
    updateSettings({
      axeDevtoolsWatcher: {
        onlyNewIssues: {
          value: data.onlyNewIssues === IssuesIncluded.new_issues_only,
          type: 'default'
        },
        impacts: {
          value: {
            critical: data.impacts.critical,
            serious: data.impacts.serious,
            moderate: data.impacts.moderate,
            minor: data.impacts.minor
          },
          type: 'default'
        },
        sessionCloseTimeout: {
          // The server expects a number, but the form returns a string. We must coerce to avoid 400s from the API.
          value: parseInt(data.sessionCloseTimeout + '', 10),
          type: 'default'
        }
      }
    });
  };

  return (
    <Form
      header={product.name}
      isDirty={formState.isDirty}
      isReadOnly={isReadOnly}
      onSave={handleSubmit(onSubmit)}
      onDiscard={() => {
        reset();
      }}
    >
      <form>
        <div className={styles.headingContainer}>
          <h3>{t('Configure A11Y Threshold')}</h3>
        </div>
        <p>
          {t(
            'You can determine the criteria used to calculate the A11Y Threshold. If the threshold is exceeded, it will cause the GitHub Actions and other integrations to treat the test suite as if it has failed.'
          )}
        </p>
        <fieldset>
          <RadioSetting
            id="issues-included-label"
            name="onlyNewIssues"
            label={t('Issues Included')}
            formControl={control}
            disabled={disabled}
            shouldShowAllowChange={false}
            tooltip={t(
              'Determine if all issues or new issues will be part of the A11Y Threshold total'
            )}
            options={[
              {
                id: IssuesIncluded.all_issues,
                label: t('All Issues'),
                value: IssuesIncluded.all_issues
              },
              {
                id: IssuesIncluded.new_issues_only,
                label: t('New Issues Only'),
                value: IssuesIncluded.new_issues_only
              }
            ]}
          />
        </fieldset>

        <fieldset>
          <div>
            <h4 className="Field__label">{t('Impacts Included')}</h4>
            <TooltipTabstop
              tooltip={t(
                'Choose the impacts that will contribute the A11Y Threshold'
              )}
              placement="top"
              variant="info"
            >
              <Icon
                className={styles.headingTooltipIcon}
                type="question-circle"
                label={t('Impacts included help')}
              />
            </TooltipTabstop>
          </div>
          {impacts.map(impact => (
            <Controller
              key={impact}
              control={control}
              name={`impacts.${impact}`}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  onChange={onChange}
                  checked={value}
                  id={`impactIncluded-${impact}`}
                  label={<ImpactLabel impact={impact} />}
                  // Critical is always enabled and cannot be disabled
                  disabled={impact === 'critical' || disabled}
                />
              )}
            />
          ))}
        </fieldset>

        {shouldShowSessionTimeout ? (
          <div data-testid="session-timeout">
            <div className={styles.headingContainer}>
              <h3>{t('Session Timeout')}</h3>
            </div>
            <p>
              {t(
                'By default, your project will stop accepting new data after 10 minutes since the last data came in. If you would like to override this, you can put your preferred timeout (in milliseconds) below. Once this timeout has been reached, the session will close and there will be NO way to add more data into that session.'
              )}
            </p>
            <fieldset>
              <Controller
                control={control}
                name="sessionCloseTimeout"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t('Session Close Timeout (in ms)')}
                    name="sessionCloseTimeout"
                    type="number"
                    onChange={(_, event) => onChange(event)}
                    disabled={disabled}
                    value={value}
                  />
                )}
              />
            </fieldset>
          </div>
        ) : null}
      </form>
    </Form>
  );
};

export default AxeDeveloperHubConfiguration;
