import React, { useEffect } from 'react';
import type { v2 } from '@deque/billing-service-client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LoaderOverlay } from '@deque/cauldron-react';
import { useAuthContext } from '../../common/contexts/auth';
import billingClient from '../../common/utils/billing-client/client-v2';
import { useGlobalToast } from '../../common/contexts/globalToast';

interface AddAdminUserProgressProps {
  email: string;
  enterprise: v2.Enterprise;
}

const AddAdminUserProgress = ({
  email,
  enterprise
}: AddAdminUserProgressProps): JSX.Element => {
  const { setContents } = useGlobalToast();
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  // There will always be a user and with a token.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const token = user!.token;

  useEffect(() => {
    (async () => {
      let errored = false;
      try {
        await billingClient.enterpriseInvitations.addMember({
          token,
          enterpriseId: enterprise.id,
          email,
          isAdmin: true
        });
      } catch (ex) {
        errored = true;
      }

      if (errored) {
        setContents(
          t(
            'There was an issue adding {{ email }} user as an admin. Please try again.',
            { email }
          ),
          'error'
        );
      } else {
        setContents(
          t('User was added successfully as an admin.'),
          'confirmation'
        );
      }

      history.push('/user-access');
    })();
  }, []);

  return (
    <div>
      <LoaderOverlay focusOnInitialRender>
        <h2>{t('Saving changes...')}</h2>
        <p>{t('Just a moment while we save the changes to your users.')}</p>
      </LoaderOverlay>
    </div>
  );
};

export default AddAdminUserProgress;
