import React from 'react';

export type ToastContents = React.ReactNode;
export type ToastType =
  | 'confirmation'
  | 'caution'
  | 'error'
  | 'action-needed'
  | 'info';

type SetContentsFunction = (contents: ToastContents, type?: ToastType) => void;

interface State {
  type: ToastType | null;
  contents?: ToastContents | null;
}

/**
 * Methods provided by the context.
 */

interface Methods {
  setContents: SetContentsFunction;
}

/**
 * State and methods provided by the context.
 */

type ContextShape = Methods & State;

/**
 * Throw a "helpful" error if using context methods without mounting its provider.
 */

const throwProviderNotMountedError = (): never => {
  throw new Error('GlobalToast: Provider not mounted');
};

const context = React.createContext<ContextShape>({
  setContents: throwProviderNotMountedError,
  type: null,
  contents: null
});

interface ProviderProps {
  children: React.ReactNode;
}

/**
 * GlobalToast context provider.
 */

export const GlobalToastProvider: React.FC<ProviderProps> = ({ children }) => {
  const [type, setType] = React.useState<ToastType | null>(null);
  const [contents, setContents] = React.useState<React.ReactNode>(null);
  const [pendingState, setPendingState] = React.useState<State | null>(null);

  const setToastContents: SetContentsFunction = (
    toastContents,
    toastType = 'info'
  ) => {
    if (!toastContents) {
      return setContents(null);
    }
    // If there is a toast already showing, hide it and show the new one so that
    // the new toast content receives focus and is read by screen readers.
    if (contents) {
      setContents(null);
      setPendingState({ type: toastType, contents: toastContents });
      return;
    }
    setContents(toastContents);
    setType(toastType);
  };

  React.useEffect(() => {
    if (pendingState) {
      setContents(pendingState.contents);
      setType(pendingState.type);
      setPendingState(null);
    }
  }, [pendingState]);

  return (
    <context.Provider
      value={{
        setContents: setToastContents,
        contents,
        type
      }}
    >
      {children}
    </context.Provider>
  );
};

/**
 * Use the GlobalToast context.
 */

export const useGlobalToast = (): ContextShape => React.useContext(context);
