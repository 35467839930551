import { useCallback } from 'react';
import billingClient, {
  EnterpriseMembers,
  memberHasAccepted,
  memberIsPendingUser
} from '../utils/billing-client/client-v2';
import useAPI, { State, Methods } from './useAPI';

interface V2EnterpriseMembersState<
  T extends EnterpriseMembers.Member = EnterpriseMembers.Member
> extends Omit<State<T[]>, 'data'> {
  enterpriseMembers: T[] | null;
  pendingOrAcceptedMembers: EnterpriseMembers.PendingOrAcceptedMember[] | null;
  pendingUsers: EnterpriseMembers.PendingUser[] | null;
  acceptedMembers: EnterpriseMembers.AcceptedMember[] | null;
}

export type V2EnterpriseMembersReturnedShape = V2EnterpriseMembersState &
  Methods;

interface useV2EnterpriseMembersProps {
  token?: string;
  enterpriseId?: string;
}

const useV2EnterpriseMembers = ({
  token,
  enterpriseId
}: useV2EnterpriseMembersProps): V2EnterpriseMembersReturnedShape => {
  const dataLoader = useCallback(() => {
    if (token && enterpriseId) {
      return billingClient.enterpriseMembers.list({ token, enterpriseId });
    } else {
      return Promise.resolve([]);
    }
  }, [token, enterpriseId]);
  const state = useAPI(dataLoader);

  let pendingUsers = null;
  let pendingOrAcceptedMembers = null;
  let acceptedMembers = null;
  if (state.data) {
    pendingUsers = [];
    pendingOrAcceptedMembers = [];
    acceptedMembers = [];
    for (const member of state.data) {
      if (memberIsPendingUser(member)) {
        pendingUsers.push(member);
      } else {
        pendingOrAcceptedMembers.push(member);

        if (memberHasAccepted(member)) {
          acceptedMembers.push(member);
        }
      }
    }
  }

  return {
    loading: state.loading,
    error: state.error,
    enterpriseMembers: state.data,
    pendingUsers,
    pendingOrAcceptedMembers,
    acceptedMembers,
    refresh: state.refresh
  };
};

export default useV2EnterpriseMembers;
