import React, { useState, useEffect, createRef } from 'react';
import { Loader, Panel } from '@deque/cauldron-react';
import { Trans, useTranslation } from 'react-i18next';
import { getBroadcast } from '../../common/api-client';
import { useGlobalToast } from '../../common/contexts/globalToast';
import dequeLogo from '../../common/assets/images/deque.png';
import styles from './Maintenance.css';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import PageTitle from '../../common/components/PageTitle';

interface Props {
  children: React.ReactNode;
}

const Maintenance: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const [loading, setLoading] = useState(true);
  const [isInMaintenanceMode, setIsInMaintenanceMode] = useState(false);
  const loaderRef = createRef<HTMLDivElement>();
  const collapsed = useMediaQuery('(max-width: 31.25rem)');

  useEffect(() => {
    (async () => {
      try {
        const res = await getBroadcast();

        if (res.isInMaintenanceMode) {
          setIsInMaintenanceMode(true);
        } else if (res.level && res.message) {
          setContents(res.message, res.level);
        }
      } catch {
        // swallow error
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  if (loading) {
    return <Loader label={t('Loading...')} ref={loaderRef} tabIndex={-1} />;
  }

  if (!isInMaintenanceMode) {
    return <>{children}</>;
  }

  return (
    <main className={styles.wrapper}>
      <PageTitle title={t('Maintenance')} />
      <img className={styles.logo} src={dequeLogo} alt={t('Deque logo')} />
      <h1>
        <Trans>
          Sorry! <span>We’re doing a bit of Maintenance</span>
        </Trans>
      </h1>
      <p className={styles.subheader}>
        {t('We should be up and running again shortly!')}
      </p>
      <Panel collapsed={collapsed}>
        <p className={styles.description}>
          {t(
            'We apologize for the inconvenience caused by our scheduled maintenance.'
          )}
        </p>
        <div className={styles.productImpact}>
          {/*
            This list of products and their names will need to be maintained manually, since
            we are unable to call the billing/v2/products endpoint while in maintenance mode.
          */}
          <p>{t('The following products are under maintenance:')}</p>
          <ul>
            <li>
              {t('axe Account')} <span>{t('(down)')}</span>
            </li>
            <li>
              axe DevTools
              <sup>{t('®')}</sup> <span>{t('(limited functionality)')}</span>
            </li>
            <li>
              axe DevTools Linter <span>{t('(limited functionality)')}</span>
            </li>
            <li>
              axe Mobile <span>{t('(limited functionality)')}</span>
            </li>
          </ul>
        </div>
      </Panel>
    </main>
  );
};

export default Maintenance;
