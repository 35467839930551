import React, { useEffect, useMemo, useRef } from 'react';
import { Route, Switch, useLocation, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import {
  ColumnHeader,
  ColumnLeft,
  ColumnRight,
  TwoColumnPanel,
  Loader,
  Link as CauldronLink
} from '@deque/cauldron-react';
import AxeDevtoolsProConfiguration from './AxeDevtoolsProConfiguration';
import AxeDeveloperHubConfiguration from './AxeDeveloperHubConfiguration';
import PageTitle from '../../../common/components/PageTitle';
import ContentToast from '../../../common/components/ContentToast';
import GlobalConfiguration from './GlobalConfiguration';
import type { v2 } from '@deque/billing-service-client';
import {
  ProductSlugs,
  SupportedIntegrationProductSlugs
} from '../../../common/constants';
import styles from './Configuration.css';
import commonStyles from '../../../common/utils/common.css';
import IntegrationConfigurations from './IntegrationConfigurations';

export interface ConfigurationProps {
  narrow: boolean;
  loading: boolean;
  error: Error | null;
  canAccessIntegrationConfiguration: boolean;
  enabledIntegrationProductSlugs: SupportedIntegrationProductSlugs[];
  getProductBySlug: (slug: ProductSlugs) => v2.Product | undefined;
  hasAccessToProduct: (slug: ProductSlugs) => boolean;
  showNoActiveSubscriptionMessage: boolean;
  activeEnterprise: v2.Enterprise | null;
  isEnterpriseAdmin: boolean;
}

const Configuration: React.FC<ConfigurationProps> = ({
  narrow,
  loading,
  error,
  canAccessIntegrationConfiguration,
  enabledIntegrationProductSlugs,
  getProductBySlug,
  hasAccessToProduct,
  showNoActiveSubscriptionMessage,
  activeEnterprise,
  isEnterpriseAdmin
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const loaderRef = useRef<HTMLDivElement>(null);

  const paths = useMemo(() => {
    const { pathname } = location;
    return {
      globalIsActive: pathname === '/configuration',
      axeDevToolsProIsActive:
        pathname === `/configuration/${ProductSlugs.axeDevToolsExtension}`,
      axeDeveloperHubIsActive:
        pathname === `/configuration/${ProductSlugs.axeDevToolsWatcher}`,
      integrationsIsActive: pathname === `/configuration/integrations`
    };
  }, [location]);

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  if (error) {
    return (
      <ContentToast show={true} type="caution">
        {t('Failed to load configuration.')}
      </ContentToast>
    );
  }

  if (loading) {
    return (
      <Loader
        label={t('Loading configuration...')}
        tabIndex={-1}
        ref={loaderRef}
      />
    );
  }

  const axeDevToolsPro =
    hasAccessToProduct(ProductSlugs.axeDevToolsExtension) &&
    getProductBySlug(ProductSlugs.axeDevToolsExtension);
  const axeDeveloperHub =
    hasAccessToProduct(ProductSlugs.axeDevToolsWatcher) &&
    getProductBySlug(ProductSlugs.axeDevToolsWatcher);

  return (
    <>
      <PageTitle title={t('Configuration')} />
      <div
        className={classNames(styles.wrap, commonStyles.resetFieldsets, {
          // This is managed in JS, rather than CSS to help keep in sync with the panel breakpoint
          [styles.collapsed]: narrow
        })}
      >
        <h1 id="configuration-heading">{t('Configuration')}</h1>
        {showNoActiveSubscriptionMessage ? (
          <Trans>
            <div>
              To view axe Configuration, you must have an active subscription to
              at least 1 product.{' '}
              <Link className="Link" to="/">
                View available products
              </Link>
            </div>
          </Trans>
        ) : (
          <>
            <Trans>
              <p>
                Adjust company-wide settings for your organization. Read about{' '}
                <CauldronLink
                  href="https://docs.deque.com/devtools-server/4.0.0/en/axe-configuration#how-it-works"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  how settings will take effect for each product.
                </CauldronLink>
              </p>
            </Trans>
            <TwoColumnPanel>
              <ColumnLeft aria-labelledby="configuration-sidebar-header">
                <ColumnHeader
                  id="configuration-sidebar-header"
                  className={styles.columnHeader}
                >
                  <h2>{t('Administrator')}</h2>
                </ColumnHeader>
                <nav aria-labelledby="configuration-heading">
                  <ul>
                    <li>
                      <Link
                        to="/configuration"
                        aria-current={paths.globalIsActive}
                      >
                        {t('Global')}
                        <em>
                          {t(
                            'Manage settings that apply to all Deque applications.'
                          )}
                        </em>
                      </Link>
                    </li>
                    {axeDevToolsPro && (
                      <li>
                        <Link
                          to={`/configuration/${ProductSlugs.axeDevToolsExtension}`}
                          aria-current={paths.axeDevToolsProIsActive}
                        >
                          {axeDevToolsPro.name}
                          <em>
                            {t(
                              'Manage settings that apply to {{ axeDevToolsPro }}.',
                              { axeDevToolsPro: axeDevToolsPro.name }
                            )}
                          </em>
                        </Link>
                      </li>
                    )}

                    {axeDeveloperHub && (
                      <li>
                        <Link
                          to={`/configuration/${ProductSlugs.axeDevToolsWatcher}`}
                          aria-current={paths.axeDeveloperHubIsActive}
                        >
                          {axeDeveloperHub.name}
                          <em>
                            {t(
                              'Manage settings that apply to {{ axeDeveloperHub }}.',
                              { axeDeveloperHub: axeDeveloperHub.name }
                            )}
                          </em>
                        </Link>
                      </li>
                    )}
                    {canAccessIntegrationConfiguration && (
                      <li>
                        <Link
                          to="/configuration/integrations"
                          aria-current={paths.integrationsIsActive}
                        >
                          {t('Integrations')}
                          <em>
                            {t(
                              'Set up third-party products to work with axe products.'
                            )}
                          </em>
                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
              </ColumnLeft>
              <ColumnRight aria-labelledby="configuration-item-header">
                <Switch>
                  <Route exact path="/configuration">
                    <GlobalConfiguration />
                  </Route>
                  {axeDevToolsPro && (
                    <Route
                      path={`/configuration/${ProductSlugs.axeDevToolsExtension}`}
                    >
                      <AxeDevtoolsProConfiguration product={axeDevToolsPro} />
                    </Route>
                  )}

                  {axeDeveloperHub && (
                    <Route
                      path={`/configuration/${ProductSlugs.axeDevToolsWatcher}`}
                    >
                      <AxeDeveloperHubConfiguration product={axeDeveloperHub} />
                    </Route>
                  )}

                  {canAccessIntegrationConfiguration && activeEnterprise && (
                    <Route path="/configuration/integrations">
                      <IntegrationConfigurations
                        enabledIntegrationProductSlugs={
                          enabledIntegrationProductSlugs
                        }
                        activeEnterprise={activeEnterprise}
                        isEnterpriseAdmin={isEnterpriseAdmin}
                      />
                    </Route>
                  )}
                </Switch>
              </ColumnRight>
            </TwoColumnPanel>
          </>
        )}
      </div>
    </>
  );
};

export default Configuration;
