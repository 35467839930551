import React from 'react';
import { Scrim, LoaderOverlay } from '@deque/cauldron-react';

interface ScrimmedLoaderProps
  extends React.ComponentPropsWithRef<typeof LoaderOverlay> {
  loaderRef?: React.Ref<HTMLDivElement>;
}

const ScrimmedLoader = ({
  label,
  loaderRef,
  children,
  focusTrap,
  ...props
}: ScrimmedLoaderProps): React.ReactElement => {
  return (
    <>
      <Scrim show={true} />
      <LoaderOverlay
        tabIndex={-1}
        ref={loaderRef}
        label={label}
        focusTrap={focusTrap}
        focusOnInitialRender
        data-testid="loaderOverlay"
        {...props}
      >
        {children}
      </LoaderOverlay>
    </>
  );
};

export default ScrimmedLoader;
