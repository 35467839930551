import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';
import styles from './NotFound.css';

const NotFound = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle title={t('Page not found')} />
      <div className={styles.wrap}>
        <h1>{t('Page not found')}</h1>
      </div>
    </>
  );
};

export default NotFound;
