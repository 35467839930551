import React, { Ref } from 'react';
import {
  Checkbox,
  Icon,
  RadioGroup,
  TooltipTabstop
} from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';
import type { RadioItem } from '@deque/cauldron-react/lib/components/RadioGroup';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import styles from './Configuration.css';

export type RadioItemWithValueOfType<T> = Array<RadioItem & { value: T }>;

export interface Props<T extends FieldValues> {
  children?: React.ReactNode;
  label: React.ReactNode;
  name: FieldPath<T>;
  id: string;
  allowChangeName?: FieldPath<T>;
  shouldShowAllowChange: boolean;
  disabled: boolean;
  formControl: Control<T>;
  options: RadioItem[];
  tooltip?: React.ReactNode;
  focusRef?: Ref<HTMLDivElement>;
}

const RadioSetting = <T extends FieldValues>({
  children,
  formControl,
  label,
  name,
  id,
  allowChangeName,
  shouldShowAllowChange,
  disabled,
  options,
  tooltip,
  focusRef = undefined
}: Props<T>) => {
  const { t } = useTranslation();

  return (
    <div className={styles.fieldWrapper}>
      <div>
        <div className="Field__label" id={`${id}-label`}>
          {label}
        </div>
        {tooltip && (
          <TooltipTabstop tooltip={tooltip} placement="top" variant="info">
            <Icon
              type="question-circle"
              label={t('{{settingName}} help', { settingName: label })}
            />
          </TooltipTabstop>
        )}
        <Controller
          name={name}
          control={formControl}
          // ref is unused
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={({ field: { onChange, ref, ...field } }) => (
            <RadioGroup
              {...field}
              onChange={radio => {
                onChange(radio.value);
              }}
              ref={focusRef}
              tabIndex={-1}
              aria-labelledby={`${id}-label`}
              radios={options.map(option => ({
                ...option,
                disabled: disabled || option.disabled,
                id: option.id || `${id}-${option.value}`
              }))}
            />
          )}
        />
      </div>
      {shouldShowAllowChange && allowChangeName && (
        <Controller
          control={formControl}
          name={allowChangeName}
          render={({ field: { value, ...field } }) => (
            <Checkbox
              {...field}
              checked={value}
              id={`allow-users-to-change-${id}`}
              label={t('Allow users to change')}
              aria-label={t('Allow users to change {{label}}', { label })}
              disabled={disabled}
            />
          )}
        />
      )}
      {children}
    </div>
  );
};

export default RadioSetting;
