import { Offscreen, TagButton } from '@deque/cauldron-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type {
  AllFilterValues,
  FilterCategory,
  FilterValue,
  FilterValues,
  FiltersLabelsAndValues,
  LabelAndValue
} from '../../../../hooks/useUserManagementFilters';
import { getCategoryLabel } from '../../../../hooks/useUserManagementFilters';
import styles from './FilterTag.css';

export interface FilterTagProps {
  categoryValue: FilterCategory;
  value: FilterValue;
  appliedFilters: FilterValues;
  handleApplyFilters: (filters: FilterValues) => void;
  allowedFilterValuesAndLabels: FiltersLabelsAndValues;
}

const FilterTag = ({
  categoryValue,
  value,
  appliedFilters,
  allowedFilterValuesAndLabels,
  handleApplyFilters
}: FilterTagProps) => {
  const { t } = useTranslation();

  const filterLabel = (
    allowedFilterValuesAndLabels[categoryValue] as LabelAndValue<
      typeof categoryValue
    >[]
  ).find(filter => filter.value === value)?.label;

  const categoryLabel = getCategoryLabel(t, categoryValue);

  return (
    <TagButton
      label={`${categoryLabel}:`}
      value={
        <>
          <Trans>
            <span>{filterLabel}</span>
            <Offscreen> filter tag. Select to remove filter.</Offscreen>
          </Trans>
        </>
      }
      icon="close"
      className={styles.filterTag}
      onClick={() => {
        const newFilters = { ...appliedFilters };
        newFilters[categoryValue] = (
          newFilters[categoryValue] as FilterValue[]
        ).filter(filter => filter !== value) as AllFilterValues;
        handleApplyFilters(newFilters);
      }}
    />
  );
};

export default FilterTag;
