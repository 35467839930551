import type { StripeTypes, v2 } from '@deque/billing-service-client';
import { ProductSlugs } from '../constants';

export interface GetPricesResult {
  product?: v2.Product;
  planSlug?: string | null;
  monthlyPrice?: StripeTypes.Price;
  yearlyPrice?: StripeTypes.Price;
}

const getPrices = (
  products: v2.Product[] | undefined,
  productSlug?: string | null,
  planSlug?: string | null
): GetPricesResult => {
  if (!products || !products.length) {
    return {};
  }

  const slugToFind = productSlug || ProductSlugs.axeDevToolsExtension;
  const product = products.find(p => p.slug === slugToFind);

  if (!product) {
    return {};
  }

  const monthlyPrice =
    product.stripe_data?.pricing &&
    product.stripe_data?.pricing.find(
      p =>
        p.recurring &&
        p.recurring.interval === 'month' &&
        p.metadata.active === 'true' &&
        (p.metadata.plan || planSlug ? p.metadata.plan === planSlug : true)
    );
  const yearlyPrice =
    product.stripe_data?.pricing &&
    product.stripe_data?.pricing.find(
      p =>
        p.recurring &&
        p.recurring.interval === 'year' &&
        p.metadata.active === 'true' &&
        (p.metadata.plan || planSlug ? p.metadata.plan === planSlug : true)
    );

  return { product, planSlug, monthlyPrice, yearlyPrice };
};

export default getPrices;
