import type { CouponProductValid } from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';

export default {
  /**
   * Validate that a discount applies to a product and retrieve its details
   */
  validateWithProduct(
    token: string,
    coupon: string,
    product_id: string
  ): Promise<CouponProductValid> {
    return apiRequest<CouponProductValid>({
      token,
      method: 'GET',
      endpoint: `/api/billing/discounts/${coupon}/product/${product_id}`
    });
  }
};
