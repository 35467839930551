import React, { useCallback, useState } from 'react';
import useAPI from '../../common/hooks/useAPI';
import { getScreenshotById } from '../../common/api-client';
import {
  getScreenshotMetadataById,
  ScreenshotMetadata
} from '../../common/api-client';
import { useAuthContext } from '../../common/contexts/auth';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { SECURE_SCREENSHOTS_API_FEATURE_FLAG } from '../../common/constants';

export interface ScreenshotData {
  blob: Blob | null;
  metadata: ScreenshotMetadata | null;
}

interface State {
  screenshots: Map<string, ScreenshotData>;
  addScreenshot: (screenshotId: string, screenshot: ScreenshotData) => void;
}

const context = React.createContext<State>({
  screenshots: new Map<string, ScreenshotData>(),
  addScreenshot: () => null
});

interface Props {
  initialScreenshots?: Map<string, ScreenshotData>;
  children: React.ReactNode;
}

export const ScreenshotProvider: React.FC<Props> = ({
  initialScreenshots = new Map<string, ScreenshotData>(),
  children
}) => {
  const [screenshots, setScreenshots] =
    useState<Map<string, ScreenshotData>>(initialScreenshots);

  const addScreenshot = (
    screenshotId: string,
    screenshot: ScreenshotData
  ): void => {
    setScreenshots(map => new Map(map).set(screenshotId, screenshot));
  };

  return (
    <context.Provider
      value={{
        screenshots,
        addScreenshot
      }}
    >
      {children}
    </context.Provider>
  );
};

interface ScreenshotState {
  loading: boolean;
  screenshot: ScreenshotData | null;
  error: Error | null;
}

export const useScreenshot = (screenshotId: string): ScreenshotState => {
  const { screenshots, addScreenshot } = React.useContext(context);
  const { user } = useAuthContext();
  const secureApiFF = useFeatureFlagState(SECURE_SCREENSHOTS_API_FEATURE_FLAG);
  const screenshot = screenshots.get(screenshotId);
  const screenshotState: ScreenshotState = {
    loading: false,
    screenshot: screenshot || null,
    error: null
  };

  const dataLoader = useCallback(async () => {
    if (!screenshotId) {
      return null;
    }

    if (screenshot) {
      return screenshot;
    }

    const [loadedScreenshot, loadedMetadata] = await Promise.all([
      getScreenshotById(screenshotId, secureApiFF ? user?.token : undefined),
      getScreenshotMetadataById(
        screenshotId,
        secureApiFF ? user?.token : undefined
      )
    ]);

    addScreenshot(screenshotId, {
      blob: loadedScreenshot,
      metadata: loadedMetadata
    });

    return {
      blob: loadedScreenshot,
      metadata: loadedMetadata
    };
  }, [screenshotId, screenshot, user]);

  const state = useAPI(dataLoader);

  if (screenshot) {
    return screenshotState;
  }

  return {
    loading: state.loading,
    screenshot: state.data,
    error: state.error
  };
};
