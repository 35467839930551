import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../common/components/PageTitle';
import globalStyles from '../app.css';
import ApiKeyManagement from '../containers/ApiKeyManagement';

const Settings: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={globalStyles.wrap}>
      <PageTitle title={t('Settings')} />
      <ApiKeyManagement />
    </div>
  );
};

export default Settings;
