import React, { useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { AxePurchaseState } from '@deque/billing-service-client';
import { Loader } from '@deque/cauldron-react';
import {
  getAllUserSubscriptions,
  listProductAccess,
  ProductSlugs
} from '@deque/billing-utils';

import { useAuthContext } from '../contexts/auth';
import { useOnPremValue } from '../contexts/onPrem';
import { useGlobalToast } from '../contexts/globalToast';
import { useProducts } from '../contexts/products';
import { useServerInfo } from '../contexts/serverInfo';

export interface SubscriptionRequiredProps {
  productSlug: ProductSlugs;
  allowedStates?: AxePurchaseState[];
  redirectTo?: string;
  nonSelfProvisioningRedirectTo?: string;
  children: React.ReactNode;
}

const SubscriptionRequired: React.FC<SubscriptionRequiredProps> = ({
  productSlug,
  allowedStates = ['paid', 'trialing'],
  redirectTo = '/',
  children,
  nonSelfProvisioningRedirectTo = '/'
}: SubscriptionRequiredProps) => {
  const { t } = useTranslation();

  const isOnPrem = useOnPremValue();
  const { loading: authLoading, billingUser } = useAuthContext();
  const { loading: productsLoading, getProductBySlug } = useProducts();
  const { loading: serverInfoLoading, serverInfo } = useServerInfo();
  const { setContents } = useGlobalToast();
  const product = getProductBySlug(productSlug);
  const productAccess = useMemo(() => {
    if (!billingUser) {
      return;
    }

    const subscriptions = getAllUserSubscriptions(billingUser);
    const allAccess = listProductAccess(subscriptions);
    return allAccess[productSlug];
  }, [billingUser, productSlug]);

  useEffect(() => {
    if (!productAccess || !product) {
      return;
    }

    if (!allowedStates.includes(productAccess)) {
      setContents(
        t('You do not have permission to access {{ productName }}', {
          productName: product.name
        }),
        'caution'
      );
    }
  }, [productAccess, product]);

  let redirectPath = redirectTo;
  if (serverInfo?.isSelfProvisioningEnabled === false) {
    redirectPath = nonSelfProvisioningRedirectTo;
  }

  if (isOnPrem) {
    return <>{children}</>;
  }

  if (authLoading || productsLoading || serverInfoLoading) {
    return <Loader />;
  }

  if (!billingUser || !product) {
    return <Redirect to={redirectPath} />;
  }

  if (productAccess && !allowedStates.includes(productAccess)) {
    return <Redirect to={redirectPath} />;
  }

  return <>{children}</>;
};

export default SubscriptionRequired;
