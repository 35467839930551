import React, { useEffect } from 'react';
import { Prompt } from 'react-router';

interface ConfirmUnloadProps {
  message: string;
  enabled?: boolean;
}

const ConfirmUnload: React.FC<ConfirmUnloadProps> = ({
  message,
  enabled = true
}) => {
  useEffect(() => {
    const handleOnBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();

      return (e.returnValue = message);
    };

    if (enabled) {
      window.addEventListener('beforeunload', handleOnBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload);
    };
  }, [enabled]);

  return <Prompt when={enabled} message={message} />;
};

export default ConfirmUnload;
