import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@deque/cauldron-react';
import Loadable from '../../common/components/Loadable';
import styles from './LoadableGapTesting.css';

const GapTesting = React.lazy(
  () => /* webpackChunkName: "GapTesting" */ import('./GapTesting')
);

interface Props {
  category: string;
}

/** Wrapper around the `<GapTesting>` component, as it's _super_ heavy. */
const LoadableGapTesting: React.FC<Props> = ({ category }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null>(null);
  const [contents, setContents] = React.useState<string>();
  const loaderRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  React.useEffect(() => {
    setLoading(true);
    setError(null);

    import(`!!raw-loader!../../../../dist/gap-coverage/${category}.md`)
      .then(data => setContents(data.default))
      .catch((err: Error) => setError(err))
      .finally(() => setLoading(false));
  }, [category]);

  if (loading) {
    return (
      <Loader
        label={t('Loading coverage data')}
        tabIndex={-1}
        ref={loaderRef}
      />
    );
  }

  if (error) {
    return (
      <p className={styles.error}>
        {t(
          'An error occurred and we were unable to load {{ category }} coverage data.',
          { category }
        )}
      </p>
    );
  }

  return (
    <Loadable>
      <GapTesting contents={contents as string} />
    </Loadable>
  );
};

export default LoadableGapTesting;
