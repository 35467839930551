import { useMemo } from 'react';
import type {
  GeneralSettingsSchema as GlobalGeneralSettingsSchema,
  SettingsSchemaGeneral as IssueGeneralSettingsSchema
} from '@deque/orgwide-settings-client';
import { type ScreenshotConfigurationStates } from '@deque/ui-issues';
import { ScreenshotConfiguration } from '@deque/ui-issues';

type ScreenshotConfigurationState = ScreenshotConfigurationStates | null;

const useScreenshotConfiguration = (
  globalConfiguration: GlobalGeneralSettingsSchema | null,
  issueConfiguration: IssueGeneralSettingsSchema | null
): ScreenshotConfigurationState => {
  const getScreenshotConfiguration = (): ScreenshotConfigurationState => {
    const globalSetting = globalConfiguration?.issueScreenshotSharing?.value;
    const issueSetting = issueConfiguration?.issueScreenshotSharing?.value;
    const issueSettingType = issueConfiguration?.issueScreenshotSharing?.type;

    if (!globalSetting || !issueSetting) {
      return null;
    }

    if (globalSetting === 'enabled' && issueSetting === 'enabled') {
      return ScreenshotConfiguration.On;
    }

    if (globalSetting === 'enabled' && issueSetting === 'disabled') {
      return ScreenshotConfiguration.MixedOn;
    }

    if (globalSetting === 'disabled' && issueSetting === 'enabled') {
      return ScreenshotConfiguration.MixedOff;
    }

    if (globalSetting === 'disabled' && issueSetting === 'disabled') {
      if (issueSettingType === 'fixed') {
        return ScreenshotConfiguration.OffLocked;
      }
      return ScreenshotConfiguration.Off;
    }

    return null;
  };

  return useMemo(getScreenshotConfiguration, [
    globalConfiguration?.issueScreenshotSharing?.value,
    issueConfiguration?.issueScreenshotSharing?.value,
    issueConfiguration?.issueScreenshotSharing?.type
  ]);
};

export default useScreenshotConfiguration;
