import React, { FC } from 'react';
import {
  AdminFeatureFlag,
  updateAdminFeatureFlag
} from '../../common/api-client';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../common/contexts/auth';
import { Checkbox } from '@deque/cauldron-react';

type FeatureFlagToggleProps = {
  disabled: boolean;
  feature: AdminFeatureFlag;
};

const FeatureFlagToggle: FC<FeatureFlagToggleProps> = ({
  feature,
  disabled
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = useAuthContext().user!;
  const [state, setState] = React.useState(feature.default_state);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!window.confirm(t('Are you sure?'))) {
      e.preventDefault();
      return;
    }

    const val = !state;

    setLoading(true);
    setError(null);
    try {
      await updateAdminFeatureFlag({
        token: user.token,
        featureId: feature.id,
        defaultState: val,
        description: feature.description,
        productName: feature.product_name
      });
      setState(val);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Checkbox
      disabled={disabled || loading}
      id={`feature_state_toggle_${feature.id}`}
      label={t('Enabled')}
      error={error?.message}
      checked={state}
      onChange={handleChange}
    />
  );
};

export default FeatureFlagToggle;
