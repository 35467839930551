import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  TooltipContent,
  TooltipHead,
  Line
} from '@deque/cauldron-react';

interface PasswordRequirementsProps {
  namespace: string;
  requirements: string[];
  target: React.RefObject<HTMLInputElement>;
}

const PasswordRequirements = ({
  namespace,
  requirements,
  target
}: PasswordRequirementsProps): React.ReactElement | null => {
  const { t } = useTranslation();

  return requirements.length ? (
    <Tooltip target={target} variant="big" placement="auto-start">
      <TooltipHead>{t('Password requirements')}</TooltipHead>
      <Line />
      <TooltipContent>
        <ul>
          {requirements.map(requirement => (
            <li key={`${namespace}${requirement}`}>{requirement}</li>
          ))}
        </ul>
      </TooltipContent>
    </Tooltip>
  ) : null;
};

export default PasswordRequirements;
