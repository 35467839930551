import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';
import { Icon, Link as CauldronLink } from '@deque/cauldron-react';

import AnalyticsEnabledLink from './AnalyticsEnabledLink';

export interface Resource extends Pick<RouterLinkProps, 'aria-label'> {
  name?: string;
  url: string;
  openInNewTab?: boolean;
  variant?: 'button' | 'button-secondary';
  thin?: boolean;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
}

const Link: React.FC<Resource> = ({
  name,
  url,
  variant,
  openInNewTab = false,
  thin = false,
  onClick,
  className,
  children,
  ...routerLinkProps
}) => {
  const { t } = useTranslation();
  const target = openInNewTab ? '_blank' : undefined;
  const rel = openInNewTab ? 'noopener noreferrer' : undefined;
  const icon = openInNewTab ? (
    <Icon type="external-link" label={' ' + t('Open in new tab')} /> // Extra space is intentional
  ) : undefined;

  const navigate = (href: string) => {
    if (openInNewTab) {
      window.open(href, '_blank', 'noopener,noreferrer');
    } else {
      window.open(href);
    }
  };

  if (url.startsWith('http')) {
    return (
      <AnalyticsEnabledLink
        href={url}
        navigate={navigate}
        sendClickEvent={onClick}
        as={CauldronLink}
        target={target}
        rel={rel}
        variant={variant}
        thin={thin}
        className={className}
      >
        {children || name || url}
        {icon}
      </AnalyticsEnabledLink>
    );
  }

  const isButton = variant && ['button', 'button-secondary'].includes(variant);
  const linkClassNames = [];
  if (variant === 'button') {
    linkClassNames.push('Button--primary');
  } else if (variant === 'button-secondary') {
    linkClassNames.push('Button--secondary');
  }
  if (isButton && thin) {
    linkClassNames.push('Button--thin');
  } else if (!isButton) {
    linkClassNames.push('Link');
  }

  const classNameProp = classNames([linkClassNames], className);

  return (
    <RouterLink
      to={url}
      target={target}
      rel={rel}
      className={classNameProp}
      onClick={onClick}
      {...routerLinkProps}
    >
      {children || name || url}
      {icon}
    </RouterLink>
  );
};

export default Link;
