import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Link, Loader } from '@deque/cauldron-react';
import {
  ConfigurationContext,
  IssuePage,
  type IssueType
} from '@deque/ui-issues';
import { mapIssueToSavedIssue } from '@deque/axe-devtools-issues-utils';
import type { GeneralSettingsSchema } from '@deque/orgwide-settings-client';
import { Trans, useTranslation } from 'react-i18next';
import { useAuthContext } from '../../common/contexts/auth';
import useIssue from '../hooks/useIssue';
import useScreenshotConfiguration from '../hooks/useScreenshotConfigurationState';
import ErrorMessage from '../components/issue/ErrorMessage';
import PageTitle from '../../common/components/PageTitle';
import { useAxeDevtoolsProAnalytics } from '../../common/contexts/analytics';
import { isNotFoundError } from '../../common/utils/errors';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { useScreenshot } from '../../common/contexts/Screenshot';
import { useConfiguration } from '../../common/contexts/Configuration';
import styles from './Issue.css';
import '@deque/ui-issues/styles';

interface RouteParams {
  id: string;
}

interface Props {
  className?: string;
  loadIssueData?: typeof useIssue;
}

const IssueV2 = ({ loadIssueData = useIssue }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  const loaderRef = useRef<HTMLDivElement>(null);
  const { user, login } = useAuthContext();
  const { settings, loading: configurationLoading } = useConfiguration();
  const screenshotFeatureFlagEnabled = useFeatureFlagState('screenshots_v1');
  const integrationsFeatureFlagEnabled = useFeatureFlagState('integrations_v1');
  const {
    issue,
    loading: issueLoading,
    error: issueError
  } = loadIssueData(id, user?.token);

  const {
    screenshot,
    loading: screenshotLoading,
    error: screenshotError
  } = useScreenshot(issue?.screenshot_id as string);

  const mappedIssue = issue
    ? mapIssueToSavedIssue<IssueType>(issue, {
        help: issue?.help,
        ruleId: issue?.rule,
        integrations: issue?.integrationUrl
          ? [{ type: 'jira', url: issue?.integrationUrl }]
          : []
      })
    : null;

  const screenshotConfiguration = useScreenshotConfiguration(
    settings?.general ?? null,
    (issue?.general_settings as GeneralSettingsSchema) ?? null
  );

  const analytics = useAxeDevtoolsProAnalytics();

  const handleLogin = (): void => {
    login();
  };

  const screenshotStatus = screenshotLoading
    ? 'loading'
    : screenshotError
    ? 'error'
    : 'complete';

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  useEffect(() => {
    if (mappedIssue) {
      analytics.issueViewSharedIssue({
        ruleName: mappedIssue.ruleId
      });
    }
  }, [mappedIssue, analytics]);

  if (issueLoading || configurationLoading || screenshotLoading) {
    return (
      <div className={styles.page}>
        <PageTitle title={t('Loading issue data')} />
        <Loader label={t('Loading issue data')} tabIndex={-1} ref={loaderRef} />
      </div>
    );
  }

  if (!issue || isNotFoundError(issueError as Error)) {
    const errorMessage = user ? (
      t('You may need to ensure you have access to this shared issue.')
    ) : (
      <Trans>
        You may need to{' '}
        <Button onClick={handleLogin} variant="link">
          sign in
        </Button>{' '}
        or ensure you have access to this shared issue.
      </Trans>
    );

    return (
      <div className={styles.page}>
        <div className={styles.error}>
          <PageTitle title={t('Shared issue not found')} />
          <ErrorMessage
            error={
              <>
                <Trans>We were unable to find this issue.</Trans> {errorMessage}
              </>
            }
            title={t('Shared issue not found')}
          />
        </div>
      </div>
    );
  }

  if (issueError || !issue) {
    return (
      <>
        <PageTitle title={t('Failed to load shared issue')} />
        <ErrorMessage
          error={
            <>
              <Trans>We were unable to load this issue.</Trans>{' '}
              <Trans>
                Please try again, and if the issue persists, contact{' '}
                <Link href="mailto:helpdesk@deque.com">helpdesk@deque.com</Link>
                .
              </Trans>
            </>
          }
          title={t('Failed to load shared issue')}
        />
      </>
    );
  }

  const screenshotURL = screenshot
    ? URL.createObjectURL(screenshot?.blob as Blob)
    : null;

  return (
    <ConfigurationContext.Provider
      value={{
        screenshots: screenshotFeatureFlagEnabled,
        integrations: integrationsFeatureFlagEnabled
      }}
    >
      <IssuePage
        issue={{
          ...mappedIssue,
          screenshotURL
        }}
        //Temporarely pass empty <span>, because header expecting this prop to be passed.
        // Comment referrence: https://github.com/dequelabs/walnut/pull/9837#discussion_r1828274832
        leadingHeaderActions={<span />}
        screenshotStatus={screenshotStatus}
        screenshotConfiguration={screenshotConfiguration}
      />
    </ConfigurationContext.Provider>
  );
};

export default IssueV2;
