import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Offscreen,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@deque/cauldron-react';
import { v2 } from '@deque/billing-service-client';
import useSortableCol, { Sort } from '../../utils/useSortableCol';
import styles from './TeamTable.css';

type TableSort = Sort<v2.TeamSortableColumn>;

export interface TeamTableProps {
  teams: v2.Team[];
  deleteTeam: (team: v2.Team) => void;
  sort?: TableSort;
  setSort: (value?: TableSort) => void;
}

const TeamTable: React.FC<TeamTableProps> = ({
  teams,
  sort,
  deleteTeam,
  setSort
}: TeamTableProps) => {
  const { t } = useTranslation();

  const sortable = useSortableCol({ sort, setSort });

  const tableRow = (team: v2.Team) => {
    return (
      <TableRow key={`team-${team.id}`}>
        <TableCell>{team.name}</TableCell>
        <TableCell>{team.member_count}</TableCell>
        <TableCell>{new Date(team.updated_at).toLocaleString()}</TableCell>
        <TableCell>
          <IconButton
            icon="pencil"
            as={Link}
            to={`/teams/edit/${team.id}`}
            label={
              <>
                {t('Edit')}
                <Offscreen> {team.name}</Offscreen>
              </>
            }
          />
          <IconButton
            icon="trash"
            onClick={() => deleteTeam(team)}
            label={
              <>
                {t('Delete')}
                <Offscreen> {team.name}</Offscreen>
              </>
            }
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className={styles.teamTable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader scope="col" {...sortable('name')}>
              {t('Team Name')}
            </TableHeader>
            <TableHeader scope="col" {...sortable('member_count')}>
              {t('Members')}
            </TableHeader>
            <TableHeader scope="col" {...sortable('updated_at')}>
              {t('Last Modified')}
            </TableHeader>
            <TableHeader scope="col">{t('Actions')}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>{teams.map(team => tableRow(team))}</TableBody>
      </Table>
    </div>
  );
};

export default TeamTable;
