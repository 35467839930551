import { useCallback } from 'react';
import { SortDirection, v2 } from '@deque/billing-service-client';
import { Paginated } from '@deque/pagination-utils';

import billingClient from '../../common/utils/billing-client/client-v2';
import useAPI, { State, Methods } from '../../common/hooks/useAPI';

export interface TeamsState extends Omit<State<Paginated<v2.Team[]>>, 'data'> {
  teams: Paginated<v2.Team[]> | null;
}

export type UseTeamsState = TeamsState & Methods;

interface UseTeamsParams {
  token: string;
  enterpriseId: string;
  name?: string;
  page?: number;
  per_page?: number;
  sortBy?: v2.TeamSortableColumn;
  sortDir?: SortDirection;
}

const useTeams = ({
  token,
  enterpriseId,
  name,
  page = 1,
  per_page = 10,
  sortBy,
  sortDir
}: UseTeamsParams): UseTeamsState => {
  const dataLoader = useCallback(
    () =>
      billingClient.teams.list({
        token,
        enterpriseId,
        name,
        page,
        per_page,
        sortBy,
        sortDir
      }),
    [token, enterpriseId, name, page, per_page, sortBy, sortDir]
  );

  const state = useAPI(dataLoader);

  return {
    teams: state.data || null,
    loading: state.loading,
    error: state.error,
    refresh: state.refresh
  };
};

export default useTeams;
