import type { ProductWithPricing } from '@deque/billing-service-client';
import { apiRequest } from '../../../api-client';

export default {
  /**
   * Get the products
   */
  list(): Promise<ProductWithPricing[]> {
    return apiRequest<ProductWithPricing[]>({
      endpoint: '/api/billing/products',
      method: 'GET'
    });
  }
};
