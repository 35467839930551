import { Icon, TextField, TooltipTabstop } from '@deque/cauldron-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  FieldPathByValue,
  FieldValues
} from 'react-hook-form';

import styles from './FeatureFlagIdField.css';

type FeatureFlagIdFieldProps<
  NameType extends FieldPathByValue<ControlType, string>,
  ControlType extends FieldValues
> = {
  name: NameType;
  control: Control<ControlType>;
};

const FeatureFlagIdField = <
  ControlType extends FieldValues,
  NameType extends FieldPathByValue<ControlType, string>
>({
  name,
  control
}: FeatureFlagIdFieldProps<NameType, ControlType>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapperFeatureFlagIdField}>
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: true,
            message: t('A flag’s name required!')
          },
          validate: {
            value: (id: string) => {
              if (!id.match(/^[a-z0-9-_]+$/i)) {
                return t(
                  'Feature flag ID must be alphanumeric with dashes and/or underscores'
                );
              }

              if (!id.match(/^(?!.*(?:old_|_old|_old_|_new_|_new|new_)).*$/)) {
                return t(
                  'A flag’s name should not contain adjectives like “new” or “old”. A feature will only be new for a short period of time. Once it’s no longer new, the feature name no longer makes sense.'
                );
              }

              return true;
            }
          }
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              label="ID"
              error={fieldState.error?.message}
              required
              {...field}
            />
          );
        }}
      />
      <TooltipTabstop
        tooltip={t(
          'A flag’s name should be self-documenting, short and easy to “copy/paste”. Examples are: “splash_heading_v1” or “axe_rebranding_2019”.'
        )}
        variant="info"
      >
        <Icon type="question-circle" label={t('Flag’s name description')} />
      </TooltipTabstop>
    </div>
  );
};

export default FeatureFlagIdField;
