import { PRODUCT_URLS, ProductSlugs } from '../../common/constants';

type ProductUrls = typeof PRODUCT_URLS;

const getProductFromUrl = (url: string) => {
  if (!url) {
    return;
  }
  // If the product slug is not in the PRODUCT_URLS object, we don't currently support it for url paths
  const productSlugs = Object.keys(PRODUCT_URLS);
  const productParam = new URL(url, window.location.origin).searchParams
    .get('product')
    ?.split('?')[0] // Remove additional query params
    ?.split('/')[0]; // Remove additional subpaths

  const productInPath = new URL(url, window.location.origin).pathname.split(
    '/'
  )[1];

  const matchingSlug = productSlugs.find(slug => {
    const productUrl = PRODUCT_URLS[slug as keyof ProductUrls];
    // Prioritize the product param over the url path
    if (
      productParam &&
      Object.values(ProductSlugs).includes(productParam as ProductSlugs)
    ) {
      return slug === productParam;
    }
    return (
      ('root' in productUrl &&
        productUrl.root.split('/')[1] === productInPath) ||
      slug === productInPath
    );
  }) as ProductSlugs | undefined;

  return matchingSlug;
};

export default getProductFromUrl;
