import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as CauldronLink } from '@deque/cauldron-react';

import { useAxeAccountAnalytics } from '../contexts/analytics';

export type NavigateFn = (url: string) => void;

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  // Override the `navigate` implementation (useful for testing).
  navigate?: NavigateFn;
  variant?: 'button' | 'button-secondary';
  thin?: boolean;
  sendClickEvent?: () => void;
  children: React.ReactNode;
  as?: 'a' | typeof CauldronLink;
}

/**
 * Default `navigate()` implementation.
 */

export const defaultNavigate: NavigateFn = url => {
  window.location.href = url;
};

/**
 * Render a link (`<a>`) which sends analytics on click.
 *
 * Will gracefully degrade when unable to send events (eg Amplitude's servers are down, the user has an ad-blocker, etc.).
 */

const AnalyticsEnabledLink: React.FC<Props> = ({
  href,
  sendClickEvent,
  navigate = defaultNavigate,
  as = 'a',
  children,
  thin,
  ...props
}) => {
  const Link = as;

  const handleClick = async (
    e: React.MouseEvent<HTMLAnchorElement>
  ): Promise<void> => {
    e.preventDefault();
    if (sendClickEvent) {
      await sendClickEvent();
    }
    navigate(href);
  };

  const isButton =
    props.variant && ['button', 'button-secondary'].includes(props.variant);

  return (
    <Link {...props} href={href} onClick={handleClick} thin={isButton && thin}>
      {children}
    </Link>
  );
};

export default AnalyticsEnabledLink;

interface CommonProps {
  children?: React.ReactNode;
  navigate?: NavigateFn;
}

/**
 * Render a link to our Privacy Policy page which sends analytics.
 */

export const PrivacyPolicyLink: React.ComponentType<CommonProps> = props => {
  const { t } = useTranslation();
  const children = props.children || t('Privacy Policy');
  const analytics = useAxeAccountAnalytics();
  return (
    <AnalyticsEnabledLink
      href="https://www.deque.com/privacy-policy/"
      sendClickEvent={() => analytics.viewPrivacyPolicy()}
      navigate={props.navigate}
    >
      {children}
    </AnalyticsEnabledLink>
  );
};

/**
 * Render a link to our Privacy Policy page which sends analytics.
 */

export const LicenseAgreementLink: React.ComponentType<CommonProps> = props => {
  const { t } = useTranslation();
  const children = props.children || t('Terms of Service');
  const analytics = useAxeAccountAnalytics();
  return (
    <AnalyticsEnabledLink
      href="https://www.deque.com/terms-of-use/axe-devtools-online/"
      sendClickEvent={() => analytics.viewLicenseAgreement()}
      navigate={props.navigate}
    >
      {children}
    </AnalyticsEnabledLink>
  );
};
