import { findJiraFieldByKey } from './findJiraFieldByKey';
import type { JiraFieldMapper } from './types';
import type { IssueTypeField } from '../getIssueTypeFields';

export class DateMapper implements JiraFieldMapper {
  id = 'jira-date-mapper';
  validCustomKeys = [
    'com.atlassian.jira.plugin.system.customfieldtypes:datepicker',
    'com.atlassian.jira.plugin.system.customfieldtypes:datetime'
  ];
  validTypes = ['date', 'datetime'];

  canMapThisField(jiraSchema: IssueTypeField[], fieldKey: string): boolean {
    const targetField = findJiraFieldByKey(jiraSchema, fieldKey);

    if (!targetField) {
      return false;
    }

    const { type, custom } = targetField.schema;
    const isDate = this.validTypes.includes(type);
    const isCustom = !!custom;

    return isCustom ? isDate && this.validCustomKeys.includes(custom) : isDate;
  }

  formatFieldValue(
    jiraSchema: IssueTypeField[],
    fieldKey: string,
    sourceValue: unknown
  ): unknown {
    const fieldName = findJiraFieldByKey(jiraSchema, fieldKey)?.name;

    if (!this.canMapThisField(jiraSchema, fieldKey)) {
      throw new Error(`Can not map to '${fieldName}'`);
    }

    if (
      sourceValue === null ||
      sourceValue === undefined ||
      sourceValue instanceof Date
    ) {
      return sourceValue;
    }

    if (typeof sourceValue !== 'string' && typeof sourceValue !== 'number') {
      throw new Error(
        `Can not map to '${fieldName}': invalid date value provided`
      );
    }

    const date = new Date(sourceValue);

    if (isNaN(date.getTime())) {
      throw new Error(
        `Can not map to '${fieldName}': invalid date value provided`
      );
    }

    return date;
  }
}
