import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InstallExtension.css';
import { PROD_EXTENSION_URL } from '../../common/constants';

const InstallExtension = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <a
      href={PROD_EXTENSION_URL}
      className={styles.install}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div>
        <div>{t('Install the extension!')}</div>
        <div>
          <em>{t('(on the chrome webstore)')}</em>
        </div>
      </div>
      <span className={styles.bubbles} />
    </a>
  );
};

export default InstallExtension;
