import React, { useRef, useEffect } from 'react';
import { TextField } from '@deque/cauldron-react';
import { Trans, useTranslation } from 'react-i18next';

export interface CompanyInformationStepProps {
  enterpriseName: string;
  enterpriseNameError: string;
  setEnterpriseName: (n: string) => void;
  setEnterpriseNameError: (n: string) => void;
}

const CompanyInformationStep = ({
  enterpriseName,
  enterpriseNameError,
  setEnterpriseName,
  setEnterpriseNameError
}: CompanyInformationStepProps) => {
  const { t } = useTranslation();
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!enterpriseNameError) {
      return;
    }

    input.current?.focus();
  }, [enterpriseNameError]);

  return (
    <>
      <Trans parent="p">
        <strong>Note:</strong> If your company name is unavailable, you can
        append your department, team, or location to your company name.
      </Trans>
      <TextField
        fieldRef={input}
        type="text"
        error={enterpriseNameError}
        value={enterpriseName}
        onChange={value => {
          setEnterpriseNameError('');
          setEnterpriseName(value);
        }}
        label={t('Company Name')}
      />
    </>
  );
};

export default CompanyInformationStep;
