import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PageTitle from '../../common/components/PageTitle';
import { useAxeDevtoolsProAnalytics } from '../../common/contexts/analytics';
import sadCorgi from '../assets/images/sad-corgi.png';
import styles from './Uninstalled.css';

const Uninstalled: React.FC = () => {
  const { t } = useTranslation();
  const analytics = useAxeDevtoolsProAnalytics();

  React.useEffect(() => {
    analytics.extensionUninstalled();
  }, []);

  return (
    <div className={styles.wrap}>
      <PageTitle title={t('Extension uninstalled')} />
      <h1>{t('Axe DevTools has been successfully uninstalled')}</h1>
      <p>
        <Trans>
          Sorry to see you go. If you removed it by accident,{' '}
          <a href="https://chrome.google.com/webstore/detail/axe-web-accessibility-tes/lhdoppojpmngadmnindnejefpokejbdd">
            reinstall here
          </a>
          .
        </Trans>
      </p>
      <img src={sadCorgi} alt="Sad corgi" />
    </div>
  );
};

export default Uninstalled;
