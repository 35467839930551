import { useCallback } from 'react';
import {
  getScreenshotMetadataById,
  ScreenshotMetadata
} from '../../common/api-client';
import useAPI, { State } from '../../common/hooks/useAPI';

export interface ScreenshotMetadataState
  extends Omit<State<ScreenshotMetadata>, 'data'> {
  metadata: ScreenshotMetadata | null;
}

const useScreenshotMetadata = (
  id: string | null | undefined,
  token?: string
): ScreenshotMetadataState => {
  const dataLoader = useCallback(() => {
    if (!id) {
      return Promise.resolve(null);
    }
    return getScreenshotMetadataById(id, token);
  }, [id, token]);
  const state = useAPI(dataLoader);
  return { loading: state.loading, error: state.error, metadata: state.data };
};

export default useScreenshotMetadata;
