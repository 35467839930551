import React, { useState, useEffect } from 'react';
import InstallExtensionComponent from '../components/InstallExtension';
import {
  EXTENSION_HEALTH_CHECK,
  EXTENSION_HEALTH_CHECK_RESPONSE
} from '../../common/constants';

const InstallExtension = (): React.ReactElement | null => {
  const [hideLink, setHideLink] = useState(false);
  const onMessage = (e: MessageEvent) => {
    if (e.data.message !== EXTENSION_HEALTH_CHECK_RESPONSE) {
      return;
    }

    setHideLink(true);
    window.removeEventListener('message', onMessage);
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);
    window.postMessage(
      {
        message: EXTENSION_HEALTH_CHECK
      },
      '*'
    );

    return () => window.removeEventListener('message', onMessage);
  }, []);

  return !hideLink ? <InstallExtensionComponent /> : null;
};

export default InstallExtension;
