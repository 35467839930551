import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../common/contexts/auth';
import styles from './DequersOnly.css';

interface Props {
  children: React.ReactNode;
}

const DequersOnly: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [authorized, setAuthorized] = React.useState(false);
  const { user } = useAuthContext();

  // If the logged-in user's email is `@deque.com`, they're authorized.
  React.useEffect(() => {
    setAuthorized(user?.email.endsWith('@deque.com') ?? false);
  }, [user]);

  // If the user doesn't have access, simply render an error.
  if (!authorized) {
    return (
      <div className={styles.unauthorized}>
        <h1>{t('Unauthorized')}</h1>
        <strong>{t('You do not have permission to view this page.')}</strong>
      </div>
    );
  }

  return <>{children}</>;
};

export default DequersOnly;
