import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, PanelContent, PanelHeader } from '@deque/cauldron-react';
import { ProductSlugs } from '@deque/billing-utils';
import classNames from 'classnames';

import Link, { Resource } from '../../common/components/Link';
import { PRODUCT_URLS } from '../../common/constants';
import ProductLogo from '../../common/components/ProductLogo';
import styles from './V2ProductCard.css';

export interface ProductCardProps {
  title: string;
  description?: string;
  subtitle?: ReactNode;
  productSlug?: ProductSlugs;
  statusMessage?: string;
  statusMessageVariant?: 'warn' | 'alert' | 'success';
  planName?: ReactNode;
  admins?: string[];
  resources?: Resource[];
  children?: ReactNode;
}

const ProductCard = ({
  title,
  subtitle,
  description,
  productSlug,
  statusMessage,
  statusMessageVariant,
  planName,
  admins = [],
  resources = [],
  children
}: ProductCardProps): ReactElement => {
  const { t } = useTranslation();
  const showResources = !!resources.length;

  return (
    <div className={styles.wrap}>
      <Panel>
        {statusMessage && (
          <div className={styles.badgeContainer}>
            <div
              className={classNames(styles.statusMessage, {
                [styles.warn]: statusMessageVariant === 'warn',
                [styles.alert]: statusMessageVariant === 'alert'
              })}
              data-testid="statusmessage"
            >
              {statusMessage}
            </div>
          </div>
        )}
        <PanelHeader>
          <h3>
            <ProductLogo
              title={title}
              subtitle={subtitle}
              homeURL={
                productSlug === ProductSlugs.axeDevToolsExtension
                  ? PRODUCT_URLS[ProductSlugs.axeDevToolsExtension].plans
                  : undefined
              }
            />
          </h3>
        </PanelHeader>
        <PanelContent>
          {planName && (
            <>
              <h4>{t('Plan')}</h4>
              <p>{planName}</p>
            </>
          )}
          {admins.length > 0 && (
            <>
              <h4>{admins.length === 1 ? t('Admin') : t('Admins')}</h4>
              <ul>
                {admins.map(admin => (
                  <li key={admin}>{admin}</li>
                ))}
              </ul>
            </>
          )}
          {description && <p>{description}</p>}
          {showResources && (
            <div>
              <h4>{t('Resources')}</h4>
              <ul>
                {resources.map(resource => (
                  <li
                    key={resource.name}
                    className={
                      resource.name === 'axe DevTools Mobile Analyzer Docs' ||
                      resource.name === 'Sample Android App'
                        ? 'mobile-space'
                        : ''
                    }
                  >
                    <Link {...resource} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {children}
        </PanelContent>
      </Panel>
    </div>
  );
};

export default ProductCard;
