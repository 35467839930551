import type { v2 } from '@deque/billing-service-client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrices } from '@deque/billing-utils';

import classes from './LicenseBox.css';

export interface LicenseBoxProps {
  currentLicenseCount: number;
  licenseChangeCount: number;
  term: v2.SubscriptionPricing['interval'];
  price: number;
  totalLicensesValue: number;
  enterpriseName?: string;
}

const LicenseBox = ({
  currentLicenseCount,
  licenseChangeCount,
  term,
  price,
  totalLicensesValue,
  enterpriseName
}: LicenseBoxProps) => {
  const { t } = useTranslation();
  const termName = term === 'monthly' ? t('month') : t('year');

  return (
    <>
      <dl className={classes.whiteList}>
        {enterpriseName && (
          <div>
            <dt className={classes.label}>
              <strong>{t('Company Name: ')}</strong>
            </dt>
            <dd>{enterpriseName}</dd>
          </div>
        )}
        <div>
          <dt className={classes.label}>{t('Current licenses: ')}</dt>
          <dd>
            {t('{{currentLicenseCount}} (${{price}}/{{termName}})', {
              currentLicenseCount,
              price: formatPrices(currentLicenseCount * price),
              termName
            })}
          </dd>
        </div>
        <div>
          <dt className={classes.label}>
            {t('Number you\u0027d like to change: ')}
          </dt>
          <dd>
            {licenseChangeCount &&
              `${licenseChangeCount < 0 ? '' : '+'}${licenseChangeCount}`}
          </dd>
        </div>
      </dl>
      <dl className={classes.grayList}>
        <div>
          <dt className={classes.label}>{t('Total number of licenses: ')}</dt>
          <dd>{totalLicensesValue}</dd>
        </div>
        <div>
          <dt className={classes.label}>
            {term === 'monthly'
              ? t('Total monthly price: ')
              : t('Total annual price: ')}
          </dt>
          <dd>
            {totalLicensesValue &&
              t('${{price}}/{{termName}}', {
                price: formatPrices(totalLicensesValue * price),
                termName
              })}
          </dd>
        </div>
      </dl>
    </>
  );
};

export default LicenseBox;
