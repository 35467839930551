import { findJiraFieldByKey } from './findJiraFieldByKey';
import type { JiraFieldMapper, JiraFieldSchema } from './types';

export class UrlMapper implements JiraFieldMapper {
  id = 'jira-url-mapper';

  canMapThisField(jiraSchema: JiraFieldSchema[], fieldKey: string): boolean {
    const targetField = findJiraFieldByKey(jiraSchema, fieldKey);

    if (!targetField) {
      return false;
    }

    const { type, custom } = targetField.schema;
    const isCustomType = !!custom;
    const isString = type === 'string';

    return (
      isString &&
      isCustomType &&
      custom === 'com.atlassian.jira.plugin.system.customfieldtypes:url'
    );
  }

  formatFieldValue(
    jiraSchema: JiraFieldSchema[],
    fieldKey: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sourceValue: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    const maxUrlLength = 255;
    const urlRegExp = /^http(s)*:\/{2}/;
    const fieldName = findJiraFieldByKey(jiraSchema, fieldKey)?.name;

    if (!this.canMapThisField(jiraSchema, fieldKey)) {
      throw new Error(`Can not map to '${fieldName}'`);
    }

    const isValidValue =
      typeof sourceValue === 'string' && urlRegExp.test(sourceValue);

    if (!isValidValue) {
      throw new Error(
        `Can not map to '${fieldName}': mapped value should be URL string`
      );
    }

    if (sourceValue.length > maxUrlLength) {
      throw new Error(
        `Can not map to '${fieldName}': mapped value length more than ${maxUrlLength}`
      );
    }

    return sourceValue;
  }
}
