import type { Config } from 'amplitude-js';
import amplitudeJs from 'amplitude-js';
import TagManager from 'react-gtm-module';
import removeTrailingSlash from 'remove-trailing-slash';
import { setDistinctIdCookie } from '@deque/distinct-id-client';
import type { AxePurchaseState } from '@deque/billing-service-client';
import { getDistinctIdCookie, type Cookie } from '@deque/distinct-id-client';

import {
  ProductSlugs,
  USER_JOB_ROLES,
  SupportedIntegrationProductSlugs
} from './constants';
import { AuthUser } from './contexts/auth';
import type { SignedUpUser } from './api-client';

const DEFAULT_TIMEOUT = 5000; // Default timeout for amplitude calls in ms

export enum AnalyticsAmplitudeEvents {
  SIGNUP = 'conversion:signup',
  VIEW_SIGNUP = 'conversion:view:signup',
  CLICK_GITHUB = 'conversion:click:github',
  CLICK_GOOGLE = 'conversion:click:google',
  CLICK_CREATE_ACCOUNT = 'conversion:click:createAccount',
  CLICK_AXE_DEVTOOLS = 'conversion:click:axeDevtools',
  TRIAL_START = 'conversion:trialstart',
  ACCEPT_TERMS = 'conversion:terms:accept',
  CLICK_ADD_USERS = 'expansion:addUsers:click',
  CLICK_CHANGE_PLAN = 'expansion:changePlan:click',
  SUBMIT_LICENSE_CHANGE = 'expansion:submit',
  INTEGRATION_CONNECT = 'integration:connect',
  INTEGRATION_CONNECT_FAIL = 'integration:connect:fail',
  INTEGRATION_DISCONNECT = 'integration:disconnect',
  INTEGRATION_DISCONNECT_FAIL = 'integration:disconnect:fail',
  INTEGRATION_TEMPLATE_ADD_CLICK = 'integration:template:add:click',
  INTEGRATION_TEMPLATE_ADD_CANCEL = 'integration:template:add:cancel',
  INTEGRATION_TEMPLATE_ADD_SAVE = 'integration:template:add:save',
  INTEGRATION_TEMPLATE_ADD_FAIL = 'integration:template:add:fail',
  INTEGRATION_TEMPLATE_EDIT_CLICK = 'integration:template:edit:click',
  INTEGRATION_TEMPLATE_EDIT_CANCEL = 'integration:template:edit:cancel',
  INTEGRATION_TEMPLATE_EDIT_SAVE = 'integration:template:edit:save',
  INTEGRATION_TEMPLATE_EDIT_FAIL = 'integration:template:edit:fail',
  INTEGRATION_TEMPLATE_DELETE_CLICK = 'integration:template:delete:click',
  INTEGRATION_TEMPLATE_DELETE_FAIL = 'integration:template:delete:fail',
  INTEGRATION_TEMPLATE_DELETE_SUCCESS = 'integration:template:delete:success',
  INTEGRATION_ISSUE_SEND_CLICK = 'integration:issue:send:click',
  INTEGRATION_ISSUE_SEND_SUCCESS = 'integration:issue:send:success',
  INTEGRATION_ISSUE_SEND_FAILURE = 'integration:issue:send:failure'
}

export interface InitParams {
  instanceId: ProductSlugs | 'axe-account';
  gtmId?: string;
  amplitudeApiKey?: string;
  amplitudeTimeout?: number;
  config?: Config;
}

export interface IdentifyParams {
  user: AuthUser | SignedUpUser;
  billingStatus?: AxePurchaseState;
}

export interface SendAmplitudeEventParams<T> {
  event: T;
  data?: unknown;
  callback?: amplitude.Callback;
}

const isAuthUser = (user: AuthUser | SignedUpUser): user is AuthUser =>
  'roles' in user;

export default abstract class Analytics<T extends string> {
  protected amplitude: amplitudeJs.AmplitudeClient;
  protected AMPLITUDE_KEY_GROUP = 'organization';
  protected AMPLITUDE_KEY_ROLE = 'role';
  protected AMPLITUDE_KEY_STATUS = 'userStatus';
  protected AMPLITUDE_KEY_ENV = 'environment';
  public distinctIdCookie: Cookie | undefined;
  public didInitializeAmplitude = false;
  protected amplitudeTimeout: number;

  constructor({
    instanceId,
    gtmId,
    amplitudeApiKey,
    amplitudeTimeout,
    config
  }: InitParams) {
    this.amplitude = amplitudeJs.getInstance(instanceId);
    this.amplitudeTimeout = amplitudeTimeout || DEFAULT_TIMEOUT;

    if (amplitudeApiKey) {
      const opts: Config = {
        includeUtm: true,
        saveParamsReferrerOncePerSession: false,
        includeGclid: true,
        includeReferrer: true,
        secureCookie: true,
        sameSiteCookie: 'Lax',
        ...config
      };

      this.amplitude.init(amplitudeApiKey, undefined, opts);
      this.didInitializeAmplitude = true;
    } else {
      // Reset (for tests)
      this.didInitializeAmplitude = false;
    }

    // Only initialize GTM when provided an ID.
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }

    // Lazily fetch/set the distinct ID cookie.
    getDistinctIdCookie({ host: window.location.origin }).then(
      cookie => (this.distinctIdCookie = cookie)
    );
  }

  public identify({
    user,
    billingStatus = 'none'
  }: IdentifyParams): Promise<unknown> {
    if (!this.didInitializeAmplitude) {
      return Promise.resolve();
    }

    this.amplitude.setUserId(user.id);
    if (user.company) {
      this.amplitude.setGroup(this.AMPLITUDE_KEY_GROUP, user.company);
    }

    const id = new this.amplitude.Identify();
    if (isAuthUser(user)) {
      const role = user.roles.find(r =>
        // Cast to avoid "Argument of type 'string' is not assignable to parameter of type" error.
        (USER_JOB_ROLES as unknown as string[]).includes(r)
      );
      if (role) {
        id.set(this.AMPLITUDE_KEY_ROLE, role);
      }
    }
    id.set(this.AMPLITUDE_KEY_STATUS, billingStatus);
    id.set(this.AMPLITUDE_KEY_ENV, window.appConfig.DEPLOY_ENV || '');

    return new Promise(resolve => this.amplitude.identify(id, resolve));
  }

  protected sendAmplitudeEvent({
    event,
    data
  }: SendAmplitudeEventParams<T>): void | Promise<void> {
    if (!this.didInitializeAmplitude) {
      return;
    }
    const timeoutPromise = new Promise<void>(resolve =>
      setTimeout(() => {
        resolve();
      }, this.amplitudeTimeout)
    );
    const amplitudePromise = new Promise<void>((resolve, reject) => {
      this.amplitude.logEvent(event, data, (status, response, details) => {
        if (status === 200) {
          return resolve();
        }

        reject(
          new Error(
            `An error occurred while tracking the event: ${response} due to ${details?.reason}`
          )
        );
      });
    });

    return Promise.race([amplitudePromise, timeoutPromise]);
  }

  public signUp() {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.SIGNUP as T
    });
  }

  public async viewSignUp(params: { href: string }) {
    const data = await this.processDistinctIdCookieData(
      params.href,
      'webapp_organic_signup'
    );
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.VIEW_SIGNUP as T,
      data
    });
  }

  public clickGitHub() {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.CLICK_GITHUB as T
    });
  }

  public clickGoogle() {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.CLICK_GOOGLE as T
    });
  }

  public clickCreateAccount() {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.CLICK_CREATE_ACCOUNT as T
    });
  }

  public clickAxeDevtools() {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.CLICK_AXE_DEVTOOLS as T
    });
  }

  public trialStart(isTrialReactivation: boolean) {
    const cookieData = this.distinctIdCookie?.data;

    this.amplitude.setUserProperties({
      original_landing_page: cookieData?.original_landing_page,
      original_utm_source: cookieData?.original_utm_source
    });

    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.TRIAL_START as T,
      data: {
        // When possible, attach the landing page and utm_source the user came from.
        original_landing_page: cookieData?.original_landing_page,
        original_utm_source: cookieData?.original_utm_source,
        reactivation: isTrialReactivation
      }
    });
  }

  public acceptTerms() {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.ACCEPT_TERMS as T
    });
  }

  public clickAddUsers() {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.CLICK_ADD_USERS as T
    });
  }

  public clickChangePlan() {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.CLICK_CHANGE_PLAN as T
    });
  }

  public submitLicenseChange(data: {
    licenseCount: number;
    prevLicenseCount: number;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.SUBMIT_LICENSE_CHANGE as T,
      data
    });
  }

  public integrationConnect(data: {
    integration: SupportedIntegrationProductSlugs;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_CONNECT as T,
      data
    });
  }

  public integrationConnectFail(data: {
    integration: SupportedIntegrationProductSlugs;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_CONNECT_FAIL as T,
      data
    });
  }

  public integrationDisconnect(data: {
    integration: SupportedIntegrationProductSlugs;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_DISCONNECT as T,
      data
    });
  }

  public integrationDisconnectFail(data: {
    integration: SupportedIntegrationProductSlugs;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_DISCONNECT_FAIL as T,
      data
    });
  }

  public integrationTemplateAddClick(data: {
    integration: SupportedIntegrationProductSlugs;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_ADD_CLICK as T,
      data
    });
  }

  public integrationTemplateAddCancel(data: {
    integration: SupportedIntegrationProductSlugs;
    connectionId: string;
    enterpriseId: string;
    project?: string;
    issueType?: string;
    template?: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_ADD_CANCEL as T,
      data
    });
  }

  public integrationTemplateAddSave(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_ADD_SAVE as T,
      data
    });
  }

  public integrationTemplateAddFail(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_ADD_FAIL as T,
      data
    });
  }

  public integrationTemplateEditClick(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_EDIT_CLICK as T,
      data
    });
  }

  public integrationTemplateEditCancel(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_EDIT_CANCEL as T,
      data
    });
  }

  public integrationTemplateEditSave(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_EDIT_SAVE as T,
      data
    });
  }

  public integrationTemplateEditFail(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_EDIT_FAIL as T,
      data
    });
  }

  public integrationTemplateDeleteClick(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_DELETE_CLICK as T,
      data
    });
  }

  public integrationTemplateDeleteFail(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_DELETE_FAIL as T,
      data
    });
  }

  public integrationTemplateDeleteSuccess(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    connectionId: string;
    enterpriseId: string;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_TEMPLATE_DELETE_SUCCESS as T,
      data
    });
  }

  public integrationIssueSendClick(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    multiple: boolean;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_ISSUE_SEND_CLICK as T,
      data
    });
  }

  public integrationIssueSendSuccess(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    multiple: boolean;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_ISSUE_SEND_SUCCESS as T,
      data
    });
  }

  public integrationIssueSendFail(data: {
    integration: SupportedIntegrationProductSlugs;
    project: string;
    issueType: string;
    template: string;
    multiple: boolean;
  }) {
    return this.sendAmplitudeEvent({
      event: AnalyticsAmplitudeEvents.INTEGRATION_ISSUE_SEND_FAILURE as T,
      data
    });
  }

  protected async processDistinctIdCookieData(
    href: string,
    defaultOriginalUtmSource: string
  ): Promise<{ original_landing_page: string; original_utm_source: string }> {
    const cookieData = this.distinctIdCookie?.data;

    if (
      !cookieData?.original_landing_page &&
      !cookieData?.original_utm_source
    ) {
      const data = {
        original_landing_page: href,
        original_utm_source: defaultOriginalUtmSource
      };

      const host = removeTrailingSlash(window.appConfig.API_SERVER_URL || '');
      await setDistinctIdCookie({ data }, { host });
      this.amplitude.setUserProperties(data);
      return data;
    }

    return {
      original_landing_page: cookieData.original_landing_page as string,
      original_utm_source: cookieData.original_utm_source as string
    };
  }
}
