import type { v2 } from '@deque/billing-service-client';

function getSubscriptionPlan(
  products: v2.Product[],
  subscription: v2.UserSubscription | v2.EnterpriseSubscription | undefined
): string | undefined {
  if (!products || !products.length || !subscription) {
    return;
  }

  const subscriptionProduct = products.find(
    p => p.slug === subscription.product_slug
  );

  return subscriptionProduct?.stripe_data?.pricing.find(
    p => p.id === subscription?.pricing?.stripe_id
  )?.metadata?.plan;
}

export { getSubscriptionPlan };
export default getSubscriptionPlan;
