import React, { useEffect, useRef, useState } from 'react';
import styles from './PostSubmitSuccess.css';
import classNames from 'classnames';

interface PostSubmitSuccessProps {
  children: React.ReactNode;
  heading: React.ReactNode; // rendered within <h1 />
  className?: string;
}

/**
 * Renders success UI
 */
const PostSubmitSuccess = ({
  children,
  heading,
  className,
  ...rest
}: PostSubmitSuccessProps): React.ReactElement => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [hasFocused, setHasFocused] = useState(false);

  useEffect(() => {
    if (hasFocused || !wrapper.current) {
      return;
    }

    wrapper.current.focus();
    setHasFocused(true);
  }, [wrapper.current]);

  return (
    <div
      ref={wrapper}
      tabIndex={-1}
      className={classNames(styles.sent, className)}
      data-testid="postSubmitSuccess"
      {...rest}
    >
      <h1>{heading}</h1>
      {children}
    </div>
  );
};

export default PostSubmitSuccess;
