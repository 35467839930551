import React, { createRef, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Link, Loader } from '@deque/cauldron-react';
import { Trans, useTranslation } from 'react-i18next';
import qs from 'query-string';
import classNames from 'classnames';
import type { TFunction } from 'i18next';
import { useAuthContext } from '../../common/contexts/auth';
import useIssue from '../hooks/useIssue';
import { DEFAULT_LANGUAGE } from '../../common/constants';
import Navigation, { NavigationLink } from '../components/issue/Navigation';
import ElementInfo from '../components/issue/ElementInfo';
import HowToFix from '../components/issue/HowToFix';
import About from '../components/issue/About';
import ad from '../assets/images/shared-issue-ad.png';
import PageTitle from '../../common/components/PageTitle';
import styles from './Issue.css';
import { useAxeDevtoolsProAnalytics } from '../../common/contexts/analytics';
import { isNotFoundError } from '../../common/utils/errors';
import ErrorMessage from '../components/issue/ErrorMessage';

interface RouteParams {
  id: string;
}

interface Props {
  className?: string;
  // Allow passing mocked hooks for testing.
  loadIssueData?: typeof useIssue;
}

// ESLint false positive: https://github.com/typescript-eslint/typescript-eslint/issues/325
// eslint-disable-next-line no-shadow
enum IssueDivId {
  ElementInfoId = 'element-info',
  HowToFixId = 'how-to-fix',
  AboutId = 'about'
}
const refs = new Map<IssueDivId, React.RefObject<HTMLElement>>([
  [IssueDivId.ElementInfoId, createRef<HTMLElement>()],
  [IssueDivId.HowToFixId, createRef<HTMLElement>()],
  [IssueDivId.AboutId, createRef<HTMLElement>()]
]);

const navigationLinks = (
  hasRuleHelp: boolean,
  t: TFunction
): Array<NavigationLink> => {
  const links = [
    { id: IssueDivId.ElementInfoId, text: t('Element information'), order: 1 },
    { id: IssueDivId.AboutId, text: t('About this issue'), order: 3 }
  ];
  if (hasRuleHelp) {
    links.push({
      id: IssueDivId.HowToFixId,
      text: t('How to fix the problem'),
      order: 2
    });
  }
  return links;
};

const Ad: React.FC<Props> = () => {
  const { t } = useTranslation();
  const alt = t(
    'Find and fix more issues for free. Install free Chrome extension.'
  );
  const plansRoute =
    'https://chrome.google.com/webstore/detail/axe-devtools-web-accessib/lhdoppojpmngadmnindnejefpokejbdd?utm_source=browser_extension&utm_medium=referral&campaign=share_issue';
  return (
    <div className={styles.ad}>
      <a href={plansRoute}>
        <img src={ad} height={178} width={254} alt={alt} />
      </a>
    </div>
  );
};

const Issue: React.FC<Props> = ({ loadIssueData = useIssue }) => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  // Some issues require authentication before viewing them (eg those with `shared_with=users`).
  const { user, login } = useAuthContext();
  // Support /issues/:id?lang=fr, but default to English.
  const { search } = useLocation();
  const [hasRuleHelp, setHasRuleHelp] = useState(false);
  const loaderRef = React.useRef<HTMLDivElement>(null);
  const language = React.useMemo(
    () => (qs.parse(search).lang as string) || DEFAULT_LANGUAGE,
    [search]
  );
  const { loading, error, issue } = loadIssueData(id, user?.token);
  const ruleId = issue?.rule;

  const analytics = useAxeDevtoolsProAnalytics();

  useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  useEffect(() => {
    if (ruleId) {
      analytics.issueViewSharedIssue({
        ruleName: ruleId
      });
    }
  }, [ruleId]);

  const handleNavigationClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const linkId = (e.currentTarget.getAttribute('href') || '').substr(1);
    const ref = refs.get(linkId as IssueDivId)?.current;

    // If we don't have a <Section> for the quick nav link, let the browser handle the click itself.
    // This should not be possible.
    /* istanbul ignore next */
    if (!ref) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error('Invalid quick navigation link ID "%s"', linkId);
      }
      return;
    }

    e.preventDefault();

    // Since we only keep references to sections and the open state
    // is not exposed, we trigger the panel expansion with a click event.
    ref.click();
    ref.focus();
  };

  const handleLogin = (): void => {
    login();
  };

  if (loading) {
    return (
      <div className={styles.page}>
        <PageTitle title={t('Loading issue data')} />
        <Loader label={t('Loading issue data')} tabIndex={-1} ref={loaderRef} />
      </div>
    );
  }
  if (!issue || isNotFoundError(error as Error)) {
    return (
      <div className={styles.page}>
        <div className={styles.error}>
          <PageTitle title={t('Shared issue not found')} />
          <ErrorMessage
            error={
              <>
                <Trans>We were unable to find this issue.</Trans>{' '}
                {user ? (
                  t(
                    'You may need to ensure you have access to this shared issue.'
                  )
                ) : (
                  <Trans>
                    You may need to{' '}
                    <Button onClick={handleLogin} variant="link">
                      sign in
                    </Button>{' '}
                    or ensure you have access to this shared issue.
                  </Trans>
                )}
              </>
            }
            title={t('Shared issue not found')}
          />
        </div>
      </div>
    );
  } else if (error) {
    return (
      <>
        <PageTitle title={t('Failed to load shared issue')} />
        <ErrorMessage
          error={
            <>
              <Trans>We were unable to load this issue.</Trans>{' '}
              <Trans>
                Please try again, and if the issue persists, contact{' '}
                <Link href="mailto:helpdesk@deque.com">helpdesk@deque.com</Link>
                .
              </Trans>
            </>
          }
          title={t('Failed to load shared issue')}
        />
      </>
    );
  }

  return (
    <div className={styles.page}>
      <PageTitle title={t('Issue details | {{ id }}', { id })} />
      <div className={styles.navigation}>
        <Navigation
          title={t('Quick Navigation')}
          links={navigationLinks(hasRuleHelp, t)}
          onClickLink={handleNavigationClick}
        />
      </div>

      <div className={classNames('narrowCenteredContainer', styles.content)}>
        <header className={styles.header}>
          <Ad />
          <h1>{issue.help}</h1>
          <p>{issue.description}</p>
        </header>
        <ElementInfo
          ref={refs.get(IssueDivId.ElementInfoId)}
          id={IssueDivId.ElementInfoId}
          selector={issue.selector}
          source={issue.source}
          relatedNodes={issue.related_nodes}
          boundingBox={issue.bounding_box}
          screenshotId={issue.screenshot_id}
        />
        {ruleId && (
          <HowToFix
            ref={refs.get(IssueDivId.HowToFixId)}
            id={IssueDivId.HowToFixId}
            ruleId={ruleId}
            language={language}
            setHasRuleHelp={setHasRuleHelp}
          />
        )}
        <About
          ref={refs.get(IssueDivId.AboutId)}
          id={IssueDivId.AboutId}
          issue={issue}
          language={language}
        />
      </div>
    </div>
  );
};

export default Issue;
