import { v2 } from '@deque/billing-service-client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrices, Subscription } from '@deque/billing-utils';

import ProductCard from './V2ProductCard';
import BillingProductCardContent from './V2BillingProductCardContent';
import getStatusMessage from '../utils/get-status-message';
import Products from './Products';
import { isSubscriptionExpired, ProductSlugs } from '../../common/constants';
import { useProducts } from '../../common/contexts/products';
import type { Enterprises } from '../../common/utils/billing-client/client-v2';

export interface BillingProductsProps {
  subscriptions: v2.EnterpriseSubscription[] | v2.UserSubscription[];
  products: v2.Product[];
  isBillingAdmin: boolean;
  licenses: Enterprises.License[];
  onAssignLicenses: (
    subscription: v2.EnterpriseSubscription,
    count: number | null
  ) => Promise<void>;
  onAssignEnterpriseName: (n: string) => Promise<v2.Enterprise | null>;
  onCancelPlan: (
    subscription: v2.EnterpriseSubscription | v2.UserSubscription
  ) => void;
  companyText: string;
  hideExpiredProducts?: boolean;
  activeEnterprise: v2.Enterprise | null;
  setError: (error: string) => void;
}

interface SubscriptionDetails {
  perUnitAmount?: number;
  amount?: string;
  term?: v2.SubscriptionPricing['interval'];
  lastPaymentAmount?: string;
  lastPaymentDate?: string;
  subscriptionEndDate?: string;
}

const getDateString = (d: Date) =>
  `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;

function BillingProducts({
  subscriptions: unFilteredSubscriptions,
  products,
  isBillingAdmin,
  licenses,
  onAssignLicenses,
  onAssignEnterpriseName,
  onCancelPlan,
  activeEnterprise,
  companyText,
  setError,
  hideExpiredProducts = false
}: BillingProductsProps): JSX.Element {
  const { t } = useTranslation();
  const { getProductBySlug } = useProducts();

  function getSubscriptionDetails(
    subscription: v2.UserSubscription | v2.EnterpriseSubscription
  ): SubscriptionDetails {
    let subscriptionEndDateText = '';
    // `end_date` is only supported for offline subscriptions
    if (!subscription.stripe_id) {
      const subscriptionEndDate = subscription.end_date;
      subscriptionEndDateText =
        subscriptionEndDate && subscription.purchase_state === 'paid' // no need to display subscription end date if subscription is not in paid state
          ? getDateString(new Date(subscriptionEndDate))
          : '';
    }

    const price = subscription.pricing;
    const licenseCount =
      (subscription as v2.EnterpriseSubscription).license_count || 1;
    const perUnitAmount = price?.amount ? price.amount / 100 : undefined;
    const amount =
      formatPrices(perUnitAmount && perUnitAmount * licenseCount) || '';
    const term = price?.interval;
    const invoice = subscription.latest_invoice;
    let lastPaymentAmount = '';
    let lastPaymentDate = '';
    if (invoice) {
      lastPaymentAmount =
        formatPrices(invoice.amount && invoice.amount / 100) || '';
      lastPaymentDate =
        (invoice.created_at && getDateString(new Date(invoice.created_at))) ||
        '';
    }
    return {
      subscriptionEndDate: subscriptionEndDateText,
      amount,
      perUnitAmount,
      term,
      lastPaymentAmount,
      lastPaymentDate
    };
  }

  const subscriptions = (unFilteredSubscriptions as Subscription[]).filter(
    s =>
      s.purchase_state !== 'none' &&
      (!hideExpiredProducts ||
        !isSubscriptionExpired(s) ||
        getProductBySlug(s.product_slug)?.has_free_tier)
  );

  const showFooter =
    !hideExpiredProducts &&
    !!(unFilteredSubscriptions as Subscription[]).find(
      sub =>
        isSubscriptionExpired(sub) &&
        !getProductBySlug(sub.product_slug)?.has_free_tier
    );

  return (
    <Products
      heading={{
        id: 'products-heading',
        level: 2,
        text: t('Products')
      }}
      showFooter={showFooter}
    >
      {subscriptions && subscriptions.length > 0 ? (
        <>
          {subscriptions.map(
            (subscription: v2.EnterpriseSubscription | v2.UserSubscription) => {
              const product = products.find(
                p => p.id === subscription.product_id
              );

              const subscriptionDetails = getSubscriptionDetails(subscription);

              const { statusMessage, statusMessageVariant } = getStatusMessage({
                subscription: subscription,
                t
              });

              const productLicenses = licenses.find(
                l => l.product_id === subscription.product_id
              );

              return (
                <ProductCard
                  key={subscription.id}
                  title={product?.name || ''}
                  productSlug={(product?.slug || '') as ProductSlugs}
                  statusMessage={statusMessage}
                  statusMessageVariant={statusMessageVariant}
                >
                  <BillingProductCardContent
                    subscription={subscription}
                    perUnitAmount={subscriptionDetails.perUnitAmount}
                    billingAmount={subscriptionDetails.amount}
                    billingTerm={subscriptionDetails.term}
                    lastPaymentAmount={subscriptionDetails.lastPaymentAmount}
                    lastPaymentDate={subscriptionDetails.lastPaymentDate}
                    subscriptionEndDate={
                      subscriptionDetails.subscriptionEndDate
                    }
                    isBillingAdmin={isBillingAdmin}
                    totalLicenses={productLicenses?.total || 1}
                    licensesUsed={
                      (productLicenses?.used || 0) +
                      (productLicenses?.pending || 0)
                    }
                    onAssignLicenses={onAssignLicenses}
                    onAssignEnterpriseName={onAssignEnterpriseName}
                    onCancelPlan={() => onCancelPlan(subscription)}
                    canChangePlan={
                      product?.slug === ProductSlugs.axeDevToolsExtension ||
                      product?.slug === ProductSlugs.dequeUniversity
                    }
                    activeEnterprise={activeEnterprise}
                    companyText={companyText}
                    setError={setError}
                  />
                </ProductCard>
              );
            }
          )}
        </>
      ) : (
        <p>{t('You do not have any products')}</p>
      )}
    </Products>
  );
}

export default BillingProducts;
