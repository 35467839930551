import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(duration);

export { dayjs };
export const addDays = (start: Date, n: number): Date => {
  const result = new Date(start);
  const startDate = start.getDate();
  result.setDate(startDate + n);
  return result;
};

export const thirty_one_days_in_the_future = addDays(new Date(), 31);

export const getRelativeTime = (date: Date | null) => {
  return date
    ? dayjs(
        new Intl.DateTimeFormat('en', {
          dateStyle: 'short',
          timeStyle: 'short'
        }).format(date)
      ).fromNow()
    : null;
};
