import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import type { AxePurchaseState, v2 } from '@deque/billing-service-client';
import { Button, Icon } from '@deque/cauldron-react';
import type { EnterpriseInvitations } from '../../common/utils/billing-client/client-v2';
import styles from './AcceptInvitationForm.css';
import { useFeatureFlagState } from '../../common/contexts/featureFlags';
import { InvitationWithEnterprise } from '../containers/AcceptInvitationForm';
import { Notice } from '@deque/cauldron-react';
import { FREE_STATES, ProductSlugs } from '../../common/constants';

interface WarningProps {
  children: React.ReactNode;
}

const Warning: React.FC<WarningProps> = ({ children }) => {
  const count = React.Children.count(children);
  return (
    <div className={styles.warning}>
      {count === 1 ? <p>{children}</p> : children}
    </div>
  );
};

interface Props {
  invitation: EnterpriseInvitations.Invitation;
  enterprise: v2.Enterprise;
  products: v2.Product[];
  personalSubscriptions: v2.UserSubscription[];
  previousEnterprise: v2.Enterprise | null;
  onSubmit: () => void;
  onReject: () => void;
  otherInvitations: InvitationWithEnterprise[];
}

const AcceptInvitationForm: React.FC<Props> = ({
  invitation,
  enterprise,
  products,
  personalSubscriptions,
  previousEnterprise,
  onSubmit,
  onReject,
  otherInvitations
}) => {
  const { t } = useTranslation();

  const isDeclineInvitationEnabled = useFeatureFlagState('decline_invitations');

  const invitationProducts = products.filter(p =>
    invitation.product_slugs.includes(p.slug as ProductSlugs)
  );

  const handleSubmit = React.useCallback(
    (e: React.FormEvent): void => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  const { noticeTitleText, noticeTitleBody } = useMemo(() => {
    let noticeTitle = '';
    let noticeBody = '';
    if (personalSubscriptions.length && !otherInvitations.length) {
      // When there are only active subscriptions but no pending invitations
      noticeTitle = t('Active subscriptions');
      noticeBody = t(
        'Accepting this invitation will decline active subscriptions for the following accounts:'
      );
    } else if (!personalSubscriptions.length && otherInvitations.length) {
      // when there are no active subscriptions but pending invitations
      noticeTitle = t('Pending invitations');
      noticeBody = t(
        'Accepting this invitation will decline pending invitations for the following accounts:'
      );
    } else if (personalSubscriptions.length && otherInvitations.length) {
      noticeTitle = t('Active subscriptions and Pending invitations');
      noticeBody = t(
        'Accepting this invitation will decline active subscriptions and pending invitations for the following accounts:'
      );
    }

    return {
      noticeTitleText: noticeTitle,
      noticeTitleBody: noticeBody
    };
  }, [personalSubscriptions, otherInvitations, t]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <ul className={styles.productList}>
        {invitation.is_admin && (
          <li key="axe-account-admin-li">{t('axe Account administrator')}</li>
        )}
        {invitationProducts.map(p => (
          <li key={p.id}>{p.name}</li>
        ))}
      </ul>

      <Trans>
        <p>
          You were invited by{' '}
          <strong>{{ senderEmail: invitation.sender_email }}</strong> at{' '}
          <strong>{{ enterprise: enterprise.name }}</strong>.<br />
          Selecting “Accept Invitation” will grant you immediate access to your
          product(s) and associate your Deque account:
        </p>

        <div className={styles.email}>
          {{ invitationEmail: invitation.email }}
        </div>

        <p>
          with <strong>{{ enterprise: enterprise.name }}</strong>’s joint
          account.
        </p>
      </Trans>

      {/* XXX: when we support multiple enterprise memberships, this will need to change. */}
      {previousEnterprise && previousEnterprise.id !== enterprise.id ? (
        <Warning>
          <Trans>
            <strong>Note:</strong> {{ invitationEmail: invitation.email }} is
            associated with {{ previousEnterprise: previousEnterprise.name }}.
            Selecting “Accept Invitation” will move your account to{' '}
            {{ enterprise: enterprise.name }}.
          </Trans>
        </Warning>
      ) : null}
      {/* because we don't support coexistence of individual and enterprise subscriptions, once a user accepts an invite to an enterprise, we will remove all their individual subscriptions */}
      {noticeTitleText && noticeTitleBody && (
        <Notice type="danger" title={noticeTitleText}>
          <p>
            {noticeTitleBody}
            <ul>
              {personalSubscriptions.map(s => {
                const product = products.find(p => p.id === s.product_id);

                if (!product) {
                  return null;
                }

                return (
                  <li key={s.id}>
                    {product.name}{' '}
                    {s.purchase_state === 'trialing'
                      ? t(' (Trial)')
                      : FREE_STATES.includes(
                          s.purchase_state as AxePurchaseState
                        )
                      ? t('(Free)')
                      : otherInvitations.length
                      ? t('(active subscription)')
                      : ''}
                  </li>
                );
              })}
              {otherInvitations.map(i => {
                return <li key={i.id}>{i.enterprise.name}</li>;
              })}
            </ul>
          </p>
        </Notice>
      )}

      <Button type="submit">
        <Icon type="check-circle" />
        {t('Accept Invitation')}
      </Button>
      {isDeclineInvitationEnabled && (
        <Button variant="error" onClick={onReject}>
          <Icon type="close" />
          {t('Decline Invitation')}
        </Button>
      )}
    </form>
  );
};

export default AcceptInvitationForm;
