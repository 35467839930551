import React, { ReactElement, useMemo } from 'react';
import {
  Button,
  Alert,
  AlertContent,
  AlertActions
} from '@deque/cauldron-react';
import { useTranslation, Trans } from 'react-i18next';
import { useGlobalToast } from '../contexts/globalToast';
import Link from './Link';
import { ProductSlugs, type Subscription } from '@deque/billing-utils';
import PlanName from '../../app/components/PlanName';
import { useProducts } from '../contexts/products';

export interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  isPaidPlan?: boolean;
  subscription: Subscription;
}

const DowngradeDialog = ({
  show,
  onClose,
  onConfirm,
  isPaidPlan,
  subscription
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const { products } = useProducts();

  const productName = useMemo(() => {
    const product = products.find(p => p.slug === subscription.product_slug);
    return product?.name;
  }, [subscription, products]);

  const handleDowngradeClick = async () => {
    await onConfirm();
    if (!isPaidPlan) {
      return;
    }

    setContents(
      <Trans>
        Your <PlanName subscription={subscription} /> plan will be downgraded at
        the end of your current billing period.{' '}
        {subscription.product_slug === ProductSlugs.axeDevToolsExtension && (
          <>
            Please give us feedback to make {{ productName }} a better product
            by filling out this{' '}
            <Link
              name={t('downgrade survey')}
              openInNewTab={true}
              url="https://www.surveymonkey.com/r/JJ2KFBH"
            />
          </>
        )}
      </Trans>,
      'confirmation'
    );
  };

  return (
    <Alert
      heading={t('Downgrade')}
      onClose={onClose}
      show={show}
      variant="warning"
    >
      <AlertContent>
        {isPaidPlan ? (
          <Trans>
            Do you want to cancel your plan at the end of your current billing
            period?&nbsp; Your <PlanName subscription={subscription} /> plan
            will then <strong>revert to Free</strong> and you will{' '}
            <strong>lose access to all of your {{ productName }} data</strong>.
          </Trans>
        ) : (
          <Trans>
            Do you want to cancel your plan, effective immediately? Your&nbsp;
            <PlanName subscription={subscription} /> plan will{' '}
            <strong>revert to Free</strong> and you will{' '}
            <strong>lose access to all of your {{ productName }} data</strong>.
          </Trans>
        )}
      </AlertContent>
      <AlertActions>
        <Button variant="error" onClick={handleDowngradeClick}>
          {t('Downgrade')}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          {t('Cancel')}
        </Button>
      </AlertActions>
    </Alert>
  );
};

export default DowngradeDialog;
