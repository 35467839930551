import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import { Panel, PanelHeader, Stepper, Step } from '@deque/cauldron-react';
import PageTitle from './PageTitle';
import styles from './StepContainer.css';

export type StepStatus = 'current' | 'complete' | 'future';

export interface Step {
  status: StepStatus;
  label: string;
}

interface StepContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  heading?: React.ReactNode;
  steps: Step[];
  panelClassName?: string;
  stepperClassName?: string;
}

const StepContainer = ({
  className,
  panelClassName,
  stepperClassName,
  title,
  heading,
  steps,
  children,
  ...props
}: StepContainerProps): JSX.Element => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    /* istanbul ignore next */
    titleRef.current?.focus();
  }, [title]);

  return (
    <section className={classnames(styles.StepContainer, className)} {...props}>
      <h1 ref={titleRef} tabIndex={-1}>
        {title}
      </h1>
      <PageTitle title={title} />
      <Stepper className={stepperClassName}>
        {steps.map((step, index) => (
          <Step status={step.status} key={index}>
            {step.label}
          </Step>
        ))}
      </Stepper>
      <Panel className={panelClassName}>
        <PanelHeader>
          <h2>{heading}</h2>
        </PanelHeader>
        {children}
      </Panel>
    </section>
  );
};

export default StepContainer;
