import { Loader } from '@deque/cauldron-react';
import React, { FC, useCallback } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { EditFeatureFlagFields } from '../types';

import FeatureFlagProductSelect from './FeatureFlagProductSelect';
import FeatureFlagDescriptionField from './FeatureFlagDescriptionField';

type EditAdminFeatureFlagFormFieldsProps = {
  loading?: boolean;
  formControl: Control<EditFeatureFlagFields, unknown>;
};

const EditAdminFeatureFlagFormFields: FC<
  EditAdminFeatureFlagFormFieldsProps
> = ({ loading, formControl }) => {
  const { t } = useTranslation();
  const loaderRef = useCallback(
    (element: HTMLDivElement | null) => {
      if (loading && element) {
        element.focus();
      }
    },
    [loading]
  );

  return loading ? (
    <Loader label={t('Updating feature')} tabIndex={-1} ref={loaderRef} />
  ) : (
    <>
      <FeatureFlagProductSelect name="productName" control={formControl} />
      <FeatureFlagDescriptionField name="description" control={formControl} />
    </>
  );
};

export default EditAdminFeatureFlagFormFields;
