import type { ProductSlugs } from '@deque/billing-utils';
import {
  AccessTypes,
  type ProductAccess
} from '../../common/utils/billing-client/client-v2';
import type { v2 } from '@deque/billing-service-client';
import type { TFunction } from 'i18next';

export interface ChangeAccess {
  product: v2.Product;
  accessType: AccessTypes;
}

export type AccessChanges = { [key in ProductSlugs]?: ChangeAccess };

export interface UserChanges {
  newProducts: v2.Product[];
  removalProducts: v2.Product[];
  accessChanges: AccessChanges;
  isAdmin: boolean;
}

export const getCurrentAccess = (
  changes: Partial<UserChanges>,
  productSlug: ProductSlugs,
  currentAccess?: ProductAccess
) => {
  if (changes.accessChanges && changes.accessChanges[productSlug]) {
    // This line won't compile without the extra coalescing operators.
    // This is a known issue in the typescript compiler: https://github.com/microsoft/TypeScript/issues/10530
    return (
      changes.accessChanges?.[productSlug]?.accessType || AccessTypes.GENERAL
    );
  }
  return (currentAccess?.access_type as AccessTypes) || AccessTypes.GENERAL;
};

export const getAccessString = (t: TFunction, access_type?: string) =>
  access_type === AccessTypes.ADMIN ? t('Admin') : t('General Access');
