import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo
} from 'react';
import { Loader } from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import type {
  ProjectsAndIssueTypes,
  IssueType
} from '../../../../../../common/utils/integrations-client/jira/getProjectsAndIssueTypes';
import StepReview from './StepReview/StepReview';
import { getAxeFieldsOptions } from '../FieldMapping/FieldMapping';
import { capitalize } from '../../../../../../common/utils/capitalize';
import { FieldMappingField } from '../../TemplateWizardForm/TemplateWizardForm';
import useIssueTypeFields from '../../../../../hooks/useIssueTypeFields';
import { useGlobalToast } from '../../../../../../common/contexts/globalToast';

type ReviewProps = {
  isPriorityEnabled: boolean;
  setIsStepDataLoading: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  getProjectById: (id: string) => ProjectsAndIssueTypes | undefined;
  getIssueTypeById: (id: string) => IssueType | undefined;
  connectionId: string;
  token: string;
  setIsStepDataError: Dispatch<SetStateAction<boolean>>;
};

const Review = ({
  isPriorityEnabled,
  setIsStepDataLoading,
  setCurrentStep,
  getProjectById,
  getIssueTypeById,
  connectionId,
  token,
  setIsStepDataError
}: ReviewProps) => {
  const { t } = useTranslation();
  const { setContents } = useGlobalToast();
  const { getValues } = useFormContext();

  const projectId = getValues('project');
  const issueTypeId = getValues('issueType');
  const {
    data: fields,
    getPriorityById,
    loading,
    error
  } = useIssueTypeFields(projectId, issueTypeId, connectionId, token);

  useEffect(() => setIsStepDataLoading(loading), [loading]);

  useEffect(() => {
    setIsStepDataError(!!error);

    if (error) {
      setContents(
        t('There was an error fetching fields for this issue type.'),
        'error'
      );
    }
  }, [error]);

  const formValue = getValues();

  const axeFieldsOptions = useMemo(() => getAxeFieldsOptions(t), [t]);

  const fieldMappingFields = useMemo(
    () =>
      formValue.fieldMapping.map(
        ({ axeField, mappingField }: FieldMappingField) => ({
          name: axeFieldsOptions.find(({ value }) => axeField === value)?.label,
          value: fields?.find(({ fieldId }) => mappingField === fieldId)?.name
        })
      ),
    [formValue, fields, axeFieldsOptions]
  );

  const sections = [
    {
      title: t('Template Information'),
      fields: [
        {
          name: t('Template Name'),
          value: formValue.templateName
        },
        {
          name: t('Project Name'),
          value: getProjectById(formValue.project)?.name
        },
        {
          name: t('Issue Type'),
          value: getIssueTypeById(formValue.issueType)?.name
        }
      ],
      onEdit: () => setCurrentStep(0)
    },
    {
      title: t('Field Mapping'),
      fields: fieldMappingFields,
      onEdit: () => setCurrentStep(1)
    }
  ];

  if (isPriorityEnabled) {
    sections.push({
      title: t('Impact Mapping'),
      fields: Object.entries(formValue.impactMapping).map(([key, value]) => ({
        name: t('{{ impact }} Impact', { impact: capitalize(key) }),
        value: value
          ? getPriorityById(value as string)?.name
          : t('Nothing selected')
      })),
      onEdit: () => setCurrentStep(2)
    });
  }

  if (loading) {
    return <Loader label={t('Loading template step...')} />;
  }

  return (
    <div>
      {sections.map(section => (
        <StepReview {...section} key={section.title} />
      ))}
    </div>
  );
};

export default Review;
