import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  AlertActions,
  AlertContent,
  Button,
  TextField
} from '@deque/cauldron-react';
import { v2 } from '@deque/billing-service-client';

export interface DeleteTeamAlertProps {
  show: boolean;
  team: v2.Team | null;
  onDelete: () => void;
  onCancel: () => void;
}

const DeleteTeamAlert: React.FC<DeleteTeamAlertProps> = ({
  show,
  team,
  onDelete,
  onCancel
}: DeleteTeamAlertProps) => {
  const { t } = useTranslation();

  const [error, setError] = useState<string | undefined>();
  const fieldRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!team || !fieldRef.current) {
      return;
    }
    if (fieldRef.current.value !== team.name) {
      setError('Entered team name does not match');
      return;
    }
    onDelete();
  };

  return (
    <Alert variant="warning" show={show} heading={<>{t('Delete Team')}</>}>
      <form onSubmit={handleSubmit}>
        <AlertContent>
          <p>
            {t(
              'You\'re about to delete the team "{{team_name}}". This action is irreversible and users could lose access to scan entities.',
              { team_name: team?.name }
            )}
          </p>
          <p>{t('For more information, please visit our documentation.')}</p>
          <TextField
            fieldRef={fieldRef}
            label={t('Type "{{team_name}}" to confirm', {
              team_name: team?.name
            })}
            error={error}
            required
          />
        </AlertContent>
        <AlertActions>
          <Button type="submit" variant="error">
            {t('Delete')}
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </AlertActions>
      </form>
    </Alert>
  );
};

export default DeleteTeamAlert;
