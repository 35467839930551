import CrossProductAnalytics from '../common/CrossProductAnalytics';

export enum AmplitudeEvents {
  TODO = 'todo'
}

class Analytics extends CrossProductAnalytics<AmplitudeEvents> {
  public todo() {
    return this.sendAmplitudeEvent({
      event: AmplitudeEvents.TODO
    });
  }
}

export default Analytics;
