import React from 'react';
import billingClient from '../../common/utils/billing-client/client-v2';
import sendPlanChange from '../../common/utils/send-plan-change-to-extension';
import { analyticsEvent } from '../../common/api-client';
import { AuthUser } from '../../common/contexts/auth';
import { Subscription } from '@deque/billing-utils';
import { HubSpotProperties } from '../constants';
import { useAnalytics } from '../contexts/analytics';
import CrossProductAnalytics from '../CrossProductAnalytics';
import type {
  AnalyticsInstances,
  AnalyticsInstanceId
} from '../analyticsInstances';

export interface DowngradeData {
  subscription: Subscription;
  user: AuthUser;
}

export interface DowngradeState {
  loading: boolean;
  error: string;
  downgrade: (data: DowngradeData) => Promise<void>;
}

const useDowngrade = (): DowngradeState => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const analyticsInstances = useAnalytics();

  const downgrade = async ({ subscription, user }: DowngradeData) => {
    setLoading(true);
    const state = subscription.purchase_state;
    const now = new Date(Date.now());
    const analytics = analyticsInstances[
      subscription.product_slug as keyof AnalyticsInstances
    ] as CrossProductAnalytics<AnalyticsInstanceId>;

    try {
      await billingClient.subscriptions.cancel({
        token: user.token,
        subscription,
        periodEnd: state === 'paid'
      });

      if (state === 'paid') {
        // notify the extension that the user's plan has changed
        sendPlanChange();
      }

      try {
        // Send cancellation analytics.
        /* istanbul ignore else */
        if (state === 'paid' && subscription.current_period_end) {
          analytics.paidSubscriptionCancel({
            cancelDate: subscription.current_period_end
          });
          analyticsEvent(
            user.token,
            HubSpotProperties.AXE_PAID_DATE_END,
            new Date(subscription.current_period_end)
              .setUTCHours(0, 0, 0, 0)
              .toString()
          );
        } else if (
          state === 'paid_payment_failed' ||
          state === 'free_payment_failed'
        ) {
          analytics.paidSubscriptionCancel({
            cancelDate: now
          });
          analyticsEvent(
            user.token,
            HubSpotProperties.AXE_PAID_DATE_END,
            now.setUTCHours(0, 0, 0, 0).toString()
          );
        } else if (
          state === 'trial_payment_failed' ||
          state === 'trial_ended'
        ) {
          analytics.trialSubscriptionCancel();
        }
      } catch {
        /* fail silently */
      }
    } catch (err) {
      setError((err as Error).message || (err as string));
    } finally {
      setLoading(false);
    }

    return;
  };

  return {
    downgrade,
    loading,
    error
  };
};

export default useDowngrade;
