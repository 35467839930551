import React from 'react';
import dompurify from 'dompurify';
import styles from './Markdown.css';
import classNames from 'classnames';

interface Props {
  source: string;
  className?: string;
}

const HEADING_SELECTOR = 'h1, h2, h3, h4, h5, h6';

/**
 * Safely render the given markdown `source` as HTML.
 */

const Markdown: React.FC<Props> = ({ source, className }) => {
  dompurify.addHook('afterSanitizeAttributes', node => {
    if (node.getAttribute('target')) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }

    if (node.matches(HEADING_SELECTOR) && node instanceof HTMLHeadingElement) {
      node.tabIndex = -1;
    }
  });

  const html = React.useMemo(() => {
    const safe = dompurify.sanitize(source, { ADD_ATTR: ['target'] });
    return { __html: safe };
  }, [source]);

  return (
    <div
      className={classNames(className, styles.markdown)}
      dangerouslySetInnerHTML={html}
    />
  );
};

export default Markdown;
