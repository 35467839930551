import React, { useEffect } from 'react';
import { useGlobalToast } from '../contexts/globalToast';
import { useAuthContext } from '../contexts/auth';
import { useTranslation } from 'react-i18next';
import { Toast } from '@deque/cauldron-react';
import { Subscription } from '@deque/billing-utils';
import { Link, useLocation } from 'react-router-dom';
import usePaymentMethods from '../hooks/usePaymentMethods';
import { useEnterprises } from '../contexts/enterprises';
import styles from './GlobalToast.css';

const GlobalToast = (): JSX.Element | null => {
  const { setContents, contents, type } = useGlobalToast();
  const { t } = useTranslation();
  const { error, billingUser } = useAuthContext();
  const {
    activeEnterprise,
    paymentMethods: enterprisePaymentMethods,
    isAdmin: isEnterpriseAdmin
  } = useEnterprises();
  const {
    paymentMethods: userPaymentMethods,
    loading: loadingUserPaymentMethods,
    error: userPaymentMethodsError
  } = usePaymentMethods();
  const location = useLocation();

  let hasPaymentMethod = false;
  const subscriptions: Subscription[] = [];

  // Only enterprise admins should see the payment method toast.
  // An enterprise member doesn't have access to add a payment method, so don't bother them with the notification.
  if (activeEnterprise && isEnterpriseAdmin) {
    hasPaymentMethod = enterprisePaymentMethods.length > 0;
    subscriptions.push(...activeEnterprise.subscriptions);
  } else {
    hasPaymentMethod = userPaymentMethods.length > 0;
    subscriptions.push(...(billingUser?.subscriptions || []));
  }
  const hasOnlinePaidSubscription = subscriptions.some(
    subscription =>
      !!subscription.stripe_id &&
      ['paid', 'paid_payment_failed'].includes(subscription.purchase_state)
  );
  const paymentMethodMissing =
    hasOnlinePaidSubscription &&
    hasPaymentMethod === false &&
    loadingUserPaymentMethods === false &&
    !userPaymentMethodsError;
  const authError = !!error; // an authentication error occurred

  const clearToast = () => setContents(null);

  useEffect(() => {
    if (authError) {
      setContents(error, 'caution');
      return;
    }

    if (paymentMethodMissing) {
      setContents(
        <>
          {t('You do not have a valid payment method.')}
          {location.pathname !== '/addpayment' && (
            <>
              &nbsp;
              <Link to="/addpayment" className={styles.globalToast}>
                {t('Add payment method')}
              </Link>
            </>
          )}
        </>,
        'caution'
      );
    }
  }, [authError, error, location.pathname, paymentMethodMissing]);

  if (!contents) {
    return null;
  }

  return (
    <div className={styles.globalToastContent}>
      <Toast show={!!contents} type={type || 'info'} onDismiss={clearToast}>
        {contents}
      </Toast>
    </div>
  );
};

export default GlobalToast;
