import React, {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { z } from 'zod';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { type TFunction, useTranslation } from 'react-i18next';
import { Loader, Panel, PanelContent } from '@deque/cauldron-react';

import type { ProjectsAndIssueTypes } from '../../../../../common/utils/integrations-client/jira/getProjectsAndIssueTypes';
import {
  IMPACTS,
  type StepMap,
  type Impacts,
  type TemplateFormFields,
  type TemplateFormFieldsKeys
} from '../../../../types';
import {
  IntegrationTemplate,
  createIntegrationTemplate,
  updateIntegrationTemplate
} from '../../../../../common/api-client';
import analyticsInstances, {
  getFallbackAnalyticsInstanceId
} from '../../../../../common/analyticsInstances';
import TemplateWizardFooter from './FormFooter/FormFooter';
import useMediaQuery from '../../../../../common/hooks/useMediaQuery';
import { useGlobalToast } from '../../../../../common/contexts/globalToast';
import { SupportedIntegrationProductSlugs } from '../../../../../common/constants';
import getIntegrationProductName from '../../../../../common/utils/get-integration-product-name-from-slug';
import styles from './TemplateWizardForm.css';

export interface FieldMappingField {
  axeField: string;
  mappingField: string;
}

export interface TemplateWizardFormProps {
  initialTemplate?: IntegrationTemplate;
  integrationProductSlug: SupportedIntegrationProductSlugs;
  connectionId: string;
  token: string;
  enterpriseId: string;
  currentStep: number;
  steps: StepMap[];
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isStepDataLoading: boolean;
  templates: IntegrationTemplate[];
  refreshTemplates: () => void;
  projects: ProjectsAndIssueTypes[];
  isStepDataError: boolean;
}

export const defaultTemplateValues: TemplateFormFields = {
  templateName: '',
  project: '',
  issueType: '',
  fieldMapping: [],
  impactMapping: IMPACTS.reduce(
    (acc, impact) => ({ ...acc, [impact]: '' }),
    {} as Record<Impacts, string>
  ),
  customLabels: []
};

const mapTemplateDataForFrom = (
  template: IntegrationTemplate
): TemplateFormFields => {
  const { name, project_id, issue_type_id, field_mappings, impact_mappings } =
    template;

  const fieldMapping = field_mappings
    ? Object.entries(field_mappings).map(([key, value]) => ({
        axeField: value as string,
        mappingField: key
      }))
    : defaultTemplateValues.fieldMapping;

  const impactMapping = impact_mappings
    ? { ...defaultTemplateValues.impactMapping, ...impact_mappings }
    : defaultTemplateValues.impactMapping;

  return {
    ...defaultTemplateValues,
    templateName: name,
    project: project_id || '',
    issueType: issue_type_id || '',
    fieldMapping,
    impactMapping
  };
};

const getTemplateSchema = (t: TFunction) =>
  z.object({
    templateName: z
      .string({
        required_error: t('Template Name is required')
      })
      .min(1, {
        message: t('Template Name is required')
      }),
    project: z
      .string({
        required_error: t('Project is required')
      })
      .min(1, {
        message: t('Project is required')
      }),
    issueType: z
      .string({
        required_error: t('Issue Type is required')
      })
      .min(1, {
        message: t('Issue Type is required')
      }),
    fieldMapping: z.array(
      z.object({
        axeField: z.string().optional(),
        mappingField: z.string().optional()
      })
    ),
    impactMapping: z.record(
      z.enum(IMPACTS as [string, ...string[]]),
      z.string().optional()
    )
    //for future implementation, out of scope for now
    // customLabels: z.array(z.record(z.string(), z.string()))
  });

const TemplateWizardForm = ({
  initialTemplate,
  integrationProductSlug,
  connectionId,
  token,
  enterpriseId,
  currentStep,
  steps,
  setCurrentStep,
  templates,
  isStepDataLoading,
  refreshTemplates,
  projects,
  isStepDataError
}: TemplateWizardFormProps) => {
  const { t } = useTranslation();
  const analytics =
    analyticsInstances[getFallbackAnalyticsInstanceId(integrationProductSlug)];
  const narrow = useMediaQuery('(max-width: 37.5rem)');
  const { setContents } = useGlobalToast();
  const history = useHistory();

  const formRef = useRef<HTMLFormElement>(null);

  const [isLoading, setIsLoading] = useState(false);

  const templateSchema = useMemo(() => getTemplateSchema(t), [t]);

  const methods = useForm({
    resolver: zodResolver(templateSchema),
    defaultValues: defaultTemplateValues
  });
  const { handleSubmit, getValues, reset, formState, setFocus } = methods;

  const focusFirstFocusableElement = () => {
    const form = formRef.current;
    if (form) {
      const focusableElements = Array.from(
        form.querySelectorAll(
          'input:not([type="hidden"]):not([disabled]), select, textarea, button, a'
        )
      ) as HTMLElement[];

      if (focusableElements.length > 0) {
        focusableElements[0].focus();
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      focusFirstFocusableElement();
    }
  }, [isLoading, isStepDataLoading]);

  useEffect(() => {
    if (!initialTemplate) {
      return;
    }

    const initialValues = mapTemplateDataForFrom(initialTemplate);
    reset(initialValues);
  }, [initialTemplate]);

  const mapFieldMappingFormValues = useCallback(() => {
    const formFieldMapping = getValues('fieldMapping');

    return formFieldMapping.reduce((result, item) => {
      result[item.mappingField] = item.axeField;
      return result;
    }, {} as Record<string, string>);
  }, []);

  useEffect(() => {
    const { errors } = formState;

    if (!Object.keys(errors).length) {
      return;
    }

    const formFields = Object.keys(
      defaultTemplateValues
    ) as TemplateFormFieldsKeys[];

    const firstErrorFieldName = formFields.find(
      field => errors[field]
    ) as TemplateFormFieldsKeys;
    const firstError = errors[firstErrorFieldName];

    // for fieldMapping field
    if (!Array.isArray(firstError)) {
      setFocus(firstErrorFieldName);
      return;
    }

    const keyOfFirstErrorArrayItem =
      `${firstErrorFieldName}[${firstError.findIndex(
        item => !!item
      )}]` as TemplateFormFieldsKeys;
    const values = getValues(keyOfFirstErrorArrayItem);

    const result = Object.entries(values).find(([, value]) => !value);
    setFocus(
      `${keyOfFirstErrorArrayItem}.${result?.[0]}` as TemplateFormFieldsKeys
    );
  }, [formState, setFocus]);

  const onSubmit = async () => {
    setIsLoading(true);

    const fieldMapping = mapFieldMappingFormValues();

    const formValues = getValues();
    const { templateName, issueType, project, impactMapping } = formValues;

    // filter out empty keys and values
    const filteredFieldMapping = Object.entries(fieldMapping).reduce(
      (result, [key, value]) => {
        if (key && value) {
          result[key] = value;
        }
        return result;
      },
      {} as Record<string, string>
    );

    const templateData = {
      name: templateName,
      issue_type_id: issueType,
      project_id: project,
      field_mappings: filteredFieldMapping,
      impact_mappings: impactMapping
    };

    const integration = getIntegrationProductName(integrationProductSlug, {
      capitalize: true
    });
    const projectEntity = projects.find(p => p.id === project);
    const projectName = projectEntity?.name || '';
    const issueTypeName =
      projectEntity?.issueTypes?.find(i => i.id === issueType)?.name || '';

    try {
      const result = initialTemplate
        ? await updateIntegrationTemplate(
            token,
            enterpriseId,
            connectionId,
            initialTemplate.id,
            templateData
          )
        : await createIntegrationTemplate(token, enterpriseId, connectionId, {
            ...templateData,
            product_slug: integrationProductSlug
          });

      refreshTemplates();

      if (initialTemplate) {
        analytics.integrationTemplateEditSave({
          integration: integrationProductSlug,
          project: projectName,
          issueType: issueTypeName,
          template: templateName,
          connectionId: connectionId,
          enterpriseId
        });
      } else {
        analytics.integrationTemplateAddSave({
          integration: integrationProductSlug,
          project: projectName,
          issueType: issueTypeName,
          template: templateName,
          connectionId: connectionId,
          enterpriseId
        });
      }

      const toastMessage = t(
        'Successfully {{ appliedAction }} {{ integration }} Template "{{ templateName }}"',
        {
          appliedAction: initialTemplate ? 'updated' : 'added',
          integration,
          templateName: result.name
        }
      );

      setContents(toastMessage, 'confirmation');
      setIsLoading(false);

      return history.push(
        `/configuration/integrations/${connectionId}/templates/`
      );
    } catch (error) {
      if (initialTemplate) {
        analytics.integrationTemplateEditFail({
          integration: integrationProductSlug,
          project: projectName,
          issueType: issueTypeName,
          template: templateName,
          connectionId: connectionId,
          enterpriseId
        });
      } else {
        analytics.integrationTemplateAddFail({
          integration: integrationProductSlug,
          project: projectName,
          issueType: issueTypeName,
          template: templateName,
          connectionId: connectionId,
          enterpriseId
        });
      }
      setIsLoading(false);

      const toastMessage = t(
        'Unable to {{ appliedAction }} a {{ integration }} Template "{{ templateName }}"',
        {
          appliedAction: initialTemplate ? 'update' : 'create',
          integration,
          templateName
        }
      );
      setContents(toastMessage, 'error');
    }
  };

  if (isLoading) {
    const loaderLabel = initialTemplate
      ? t('Updating template...')
      : t('Creating template...');

    return <Loader label={loaderLabel} />;
  }
  return (
    <FormProvider {...methods}>
      <form
        className={styles.wrap}
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
      >
        <Panel padding={false} className={styles.container}>
          <PanelContent
            className={classNames(
              styles.footerContentContainer,
              narrow ? styles.mobilePanelContent : undefined
            )}
          >
            <div className={styles.headerContainer}>
              <h1 className={styles.header}>{steps[currentStep].header}</h1>
              {steps[currentStep].description && (
                <p className={styles.description}>
                  {steps[currentStep].description}
                </p>
              )}
            </div>
            {steps[currentStep].content}
          </PanelContent>
          <TemplateWizardFooter
            integrationProductSlug={integrationProductSlug}
            connectionId={connectionId}
            handleSubmit={handleSubmit(onSubmit)}
            narrow={narrow}
            isEditMode={!!initialTemplate}
            steps={steps}
            currentStep={currentStep}
            isStepDataLoading={isStepDataLoading}
            isStepDataError={isStepDataError}
            setCurrentStep={setCurrentStep}
            templates={templates}
            projects={projects}
            enterpriseId={enterpriseId}
          />
        </Panel>
      </form>
    </FormProvider>
  );
};

export default TemplateWizardForm;
