import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Impact } from '../../constants';

interface ImpactLabelProps {
  impact: Impact;
  totalIssues?: number;
}

const ImpactLabel = ({ impact, totalIssues }: ImpactLabelProps) => {
  const { t } = useTranslation();

  switch (impact) {
    case 'critical':
      return (
        <>
          {totalIssues
            ? t('Critical ({{n}})', { n: totalIssues })
            : t('Critical')}
        </>
      );

    case 'serious':
      return (
        <>
          {totalIssues
            ? t('Serious ({{n}})', { n: totalIssues })
            : t('Serious')}
        </>
      );

    case 'moderate':
      return (
        <>
          {totalIssues
            ? t('Moderate ({{n}})', { n: totalIssues })
            : t('Moderate')}
        </>
      );

    case 'minor':
      return (
        <>{totalIssues ? t('Minor ({{n}})', { n: totalIssues }) : t('Minor')}</>
      );

    case 'new_issues':
      return (
        <>
          {totalIssues
            ? t('New Issues ({{n}})', { n: totalIssues })
            : t('New Issues')}
        </>
      );

    default:
      throw new Error(`Invalid impact: ${impact}`);
  }
};

export default ImpactLabel;
