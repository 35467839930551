import { TFunction } from 'react-i18next';
import { v2 } from '@deque/billing-service-client';
import { Subscription } from '@deque/billing-utils';

import getExpiryDate from './get-expiry-date';

interface getStatusMessageProps {
  subscription: Subscription;
  t: TFunction<'translation', undefined>;
  activeEnterprise?: v2.Enterprise | null;
  isAdmin?: boolean;
}

const getStatusMessage = ({
  subscription,
  t,
  activeEnterprise,
  isAdmin
}: getStatusMessageProps) => {
  const purchaseState = subscription.purchase_state;

  let statusMessage = ''; // If purchase state is none
  let statusMessageVariant: 'success' | 'alert' | 'warn' = 'warn';

  if (activeEnterprise && !isAdmin && purchaseState === 'paid') {
    statusMessage = t('Active');
    statusMessageVariant = 'success';
  } else if (
    purchaseState === 'paid' &&
    (subscription.cancel_at ||
      (subscription.end_date && !subscription.stripe_id))
  ) {
    statusMessage = t('Active • Expires {{expiry}}', {
      expiry: getExpiryDate(
        new Date(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          subscription.cancel_at || subscription.end_date!
        )
      )
    });
  } else if (purchaseState === 'paid' && subscription.current_period_end) {
    statusMessage = t('Active • Renews {{expiry}}', {
      expiry: getExpiryDate(new Date(subscription.current_period_end))
    });
    statusMessageVariant = 'success';
  } else if (purchaseState === 'trialing' && subscription.trial_expires) {
    statusMessage = t('Trial Active • Ends {{expiry}}', {
      expiry: getExpiryDate(new Date(subscription.trial_expires))
    });
    statusMessageVariant = 'success';
  } else if (
    [
      'trial_payment_failed',
      'paid_payment_failed',
      'free_payment_failed'
    ].includes(purchaseState)
  ) {
    statusMessage = t('Payment Failed');
    statusMessageVariant = 'alert';
  } else if (purchaseState === 'free') {
    statusMessage = t('Active');
    statusMessageVariant = 'success';
  } else if (purchaseState === 'trial_ended') {
    statusMessage = t('Trial Expired');
    statusMessageVariant = 'alert';
  }

  return {
    statusMessage,
    statusMessageVariant
  };
};

export default getStatusMessage;
