import {
  Button,
  PanelContent,
  Checkbox,
  FieldWrap,
  Link,
  Select,
  SelectOption
} from '@deque/cauldron-react';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import type { v2 } from '@deque/billing-service-client';
import {
  AccessTypes,
  EnterpriseMembers,
  Enterprises,
  ProductAccess
} from '../../common/utils/billing-client/client-v2';
import {
  productHasAccessLevelManagement,
  ProductSlugs
} from '../../common/constants';
import {
  getAccessString,
  getCurrentAccess,
  UserChanges
} from '../utils/access-utils';
import styles from './EditUser.css';

interface GetAccessCount {
  currentCount: number;
  hasAccess: boolean;
  willHaveAccess: boolean;
}

const getAccessCount = ({
  currentCount,
  hasAccess,
  willHaveAccess: updatedAccess
}: GetAccessCount) =>
  hasAccess && !updatedAccess
    ? currentCount - 1
    : !hasAccess && updatedAccess
    ? currentCount + 1
    : currentCount;

interface EditUserProps {
  member: EnterpriseMembers.AcceptedMember;
  members: EnterpriseMembers.Member[];
  products: v2.Product[];
  licenses: Enterprises.License[];
  userChanges: UserChanges;
  activeUser: v2.User;
  onAccessChange: (userChanges: UserChanges) => void;
  onSubmit: () => void;
}

export default function EditUser({
  member,
  members,
  products,
  licenses,
  userChanges,
  activeUser,
  onAccessChange,
  onSubmit
}: EditUserProps): JSX.Element {
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const accessOptions: SelectOption[] = [
    {
      key: AccessTypes.GENERAL,
      value: AccessTypes.GENERAL,
      label: t('General Access')
    },
    {
      key: AccessTypes.ADMIN,
      value: AccessTypes.ADMIN,
      label: t('Admin')
    }
  ];

  const adminCount = members.filter(m => m.is_admin).length;

  const grantProductAccess = (product: v2.Product) => {
    onAccessChange({
      ...userChanges,
      removalProducts: userChanges.removalProducts.filter(
        p => p.slug !== product.slug
      ),
      newProducts: member.product_access.some(
        m => m.product_slug === product.slug
      )
        ? userChanges.newProducts
        : [...userChanges.newProducts, product]
    });
  };

  const removeProductAccess = (product: v2.Product) => {
    onAccessChange({
      ...userChanges,
      newProducts: userChanges.newProducts.filter(p => p.slug !== product.slug),
      removalProducts: member.product_access.some(
        m => m.product_slug === product.slug
      )
        ? [...userChanges.removalProducts, product]
        : userChanges.removalProducts
    });
  };

  const setProductAccessType = (
    product: v2.Product,
    newAccess: AccessTypes,
    currentProductAccess?: ProductAccess
  ) => {
    let accessChanges = userChanges.accessChanges;
    if (newAccess === currentProductAccess?.access_type) {
      delete accessChanges[product.slug as ProductSlugs];
    } else {
      accessChanges = {
        ...accessChanges,
        [product.slug]: {
          product,
          accessType: newAccess
        }
      };
    }
    onAccessChange({
      ...userChanges,
      accessChanges
    });
  };

  return (
    <>
      <PanelContent>
        <form id="edit-user-form" onSubmit={handleSubmit}>
          <h3>
            {t("Edit {{name}}'s access", {
              name: `${member.first_name} ${member.last_name}`
            })}
          </h3>
          <ul className={styles.accessList}>
            {/* Render a product access tile for each product */}
            {licenses.map(license => {
              const product = products.find(p => p.id === license.product_id);

              if (!product) {
                return null;
              }

              const memberAccess = member.product_access.find(
                access => access.product_slug === product.slug
              );
              const memberHasAccess = memberAccess !== undefined;
              const memberWillHaveAccess =
                (memberHasAccess ||
                  userChanges.newProducts.some(p => p.slug === product.slug)) &&
                !userChanges.removalProducts.some(p => p.slug === product.slug);
              const currentUserCount = license.pending + license.used;
              const accessCheckboxEnabled =
                license.total === null ||
                currentUserCount < license.total ||
                memberHasAccess;

              const label = t('{{productName}} permission: ', {
                productName: product.name
              });
              const currentAccess = getCurrentAccess(
                userChanges,
                product.slug as ProductSlugs,
                memberAccess
              );

              return (
                <li
                  key={product.id}
                  aria-labelledby={`product_access_checkbox_${product.id}`}
                >
                  <FieldWrap>
                    <div className={styles.accessWrapper}>
                      <Checkbox
                        id={`product_access_checkbox_${product.id}`}
                        label={product.name}
                        disabled={!accessCheckboxEnabled}
                        checked={memberWillHaveAccess}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.checked) {
                            grantProductAccess(product);
                          } else {
                            removeProductAccess(product);
                          }
                        }}
                      />
                      <div className={styles.userCount} role="status">
                        <span>{t('users')}</span>
                        {getAccessCount({
                          currentCount: currentUserCount,
                          hasAccess: memberHasAccess,
                          willHaveAccess: memberWillHaveAccess
                        })}
                        {license.total !== null && `/${license.total}`}
                      </div>
                    </div>
                    <div className={styles.permissionLevel}>
                      {productHasAccessLevelManagement(
                        product.slug as ProductSlugs
                      ) ? (
                        <Select
                          label={label}
                          options={accessOptions}
                          value={currentAccess}
                          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            const newAccessType = e.target.value as AccessTypes;
                            setProductAccessType(
                              product,
                              newAccessType,
                              memberAccess
                            );
                          }}
                        />
                      ) : (
                        <>
                          <span className={styles.permissionLevelLabel}>
                            {label}
                          </span>
                          {getAccessString(t, currentAccess)}
                        </>
                      )}
                    </div>
                  </FieldWrap>
                </li>
              );
            })}
            {/* Render an account access tile for the axe Account */}
            <li aria-labelledby="axe_account_access_checkbox">
              <FieldWrap>
                <div className={styles.accessWrapper}>
                  <Checkbox
                    id="axe_account_access_checkbox"
                    label={t('axe Account')}
                    disabled
                    checked
                  />
                  <div className={styles.userCount} role="status">
                    <span>{t('admins')}</span>
                    {getAccessCount({
                      currentCount: adminCount,
                      hasAccess: member.is_admin,
                      willHaveAccess: userChanges.isAdmin
                    })}
                  </div>
                </div>
                <div className={styles.permissionLevel}>
                  <Select
                    label={t('axe Account permission:')}
                    options={accessOptions}
                    value={
                      userChanges.isAdmin
                        ? AccessTypes.ADMIN
                        : AccessTypes.GENERAL
                    }
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      onAccessChange({
                        ...userChanges,
                        isAdmin: e.target.value === AccessTypes.ADMIN
                      });
                    }}
                    disabled={activeUser.keycloak_id === member.user_id}
                  />
                </div>
              </FieldWrap>
            </li>
          </ul>
        </form>
      </PanelContent>
      <PanelContent>
        <Link variant="button-secondary" href="/user-access">
          {t('Cancel')}
        </Link>
        <Button
          type="submit"
          form="edit-user-form"
          variant="primary"
          onClick={handleSubmit}
        >
          {t('Next')}
        </Button>
      </PanelContent>
    </>
  );
}
