import React from 'react';
import NotFoundPage from '../pages/NotFound';
import {
  useFeatureFlagState,
  useFeatureFlagLoading
} from '../contexts/featureFlags';
import { Loader } from '@deque/cauldron-react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../contexts/auth';

// Exported for testing.
export interface Props {
  featureFlagId: string;
  children: React.ReactNode;
  off?: React.ReactNode;
}

/**
 * Block rendering the provided `children` unless the user has a feature flag enabled.
 *
 * Will render the default 404 page unless an `off` component is provided.
 */

const FeatureRequired: React.FC<Props> = ({
  children,
  featureFlagId,
  off = <NotFoundPage />
}) => {
  const { t } = useTranslation();
  const state = useFeatureFlagState(featureFlagId);
  const isLoading = useFeatureFlagLoading();
  const { loading: userLoading } = useAuthContext();
  const loaderRef = React.createRef<HTMLDivElement>();
  React.useEffect(() => {
    loaderRef.current?.focus();
  }, [loaderRef.current]);

  if (isLoading || userLoading) {
    return <Loader label={t('Loading')} ref={loaderRef} tabIndex={-1} />;
  }
  return <>{state ? children : off}</>;
};

export default FeatureRequired;
