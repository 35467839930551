import {
  Button,
  Modal,
  ModalContent,
  ModalFooter
} from '@deque/cauldron-react';
import React from 'react';
import {
  type AdminFeatureFlag,
  updateAdminFeatureFlag
} from '../../common/api-client';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../common/contexts/auth';
import { useForm } from 'react-hook-form';
import type { EditFeatureFlagFields } from '../types';

import ErrorMessage from '../components/issue/ErrorMessage';
import type { BaseModalProps } from '../../common/types';
import EditAdminFeatureFlagFormFields from '../components/EditAdminFeatureFlagFormFields';

type EditModalProps = {
  feature: AdminFeatureFlag | null;
} & BaseModalProps;

const EditAdminFeatureFlagModal: React.FC<EditModalProps> = ({
  feature,
  show,
  onClose
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = useAuthContext().user!;
  const [loading, setLoading] = React.useState(false);
  const { handleSubmit, reset, control } = useForm<EditFeatureFlagFields>({
    mode: 'onChange',
    defaultValues: {
      defaultState: false
    }
  });
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    reset({
      featureId: feature?.id,
      description: feature?.description,
      defaultState: feature?.default_state,
      productName: feature?.product_name
    });
  }, [feature]);

  const editFeature = handleSubmit(async formState => {
    if (!feature) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await updateAdminFeatureFlag({
        token: user.token,
        ...formState
      });
      // Ideally we'd re-fetch the fetch the features, but this works too ¯\_(ツ)_/¯
      window.location.reload();
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal
      show={show}
      heading={
        <span>
          {t('Edit`{{featureId}}` feature', {
            featureId: feature?.id
          })}
        </span>
      }
      onClose={onClose}
    >
      <form onSubmit={editFeature}>
        <ModalContent>
          {error ? (
            <ErrorMessage error={error} title={t('Unable to create feature')} />
          ) : null}
          <EditAdminFeatureFlagFormFields
            formControl={control}
            loading={loading}
          />
        </ModalContent>
        <ModalFooter>
          <Button type="submit" disabled={!feature}>
            {t('Save')}
          </Button>
          <Button variant="secondary" type="button" onClick={onClose}>
            {t('Cancel')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditAdminFeatureFlagModal;
