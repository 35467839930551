import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../common/contexts/auth';
import { useForm } from 'react-hook-form';
import type { CreateFeatureFlagFields } from '../types';
import type { BaseModalProps } from '../../common/types';
import { createAdminFeatureFlag } from '../../common/api-client';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter
} from '@deque/cauldron-react';
import ErrorMessage from '../components/issue/ErrorMessage';
import CreateFeatureFlagFormFields from '../components/CreateFeatureFlagFormFields';

const CreateAdminFeatureModal: FC<BaseModalProps> = ({ show, onClose }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = useAuthContext().user!;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const { control, handleSubmit } = useForm<CreateFeatureFlagFields>({
    mode: 'onChange',
    defaultValues: {
      defaultState: false
    }
  });

  const createNewFeatureFlag = handleSubmit(async formState => {
    const { defaultState, description, productName, featureId } = formState;
    setLoading(true);
    setError(null);
    try {
      await createAdminFeatureFlag({
        token: user.token,
        featureId,
        defaultState,
        description,
        productName
      });
      // Ideally we'd re-fetch the fetch the features, but this works too ¯\_(ツ)_/¯
      window.location.reload();
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal
      show={show}
      heading={<span>{t('Create feature')}</span>}
      onClose={onClose}
    >
      <form onSubmit={createNewFeatureFlag}>
        <ModalContent>
          {error ? (
            <ErrorMessage error={error} title={t('Unable to create feature')} />
          ) : null}
          <CreateFeatureFlagFormFields
            formControl={control}
            loading={loading}
          />
        </ModalContent>
        <ModalFooter>
          <Button type="submit">{t('Create')}</Button>
          <Button variant="secondary" type="button" onClick={onClose}>
            {t('Cancel')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateAdminFeatureModal;
